import { fetchRecentlyViewedEntities } from "api/recentlyViewedEntity.api";
import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { CANCELLED_MESSAGE } from "helpers/userActions/userPageActions";
import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  RecentlyViewedEntitiesActionTypes,
  RecentlyViewedEntitiesActions,
} from "./recentlyViewedEntities-actions";

export function* watchFetchRecentlyViewedEntities(): SagaIterator {
  yield takeEvery(
    RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES,
    function* () {
      try {
        let recentlyViewedEntities: PagedListDto<EntityDto> = yield call(
          fetchRecentlyViewedEntities
        );
        yield put(
          RecentlyViewedEntitiesActions.fetchRecentlyViewedEntitiesSuccess(
            recentlyViewedEntities
          )
        );
      } catch (e: any) {
        if (e.message === CANCELLED_MESSAGE) return;
        yield put(
          RecentlyViewedEntitiesActions.fetchRecentlyViewedEntitiesFailure(e)
        );
      }
    }
  );
}

export default function* RecentlyViewedEntitySaga(): SagaIterator {
  yield all([fork(watchFetchRecentlyViewedEntities)]);
}
