import produce from "immer";
import { FetchOrderAction, FetchOrderHistoryAction, FetchOrderHistorySuccessAction, FetchOrderSuccessAction, FetchOrderSummariesSuccessAction } from "./types/order-actions-types";
import { OrderState } from "./types/order-state";
import { orderActionTypes } from "./order-actions";
import { FetchStatus } from "common-types/fetchStatusEnum";

const initialState: OrderState = {
    entityId: 0,
    orderId: 0,
    orderFetchStatus: undefined,
    ordersFetchStatus: undefined,
    order: undefined,
    orders: undefined,
    ordersOverview: [],
    orderOverviewFetchStatus: FetchStatus.Default
};

type Action = (FetchOrderAction & FetchOrderSuccessAction & FetchOrderHistoryAction & FetchOrderHistorySuccessAction & FetchOrderSummariesSuccessAction) | undefined;

export default (
    state = initialState,
    action: Action = undefined
): OrderState =>
    produce(state, (draft) => {
        switch (action?.type) {
            case orderActionTypes.FETCH_ORDER: {
                const { entityId, orderId } = action;
                draft.entityId = entityId;
                draft.orderId = orderId;
                draft.orderFetchStatus = FetchStatus.InProgress;
                draft.order = undefined;
                return;
            }
            case orderActionTypes.FETCH_ORDER_SUCCESS:
            case orderActionTypes.CANCEL_ORDER_SUCCESS:
            case orderActionTypes.COMPLETE_ORDER_SUCCESS: {
                const { order } = action;
                draft.order = order;
                draft.orderFetchStatus = order ? FetchStatus.Complete : FetchStatus.NoContent;
                return;
            }
            case orderActionTypes.FETCH_ORDER_FAILURE:
            case orderActionTypes.CANCEL_ORDER_FAILURE:
            case orderActionTypes.COMPLETE_ORDER_FAILURE: {
                draft.order = undefined;
                draft.orderFetchStatus = FetchStatus.NotFound;
                return;
            }
            case orderActionTypes.CANCEL_ORDER: {
                return;
            }

            case orderActionTypes.COMPLETE_ORDER: {
                return;
            }

            case orderActionTypes.FETCH_ORDER_HISTORY: {
                const { entityId } = action;
                draft.entityId = entityId;
                draft.orders = undefined;
                draft.ordersFetchStatus = FetchStatus.InProgress;
                return;
            }
            case orderActionTypes.FETCH_ORDER_HISTORY_SUCCESS: {
                const { orders } = action;
                draft.orders = orders;
                draft.ordersFetchStatus = orders && orders.length > 0 ? FetchStatus.Complete : FetchStatus.NoContent;
                return;
            }
            case orderActionTypes.FETCH_ORDER_HISTORY_FAILURE: {
                draft.orders = [];
                draft.ordersFetchStatus = FetchStatus.NotFound;
                return;
            }
            case orderActionTypes.FETCH_ORDER_SUMMARIES: {
                draft.orderOverviewFetchStatus = FetchStatus.InProgress;
                return;
            }
            case orderActionTypes.FETCH_ORDER_SUMMARIES_SUCCESS: {
                const { orderSummaries } = action;
                draft.ordersOverview = orderSummaries;
                draft.orderOverviewFetchStatus = FetchStatus.Complete;
                return;
            }
            case orderActionTypes.FETCH_ORDER_SUMMARIES_FAILURE: {
                draft.orderOverviewFetchStatus = FetchStatus.Error;
                return;
            }
            default:
                return state;
        }
    });
