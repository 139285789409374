import { FetchStatus } from "common-types/fetchStatusEnum";
import produce from "immer";
import { EntityActionTypes } from "./entity-actions";
import {
  ClearCurrentEntityAction,
  DownloadEntityDocumentAction,
  EntityDetailAction,
  FetchEntityAction,
  FetchEntitySuccessAction,
  ShowEntityDetailAction,
} from "./types/entity-actions-types";
import { ActiveEntityState, EntityDetailState } from "./types/entity-state";

const initialState: ActiveEntityState = {
  entityId: 0,
  fetchStatus: undefined,
  activeEntity: undefined,
};

export default (
  state = initialState,
  action: FetchEntitySuccessAction &
    FetchEntityAction &
    ClearCurrentEntityAction &
    DownloadEntityDocumentAction
): ActiveEntityState =>
  produce(state, (draft) => {
    switch (action.type) {
      case EntityActionTypes.FETCH_ENTITY: {
        const { entityId } = action;
        draft.entityId = entityId;
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case EntityActionTypes.FETCH_ENTITY_SUCCESS: {
        const { entity } = action;
        draft.activeEntity = entity;
        draft.fetchStatus = entity
          ? FetchStatus.Complete
          : FetchStatus.NoContent;
        return;
      }
      case EntityActionTypes.FETCH_ENTITY_FAILURE: {
        draft.activeEntity = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      case EntityActionTypes.CLEAR_CURRENT_ENTITY: {
        draft.activeEntity = undefined;
        draft.entityId = 0;
        draft.fetchStatus = undefined;
        return;
      }
      default:
        return state;
    }
  });

const initialDetailState: EntityDetailState = {
  entityDetail: undefined,
};

export const entityDetailReducer = (
  state = initialDetailState,
  action: (ShowEntityDetailAction & EntityDetailAction) | undefined = undefined
): EntityDetailState =>
  produce(state, (draft) => {
    switch (action?.type) {
      case EntityActionTypes.SHOW_ENTITY_DETAIL: {
        return;
      }
      case EntityActionTypes.FETCH_ENTITY_DETAIL_SUCCESS: {
        const { entityDetail } = action;

        draft.entityDetail = entityDetail;
        return;
      }
      default:
        return state;
    }
  });
