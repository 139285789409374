import { SECURITYINTERESTS_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { SecurityInterestsOverLlcInterestsDto } from "../common-types/securityInterestsOverLlcInterestsDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchSecurityInterests";

export const fetchSecurityInterests = async(request: EntityPageRequest): Promise<PagedListDto<SecurityInterestsOverLlcInterestsDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${SECURITYINTERESTS_ENDPOINT}${EntityPageRequestToQueryString(request)}`,
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchSecurityInterests = (): void => tryCancelPending(signalKey);
