import { useEffect, useState } from "react";
import { KirbySection, KirbyTableServer } from "maples-kirby-react";
import styles from "./reports.module.scss";
import { downloadReport } from "api/report-api";
import { addToast, updateToast } from "components/common/Toaster";
import { data, columns } from "./reportsGrid";
import { genericErrorMessage } from "helpers/apiCaller/constants";
import { Actions, hasAction } from '../../helpers/userActions';
import AccessDenied from '../Pages/Errors/accessDenied';

const Reports = (props: any) => {
  const [disabledReports, setDisabledReports] = useState<Array<string>>([]);

  useEffect(() => {
    document.title = "Reporting";
  });

  const downloadClick = async (reportName: string) => {
    if (disabledReports.find((a) => a === reportName)) return;

    let disabledItems = disabledReports;
    disabledItems.push(reportName);
    setDisabledReports(disabledItems);

    const toastId = await addToast(
      `Downloading...`,
      `Downloading '${reportName}'.`,
      "spinner"
    );
    downloadReport(reportName).then((response) => {
      if (response.success) {
        updateToast(toastId, `Download Complete`, reportName, "success");
      } else {
        updateToast(
          toastId,
          `Download Failed: ${reportName}`,
          response.errors ? response.errors[0] : genericErrorMessage,
          "error"
        );
      }
      disabledItems = disabledReports;
      disabledItems.splice(disabledReports.indexOf(reportName), 1);
      setDisabledReports(disabledItems);
    });
  };

  return (
     <>
      {hasAction(Actions.ViewEntity) ? (
    <div className={"portalForm"}>
      <div className={styles.reportsContainer}>
        <KirbySection sectionTitle="Reporting">
          <div className={styles.content}>
            <KirbyTableServer
              columns={columns(downloadClick)}
              data={data}
              pageSizeOptions={[]}
              defaultPageSize={10}
              rowCount={data.length}
              headerSortable={false}
              tablePart="reportTable"
              hidePagination={true}
              showTooltips={false}
              showHeaderTooltips={false}
            />
          </div>
        </KirbySection>
      </div>
        </div>) : <AccessDenied></AccessDenied>
      }
    </>);
  
};

export default Reports;
