import { RegistersDto } from "common-types/registerManifestDto";

export const RegistersActionTypes = {
  FETCH_REGISTERS: "FETCH_REGISTERS",
  FETCH_REGISTERS_SUCCESS: "FETCH_REGISTERS_SUCCESS",
  FETCH_REGISTERS_FAILURE: "FETCH_REGISTERS_FAILURE",
  CANCEL_REGISTERS: "CANCEL_REGISTERS",
};

export const RegistersActions = {
  fetchRegisters: (entityId: number) => ({
    type: RegistersActionTypes.FETCH_REGISTERS,
    entityId,
  }),

  fetchRegistersSuccess: (registers: RegistersDto) => ({
    type: RegistersActionTypes.FETCH_REGISTERS_SUCCESS,
    registers,
  }),

  fetchRegistersFailure: (error: any) => ({
    type: RegistersActionTypes.FETCH_REGISTERS_FAILURE,
    error,
  }),

  cancelRegisters: () => ({
    type: RegistersActionTypes.CANCEL_REGISTERS
  })
};
