import { getAbortSignalTryCancelPending, tryCancelPending } from "api/utils/requestAbortion";
import { EntityTerminationDto } from "../../common-types/entityTerminationDto";
import { ENTITIES_ENDPOINT, ENTITYTERMINATION_ENDPOINT } from "../../constants/urls";
import { ApiCaller } from "../../helpers";

const signalKey = "fetchEntityTermination";

export const fetchEntityTermination = async (entityId: number): Promise<EntityTerminationDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${ENTITYTERMINATION_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchEntityTermination = (): void => tryCancelPending(signalKey);
