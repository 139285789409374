export default {
  AMLOFFICERS: "AMLOFFICERS",
  AUTHORISEDSHARECAPITAL: "AUTHORISEDSHARECAPITAL",
  AUTHORISEDSHARECAPITALBVI: "AUTHORISEDSHARECAPITALBVI",
  CURRENT_DIRECTORSANDOFFICERS: "CURRENT_DIRECTORSANDOFFICERS",
  CURRENT_DIRECTORSANDOFFICERSBVI: "CURRENT_DIRECTORSANDOFFICERSBVI",
  CURRENT_MANAGERS: "CURRENT_MANAGERS",
  CURRENT_PARTNERS: "CURRENT_PARTNERS",
  PARTNERSHIPS: "PARTNERSHIPS",
  MEMBERS: "MEMBERS",
  NAME_HISTORY: "NAME_HISTORY",
  OFFICERS: "OFFICERS",
  SHAREHOLDERS: "SHAREHOLDERS",
  UNIT_PARTNERSHIP_INTERESTS: "UNIT_PARTNERSHIP_INTERESTS",
  REGISTERS: "REGISTERS",
  SECURITYINTERESTSOVERLLCINTERESTS: "SECURITYINTERESTSOVERLLCINTERESTS",
  SEGREGATED_PORTFOLIOS: "SEGREGATE_PORTFOLIOS",
  TRADEMARK_CLASSES: "TRADEMARK_CLASSES",
  VESSELS: "VESSELS",
};
