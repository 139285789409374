import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { RegistersActions, RegistersActionTypes } from "./registers-actions";
import { FetchRegistersAction } from "./types/registers-actions-types";
import { cancelFetchRegisters, fetchRegisters } from "../../api/registers-api";
import { RegistersDto } from "common-types/registerManifestDto";
import { cancelFetchRegisterProperties } from "api/registerProperties-api";

export function* watchFetchRegisters(): SagaIterator {
  yield takeEvery(
    RegistersActionTypes.FETCH_REGISTERS,
    function* (action: FetchRegistersAction): any {
      try {
        const { entityId } = action;
        let registers: RegistersDto = yield call(fetchRegisters, entityId);
        yield put(RegistersActions.fetchRegistersSuccess(registers));
      } catch (e) {
        yield put(RegistersActions.fetchRegistersFailure(e));
      }
    }
  );

  yield takeEvery(
    RegistersActionTypes.CANCEL_REGISTERS,
    function* (): any {
        yield call(cancelFetchRegisters);
        yield call(cancelFetchRegisterProperties);
    }
  );
}

export default function* RegistersSaga(): SagaIterator {
  yield all([fork(watchFetchRegisters)]);
}
