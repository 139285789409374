import { InvoiceRequest } from "api/interfaces/invoiceRequest";
import { PagedListDto } from "common-types";
import { InvoiceDto } from "common-types/invoiceDto";
import { INVOICES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { ApiResponse } from "helpers/apiCaller/ApiResponse";

export const fetchInvoices = async(): Promise<PagedListDto<InvoiceDto>> => 
  ApiCaller.get(`${INVOICES_ENDPOINT}`);

let filterSortInvoicesController: AbortController;

export const filterSortInvoices = async(request: InvoiceRequest): Promise<PagedListDto<InvoiceDto>> => {
  if (filterSortInvoicesController) {
    filterSortInvoicesController.abort();
  }

  filterSortInvoicesController = new AbortController();

  return ApiCaller.post(`${INVOICES_ENDPOINT}`, request, { signal: filterSortInvoicesController.signal });
}
 
export const exportInvoiceFile = async(request: InvoiceRequest, blobHandler: Function): Promise<ApiResponse<any>> => {
  return ApiCaller.downloadPost(`${INVOICES_ENDPOINT}/export`, blobHandler, request);
}

export const downloadInvoicePdf = async (entityId: number, invoiceNumber: number, blobHandler: Function): Promise<any> => {
    return ApiCaller.download(`${INVOICES_ENDPOINT}/download?entityId=${entityId}&invoicenumber=${invoiceNumber}`, blobHandler);
}
