import { HomePageType } from "common-types/homePageTypeEnum";

export const EntitiesActionTypes = {
  SET_HOMEPAGETYPE: "SET_HOMEPAGETYPE",
};

export const EntitiesActions = {
  setHomePageType: (homePageType: HomePageType) => ({
    type: EntitiesActionTypes.SET_HOMEPAGETYPE,
    homePageType,
  }),
};
