import { EntityTerminationDto } from "common-types/entityTerminationDto";

export const EntityTerminationActionTypes = {
  FETCH_ENTITYTERMINATION: "FETCH_ENTITYTERMINATION",
  FETCH_ENTITYTERMINATION_SUCCESS: "FETCH_ENTITYTERMINATION_SUCCESS",
  FETCH_ENTITYTERMINATION_FAILURE: "FETCH_ENTITYTERMINATION_FAILURE",
  CANCEL_FETCH: "CANCEL_FETCH"
};

export const EntityTerminationActions = {
  fetchEntityTermination: (entityId: number) => ({
    type: EntityTerminationActionTypes.FETCH_ENTITYTERMINATION,
    entityId,
  }),

  fetchEntityTerminationSuccess: (entityTerminationDto: EntityTerminationDto) => ({
    type: EntityTerminationActionTypes.FETCH_ENTITYTERMINATION_SUCCESS,
    entityTerminationDto,
  }),

  fetchEntityTerminationFailure: (error: any) => ({
    type: EntityTerminationActionTypes.FETCH_ENTITYTERMINATION_FAILURE,
    error,
  }),

  cancel: () => ({
    type: EntityTerminationActionTypes.CANCEL_FETCH
  })
};
