import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { cancelFetchOutstandingInvoices, fetchOutstandingInvoices } from "api/outstanding-invoice-api";
import { OutstandingInvoicesDto } from "common-types/outstandingInvoicesDto";
import { KirbySpinner } from "maples-kirby-react";
import styles from "./OutstandingInvoices.module.scss";
import { EntityDto } from "common-types";
import { format } from "helpers/formatting";
import { addToast } from "components/common/Toaster";
import { genericErrorMessage } from "helpers/apiCaller/constants";

export interface OutstandingInvoicesStateProps {
  entity: EntityDto;
}

export interface OutstandingInvoicesDispatchProps {
  setInvoicesFilter: Function;
}

export default function OutstandingInvoices(
  props: OutstandingInvoicesStateProps & OutstandingInvoicesDispatchProps
) {
  const navigate = useNavigate();
  const [outstandingInvoices, setOutstandingInvoices] = useState<OutstandingInvoicesDto | undefined>(undefined);
  const { entity } = props;
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const currencySymbol: string = "USD";
  const timeoutMs: number = 100000;
  const timeoutErrorTitle: string = "Error retrieving Invoices Outstanding amount";

  const getOutstandingInvoices = useCallback(async () => {
    return new Promise<OutstandingInvoicesDto>((resolve, reject) => {
      const timeoutID = setTimeout(() => reject("Api call timeout"), timeoutMs);

      fetchOutstandingInvoices(entityId).then((result: OutstandingInvoicesDto) => {
        clearTimeout(timeoutID);
        resolve(result);
      }).catch(() => {
        clearTimeout(timeoutID);
      });
    });
  }, [entityId]);

  useEffect(() => {
    if (entityId > 0) {
      getOutstandingInvoices()
        .then((response) => setOutstandingInvoices(response))
        .catch((err) => {
          addToast(timeoutErrorTitle, genericErrorMessage, "error");
          setOutstandingInvoices(new OutstandingInvoicesDto());
        });
    }
    return (() => {
      cancelFetchOutstandingInvoices();
    })
  }, [entityId, getOutstandingInvoices]);

  const setInvoiceFilter = () => {
    if (!outstandingInvoices) 
      return;
    
    props.setInvoicesFilter(entity.clientNumber, entity.name);
    navigate("/invoices");
  };

  return (
      <div className={`${styles.displayBlock} ${outstandingInvoices ? styles.loaded : ""}`} onClick={setInvoiceFilter}>
        <span>Invoices Outstanding</span>
        {outstandingInvoices !== undefined ? (
          <h3 className={styles.paragraph}>
            {currencySymbol}{" "}{format.currency(outstandingInvoices.outstandingAmount)}
          </h3>
        ) : (
          <div className="paragraph">
            <KirbySpinner size="small" mono="white" />
          </div>
        )}
      </div>
  );
}
