import { sleep } from "./sleep";

/*
  Designed to stop multiple API calls from happening at the same time.
*/
export class Locker {
  private locked: boolean;

  constructor() {
    this.locked = false;
  }

  readonly executeWithLock = async <T>(func: () => Promise<T>, ms: number = 800): Promise<T> => {
    if (this.locked) {
      await sleep(ms);
      return this.executeWithLock(func);
    }

    this.locked = true;
    return func().finally(() => this.locked = false);
  }
}