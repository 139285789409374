import { OrderDto } from "common-types/serviceRequests/orderDto";
import { OrderSummaryDto } from "common-types/serviceRequests/orderSummaryDto";

export const orderActionTypes = {
    FETCH_ORDER: "FETCH_ORDER",
    FETCH_ORDER_SUCCESS: "FETCH_ORDER_SUCCESS",
    FETCH_ORDER_FAILURE: "FETCH_ORDER_FAILURE",
    CANCEL_FETCH_ORDER: "CANCEL_FETCH_ORDER",
    CANCEL_ORDER: "CANCEL_ORDER",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",
    COMPLETE_ORDER: "COMPLETE_ORDER",
    COMPLETE_ORDER_SUCCESS: "COMPLETE_ORDER_SUCCESS",
    COMPLETE_ORDER_FAILURE: "COMPLETE_ORDER_FAILURE",
    FETCH_ORDER_HISTORY: "FETCH_ORDER_HISTORY",
    FETCH_ORDER_HISTORY_SUCCESS: "FETCH_ORDER_HISTORY_SUCCESS",
    FETCH_ORDER_HISTORY_FAILURE: "FETCH_ORDER_HISTORY_FAILURE",
    CANCEL_FETCH_ORDER_HISTORY: "CANCEL_FETCH_ORDER_HISTORY",
    FETCH_ORDER_SUMMARIES: "FETCH_ORDER_SUMMARIES",
    FETCH_ORDER_SUMMARIES_SUCCESS: "FETCH_ORDER_SUMMARIES_SUCCESS",
    FETCH_ORDER_SUMMARIES_FAILURE: "FETCH_ORDER_SUMMARIES_FAILURE"
  }
  
  export const orderActions = {
    fetchOrder: (entityId: number, orderId: number) => ({
      type: orderActionTypes.FETCH_ORDER,
      entityId,
      orderId
    }),
  
    fetchOrderSuccess: (order: OrderDto) => ({
      type: orderActionTypes.FETCH_ORDER_SUCCESS,
      order,
    }),
  
    fetchOrderFailure: (error: any) => ({
      type: orderActionTypes.FETCH_ORDER_FAILURE,
      error
    }),
  
    cancel: () => ({
      type: orderActionTypes.CANCEL_FETCH_ORDER
    }),

    cancelOrder: (entityId: number, orderId: number) => ({
      type: orderActionTypes.CANCEL_ORDER,
      entityId,
      orderId
    }),

    cancelOrderSuccess: (order: OrderDto) => ({
      type: orderActionTypes.CANCEL_ORDER_SUCCESS,
      order,
    }),

    cancelOrderFailure: (error: any) => ({
      type: orderActionTypes.CANCEL_ORDER_FAILURE,
      error,
    }),

    completeOrder: (entityId: number, orderId: number) => ({
      type: orderActionTypes.COMPLETE_ORDER,
      entityId,
      orderId
    }),

    completeOrderSuccess: (order: OrderDto) => ({
      type: orderActionTypes.COMPLETE_ORDER_SUCCESS,
      order,
    }),

    completeOrderFailure: (error: any) => ({
      type: orderActionTypes.COMPLETE_ORDER_FAILURE,
      error,
    }),

    fetchOrderHistory: (entityId: number) => ({
        type: orderActionTypes.FETCH_ORDER_HISTORY,
        entityId,
    }),

    fetchOrderHistorySuccess: (orders: OrderDto[]) =>({
        type: orderActionTypes.FETCH_ORDER_HISTORY_SUCCESS,
        orders,
    }),

    fetchOrderHistoryFailure: (error: any) => ({
        type: orderActionTypes.FETCH_ORDER_HISTORY_FAILURE,
        error
    }),

    cancelHistory: () => ({
        type: orderActionTypes.CANCEL_FETCH_ORDER_HISTORY
    }),

    fetchOrderSummaries: () => ({
      type: orderActionTypes.FETCH_ORDER_SUMMARIES
    }),

    fetchOrderSummariesSuccess: (orderSummaries: OrderSummaryDto[]) =>({
        type: orderActionTypes.FETCH_ORDER_SUMMARIES_SUCCESS,
        orderSummaries,
    }),

    fetchOrderSummariesFailure: (error: any) => ({
        type: orderActionTypes.FETCH_ORDER_SUMMARIES_FAILURE,
        error
    })
  };