import { all, call, CallEffect, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from "redux-saga";
import { EntityActions, EntityActionTypes } from './entity-actions';
import { DownloadEntityDocumentAction, DownloadEntityInvoiceAction, FetchEntityAction } from './types/entity-actions-types';
import { fetchEntity, fetchEntityDetails } from '../../api/entity.api';
import { EntityDto } from 'common-types/entityDto';
import { EntityDetailDto } from 'common-types/entityDetailDto';
import { downloadDocs } from 'components/Pages/DocManagement/Actions/doc-management-actions';
import { DocumentDto } from 'common-types/documentDto';
import { handleInvoiceDownload } from 'components/invoices/invoiceEventHandlers';

export function* watchFetchEntity(): SagaIterator {
    yield takeEvery(EntityActionTypes.FETCH_ENTITY, function*(action: FetchEntityAction): any {
        try {
            const { entityId } = action;
            const [entity, entityData] = yield all([
              call(fetchEntitySaga, entityId),
              call(fetchEntityDetailSaga, entityId)
            ]);

            yield all([
              put(EntityActions.fetchEntitySuccess(entity)),
              put(EntityActions.fetchEntityDetailSuccess(entityData))
            ]);
        } catch (e) {
            yield put(EntityActions.fetchEntityFailure(e));
        }
    });
}

export function* downloadEntityDocument(): SagaIterator {
  yield takeEvery(EntityActionTypes.DOWNLOAD_ENTITY_DOCUMENT, function*(action: DownloadEntityDocumentAction): any {
      const { entityId, documentId } = action;
      yield call(downloadDocs, entityId, [{ id: documentId } as DocumentDto], { toastMessage: "Downloading document..." });
  });
}

export function* downloadEntityInvoice(): SagaIterator {
  yield takeEvery(EntityActionTypes.DOWNLOAD_ENTITY_INVOICE, function*(action: DownloadEntityInvoiceAction): any {
      const { entityId, invoiceId } = action;
      yield call(handleInvoiceDownload, entityId, Number(invoiceId));
  });
}

export function* fetchEntitySaga(entityId: number): 
  Generator<CallEffect<EntityDto>, EntityDto, EntityDto> {
  return yield call(fetchEntity, entityId);
}

export function* fetchEntityDetailSaga(entityId: number):
  Generator<CallEffect<EntityDetailDto>, EntityDetailDto, EntityDetailDto> {
  return yield call(fetchEntityDetails, entityId);
}

export default function* EntitySaga(): SagaIterator {
  yield all([
    fork(watchFetchEntity),
    fork(downloadEntityDocument),
    fork(downloadEntityInvoice)
  ]);
}
