import { isNumber } from "lodash";
import history from "./history";
import { KirbyIcon } from "maples-kirby-react";
import { format } from "helpers";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { supportEmailAddress } from "constants/copy";
import fromExponential from "from-exponential";
import { CellComponent } from "tabulator-tables";

interface GridLinkProperties {
  to: string;
  title?: string;
  dataSelector: string;
  children?: any;
}

const CurrencyCell = (cell: CellComponent): string => {
  let cellValue = cell.getValue();
  return isNumber(cellValue) ? format.currency(cellValue) : "";
};

const DateCell = (cell: CellComponent) => {
  let cellValue = cell.getValue();

  if (!cellValue || !Date.parse(cellValue)) {
    return "";
  }

  const date = new Date(cellValue);

  return format.date(date);
};

const SharesCell = (cell: CellComponent) => {
  const cellValue = cell.getValue();

  if (cellValue === null) {
    return "";
  }

  return cellValue.toLocaleString();
};

const IssuedSharesCell = (cell: CellComponent) => {
  const rowData = cell.getRow().getData();
  const cellValue = cell.getValue();

  if (rowData.votingRights === "MCS does not maintain register") {
    return rowData.votingRights;
  }

  if (cellValue === null) {
    return "";
  }

  return cellValue.toLocaleString();
};

const RemainingSharesCell = (cell: CellComponent) => {
  const rowData = cell.getRow().getData();
  const cellValue = cell.getValue();

  if (rowData.votingRights === "MCS does not maintain register") {
    return rowData.votingRights;
  }

  if (cellValue === null) {
    return "";
  }

  return cellValue.toLocaleString();
};

const TotalUnitsBviCell = (cell: CellComponent) => {
  const cellValue = cell.getValue();
  const rowData = cell.getRow().getData();

  if (cellValue === null) {
    return "";
  }

  if (rowData.unlimited === true) {
    return "Unlimited Shares";
  }

  return cellValue.toLocaleString();
};

const ShareCapitalCell = (cell: CellComponent) => {
  const cellValue = cell.getValue();
  const rowData = cell.getRow().getData();

  if (cellValue === null || cellValue === undefined) {
    return "";
  }

  if (rowData.parValue === 0 && cellValue === 0) {
    return "No Par Value";
  }

  return cellValue.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

const ParValueCell = (cell: CellComponent) => {
  const cellValue = cell.getValue();

  if (cellValue === null) {
    return "";
  }

  if (cellValue === 0) {
    return "No Par Value";
  }

  return fromExponential(cellValue);
};

const GridLink = (linkProperties: GridLinkProperties) => {
  const { to, title, dataSelector, children } = linkProperties;
  return (
    <a href={to} title={title} data-selector={dataSelector}>
      {children}
    </a>
  );
};

const LinkClick = (e: any) => {
  e.preventDefault();
  if (e.ctrlKey) {
    window.open(e.target.href, "_blank");
  } else {
    history.push(e.target.href);
  }
};

const LinkClickNewTab = (e: any) => {
  e.preventDefault();
  window.open(e.target.href, "_blank");
};

const EntityLinkCell = (id: number) => {
  return (
    <GridLink
      to={`/entities/${id}`}
      title={`Open`}
      dataSelector={`go-to-entity-${id}`}>
      GO TO<span className={"label"}></span>
    </GridLink>
  );
};

const EntityHyperLinkCell = (
  id: number,
  linkText: string,
  title: string = "Open",
  subpage?: string
): JSX.Element => {
  return (
    <GridLink
      to={`/entities/${id}${subpage ? "/" + subpage : ""}`}
      aria-label={title}
      title={title}
      dataSelector={`go-to-entity-${id}`}>
      {linkText}
    </GridLink>
  );
};

const DownloadInvoiceLinkButton = (
  invoiceNumber: number,
  children?: ReactJSXElement
) => {
  return <span id={`invoice-download-${invoiceNumber}`}>{children}</span>;
};

const BoolCell = (cell: CellComponent) => {
  let cellValue = cell.getValue();
  return format.yesNo(cellValue);
};

const StatusCell = (cell: CellComponent) => {
  let cellValue = cell.getValue();
  if (typeof cellValue === "boolean") {
    return cellValue ? "Active" : "Inactive";
  } else {
    return format.boolean(cellValue, "Active", "Inactive");
  }
};

const IconCell = (
  title: string,
  icon: any,
  width: string = "30px",
  color?: string
) => {
  return (
    <KirbyIcon
      title={title}
      style={{ fill: color, width: width }}
      icon={icon}
    />
  );
};

const MailToCell = (
  name: string | null | undefined,
  email: string | null | undefined
): JSX.Element => {
  const contactName = name ? name : "Unknown";
  const emailAddress = email ? email : supportEmailAddress;

  return (
    <a
      aria-label={emailAddress}
      title={emailAddress}
      className="email-link"
      href={`mailto:${emailAddress}`}
      data-testid="mailto">
      {contactName}
    </a>
  );
};

export {
  CurrencyCell,
  DateCell,
  SharesCell,
  TotalUnitsBviCell,
  ShareCapitalCell,
  ParValueCell,
  EntityLinkCell,
  EntityHyperLinkCell,
  BoolCell,
  StatusCell,
  MailToCell,
  IconCell,
  LinkClick,
  DownloadInvoiceLinkButton,
  GridLink,
  LinkClickNewTab,
  IssuedSharesCell,
  RemainingSharesCell
};
