import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "modules/entity/entity-selectors";
import AuthorisedShareCapitalBvi from "./AuthorisedShareCapitalBvi";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import { AuthorisedShareCapitalBviDto } from "common-types";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import PagedListKeys from "constants/pagedListKeys";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import { getAuthorisedShareCapitalBviGridColumns } from "./authorisedShareCapitalBviGridColumns";
import headerText from "constants/copy/registers/headerText";

const stateSelector = (state: AppState): GenericPagedListState<AuthorisedShareCapitalBviDto> => state.authorisedShareCapitalBvi;

const getAuthorisedShareCapitalBvi = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<AuthorisedShareCapitalBviDto> & ServerTableContainerOwnProps => {
  return {
    list: getAuthorisedShareCapitalBvi(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state), 
    header: headerText.AuthorisedShareCapital,
    columns: getAuthorisedShareCapitalBviGridColumns(state.entityDetail.entityDetail?.bcActTransProvApplicable || false, state.registers.registers),
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 50,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.AUTHORISEDSHARECAPITALBVI);

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisedShareCapitalBvi);
