import { ENTITIES_ENDPOINT, AUTHORISEDSHARECAPITALBVI_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { AuthorisedShareCapitalBviDto } from "common-types/authorisedShareCapitalBviDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchAuthorisedShareCapitalBvi";

export const fetchAuthorisedShareCapitalBvi = async(request: EntityPageRequest): Promise<PagedListDto<AuthorisedShareCapitalBviDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${AUTHORISEDSHARECAPITALBVI_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchAuthorisedShareCapitalBvi = (): void => tryCancelPending(signalKey);

