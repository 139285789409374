import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { OrderDto } from "common-types/serviceRequests/orderDto";
import { basketUtils } from "common-types/serviceRequests/utils/basketUtils";

export interface OrderAction {
    type: 'update_order' | 'update_order_item' | 'order_created';
    changedOrderItem?: BasketItemDto;
    order?: OrderDto;
    orderId?: number;
}

const updateOrderItems = (subject: BasketItemDto, currentBasket: BasketItemDto[]): BasketItemDto[] => {
    let newOrderItems = currentBasket;
    let currentBasketItem = basketUtils.findMatch(currentBasket, subject);
    
    if (currentBasketItem)
        newOrderItems.splice(basketUtils.findIndex(newOrderItems, subject), 1, subject);
    else
        newOrderItems.push(subject);

    return newOrderItems;
}

export const orderReducer = (state: OrderDto, action: OrderAction): OrderDto => {
    const BasketStatusId: number = 1;
    switch (action.type) {
        case 'order_created':
            return {
                ...state,
                id: action.orderId!,
                serviceRequestOrderStatusId: BasketStatusId
            }
        case 'update_order':
            return action.order ?? state;
        case 'update_order_item':
            return {
                ...state,
                orderItems: updateOrderItems(action.changedOrderItem!, state.orderItems)
            }
    }
}