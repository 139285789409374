import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { PageInfo, DefaultPageInfo } from 'components/common/types/ServerTableContainerProps';
import { GenericPagedListState } from "./paged-list-state";
import { PagedListDto } from 'common-types';

const getItemsSelector = <Type>(stateSelector: (state: AppState) => GenericPagedListState<Type>) =>
  createSelector(
    stateSelector,
    (state: GenericPagedListState<Type>): PagedListDto<Type> => state?.items ?? new PagedListDto<Type>()
  );

const getPageInfoSelector = <Type>(stateSelector: (state: AppState) => GenericPagedListState<Type>) => createSelector(
    stateSelector,
  (state: GenericPagedListState<Type>): PageInfo => {
      if (!state)
        return DefaultPageInfo;

      if (!state.items)
        return DefaultPageInfo;

      return {
        pageNumber: state.items.currentPage,
        pageSize: state.items.pageSize,
        totalPages: state.items.totalPages,
        totalCount: state.items.totalCount
      }
    }
  );

const getFetchStatusSelector = <Type>(stateSelector: (state: AppState) => GenericPagedListState<Type>) => createSelector(
  stateSelector,
  (state: GenericPagedListState<Type>): boolean => {
    if (!state)
      return false;

    return state.fetchInProgress;
  }
);

export default {
  getFetchStatusSelector,
  getPageInfoSelector,
  getItemsSelector
}
