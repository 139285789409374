import { RegisterManifestDto } from "common-types";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { RegistersState } from "./types/registers-state";

const registerStateSelector = (state: AppState): RegistersState =>
  state.registers;

export const getRegisters = createSelector(
  registerStateSelector,
  (registerState) => {
    if (!registerState || !registerState.registers) return {} as RegisterManifestDto[];

    return registerState.registers;
  }
);

export const getRegistersFetchStatus = createSelector(
  registerStateSelector,
  (registers) => registers.fetchStatus
);
