import { FormState } from "common-types/forms/FormState";
import { UserSettingsDto } from "common-types/user/userSettingsDto";

export const UserActionTypes = {
  FETCH_SETTINGS: "FETCH_SETTINGS",
  FETCH_SETTINGS_SUCCESS: "FETCH_SETTINGS_SUCCESS",
  FETCH_SETTINGS_FAILURE: "FETCH_SETTINGS_FAILURE",
  SAVE_SETTINGS: "SAVE_SETTINGS",
  SAVE_SETTINGS_SUCCESS: "SAVE_SETTINGS_SUCCESS",
  SAVE_SETTINGS_FAILURE: "SAVE_SETTINGS_FAILURE",
  SET_FORM_STATE: "SET_FORM_STATE"
};

export const UserActions = {
  fetchSettings: () => ({
      type: UserActionTypes.FETCH_SETTINGS
  }),
  fetchSettingsSuccess: (settings: UserSettingsDto) => ({
      type: UserActionTypes.FETCH_SETTINGS_SUCCESS,
      settings
  }),
  fetchSettingsFailure: (error: any) => ({
    type: UserActionTypes.FETCH_SETTINGS_FAILURE,
    error
  }),
  saveSettings: (settings: UserSettingsDto) => ({
    type: UserActionTypes.SAVE_SETTINGS,
    settings
  }),
  saveSettingsSuccess: () => ({
    type: UserActionTypes.SAVE_SETTINGS_SUCCESS
  }),
  saveSettingsFail: (error: any) => ({
    type: UserActionTypes.SAVE_SETTINGS_FAILURE,
    error
  }),
  setFormState: (formState: FormState) => ({
    type: UserActionTypes.SET_FORM_STATE,
    formState
  })
};
