import { FullDataRequest, PagedDataRequest } from "api/interfaces/pagedDataRequest"
import { PagedListDto } from "common-types"
import { EconomicSubstanceFilingDto } from "common-types/economicSubstanceFilingDto"
import { ApiResponse } from "../../helpers/apiCaller/ApiResponse"

export const entityFilingsActionTypes = {
    FETCH_FILINGS: 'FETCH_FILINGS',
    FETCH_FILINGS_SUCCESS: 'FETCH_FILINGS_SUCCESS',
    FETCH_FILINGS_FAILURE: 'FETCH_FILINGS_FAILURE',
    FETCH_FILINGS_CANCELLED: 'FETCH_FILINGS_CANCELLED',
    DOWNLOAD_FILINGS: 'DOWNLOAD_FILINGS',
    DOWNLOAD_FILINGS_FAILURE: 'DOWNLOAD_FILINGS_FAILURE',
    DOWNLOAD_FILINGS_SUCCESS: 'DOWNLOAD_FILINGS_SUCCESS'
}

export const entityFilingActions = {
    fetchFilings: (request: PagedDataRequest) => ({
        type: entityFilingsActionTypes.FETCH_FILINGS,
        request
    }),

    fetchFilingsSuccess: (page: PagedListDto<EconomicSubstanceFilingDto>) => ({
        type: entityFilingsActionTypes.FETCH_FILINGS_SUCCESS,
        page
    }),

    fetchFilingsFailure: (error: any) => ({
        type: entityFilingsActionTypes.FETCH_FILINGS_FAILURE,
        error
    }),

    fetchFilingsCancelled: () => ({
        type: entityFilingsActionTypes.FETCH_FILINGS_CANCELLED
    }),

    downloadFilings: (request: FullDataRequest, callBack: (response: ApiResponse<any>) => void) => ({
        type: entityFilingsActionTypes.DOWNLOAD_FILINGS,
        request,
        callBack
      }),

    downloadFilingsFailure: (error: any) => ({
        type: entityFilingsActionTypes.DOWNLOAD_FILINGS_FAILURE,
        error
    }),

    downloadFilingsSuccess: () => ({
        type: entityFilingsActionTypes.DOWNLOAD_FILINGS_SUCCESS
    })
}
