import { Component } from "react";
import { UnitPartnershipInterestDto } from "common-types";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";

export type UnitPartnershipInterestsProps =
  SimplePagedSectionProps<UnitPartnershipInterestDto>;

class UnitPartnershipInterests extends Component<UnitPartnershipInterestsProps> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default UnitPartnershipInterests;
