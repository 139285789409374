import { Component, ReactNode } from "react";
import { Provider } from "react-redux";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import store from "./store/configureStore";
import "./custom.scss";
import history from "./helpers/history";
import withAppInsights from "./modules/app-insights";
import { APPINSIGHTS_INSTRUMENTATION_KEY } from "constants/env";
import Maintenance from "maintenance/Maintenance";
import AppRoutes from "components/controls/navigation/AppRoutes";
import FeatureFlagsProvider from "helpers/featureManagement/FeatureFlagsProvider";

class ConnectedApp extends Component {
  render(): ReactNode {
    return (
      <Provider store={store}>
        <FeatureFlagsProvider>
          <ReduxRouter history={history}>
            <AppRoutes />
          </ReduxRouter>
        </FeatureFlagsProvider>
      </Provider>
    );
  }
}

class App extends Component {

  maintenance = false;

  render(): ReactNode {
    return this.maintenance ? <Maintenance /> : <ConnectedApp />;
  }
}

export default APPINSIGHTS_INSTRUMENTATION_KEY ? withAppInsights(App) : App;
