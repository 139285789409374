import { MaplesTeamDto } from "common-types/maplesTeamDto";
import styles from "./MaplesTeam.module.scss";
import { KirbyIcon, KirbySpinner } from "maples-kirby-react";
import {
  kirbyPhone,
  kirbyMail,
  kirbyMobile,
  kirbyTeam
} from "maples-kirby-icons";
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";

export interface Props {
  member: MaplesTeamDto;
}

export default function RegisterProperties(props: Readonly<Props>) {
  const { member } = props;
  const [profilePictureEnabled, loaded] = useFeatureFlag('ProfilePictures');

  let isContactUs = member.firstName === "Contact Us";
  let memberTitle = isContactUs ? "" : `${member.role} / ${member.office}`;
  let memberName = `${member.firstName} ${member.lastName ?? ""}`;
  const memberEmailAddressName = member?.email ? member.email.split('@')[0] : "";

  const getMemberImage = () => {
    if (!loaded) return <KirbySpinner single></KirbySpinner>
    if (member.email === "PhoenixSupport@maples.com") return <img className={styles.phoenixLogo} src="/favicon.svg" alt="Contact Us" />
    if (profilePictureEnabled) {
      return (
        <img src={`/static/thumbnail/${memberEmailAddressName}`}
          alt={`${member.firstName} ${member.lastName}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "Profile.svg";
          }}
        />
      )
    }else{
      return <KirbyIcon icon={kirbyTeam} title={memberName}></KirbyIcon>
    }
  }

  return (
    <div className={`${styles.memberTile} ${isContactUs ? styles.contactTile : ""}`}
      title={memberName}
      data-testid="member-tile">
      <div className={styles.teamPhoto}>
        {getMemberImage()}
      </div>
      <div className={styles.details}>
        <div
          className={styles.memberTitle}
          data-testid="member-title"
          title={memberTitle}>
          <span>{memberTitle}</span>
        </div>
        <span className={styles.memberName} data-testid="member-name">
          {memberName}
        </span>
        <span
          title="Mobile phone number"
          className={`${!member.phoneMobile ? styles.hidden : ""} ${styles.memberContact
            }`}
          data-testid="member-phone-mobile">
          <KirbyIcon
            icon={kirbyMobile}
            title={`Contact by mobile phone: ${member.phoneMobile}`}></KirbyIcon>
          {member.phoneMobile ? (
            <a href={`tel:${member.phoneMobile}`} title="Mobile phone number">
              {member.phoneMobile}
            </a>
          ) : (
            <span>N/A</span>
          )}
        </span>
        <span
          title="Office phone number"
          className={`${!member.phoneOffice ? styles.hidden : ""} ${styles.memberContact
            }`}
          data-testid="member-phone-office">
          <KirbyIcon
            icon={kirbyPhone}
            title={`Contact by office phone: ${member.phoneOffice}`}></KirbyIcon>
          {member.phoneOffice ? (
            <a href={`tel:${member.phoneOffice}`} title="Office phone number">
              {member.phoneOffice}
            </a>
          ) : (
            <span>N/A</span>
          )}
        </span>
        <span
          title="Email address"
          className={`${!member.email ? styles.hidden : ""} ${styles.memberContact
            }`}
          data-testid="member-email">
          <KirbyIcon
            icon={kirbyMail}
            title={`Contact by email: ${member.email}`}></KirbyIcon>
          {member.email ? (
            <a href={`mailto:${member.email}`} title={member.email}>
              {member.email}
            </a>
          ) : (
            <span>N/A</span>
          )}
        </span>
      </div>
    </div>
  );
}
