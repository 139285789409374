import { DateTime } from 'luxon';

export function getFormattedDate(date: Date, format: string) {
 return DateTime.fromISO(new Date(date).toISOString()).toFormat(format);
}

export function getFormattedDateFromString(date: string, format: string) {
  return DateTime.fromISO(date).toFormat(format);
}

export function getFormattedCurrentDateTime(format: string) {
  return DateTime.now().toFormat(format);
}


