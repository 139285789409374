import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import useFeatureFlag from "../helpers/featureManagement/useFeatureFlag";
import { FixedFeeFiles } from "./fixedFeeFiles/FixedFeeFiles";
import styles from "./footer.module.scss";
import { MAPLES_TERM_URL, MAPLES_PRIVACY_POLICY_URL } from "../constants/env";

export const Footer = () => {
  const [fixedFeesOpen, setFixedFeesOpen] = useState(false);
  const [releaseNotesEnabled] = useFeatureFlag('ReleaseNotes');
    
    function fixedFeesClick() {
        setFixedFeesOpen(true);
        return false;
    } 
    
    const hideFixedFees = useCallback(() => {
        setFixedFeesOpen(false);
    }, [setFixedFeesOpen]);

    return (
        <>
            <div className={styles.footerContainer}>
                <div>&copy;{new Date().getUTCFullYear().toString()} Maples Group</div>
                {releaseNotesEnabled ? (<NavLink to="/releases" title="Releases">Releases</NavLink>):''}
                <a onClick={fixedFeesClick} title="Fixed Fee Schedules" >Fixed Fee Schedules</a>
          <a href={MAPLES_TERM_URL} title="Maples Terms" target={'_blank'} rel="noopener noreferrer" >Maples Terms</a>
                <NavLink to="/termsofuse" title="Terms of Use">Terms of Use</NavLink>
          <a href={MAPLES_PRIVACY_POLICY_URL} title="Privacy Policy" >Privacy Policy</a>
				        <a href="mailto:PhoenixSupport@maples.com?subject=Maples%20Phoenix%20Enquiry&body=Contact%20Number%20we%20can%20call%20you%20on:%0D%0A%0D%0AOutline%20of%20Issue%20/%20Enquiry%20(please%20be%20as%20descriptive%20as%20possible):" title="Contact Us">
					        Contact Us
				        </a>
            </div> 
            <div>
                <FixedFeeFiles open={fixedFeesOpen} closeDialog={hideFixedFees} />
            </div>
        </>
    );
}
