import { AppState } from "store/AppState";
import { connect } from "react-redux";
import { getOrderSummary, getOrderSummaryFetchStatus } from "modules/order/order-selectors";
import { orderActions } from "modules/order/order-actions";
import ServiceRequests from "./serviceRequests";
import { FetchOrderSummariesAction } from "modules/order/types/order-actions-types";

const mapStateToProps = (state: AppState) => ({
    orderSummaries: getOrderSummary(state),
    fetchStatus: getOrderSummaryFetchStatus(state),
});

const mapDispatchToProps = {
    fetchOrderSummaries: (): FetchOrderSummariesAction => orderActions.fetchOrderSummaries(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequests);