import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "../../../../modules/entity/entity-selectors";
import { EntityTerminationActions } from "../../../../modules/entityTermination/entityTermination-actions";
import {
  getEntityTermination,
  getEntityTerminationFetchStatus,
} from "../../../../modules/entityTermination/entityTermination-selectors";
import { FetchEntityTerminationAction } from "../../../../modules/entityTermination/types/entityTermination-actions-types";
import EntityTermination, { EntityTerminationProps } from "./EntityTermination";
import { Action } from "typesafe-actions";

const mapStateToEntityTerminationProps = (
  state: AppState
): Partial<EntityTerminationProps> => ({
  entityTerminationDto: getEntityTermination(state),
  fetchEntityTerminationStatus: getEntityTerminationFetchStatus(state),
  entity: getEntity(state),
});

const mapDispatchToEntityTerminationProps = {
  fetchEntityTermination: (entityId: number): FetchEntityTerminationAction => EntityTerminationActions.fetchEntityTermination(entityId),
  cancel: (): Action => EntityTerminationActions.cancel()
};

export default connect(
  mapStateToEntityTerminationProps,
  mapDispatchToEntityTerminationProps
)(EntityTermination);
