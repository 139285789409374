import { kirbyMsExcel } from "maples-kirby-icons";
import { KirbyIcon } from "maples-kirby-react";
import { renderToStaticMarkup } from "react-dom/server";

const data = [
  { reportName: "Main Properties" },
  { reportName: "Directors and Officers" },
  { reportName: "LLC Managers" },
  { reportName: "Shareholders" },
  { reportName: "Partnership Interests" },
  { reportName: "LLC Members" },
  { reportName: "Active Entity Contacts" },
  { reportName: "Inactive Entity Contacts" },
  { reportName: "AML Officers" },
];

const columns = (downloadClick: (reportName: string) => void): Array<any> => {
  return [
    {
      title: "Report Name",
      field: "",
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        const reportName = cell.getRow().getData().reportName;
        onRendered(() => {
          let wrapper = cell.getElement().querySelector("div");
          wrapper.setAttribute("part", "downloadReport");
          wrapper.onclick = () => downloadClick(reportName);

          const cellIcon = cell.getElement().querySelector("kirby-icon");
          cellIcon.icon = kirbyMsExcel;
          cellIcon.setAttribute("part", "downloadReportIcon");
        });
        return renderToStaticMarkup(
          <div>
            <KirbyIcon title={reportName} icon={kirbyMsExcel} />
            <span>{reportName}</span>
          </div>
        );
      },
    },
  ];
};

export { columns, data };
