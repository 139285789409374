import { useState, CSSProperties } from "react";
import styles from "./OrderHistoryDetails.module.scss";
import { OrderHistoryDetailsComponentProps } from "modules/order/types/order-component-types";
import OrderHistoryDetailsContent from "./OrderHistoryDetailsContent";
import EntityViewListeners from "../../../common/EntityViewUtils";


export default function OrderHistoryDetails(props: OrderHistoryDetailsComponentProps) {

  const { order } = props;
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [maxHeaderHeight, setMaxHeaderHeight] = useState<number>(0);
  EntityViewListeners(headerHeight, setHeaderHeight, maxHeaderHeight, setMaxHeaderHeight, order);

  return (
    <div className={styles.container} style={{ "--entity-page-header-height": headerHeight + "px", "--entity-page-max-header-height": maxHeaderHeight + "px" } as CSSProperties}>
      <OrderHistoryDetailsContent {...props} />
    </div>
  );
}

