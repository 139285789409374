import utils from "components/Pages/ServiceRequests/OrderHistory/utils";
import { distinct } from "helpers/array";
import { DateCell, EntityHyperLinkCell, LinkClick } from "helpers/gridCells";
import { renderToStaticMarkup } from "react-dom/server";
import { getFormattedCurrentDateTime, getFormattedDateFromString } from "../../helpers/formatting/dateConverter"

export const getTableHeaders = (docTypes: any[], statusOptions: any[], userOptions: any[]): any[] => {
  return [
    {
      title: "Order ID",
      field: "id",
      headerFilter: "text",
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          cell.getElement().querySelector("a").onclick = LinkClick;
        });
        if (cell.getRow().getData().serviceRequestOrderStatusId === 1) {
          return renderToStaticMarkup(
            EntityHyperLinkCell(
              cell.getRow().getData().entityId,
              cell.getRow().getData().id,
              "",
              `servicerequests/orderreview`
            )
          );
        }
        return renderToStaticMarkup(
          EntityHyperLinkCell(
            cell.getRow().getData().entityId,
            cell.getRow().getData().id,
            "",
            `servicerequests/order/${cell.getRow().getData().id}`
          )
        );
      },
    },
    {
      title: "Entity Name",
      field: "entityName",
      headerFilter: "text",
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          cell.getElement().querySelector("a").onclick = LinkClick;
        });
        return renderToStaticMarkup(
          EntityHyperLinkCell(
            cell.getRow().getData().entityId,
            cell.getRow().getData().entityName,
            "View Entity"
          )
        );
      },
    },
    {
      title: "Client Number",
      field: "clientNumber",
      headerFilter: "text",
    },
    {
      title: "Order Date",
      field: "orderDate",
      formatter: DateCell,
      formatterParams: {
        dataFormat: "iso",
        displayFormat: "dd-MM-yyyy",
        invalidPlaceholder: "(invalid date)"
      },
      headerFilter: "date",
      headerFilterParams: {
        maxFilterDate: getFormattedCurrentDateTime('yyyy-MM-dd')
      },
      advFilters: true,
      width: 11.875,
      accessorDownload: function (value: string) {
        return getFormattedDateFromString(value, 'yyyy-MM-dd')
      }
    },
    {
      title: "Document Title",
      field: "orderItemNames",
      headerFilter: "select",
      headerFilterFunc: 'arrayIncludes',
      headerFilterParams: {
        options: docTypes
      },
      sorter: "array",
      sorterParams: {
        type: "length",
        alignEmptyValues: "bottom"
      },
      variableHeight: true,
      width: 11.875,
      formatter: (cell: any, _formatterParams: any) => {
        let orderItems = cell.getRow().getData().orderItems;

        if (!orderItems || orderItems.length === 0)
          return 'None.';

        let parsedList = distinct(orderItems.map((a: any) => utils.docTypeDisplayName(a))).map((item: any) => {
          return `<li>${item}</li>`
        }).sort((a, b) => a.localeCompare(b));

        return `<ul part="listCell">${parsedList.join('')}</ul>`;
      },
      accessorDownload: function (value: Array<string>, data: any, type: any, params: any, column: any) {
        let formattedVal: string = '';
        value.forEach((line, index) => {
          formattedVal = formattedVal + `${line}${index !== value.length - 1 ? '\r\n' : ''}`
        });
        return formattedVal
      }
    },
    {
      title: "Status",
      field: "serviceRequestOrderStatusId",
      headerFilter: "select",
      headerFilterParams: {
        options: statusOptions,
      },
      formatter: (cell: any, _formatterParams: any) => utils.getStatusLabel(cell.getValue()),
      accessorDownload: function (value: number, data: any, type: any, params: any, column: any) {
        return utils.getStatusLabel(value)
      }
    },
    {
      title: "Ordered By",
      field: "orderedByName",
      headerFilter: 'select',
      headerFilterParams: {
        options: userOptions
      },
    }
  ];
}
