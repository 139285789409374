import { CURRENTPARTNERS_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { PartnerDto } from "common-types";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchPartners";

export const fetchPartners = async(request: EntityPageRequest): Promise<PagedListDto<PartnerDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${CURRENTPARTNERS_ENDPOINT}${EntityPageRequestToQueryString(request)}`,
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchPartners = (): void => tryCancelPending(signalKey);