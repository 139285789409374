import { BasketItemDto } from "./basketItemDto";

export class OrderDto {
    id: number;
    name: string;
    createdByName?: string;
    createdDate?: Date;
    submittedByName?: string;
    submittedDate?: Date;
    deletedByName?: string;
    deletedDate?: Date;
    completedByName?: string;
    completedDate?: Date;
    cancelledByName?: string;
    cancelledDate?: Date;
    serviceRequestOrderStatusId: number;
    orderItems: BasketItemDto[];
}

export const buildEmptyBasket = (): OrderDto => { 
    return { id: 0, name: '', orderItems: [], createdByName: '', serviceRequestOrderStatusId: 0 };
};
