import produce from "immer";
import { AuthActionTypes } from "./auth-actions";
import { AuthState } from "./auth-state";
import { FetchAuthenticatedUserAction, FetchAuthenticatedUserSuccessAction, FetchIsAuthenticatedAction, FetchIsAuthenticatedSuccessAction } from "./auth-action-types";
import { FetchStatus } from "common-types/fetchStatusEnum";

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  authenticatedUserFetchStatus: FetchStatus.Default,
  isAuthenticatedFetchStatus: FetchStatus.Default
};

type Action = (FetchAuthenticatedUserSuccessAction & FetchAuthenticatedUserAction & FetchIsAuthenticatedAction & FetchIsAuthenticatedSuccessAction) | undefined;

export default (state: AuthState = initialState, action: Action = undefined): AuthState =>
  produce(state, draft => {
    switch (action?.type) {
      case AuthActionTypes.FETCH_AUTHENTICATED_USER: {
        draft.authenticatedUserFetchStatus = FetchStatus.InProgress;
        return;
      }
      case AuthActionTypes.FETCH_AUTHENTICATED_USER_SUCCESS: {
        const { user } = action;

        draft.user = user;
        draft.authenticatedUserFetchStatus = FetchStatus.Complete;
        return;
      }
      case AuthActionTypes.FETCH_AUTHENTICATED_USER_FAILURE: {
        draft.user = null;
        draft.authenticatedUserFetchStatus = FetchStatus.Error;
        return;
      }
      case AuthActionTypes.FETCH_IS_AUTHENTICATED: {
        draft.isAuthenticatedFetchStatus = FetchStatus.InProgress;
        return;
      }
      case AuthActionTypes.FETCH_IS_AUTHENTICATED_SUCCESS: {
        const { isAuthenticated } = action;
        draft.isAuthenticatedFetchStatus = FetchStatus.Complete;
        draft.isAuthenticated = isAuthenticated;
        return;
      }
      case AuthActionTypes.FETCH_IS_AUTHENTICATED_FAILURE: {
        draft.isAuthenticatedFetchStatus = FetchStatus.Error;
        draft.isAuthenticated = false;
        return;
      }
      default:
        return state;
    }
  });
