export function handleScroll(this: any) {
  if (window.scrollY > 0 && this.state.fixDetails === false) {
    this.setState({
      fixDetails: true,
    });
  } else if (window.scrollY <= 0 && this.state.fixDetails === true) {
    this.setState({
      fixDetails: false,
    });
  }
  setHeaderHeight(this);
};

export function handleResize(this: any) {
  this.setState({
    mobileMode: window.innerWidth < 600,
  });
  setHeaderHeight(this);
}

export function setHeaderHeight(component?: any) {
  let newHeaderHeight = document.querySelector("header")?.getBoundingClientRect().height;
  if (newHeaderHeight !== component.state.headerHeight) {
    component.setState({
      headerHeight: newHeaderHeight,
      maxHeaderHeight: newHeaderHeight && newHeaderHeight > component.state.maxHeaderHeight ? newHeaderHeight : component.state.maxHeaderHeight
    });
  }
};
