
export const selectMany = (array: any[], propFunc: (a: any) => any[]): any[] => {
    let result: string[] = [];
    
    const spreader = (item: any, propFunc: (a: any) => any[]) : any[] => {
        const i = propFunc(item);
        if (!i)
            return [];

        return i;
    };
    
    array.forEach(element => {
        if (element && propFunc)
            result = [...result, ...spreader(element, propFunc)];
    });

    return result;
};