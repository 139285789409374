import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";

export const RecentlyViewedEntitiesActionTypes = {
  FETCH_RECENTLYVIEWEDENTITIES: "FETCH_RECENTLYVIEWEDENTITIES",
  FETCH_RECENTLYVIEWEDENTITIES_SUCCESS: "FETCH_RECENTLYVIEWEDENTITIES_SUCCESS",
  FETCH_RECENTLYVIEWEDENTITIES_FAILURE: "FETCH_RECENTLYVIEWEDENTITIES_FAILURE",
  SET_REDIRECTED: "SET_REDIRECTED",
  IN_PROGRESS: "IN_PROGRESS",
};

export const RecentlyViewedEntitiesActions = {
  fetchRecentlyViewedEntities: () => ({
    type: RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES,
  }),

  fetchRecentlyViewedEntitiesSuccess: (
    recentlyViewedEntities: PagedListDto<EntityDto>
  ) => ({
    type: RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES_SUCCESS,
    recentlyViewedEntities,
  }),

  fetchRecentlyViewedEntitiesFailure: (error: any) => ({
    type: RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES_FAILURE,
    error,
  }),

  setRedirected: () => ({
    type: RecentlyViewedEntitiesActionTypes.SET_REDIRECTED,
  }),
};
