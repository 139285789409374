import { CurrentManagerDto } from "common-types";
import { ENTITIES_ENDPOINT, CURRENTMANAGERS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import {
  EntityPageRequest,
  EntityPageRequestToQueryString,
} from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchCurrentManagers";

export const fetchCurrentManagers = async (
  request: EntityPageRequest
): Promise<PagedListDto<CurrentManagerDto>> =>
  ApiCaller.get(
    `${ENTITIES_ENDPOINT}/${
      request.entityId
    }${CURRENTMANAGERS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });
    
export const cancelFetchCurrentManagers = (): void => tryCancelPending(signalKey);
