import { EntityDetailDto } from "common-types/entityDetailDto";
import { EntityDto } from "common-types/entityDto";

export const EntityActionTypes = {
  FETCH_ENTITY: "FETCH_ENTITY",
  FETCH_ENTITY_SUCCESS: "FETCH_ENTITY_SUCCESS",
  FETCH_ENTITY_FAILURE: "FETCH_ENTITY_FAILURE",
  CLEAR_CURRENT_ENTITY: "CLEAR_CURRENT_ENTITY",
  SHOW_ENTITY_DETAIL: "SHOW_ENTITY_DETAIL",
  FETCH_ENTITY_DETAIL_SUCCESS: 'FETCH_ENTITY_DETAIL_SUCCESS',
  DOWNLOAD_ENTITY_DOCUMENT: 'DOWNLOAD_ENTITY_DOCUMENT',
  DOWNLOAD_ENTITY_INVOICE: 'DOWNLOAD_ENTITY_INVOICE'
};

export const EntityActions = {
  fetchEntity: (entityId: number) => ({
    type: EntityActionTypes.FETCH_ENTITY,
    entityId,
  }),

  fetchEntitySuccess: (entity: EntityDto) => ({
    type: EntityActionTypes.FETCH_ENTITY_SUCCESS,
    entity,
  }),

  fetchEntityFailure: (error: any) => ({
    type: EntityActionTypes.FETCH_ENTITY_FAILURE,
    error,
  }),

  clearCurrentEntity: () => ({
    type: EntityActionTypes.CLEAR_CURRENT_ENTITY,
  }),

  fetchEntityDetailSuccess: (entityDetail: EntityDetailDto) => ({
    type: EntityActionTypes.FETCH_ENTITY_DETAIL_SUCCESS,
    entityDetail
  }),

  downloadDocument: (entityId: number, documentId: string) => ({
    type: EntityActionTypes.DOWNLOAD_ENTITY_DOCUMENT,
    entityId, 
    documentId
  }),

  downloadInvoice: (entityId: number, invoiceId: string) => ({
    type: EntityActionTypes.DOWNLOAD_ENTITY_INVOICE,
    entityId, 
    invoiceId
  })
};
