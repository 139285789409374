import { Component } from "react";
import { PartnershipDto } from "common-types";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";

export type PartnershipsProps = SimplePagedSectionProps<PartnershipDto>;

class Partnerships extends Component<PartnershipsProps> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default Partnerships;
