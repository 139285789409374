import { KirbySection } from "maples-kirby-react";
import styles from "./Errors.module.scss";
import { EmailHelp } from ".";

export function Page204() {
  return (
    <div className={styles.errorPage}>
      <KirbySection sectionTitle="Not Found">
        <div className={styles.content}>
          The requested action could not be completed. Please try again and
          contact <EmailHelp /> for further assistance if this continues.
        </div>
      </KirbySection>
    </div>
  );
}
