import styles from "./EntityFilings.module.scss";
import { KirbySection, KirbyTableServer } from "maples-kirby-react";
import { getActions, getColumns } from "./EntityFilingsGrid";
import { useCallback, useEffect, useState, useRef } from "react";
import { ESFilingStatuses, EconomicSubstanceFilingDto } from "common-types/economicSubstanceFilingDto";
import { PagedListDto } from "common-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { FullDataRequest, PagedDataRequest } from "api/interfaces/pagedDataRequest";
import { HandleDataChangeData } from "common-types/kirby/serverTable/handleDataChangeData";
import { toFullDataRequest, toPagedDataRequest } from "common-types/converters/pagedDataRequestConverter";
import { defaultFilingsFilter } from "modules/entityfilings/entityFilings-selectors";
import { EntityFilingGridFilters } from "./types/EntityFilingGridFilters";
import { SwitchCallbackData } from "common-types/kirby/serverTable/switchCallbackData";
import { addToast, updateToast } from "components/common/Toaster";
import { genericErrorMessage } from "helpers/apiCaller/constants";
import { cloneDeep } from "lodash";
import { Actions, hasAction } from "../../helpers/userActions";
import AccessDenied from "../Pages/Errors/accessDenied";
import { GridFilterOption } from "../../common-types/grids/gridFilterOption";
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";
import { PageTracker } from "../controls/navigation/PageTracker";
import { PageIdentifierEnum } from "../../common-types/pageIdentifierEnum";
import { entityFilingsGridMessages } from "constants/copy/entityFilings/entityFilingsGridMessage";
import { ApiResponse } from "../../helpers/apiCaller/ApiResponse";
interface EntityFilingsProps {
  page: PagedListDto<EconomicSubstanceFilingDto>;
  fetchStatus: FetchStatus;
  downloadStatus: FetchStatus;
  filters: PagedDataRequest;
  filterAndSort: (request: PagedDataRequest) => void;
  download: (request: FullDataRequest, callBack: (response: ApiResponse<any>) => void) => void;
}

const EntityFilings = (props: EntityFilingsProps) => {
  const defaultLoadingMessage: string = 'Loading...';
  const [linkClicked, setLinkClicked] = useState(false);
  const [customActions, setCustomActions] = useState<any>()
  const [loadingMessage, setLoadingMessage] = useState(defaultLoadingMessage);
  const [downloadToastId, setDownloadToastId] = useState<any>();
  const tabRef = useRef<any>(null);
  const showCompletedRef = useRef(false);
  const { filters, download, filterAndSort, page, downloadStatus } = props;
  const [entityFilingsEnabled, entityFilingsFlagLoaded] = useFeatureFlag('EntityFilings');


  /** Load filings on component load. **/
  useEffect(() => {
    document.title = "Entity Filings";
    const apifFilter = props.filters.filters.length === 0 ? defaultFilingsFilter : { ...defaultFilingsFilter, filters: props.filters.filters }
    filterAndSort(apifFilter);
    if (tabRef.current)
      tabRef.current.addEventListener("KirbyTableRendered", () => {
        toggleCompletedOption();
      })
  }, []);

  /** Add event listener on focus so the filings data refreshes when user returns to page after clicking a filing link. **/
  useEffect(() => {
    const onFocus = () => {
      if (!linkClicked)
        return;

      setLoadingMessage('Refreshing...');
      filterAndSort(filters);
      setLinkClicked(false);
    };

    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [linkClicked, filters]);

  const downloadCallback = useCallback(async (request: FullDataRequest) => {
    if (downloadStatus === FetchStatus.InProgress)
      return;
    const toastId = await addToast('Exporting Entity Filings...', '', 'spinner');
    setDownloadToastId(toastId);

    /** Update toasts when download status changes. **/
    const handleToastAfterDownload = (response: ApiResponse<any>) => {
      if (response.statusCode === 200)
        updateToast(toastId, 'Export Complete!', '', 'success');
      else
        updateToast(toastId, "Export Failed.", genericErrorMessage, "error");
    }

    download(request, ((response: ApiResponse<any>) => handleToastAfterDownload(response)));
  }, [downloadStatus, download]);

  /** Update grid actions **/
  useEffect(() => {
    const exportDisabled = page.totalCount === 0 || props.downloadStatus === FetchStatus.InProgress;
    const includeCompletedCallback = (data: SwitchCallbackData<EntityFilingGridFilters>) => {
      showCompletedRef.current = data.switchState;
      if (data.switchState)
        data.filters.statuses = ESFilingStatuses;
      else
        data.filters.statuses = ESFilingStatuses.filter(a => a !== 'Completed');
      filterAndSort(toPagedDataRequest(data));
    };
    setCustomActions(getActions(exportDisabled, (state: any) => downloadCallback(toFullDataRequest(state)), includeCompletedCallback));
  }, [page, downloadStatus, filterAndSort, downloadCallback]);

  const handleDataChange = (data: HandleDataChangeData<EntityFilingGridFilters>) => {
    setLoadingMessage(defaultLoadingMessage);
    filterAndSort(toPagedDataRequest(data));
  }

  const columnsCallback = useCallback(() => {
    const tempCols: any = cloneDeep(getColumns(() => setLinkClicked(true), getFilingTypeOptions()))
    if (props.filters.filters.length > 0) {
      props.filters.filters.map((filter: any) => {
        const matchingCol = tempCols.find((col: any) => { return col.field === filter.fieldName })
        if (matchingCol) {
          const filterFormat = getIntialFilterFormat(matchingCol.headerFilter, filter)
          matchingCol.initialFilterValue = filterFormat;
        }
      })
    }
    return tempCols;
  }, []);

  /*Hide Completed Option in status filter*/
  const toggleCompletedOption = () => {
    const completedOption = (document.querySelector("li[data-value='Completed']") ? document.querySelector("li[data-value='Completed']") : document.getElementsByTagName("kirby-table-server")[0].shadowRoot?.querySelector("li[data-value='Completed']")) as HTMLElement;
    if (completedOption) completedOption.style.display = showCompletedRef.current ? 'block' : 'none';
  }

  const getFilingTypeOptions = (): GridFilterOption[] => {
    const options: GridFilterOption[] = [];

    if (hasAction(Actions.BVIEconomicSubstance))
      options.push({ value: 'BVI Economic Substance', label: 'BVI Economic Substance' });

    if (hasAction(Actions.BVIAnnualReturn))
      options.push({ value: 'BVI Annual Return', label: 'BVI Annual Return' });

    return options;
  }

  const getIntialFilterFormat = (headerFilter: string, filter: any) => {
    switch (headerFilter) {
      case "text":
        return filter.value1;
      case "select":
        if (filter.fieldName === 'status') {
          return filter.value1.includes(",") ? filter.value1.split(',') : [filter.value1];
        } else {
          return filter.value1;
        }
      case "date":
        return filter.value2 ? {
          type: "dateRangeMatch",
          value: { start: filter.value1, end: filter.value2 }
        } : {
          type: "dateMatch",
          value: filter.value1
        }
    }
  }

  return (
    <>
      {hasAction(Actions.ReadEconomicSubstanceFiling) && entityFilingsEnabled ? (
        <div className={styles.page}>
          <PageTracker path={PageIdentifierEnum.EntityFilings} />
          <KirbySection sectionTitle="Entity Filings">
            <div className={styles.content}>
              <KirbyTableServer
                ref={tabRef}
                columns={columnsCallback()}
                data={props.page.page}
                handleDataChange={handleDataChange}
                batchFilter={true}
                defaultPageSize={20}
                pageSizeOptions={[20, 50, 100]}
                rowCount={props.page.totalCount}
                pageIndex={props.page.currentPage}
                showManageColumns={true}
                customActions={customActions}
                loading={{
                  active: props.fetchStatus === FetchStatus.InProgress,
                  text: loadingMessage
                }}
                headerSortable={false}
                resetTableColumns={getColumns(() => setLinkClicked(true), getFilingTypeOptions())}
                emptyMessage={entityFilingsGridMessages.NotFound}
              />
            </div>
          </KirbySection>
        </div>
      ) : entityFilingsFlagLoaded && (<AccessDenied></AccessDenied>)
      }
    </>
  )
}

export default EntityFilings;
