import { ShippingDetailsDto } from "common-types/shippingDetailsDto"

export const ShippingDetailsActionTypes = {
  FETCH_SHIPPING_DETAILS: "FETCH_SHIPPING_DETAILS",
  FETCH_SHIPPING_DETAILS_SUCCESS: "FETCH_SHIPPING_DETAILS_SUCCESS",
  FETCH_SHIPPING_DETAILS_FAILURE: "FAILURE",
  CANCEL_FETCH_SHIPPING_DETAILS: "CANCEL_FETCH_SHIPPING_DETAILS"
}

export const ShippingDetailsActions = {
  fetchShippingDetails: (entityId: number) => ({
    type: ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS,
    entityId
  }),

  fetchShippingDetailsSuccess: (shippingDetailsDto: ShippingDetailsDto) => ({
    type: ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS_SUCCESS,
    shippingDetailsDto
  }),

  fetchShippingDetailsFailure: (error: any) => ({
    type: ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS_FAILURE,
    error
  }),

  cancel: () => ({
    type: ShippingDetailsActionTypes.CANCEL_FETCH_SHIPPING_DETAILS
  })
};
