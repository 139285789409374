import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getCurrentPage, getDownloadStatus, getFetchStatus, getFilterRequest } from "modules/entityfilings/entityFilings-selectors";
import { FullDataRequest, PagedDataRequest } from "api/interfaces/pagedDataRequest";
import { DownloadEntityFilingsAction, FetchEntityFilingsAction } from "modules/entityfilings/types/entityFilings-actions-types";
import { entityFilingActions } from "modules/entityfilings/entityFilings-actions";
import EntityFilings from "./EntityFilings";
import { ApiResponse } from "../../helpers/apiCaller/ApiResponse";

const mapStateToProps = (state: AppState) => ({
    page: getCurrentPage(state),
    fetchStatus: getFetchStatus(state),
    downloadStatus: getDownloadStatus(state),
    filters: getFilterRequest(state)
});

const mapDispatchToProps = {
    filterAndSort: (request: PagedDataRequest): FetchEntityFilingsAction => entityFilingActions.fetchFilings(request),
    download: (request: FullDataRequest, callBack: (response: ApiResponse<any>) => void): DownloadEntityFilingsAction => entityFilingActions.downloadFilings(request,callBack)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityFilings);
