import { ReactElement, useEffect } from "react";
import ServerTableContainerProps, {
  ServerTableContainerDispatchProps,
  TableDataChangeResponse,
} from "./types/ServerTableContainerProps";
import {
  KirbySection,
  KirbyTableServer,
  KirbySpinner,
} from "maples-kirby-react";
import componentStyles from "./ServerTableContainer.module.scss";

type ServerTableContainerFnProps = ServerTableContainerProps<any> &
  ServerTableContainerDispatchProps;

export const ServerTableContainer = (props: ServerTableContainerFnProps) => {
  const {
    collapsed,
    columns,
    currentPageInfo,
    handleOpenToggle,
    header,
    id,
    list,
    loading,
    pageSize,
    responsiveWidth,
    rowFormatter
  } = props;

  useEffect(() => {
    const pageSize = getCurrentPageSize(props);
    const pageNumber = getCurrentPageNumber(props);
    getPage(props, pageNumber, pageSize);

    return () => unmount(props);
  }, []);

  useEffect(() => {
    getPage(props, 1, pageSize);
  }, [id, pageSize]);

  useEffect(() => {
    props.updateLoadingStatus({
      loading: props.loading,
      hasData: props.list?.length > 0,
      name: props.header,
    });
  }, [loading]);

  return (
    <KirbySection
      data-testid={"entity-section-" + header}
      sectionTitle={header}
      open={!collapsed}
      handleOpenToggle={() => handleOpenToggle && handleOpenToggle(header)}>
      {list?.length > 0 ? (
        <div className="content">
          <KirbyTableServer
            columns={columns}
            data={JSON.parse(JSON.stringify(list)) ?? []}
            rowFormatter={rowFormatter}
            defaultPageSize={pageSize}
            pageIndex={currentPageInfo.pageNumber}
            handleDataChange={(dataParams: TableDataChangeResponse) => {
              getPage(props, dataParams.newPageIndex, dataParams.newPageSize);
            }}
            loading={{
              active: props.loading ?? false,
              text: "Loading...",
            }}
            rowCount={currentPageInfo.totalCount}
            pageSizeOptions={[5, 10, 20]}
            headerSortable={false}
            widthBreakpoints={{
              responsiveWidth: responsiveWidth,
              stickyColumnsActive: 25,
            }}
            hideReset={true}
          />
        </div>
      ) : (
        <span className={componentStyles.tablemessage}>
          {getSupplimentaryMessage(props)}
        </span>
      )}
    </KirbySection>
  );
};

const getPage = (
  props: ServerTableContainerFnProps,
  pageNumber: number,
  pageSize: number
): void => {
  const { fetch, id } = props;
  fetch({ entityId: id, pageNumber, pageSize });
  props.currentPageInfo.pageSize &&
    props.handleTableChange(props.header, pageSize);
};

const getCurrentPageSize = (props: ServerTableContainerFnProps): number =>
  props.pageSize ?? props.currentPageInfo.pageSize ?? 5;

const getCurrentPageNumber = (props: ServerTableContainerFnProps): number =>
  props.currentPageInfo.pageNumber ?? 1;

const getSupplimentaryMessage = (
  props: ServerTableContainerFnProps
): ReactElement | string | null => {
  const { loading, list, emptyMessage } = props;
  if (loading) return <KirbySpinner single size="small"></KirbySpinner>;
  if (list?.length > 0) return null;

  return emptyMessage ?? "No data present for this entity";
};

const unmount = (props: ServerTableContainerFnProps) => {
  if (props.cancel) props.cancel();
};

export default ServerTableContainer;
