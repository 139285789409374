import { useEffect } from "react";
import { useLocation } from "react-router";
import { PageIdentifierEnum } from "../../../common-types/pageIdentifierEnum";
import { auditPageVisit } from "../../../api/audit-api";
import { PageIdentifierActionEnum } from "../../../common-types/pageIdentifierActionEnum";
import { ActionDescriptionEnum } from "../../../common-types/actionDescriptionEnum";

export interface PageTrackerProps {
  path: PageIdentifierEnum;
}

export const PageTracker = (pageTrackerProps: PageTrackerProps) => {

  let location = useLocation();

  useEffect(() => {
    switch (pageTrackerProps.path) {
      case PageIdentifierEnum.Invoices: {
        logPageVisit(PageIdentifierActionEnum.ViewInvoices,ActionDescriptionEnum.NavigateToInvoices);
        break;
      }
      case PageIdentifierEnum.EntityFilings: {
        logPageVisit(PageIdentifierActionEnum.ViewEntityFilings, ActionDescriptionEnum.NavigateToEntityFilings);
        break;
      }
      default: {
        logPageVisit(location.pathname,''); 
        break;
      }
    }
  }, [location]);

 const logPageVisit = async (actionType: string, description: string) => {
   await auditPageVisit(actionType, description);
  }
  return (<></>);
};
