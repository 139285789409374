import produce from "immer";
import { RegistersActionTypes } from "./registers-actions";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { RegistersState } from "./types/registers-state";
import {
  FetchRegistersAction,
  FetchRegistersSuccessAction,
} from "./types/registers-actions-types";

const initialState: RegistersState = {
  registers: undefined,
  fetchStatus: undefined,
  entityId: 0,
};

export default (
  state = initialState,
  action: FetchRegistersAction & FetchRegistersSuccessAction | undefined = undefined
): RegistersState =>
  produce(state, (draft) => {
    switch (action?.type) {
      case RegistersActionTypes.FETCH_REGISTERS: {
        const { entityId } = action;
        draft.entityId = entityId;
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case RegistersActionTypes.FETCH_REGISTERS_SUCCESS: {
        const { registers } = action;
        draft.registers = registers;
        draft.fetchStatus = registers
          ? FetchStatus.Complete
          : FetchStatus.NoContent;
        return;
      }
      case RegistersActionTypes.FETCH_REGISTERS_FAILURE: {
        draft.registers = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
