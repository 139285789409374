import { FormState } from "common-types/forms/FormState";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { UserSettingsState } from "./types/userSettingsState";

const userSettingsStateSelector = (state: AppState): UserSettingsState => state.userSettings;

export const getUserSettings = createSelector(
  userSettingsStateSelector,
  (userSettingsState) => userSettingsState?.settings ?? undefined
);

export const getFormState = createSelector(
  userSettingsStateSelector,
  (userSettingsState) => {
    if (!userSettingsState) 
      return FormState.Active;

    return userSettingsState.formState;
  }
)

export const getSaveSettingsStatus = createSelector(
  userSettingsStateSelector,
  (userSettingsState) => userSettingsState?.saveSettingsStatus
);