export class DocumentFolderDto {
    id: string;
    identifier: string;
    source: string;
    parentId: any;
    parentIdentifier: string | undefined;
    isRoot: boolean;
    name: string;
    displayName: string;
    description: string;
    documentType: string;
    folders: DocumentFolderDto[];
    hasChildFolders: boolean;
    features: string[];
  }