import { useCallback, useEffect, useRef, useState } from "react";
import { KirbyTextfield, KirbyDialog } from "maples-kirby-react";
import { renameDocumentItem } from "../Actions/doc-management-actions";
import { useParams } from "react-router";
import styles from "./RenameFolderDialog.module.scss";
import { DocumentDto } from "common-types/documentDto";
import { Copy } from "constants/copy/document-management/copy";
import { getMercuryDocumentName } from "../Common/documentUtilities";
import { isFolderReplacement } from "../Common/documentErrorMessageUtils";
import { useGlobalContext } from "../DocManagement";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { addToast, removeToast } from "components/common/Toaster";

export interface RenameFolderDialogProps {
  open: boolean;
  document: DocumentDto | undefined;
  handleClose: Function;
  handleNotFound: Function;
  documentUpdated: Function;
}

const RenameFolderDialog = (props: RenameFolderDialogProps) => {
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;

  const { document } = props;
  const [renameFolderName, setRenameFolderName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const KirbyTextfieldRef = useRef<any>(null);
  let activetoast : string;
  const { entityFolders } = useGlobalContext();

  const dialogTitle = `Rename ${document?.isFolder ? "Folder" : "Document"}`;
  const dialogButtonText = `Rename ${
    document?.isFolder ? "Folder" : "Document"
  }`;

  const { open, handleClose } = props;

  let documentName = document?.name ?? "";

  const onHandleClose = useCallback(() => {
    setErrorMessage("");
    handleClose();
  }, [handleClose, documentName]);

  useEffect(() => {
    if (open) {
      const keyDownHandler = (event: {
        key: string;
        preventDefault: () => void;
      }) => {
        if (event.key === "Escape") {
          event.preventDefault();
          onHandleClose();
        }
      };
      window.document.addEventListener("keydown", keyDownHandler);

      return () => {
        window.document.removeEventListener("keydown", keyDownHandler);
      };
    }
  }, [open, onHandleClose]);

  useEffect(() => {
    setRenameFolderName(documentName);
  }, [documentName]);

  const isValidFoldername = (
    folderName: string,
    folders: DocumentFolderDto[]
  ) => {
    if (document?.name.toLowerCase() === folderName.toLowerCase()) {
      setErrorMessage(Copy.RenameNoChange);
      return false;
    }

    if (
      folders.filter(
        (x) =>
          x.name.toLowerCase() === folderName.toLowerCase() &&
          x.parentId === document?.parentId
      ).length > 0
    ) {
      setErrorMessage(Copy.RenameFolderAlreadyExists);
      return false;
    }

    return true;
  };

  async function renameDocument(){
    let sanitizedFolderName = getMercuryDocumentName(renameFolderName);

    setRenameFolderName(sanitizedFolderName);

    if (
      entityFolders !== undefined &&
      !isValidFoldername(sanitizedFolderName, entityFolders)
    ) {
      return;
    }

    if (document) {
      onHandleClose();

      await addToast(
        Copy.RenameDocumentToastTitle,
        `Renaming '${documentName}' to '${renameFolderName}'`,
        "spinner"
      ).then((toastId:any)=>{
        activetoast = toastId;
      }).catch(() => {
        console.log("Unable to add toast")
      });

      await renameDocumentItem(
        entityId,
        sanitizedFolderName,
        document.id.toString()
      ).then((result) => {
        if (result.success) {
          setRenameFolderName(documentName);
          props.documentUpdated(document.id.toString(), result.value);
        } else {
          switch (result.statusCode) {
            case 403: {
              break;
            }
            case 404: {
              props.handleNotFound(document.id.toString());
              break;
            }
            default: {
              setErrorMessage(isFolderReplacement(document, result.error));
              break;
            }
          }
        }
        removeToast(activetoast).catch(() => {
          console.log("Unable to remove toast")
        });
      })
    }
  };

  const textFieldHandleChange = useCallback(
    (e: any) => {
      setRenameFolderName(e.target.value);
    },
    [setRenameFolderName]
  );

  return (
    <>
      <KirbyDialog
        open={props.open}
        handleClose={onHandleClose}
        header={dialogTitle}
        actions={[
          { action: dialogButtonText, func: renameDocument, primary: true },
        ]}>
        <KirbyTextfield
          ref={KirbyTextfieldRef}
          label="Name"
          value={renameFolderName}
          handleChange={textFieldHandleChange}
          test-id={"folder-rename"}
          aria-placeholder={document?.name}
        />
        <p className={styles.notification}>{errorMessage}</p>
      </KirbyDialog>
    </>
  );
};

export default RenameFolderDialog;
