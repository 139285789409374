import { useCallback, useEffect, useRef, useState } from "react";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { KirbyButton, KirbySearchBox } from "maples-kirby-react";
import { kirbyAdd } from "maples-kirby-icons";
import styles from "./DocManagement.module.scss";
import { useParams } from "react-router";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import NewFolderDialog from "./New/NewFolderDialog";
import { ToTreeItem } from "common-types/converters/iTreeItemConverters";
import { findNode } from "./Common/ITreeItemQueries";
import { useGlobalContext } from "./DocManagement";
import { UpdatedDocument } from "./Context/DocumentContext";
import { convertToTree } from "./Common/treeViewUtilities";
import ContentFolderTree from "./ContentFolderTree";

export interface FolderTreeProps {
  selectFolder: (value: DocumentFolderDto) => void;
  selected: Partial<DocumentFolderDto> | undefined;
  folderIdentifier: string;
  allowCreate: boolean;
  create: (
    entityId: number,
    parentId: string,
    folderProperties: { name: string }
  ) => Promise<{ success: boolean; error: string; value?: TreeItem }>;
  setIsSearchActive: Function;
  setCurrentSearchTerm: Function;
  panelOpenFunc?: Function;
}

export default function FolderTree(props: FolderTreeProps) {
  const { id } = useParams();
  const { selectFolder, selected, allowCreate, create, panelOpenFunc } = props;
  const [folders, setFolders] = useState<Array<TreeItem>>([]);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(
    FetchStatus.InProgress
  );
  const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
  const asyncTreeEl = useRef<HTMLKirbyTreeElement>(null);
  const entityId = id ? parseInt(id) : 0;
  const {
    entityFolders,
    folderDeletedKey,
    folderAdded,
    setFolderAdded,
    folderRenamed,
  } = useGlobalContext();

  const addItemToTree = useCallback(
    (newItem: TreeItem) => {
      asyncTreeEl?.current?.getTree().then((tree: Array<TreeItem>) => {
        let parentNode = findNode(tree, newItem.value.parentId.toString());

        if (!parentNode) return;

        if (!parentNode.children) parentNode.children = [];

        parentNode.children.push(newItem);
        parentNode.hasChildren = true;

        let newChildren = sortChildren(parentNode);

        parentNode.children = newChildren;

        setFolders(JSON.parse(JSON.stringify(tree)));
      });
    },
    [asyncTreeEl]
  );

  const updateTreeItemName = useCallback(
    (updatedDocument: UpdatedDocument) => {
      let key = updatedDocument.key;

      asyncTreeEl?.current?.getTree().then((tree: Array<TreeItem>) => {
        let node = findNode(tree, key);
        if (node) {
          let parentNode = findNode(tree, node?.value?.parentId);

          if (parentNode) {
            let newTreeItem = ToTreeItem(updatedDocument.document);

            const index = parentNode.children.indexOf(node, 0);
            parentNode.children[index] = newTreeItem;

            parentNode.children = sortChildren(parentNode);
          }

          let newValue: TreeItem[] = [];

          tree.forEach((doc) => {
            newValue.push(Object.assign({}, doc));
          });

          setFolders(JSON.parse(JSON.stringify(newValue)));
        }
      });
    },
    [asyncTreeEl]
  );

  useEffect(() => {
    if (entityFolders !== undefined) {
      setFetchStatus(FetchStatus.InProgress);
      let newTreeItems = convertToTree(entityFolders);
      setFolders(newTreeItems);
      setFetchStatus(FetchStatus.Complete);
    }
  }, [entityFolders]);

  useEffect(() => {
    if (selected !== undefined) {
      expandParentNodes(selected.id ?? "");
    }
  }, [selected]);

  useEffect(() => {
    if (folderDeletedKey.length > 0) {
      removeItemFromTree(folderDeletedKey);
    }
  }, [folderDeletedKey]);

  useEffect(() => {
    if (folderAdded) {
      addItemToTree(folderAdded);
    }
  }, [folderAdded, addItemToTree]);

  useEffect(() => {
    if (folderRenamed !== undefined) {
      folderRenamed.document.id = folderRenamed.key;
      updateTreeItemName(folderRenamed);
    }
  }, [folderRenamed, updateTreeItemName]);

  const sortChildren = (parentNode: any) => {
    return parentNode.children
      ?.sort((a: { label: string }, b: { label: string }) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map((x: any) => x);
  };

  const openAddFolder = useCallback(() => {
    setAddFolderDialogOpen(true);
  }, [setAddFolderDialogOpen]);

  const createFolder = useCallback(
    (name: string, onComplete: Function) => {
      if (!selected?.id) return;

      create(entityId, selected.id, { name: name }).then((result) => {
        if (result.success) {
          let item = ToTreeItem(result.value?.value);
          setFolderAdded(item);
          setAddFolderDialogOpen(false);
        }
        onComplete(result);
      });
    },
    [selected, create, entityId, setFolderAdded, setAddFolderDialogOpen]
  );

  const expandParentNodes = async (identifier: string) => {
    let workingTree = await asyncTreeEl?.current?.getTree();

    if (!workingTree) return;

    let node: TreeItem = findNode(workingTree, identifier);
    if (!node?.value) return;

    let parentNode: TreeItem = findNode(workingTree, node.value.parentId);
    while (parentNode && node.key !== parentNode.key) {
      parentNode.expanded = true;
      parentNode = findNode(workingTree, parentNode.value.parentId);
    }
  };

  const removeItemFromTree = (key: string) => {
    asyncTreeEl?.current?.getTree().then((tree: Array<TreeItem>) => {
      let node = findNode(tree, key);

      if (node) {
        let parentNode = findNode(tree, node?.value?.parentId);

        if (parentNode && parentNode.children) {
          const index = parentNode.children.indexOf(node, 0);

          if (index > -1) {
            parentNode.children.splice(index, 1);
          }

          if (parentNode.expanded && parentNode.children?.length === 0) {
            parentNode.hasChildren = false;
            parentNode.expanded = false;
          }
        }
      }
      let newValue: TreeItem[] = [];
      tree.forEach((doc) => newValue.push(Object.assign({}, doc)));
      setFolders(JSON.parse(JSON.stringify(newValue)));
    });
  };

  const search = useCallback(
    (searchTerm: string) => {
      if (searchTerm === undefined || searchTerm === "") {
        props.setIsSearchActive(false);
        props.setCurrentSearchTerm(undefined);
        return;
      }

      props.setIsSearchActive(true);
      props.setCurrentSearchTerm(searchTerm);
      !!panelOpenFunc && panelOpenFunc(false);
    },
    [props]
  );

  const newFolderDialogHandleClose = useCallback(() => {
    setAddFolderDialogOpen(false);
  }, [setAddFolderDialogOpen]);

  return (
    <>
      <div className={styles.folderTree} data-testid="folder-tree">
        <div className={styles.sectionTitle}>
          <h4>Folders</h4>
          <KirbyButton
            icon={kirbyAdd}
            handleClick={openAddFolder}
            title="New Folder"
            disabled={!allowCreate}
            data-testid={"new-button"}>
            New
          </KirbyButton>
        </div>
        <div className={styles.docManagementSearch}>
          <KirbySearchBox searchFunction={search} label="Search Documents" />
        </div>
        <ContentFolderTree
          entityId={entityId}
          folders={folders}
          selectFolder={selectFolder}
          treeElement={asyncTreeEl}
          selected={props.selected}
          fetchStatus={fetchStatus}
        />
      </div>
      <div data-testid="new-folder-dialog">
        <NewFolderDialog
          open={addFolderDialogOpen}
          confirmFunction={createFolder}
          handleClose={newFolderDialogHandleClose}
          parentName={selected?.displayName}
        />
      </div>
    </>
  );
}
