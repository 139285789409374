import { useFeatureFlagsContext } from "./FeatureFlagsProvider";

function useFeatureFlag(flagKey: string) : [boolean, boolean] {
    const featureContext = useFeatureFlagsContext();

    if (!featureContext?.isInitialized)
        throw new Error("Feature flags are not initialized. Wrap app in <FeatureFlagsProvider> to initialize feature flags.");

    const isFlagEnabled = featureContext?.featureFlags?.includes(flagKey) ?? false;
    return [isFlagEnabled, true];
}

export default useFeatureFlag;