import { Component } from "react";
import { KirbyIcon } from "maples-kirby-react";
import { kirbyRegister, kirbyUpload } from "maples-kirby-icons";
import styles from "./Registers.module.scss";
import RegisterProperties from "./RegisterProperties";
import { RegisterManifestDto } from "common-types";
import { RegisterPropertyDto } from "common-types/registerPropertiesDto";

export interface IRegisterProps {
  register: RegisterManifestDto;
  registerProperties: RegisterPropertyDto | undefined;
  handleDownloadClick: (register: RegisterManifestDto) => void;
}

class Register extends Component<IRegisterProps> {
  render() {
    const { register, handleDownloadClick, registerProperties } = this.props;
    const title = !register.active
      ? "Register is currently offline"
      : `Download ${register.registerName}`;
    return (
      <div
        title={title}
        key={`header_${register.displayOrder}_${register.internalCode}_${register.registerName}`}
        className={`${styles.registersInstanceContainer} ${
          !register.active && styles.disabledRegister
        }`}>
        {register.active ? (
          <button
            className={styles.download}
            title={!register.active ? "" : `Download ${register.registerName}`}
            onClick={() => {
              handleDownloadClick(register);
            }}>
            <KirbyIcon
              data-testid="entity-registers-downloadbutton"
              className={styles.icon}
              icon={kirbyUpload}
            />
          </button>
        ) : (
          <KirbyIcon className={styles.icon} icon={kirbyRegister} />
        )}
        <div className={styles.registerTextContent}>
          {register.active ? (
            <button
              className={styles.buttonlink}
              key={`download_${register.displayOrder}_${register.internalCode}_${register.registerName}`}
              onClick={() => handleDownloadClick(register)}>
              {register.registerName}
            </button>
          ) : (
            <span id="test">{register.registerName}</span>
          )}

          <div className={styles.registerDetails}>
            <RegisterProperties
              registerProperties={registerProperties}
              registerName={register.registerName}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
