export type QueryStringParams = { [key: string]: string | number }

export const url = (endpoint: string, queryStringParams: QueryStringParams = {}): string => {
    const params = Object.keys(queryStringParams);
    return params.length === 0
        ? endpoint
        : `${endpoint}?${params.map(p => p + "=" + queryStringParams[p]).join("&")}`;
}

export const urlEncoded = (endpoint: string, queryStringParams: QueryStringParams = {}): string => {
    const params = Object.keys(queryStringParams);
    return params.length === 0
        ? endpoint
        : `${endpoint}?${params.map(p => p + "=" + encodeURIComponent(queryStringParams[p])).join("&")}`;
}


