import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { ApiResponse, toApiResponse } from "./apiCaller/ApiResponse";
import { genericErrorMessage } from "./apiCaller/constants";
import { navigateToPage } from "./history";
import { fetchIsAuthenticated } from 'api/auth-api';
import { getToken } from "api/antiforgery-api";

const headers= {};

axios.interceptors.request.use(
  async (request: InternalAxiosRequestConfig) => {
    const csrfMethods = ['post', 'put', 'patch', 'delete']
    if (csrfMethods.includes(request.method ?? ''))
      request.headers.set('X-CSRF-TOKEN', await getToken());

    return request;
  }
)
axios.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      switch(error.response.status) {
        case 401:
          setTimeout(()=>{
            // When logged out, there is a race condition between this and the RequireAuth component redirecting to login
            // This slight delay avoids flashing the 401 page in this scenario
            navigateToPage("401");
          }, 3000)

          break;
        case 403:
          fetchIsAuthenticated()
            .then(isUserAuthenticated => {
              const nextPage = isUserAuthenticated ? "unauthorised" : "403";
              navigateToPage(nextPage);
            })
            .catch(error => {
              console.log("Error fetching user authenticated status", error);
            });
          break;
        default:
          break;
      }

      return error.response;
    }

    return Promise.reject(error)
  }
)

const get = async(url: string, config: AxiosRequestConfig = {}) => {
    try {
      const response = await axios.get(url, config);
      return response?.data;
    } catch (err: unknown) {
        throw err;
    }
};

const post = async(url: string, body: any, config: AxiosRequestConfig<any> | undefined = undefined) => {
  try {
      const response = await axios.post(url, body, config);
      return response.data;
  } catch (err: any) {
      if (err && err.message === 'canceled')
        throw err;

      throw err;
  }
};

const apiResponsePost = async<TReturn>(url: string, body: any = null, handleError: boolean = false) : Promise<ApiResponse<TReturn>> => {
  try {
    const config: AxiosRequestConfig<any> = {
      validateStatus: (status) => status < 500  && status !== 401
    };

    const response: AxiosResponse<any, any> = await axios.post(url, body, config);

    return toApiResponse<TReturn>(response, 203);
  } catch (err: any) {
    if (!handleError) 
      throw err;    
    
    return {
      success: false,
      errors: [genericErrorMessage, err],
      statusCode: 500
    };
  }
};

const postFile = async <TReturn> (url: string, file: File): Promise<ApiResponse<TReturn>> => {
  try {
    let formData = new FormData();
    formData.append('file', file);
    
    let response = await axios.post(url, formData, {
      validateStatus: (status) => status < 500 && status !== 401
    })

    return toApiResponse<TReturn>(response, 203);
  } catch (err: any) {
    return {
      success: false,
      errors: [genericErrorMessage, err],
      statusCode: 500
    };
  }
};

const postBlobAudit = async <TReturn> (url: string, body: any, file: File): Promise<ApiResponse<TReturn>> => {
  try{
    let formData = new FormData();
    formData.append('file', file);
    formData.append('request', JSON.stringify(body));
    
    let response = await axios.post(url, formData, {
      validateStatus: (status) => status < 500 && status !== 401,
      headers: { "Content-Type": 'multipart/form-data' }
    })

    return toApiResponse<TReturn>(response, 203);
  } catch (err: any) {
    return {
      success: false,
      errors: [genericErrorMessage, err],
      statusCode: 500
    };
  }
};

const put = async(url: string, body: any) => {
    try {
        const response = await axios.put(url, body, headers);
        return response.data;
    } catch (err: unknown) {
        throw err;
    }
};

const patch = async(url: string, body: any) => {
  try {
      const response: AxiosResponse<any, any> = await axios.patch(url, body, {
        validateStatus: (status) => status < 500 && status !== 401,
        headers: { 'Content-Type': 'application/json' }
      });
      return response.data;

  } catch (err: unknown) {
      throw err; 
  }
};

const patchFile = async<TReturn>(url: string, body: any) : Promise<ApiResponse<TReturn>> => {
    try {
        const response: AxiosResponse<any, any> = await axios.patch(url, body, {
          validateStatus: (status) => status < 500 && status !== 401
        });
        return toApiResponse<TReturn>(response);

    } catch (err: unknown) {
        throw err; 
    }
};

const remove = async<TReturn>(url: string) : Promise<ApiResponse<TReturn>> => {

  try {
    const response: AxiosResponse<any, any> = await axios.delete(url, {
      validateStatus: (status) => status < 500 && status !== 401
    });

    return toApiResponse<TReturn>(response);

  } catch (err: unknown) {
    throw err;
  }
};

const downloadPost = async(url: string, blobHandler: Function, data: any = {}, config: AxiosRequestConfig = {}): Promise<ApiResponse<any>> => {
    
  try {
    if (!config) {
      config = { };
    }

    config.responseType = 'blob';
    config.validateStatus = config.validateStatus ? config.validateStatus : (status) => status !== 401;

    const response = await axios.post(url, data, config);

    return handleDownloadResponse(response, blobHandler);
  } catch (err: unknown) {
    throw err;
  }
};

const download = async(url: string, blobHandler: Function): Promise<ApiResponse<any>> => {
  try {
      const response = await axios.get(url, {
        responseType: 'blob',
        validateStatus: (status) => status !== 401
      });

      return handleDownloadResponse(response, blobHandler);
  } catch (err: unknown) {
    throw err;
  }
};

const handleDownloadResponse = async(response: AxiosResponse<any, any>, blobHandler: Function): Promise<ApiResponse<any>> => {

    let streamError = response.headers["x-stream-error-code"];
    if (streamError) {
      response.status = parseInt(streamError);
    }
  
    if (response.status !== 405 && response.status >= 400 && response.status < 500) {
      return toApiResponse(response, 201);
    }
  
    if (response.status === 200) {
      blobHandler(response);
    }
  
    return toApiResponse(response, 201);
};

export default { get, post, put, patch, patchFile, remove, download, downloadPost, postFile, postBlobAudit, apiResponsePost };
