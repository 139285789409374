import { kirbyDocument, kirbyFolderClosed, kirbyMsExcel, kirbyMsPowerpoint, kirbyMsWord, kirbyPdf } from "maples-kirby-icons";

export const getMercuryDocumentName = (name: string) => {
    /*
    Remove entire Script tags
    Remove surrounding HTML tags
    Remove <
    Remove |
    */
    let removeTagsExpr = /<[^>].+<\//gi;
    let htmlTagsExpr = /<.+>/gi;
    let ltExpr = /</gi;
    let pipeExpr = /\|/gi;

    return name.replace(removeTagsExpr,"")
                .replace(htmlTagsExpr,"")
                .replace(ltExpr,"")
                .replace(pipeExpr,"")
                .trim();
}

export const getContentIcon = (document: any) => {
    if(document.isFolder) 
        return kirbyFolderClosed;
    switch (document.fileType.toLowerCase()){
      case "pdf": 
        return kirbyPdf
      case "doc":
      case "docx": 
        return kirbyMsWord
      case "ppt":
      case "pptx": 
        return kirbyMsPowerpoint
      case "xls":
      case "xlsx": 
        return kirbyMsExcel
      case "txt":
      default: 
        return kirbyDocument
    }
  }
