import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "../../../../modules/entity/entity-selectors";
import { EntityIdentifiersActions } from "../../../../modules/entityIdentifiers/entity-identifiers-actions";
import { getEntityIdentifiers, getEntityIdentifiersFetchStatus } from "../../../../modules/entityIdentifiers/entity-identifiers-selectors";
import { FetchEntityIdentifiersAction } from "../../../../modules/entityIdentifiers/types/entity-identifiers-actions-types";
import EntityIdentifiers, { EntityIdentifiersProps } from "./EntityIdentifiers";
import { Action } from "typesafe-actions";

const mapStateToEntityIdentifiersProps = (state: AppState): Partial<EntityIdentifiersProps> => ({
  entityIdentifiers: getEntityIdentifiers(state),
  fetchEntityIdentifiersStatus: getEntityIdentifiersFetchStatus(state),
  entity: getEntity(state),
});

const mapDispatchToEntityIdentifiersProps = {
  fetchEntityIdentifiers: (entityId: number): FetchEntityIdentifiersAction => EntityIdentifiersActions.fetchEntityIdentifiers(entityId),
  cancel: (): Action => EntityIdentifiersActions.cancel()
};

export default connect(mapStateToEntityIdentifiersProps, mapDispatchToEntityIdentifiersProps)(EntityIdentifiers)
