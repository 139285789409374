import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from "redux-saga";
import { PagedListActions, PagedListActionTypes, PagedListFetchAction } from './paged-list-actions';
import { PagedListDto } from 'common-types';
import { EntityPageRequest } from 'api/interfaces/entityPageRequest';

export const buildSagaIterator = <Type>(
    name: string, 
    getMethod: (request: EntityPageRequest) => Promise<PagedListDto<Type>>) : () => SagaIterator => {
  const actionTypes = PagedListActionTypes(name);
  const actions = PagedListActions(name);

  function* watchFetchPage(): SagaIterator {
    yield takeEvery(actionTypes.FETCH, function* (action: PagedListFetchAction) {
      try {
        const { request } = action;
        
        let segregatedPortfolios: PagedListDto<Type> = yield call(getMethod, request);
        yield put(actions.fetchSuccess(segregatedPortfolios));
      }
      catch (e) {
        yield put(actions.fetchFailure(e));
      }
    })
  }

  return watchFetchPage;
}

export const buildCancelSagaIterator = <Type>(name: string, cancelMethod?: () => void) : () => SagaIterator => {
  const actionTypes = PagedListActionTypes(name);

  function* watchCancel(): SagaIterator {
    yield takeEvery(actionTypes.CANCEL_FETCH, function* () {
      if (cancelMethod)
        yield call(cancelMethod)
    })
  }

  return watchCancel;
}

export default function* PagedListSaga<Type>(
  name: string, 
  getMethod: (request: EntityPageRequest) => Promise<PagedListDto<Type>>,
  cancelMethod?: () => void): SagaIterator {
  yield all([
    fork(buildSagaIterator(name, getMethod)),
    fork(buildCancelSagaIterator(name, cancelMethod ?? (() => {})))
  ]);
}
