import { KirbyButton, KirbyDialog } from "maples-kirby-react";
import styles from "./fixedFeeFiles.module.scss";
import { ApiCaller } from "helpers";
import { AxiosResponse } from "axios";
import { browserDownloadResult } from "modules/download/fileDownload";
import { useCallback } from "react";
import {
  FIXEDFEE_SCHEDULE_CAYMANISLAND_FEES_DOC,
  FIXEDFEE_SCHEDULE_BVI_FEES_DOC,
  FIXEDFEE_SCHEDULE_AUTHORISED_REPRESENTATIVE_BVI_FEES_DOC
} from "../../constants/env"

export const FixedFeeFiles = (props: any) => {
  const files: Array<{ title: string; fileName: string }> = [
    {
      title: "Cayman Islands - Maples Corporate Services Limited Fixed Fees",
      fileName: FIXEDFEE_SCHEDULE_CAYMANISLAND_FEES_DOC,
    },
    {
      title:
        "British Virgin Islands - Maples Corporate Services (BVI) Limited Fixed Fees",
      fileName: FIXEDFEE_SCHEDULE_BVI_FEES_DOC,
    },
    {
      title:
        "British Virgin Islands - Maples Authorised Representative Services (BVI) Limited Fixed Fees",
      fileName: FIXEDFEE_SCHEDULE_AUTHORISED_REPRESENTATIVE_BVI_FEES_DOC,
    },
  ];

  const downloadFile = useCallback((fileInfo: { title: string; fileName: string }) => {
    const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
      browserDownloadResult(new Blob([axiosResponse.data]), fileInfo.fileName);
    }
    ApiCaller.download(`/static/footer/${fileInfo.fileName}`, blobHandler)
      .then((a) => {
        if (a.statusCode === 401)
          props.closeDialog();
      });
  }, []);

  return (
    <KirbyDialog
      open={props.open}
      handleClose={props.closeDialog}
      header="Fixed Fee Schedules">
      <ul className={styles.fileList}>
        {files.map((fileInfo) => (
          <li key={fileInfo.title}>
            <KirbyButton 
              className={styles.fileLink}
              title={fileInfo.title} 
              handleClick={(evt: any) => {
                evt.preventDefault();
                downloadFile(fileInfo);
            }}>
              {fileInfo.title}
            </KirbyButton>
          </li>
        ))}
      </ul>
    </KirbyDialog>
  );
};
