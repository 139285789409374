import styles from "./ServiceRequestsMessage.module.scss";
import { ServiceRequestsCopy } from "constants/copy/service-requests/service-request-copy";
import { RequestItemsProps } from "./ServiceRequestItems";

export default function ServiceRequestsMessage(props: RequestItemsProps) {
    
    const expressItems = props.requestItems?.filter(i => i?.certificationTypes.filter(j => j.supportExpress).length > 0) ?? [];

    return (
        expressItems.length > 0 ?
        <>  
            <div className={styles.expressMessage} data-testid="service-request-management-component-express-message">
                {ServiceRequestsCopy.OrderItems.ExpressMessage}
                <span className={styles.MandatoryMessage}>{ServiceRequestsCopy.OrderItems.MandatoryMessage}</span>
            </div>
        </> : <></>
    );
}