import { useEffect, useState, createContext, useMemo, useContext } from 'react'
import { FEATUREMANAGEMENT_ENDPOINT } from "constants/urls";
import ApiCaller from "helpers/ApiCaller";
import { FeatureFlagsContextType, DefaultFeatureFlagContext } from "./FeatureFlagsContext";

export const FeatureFlagsContext: React.Context<FeatureFlagsContextType> 
  = createContext<FeatureFlagsContextType>(DefaultFeatureFlagContext);

const FeatureFlagsProvider = (props: any) => {
    const [isInitialized] = useState<boolean>(true);
    const [featureFlags, setFeatureFlags] = useState<string[]>([]);
    
    useEffect(() => {
        ApiCaller.get(`${FEATUREMANAGEMENT_ENDPOINT}`)
            .then((features: string[]) => {
                setFeatureFlags(features);
            });
    }, []);

    const context = useMemo(() => {
        return { featureFlags, isInitialized };
    }, [featureFlags]);
    
    return (<FeatureFlagsContext.Provider value={context}>
                {props.children}
            </FeatureFlagsContext.Provider>);
}

export const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
export default FeatureFlagsProvider;