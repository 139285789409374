import { AcceptTermsResultDto } from "common-types/user/acceptTermsResultDto";
import { UserSettingsDto } from "common-types/user/userSettingsDto";
import { USERACCEPTTERMS_ENDPOINT, USERSETTINGS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { ApiResponse } from "helpers/apiCaller/ApiResponse";

export const fetchUserSettings = async(): Promise<UserSettingsDto> => 
  ApiCaller.get(USERSETTINGS_ENDPOINT);

export const saveUserSettings = async(settings: UserSettingsDto): Promise<ApiResponse<UserSettingsDto>> => 
  ApiCaller.apiResponsePost(USERSETTINGS_ENDPOINT, settings);

export const acceptTerms = async(): Promise<ApiResponse<AcceptTermsResultDto>> => 
  ApiCaller.apiResponsePost(USERACCEPTTERMS_ENDPOINT, {});
