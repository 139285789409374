import { takeEvery } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import { all, call, fork, put } from "redux-saga/effects";
import { EntityIdentifierDto } from "../../common-types/entityIdentifierDto";
import { FetchCimaRegistrationsAction } from "../cimaRegistrations/types/cima-registrations-actions-types";
import { EntityIdentifiersActions, EntityIdentifiersActionTypes } from "./entity-identifiers-actions";
import { cancelFetchEntityIdentifiers, fetchEntityIdentifiers } from "./entity-identifiers.api";

export function* watchFetchEntityIdentifiers(): SagaIterator {
  yield takeEvery(
    EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS,
    function* (action: FetchCimaRegistrationsAction): any {
      try {
        const { entityId } = action;
        let entityIdentifiers: EntityIdentifierDto = yield call(
          fetchEntityIdentifiers,
          entityId
        );
        yield put(
          EntityIdentifiersActions.fetchEntityIdentifiersSuccess(
            entityIdentifiers
          )
        );
      } catch (e) {
        yield put(EntityIdentifiersActions.fetchEntityIdentifiersFailure(e));
      }
    }
  );

  yield takeEvery(
    EntityIdentifiersActionTypes.CANCEL_FETCH,
    function* (): any {
        yield call(cancelFetchEntityIdentifiers);
    }
  );
}

export default function* EntityIdentifiersSaga(): SagaIterator {
  yield all([fork(watchFetchEntityIdentifiers)]);
}
