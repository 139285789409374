import { hasAction } from "helpers/userActions";
import { RequireActionProps } from "modules/auth/auth-component-types";

export function RequireAction(props: RequireActionProps) {

  const { actionName, children, fallbackComponent } = props;

  if (hasAction(actionName))
    return children;

  if (fallbackComponent)
    return fallbackComponent;

  return null;
}
