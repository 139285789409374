import { useState } from "react";
import styles from "./ServiceRequestManagement.module.scss";
import { KirbyButton, KirbyLoaderPage, KirbySection } from "maples-kirby-react";
import ServiceRequestItems from "./Items/ServiceRequestItems";
import { ServiceRequestsCopy } from "constants/copy/service-requests/service-request-copy";
import OrderPreview from "./OrderPreview/OrderPreview";
import { useServiceRequestContext } from "./ServiceRequestRoot";
import { verifyIfOrderStatusChanged } from "./Util/ServiceRequestUtil";
import ServiceRequestErrorDialog from "./Dialogs/ServiceRequestErrorDialog";
import { useNavigate, useParams } from "react-router-dom";

export default function ServiceRequestManagementContent() {
  const [showPreview, setShowPreview] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { serviceRequestItems, order } = useServiceRequestContext();
  const [showServiceRequestError, setShowServiceRequestError] = useState<boolean>(false);

  const reviewOrderClick = () => {
    verifyIfOrderStatusChanged(order, id ? parseInt(id) : 0).then((result) => {
      if (!result)
        setTimeout(() => {
          setShowPreview(!showPreview);
        }, 1000);
    })
  }
  const confirmReturnToOrderHistory = () => {
    setShowServiceRequestError(false);
    setTimeout(() => navigate('/entities/' + id + "/servicerequests/orderhistory"), 500);
  };

  return (
    <>
      <div className={`${styles.serviceRequestManagementContent}`}>
        {
          serviceRequestItems !== undefined ?
            <div className={`${styles.container} ${showPreview ? styles.showPreview : ""}`}>
              <KirbyButton variant="secondary" handleClick={() => { reviewOrderClick() }} cssPart="orderPreviewbutton">Order Preview</KirbyButton>
              <KirbySection
                className={styles.itemSection}
                sectionTitle={ServiceRequestsCopy.OrderItems.Title} >
                <ServiceRequestItems requestItems={serviceRequestItems} order={order} />
              </KirbySection>
              <OrderPreview />
            </div> :
            <KirbyLoaderPage />
        }
      </div>
      <ServiceRequestErrorDialog
        confirm={() => confirmReturnToOrderHistory()}
        close={() => setShowServiceRequestError(false)}
        show={showServiceRequestError}
      />
    </>
  );
}
