import { AxiosResponse } from "axios";
import { genericErrorMessage } from "./constants";

export class ApiResponse<Type> {
  success: boolean;
  statusCode: number;
  message?:string;
  errors?: string[];
  value?: Type;
}

export const toApiResponse = <Type>(axiosResponse: AxiosResponse<any, any>, successBoundary: number = 300) : ApiResponse<Type> => {
  let apiResponse: ApiResponse<Type> = {
    success: axiosResponse.status < successBoundary,
    statusCode: axiosResponse.status
  };

  if (apiResponse.success) {
    apiResponse.value = axiosResponse.data as Type;
    return apiResponse;
  }
    
  apiResponse.errors = getErrors(axiosResponse);
  return apiResponse;
}

const getErrors = (axiosResponse: AxiosResponse<any, any>): string[] => {
  if (axiosResponse.data.message)
    return [axiosResponse.data.message];

  if (axiosResponse.data.errors)
    return Object.entries(axiosResponse.data.errors).map((error: any) => error[1]);
  
  return [genericErrorMessage];
}
