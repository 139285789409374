import { ShippingDetailsDto } from "common-types/shippingDetailsDto";
import { createSelector } from "reselect";
import { AppState } from "../../store/AppState";
import { ShippingDetailsState } from "./types/shipping-details-state";

const shippingDetailsStateSelector = (
  state: AppState
): ShippingDetailsState => state.shippingDetails;

export const getShippingDetails = createSelector(
  shippingDetailsStateSelector,
  (shippingDetailsState: ShippingDetailsState): ShippingDetailsDto => {
    
    if (!shippingDetailsState || !shippingDetailsState.shippingDetailsDto)
      return {} as ShippingDetailsDto;

    return shippingDetailsState.shippingDetailsDto;
  }
);

export const getShippingDetailsFetchStatus = createSelector(
  shippingDetailsStateSelector, 
  (properties) => properties?.fetchShippingDetailsStatus
);
