import { EntityDto } from 'common-types';

export function getHeader(entity: EntityDto) {
    const { name, clientNumber } = entity || {};
    return (name ? `${name} ` : "") + (clientNumber ? `(${clientNumber})` : "");
}

export function getMessage(entity: EntityDto) {
    if (!entity.clientNumber) return undefined;

    if (!!entity.closed && !entity.showInPortal)
        return 'This Entity is closed and offline';

    if (!!entity.closed)
        return 'This Entity is closed';
        
    if (!entity.showInPortal)
        return 'This Entity is offline';
        
    return undefined;
}
