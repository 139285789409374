import { EntityDto } from "common-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { HomePageType } from "common-types/homePageTypeEnum";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { recentlyViewedGridMessages } from "constants/copy/entities/recentlyViewedGridMessages";
import { kirbySearch } from "maples-kirby-icons";
import {
  KirbySection,
  KirbyTableServer
} from "maples-kirby-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "../Entities.module.scss";
import { entityGridColumns } from "../entityGridColumns";
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";

export interface RecentlyViewedEntitiesProps {
  fetchRecentlyViewedEntities: () => void;
  recentlyViewedEntities: PagedListDto<EntityDto> | undefined;
  fetchStatus: FetchStatus | undefined;
  setHomePageType: (homePageType: HomePageType) => void;
}

export default function RecentlyViewedEntities({
  fetchRecentlyViewedEntities,
  recentlyViewedEntities,
  fetchStatus,
  setHomePageType,
}: RecentlyViewedEntitiesProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceRequestsEnabled, featureFlagsLoaded] = useFeatureFlag('ServiceRequests');
  const columns: Array<any> = entityGridColumns(serviceRequestsEnabled);
  const { length } = recentlyViewedEntities?.page ?? {};
  const [routeLoading, setRouteLoading] = useState(false);

  useEffect(() => {
    fetchRecentlyViewedEntities();
  }, [fetchRecentlyViewedEntities]);

  useEffect(() => {
    if (location.state?.reset) {
      navigate(".", { replace: true });
      setRouteLoading(true);
      setTimeout(() => {
        setRouteLoading(false);
      }, 100)
    }
  }, [location]);

  useEffect(() => {
    setRouteLoading(false);
  }, [routeLoading]);

  return (
    <>
      {!routeLoading === true &&
        <KirbySection sectionTitle={recentlyViewedGridMessages.Title}>
          <div className={styles.content}>
            {fetchStatus === FetchStatus.NotFound && (
              <div>{recentlyViewedGridMessages.NotFound}</div>
            )}
            {(fetchStatus !== FetchStatus.NotFound && featureFlagsLoaded) &&
              (
                <KirbyTableServer
                  data-testid="recentlyviewedentitylisttable"
                  columns={columns}
                  data={recentlyViewedEntities?.page}
                  defaultPageSize={20}
                  rowCount={length}
                  hideReset={true}
                  hidePagination={true}
                  widthBreakpoints={{
                    responsiveWidth: 50,
                    stickyColumnsActive: 25,
                  }}
                  headerSortable={false}
                  loading={{
                    active: fetchStatus === FetchStatus.InProgress,
                    text: recentlyViewedGridMessages.FetchInProgress,
                  }}
                  tablePart="clientTable"
                  pageIndex={0}
                  emptyMessage={recentlyViewedGridMessages.NotFound}
                  showManageColumns={true}
                  tableStore={{
                    name: 'PhoenixRecentlyViewedEntityListTable',
                    version: 1
                  }}
                  customActions={[{
                    label: recentlyViewedGridMessages.ToggleAllEntitites,
                    func: () => setHomePageType(HomePageType.AllEntities),
                    placement: "toolbar",
                    icon: {
                      icon: kirbySearch
                    }
                  }]}
                />
              )}
          </div>
        </KirbySection>
      }
    </>
  );
}
