import { ENTITIES_ENDPOINT, SEGREGATEDPORTFOLIOS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { SegregatedPortfolioDto } from "common-types";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchSegregatedPortfolios";

export const fetchSegregatedPortfolios = async(request: EntityPageRequest): Promise<PagedListDto<SegregatedPortfolioDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${SEGREGATEDPORTFOLIOS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchSegregatedPortfolios = (): void => tryCancelPending(signalKey);

