import { takeEvery } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import { call, put, all, fork } from "redux-saga/effects";
import { CimaRegistrationDto } from "../../common-types";
import { CimaRegistrationsActions, CimaRegistrationsActionTypes } from "./cima-registrations-actions";
import { cancelFetchCimaRegistrations, fetchCimaRegistrations } from "./cima-registrations.api";
import { FetchCimaRegistrationsAction } from "./types/cima-registrations-actions-types";

export function* watchFetchCimaRegistrations(): SagaIterator {
  yield takeEvery(CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS, function* (action: FetchCimaRegistrationsAction): any {
    try {
      const { entityId } = action;
      let cimaProperties: CimaRegistrationDto = yield call(fetchCimaRegistrations, entityId);
      yield put(CimaRegistrationsActions.fetchCimaRegistrationsSuccess(cimaProperties));
    } catch (e) {
      yield put(CimaRegistrationsActions.fetchCimaRegistrationsFailure(e));
    }
  });

  yield takeEvery(CimaRegistrationsActionTypes.CANCEL_CIMA_REGISTRATIONS, function* (): any {
    yield call(cancelFetchCimaRegistrations);
  });
}

export default function* EntityCimaRegistrationSaga(): SagaIterator {
  yield all([
    fork(watchFetchCimaRegistrations)
  ]);
}
