import { SearchResultActions } from "modules/entities/searchResults/searchResults-actions";
import {
  getCurrentPage,
  getPageSize,
  getRedirectedForSingle,
  getSearchResults,
  getSearchResultsFetchStatus,
  getSearchTerm
} from "modules/entities/searchResults/searchResults-selectors";
import {
  ClearEntitiesSearchTermAction,
  FetchSearchResultsAction,
  SetCurrentPageAction,
  SetPageSizeAction,
  SetRedirectedAction,
} from "modules/entities/types/entities-actions-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import SearchResults from "./SearchResults";

const mapStateToProps = (state: AppState) => {
  return {
    searchResults: getSearchResults(state),
    searchTerm: getSearchTerm(state),
    redirectedForSingleResult: getRedirectedForSingle(state),
    fetchStatus: getSearchResultsFetchStatus(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
  };
};

const mapDispatchToProps = {
  fetchSearchResults: (pageNumber: number, pageSize: number, searchTerm: string): FetchSearchResultsAction =>
    SearchResultActions.fetchSearchResults(pageNumber, pageSize, searchTerm),
  setCurrentPage: (pageNumber: number): SetCurrentPageAction =>
    SearchResultActions.setCurrentPage(pageNumber),
  setPageSize: (pageSize: number): SetPageSizeAction =>
    SearchResultActions.setPageSize(pageSize),  
  clearEntitiesSearchTerm: (): ClearEntitiesSearchTermAction => 
    SearchResultActions.clearEntitiesSearchTerm(),
  recordRedirect: (): SetRedirectedAction => SearchResultActions.setRedirected(),
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
