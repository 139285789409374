import { EntityDto } from "common-types";
import { EmptyPagedList } from "common-types/paging/pagedListDto";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { SearchResultsState } from "../types/entities-state";

export const searchResultsStateSelector = (
  state: AppState
): SearchResultsState => state.searchResults;

export const getSearchResults = createSelector(
  searchResultsStateSelector,
  (searchResultsState) => {
    if ((searchResultsState?.searchResults?.page?.length ?? 0) <= 0)
      return EmptyPagedList<EntityDto>();

    return searchResultsState.searchResults;
  }
);

export const getSearchResultsFetchStatus = createSelector(
  searchResultsStateSelector,
  (searchResultsState) => searchResultsState.fetchStatus
);

export const getSearchTerm = createSelector(
  searchResultsStateSelector,
  (searchResultsState) => {
    if (!searchResultsState?.searchTerm) return "";

    return searchResultsState.searchTerm;
  }
);

export const getRedirectedForSingle = createSelector(
  searchResultsStateSelector,
  (searchResultsState) => {
    if (!searchResultsState?.redirectedForSingleResult) return false;

    return searchResultsState.redirectedForSingleResult;
  }
);

export const getCurrentPage = createSelector(
  searchResultsStateSelector,
  (SearchResultsState) => {
    if (!SearchResultsState?.currentPage) return 1;

    return SearchResultsState.currentPage;
  }
);

export const getPageSize = createSelector(
  searchResultsStateSelector,
  (SearchResultsState) => {
    if (!SearchResultsState?.pageSize) return 20;

    return SearchResultsState.pageSize;
  }
);
