import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as ServiceRequestApiModule from "api/service-requests-api";
import { DefaultServiceRequestContext, ServiceRequestContextType } from "./Context/ServiceRequestContext";
import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { addToast } from "components/common/Toaster";
import { ServiceRequestsCopy } from "constants/copy/service-requests/service-request-copy";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { basketUtils } from "common-types/serviceRequests/utils/basketUtils";
import BasketItemUpdatedDialog from "./Dialogs/BasketItemUpdatedDialog";
import { buildEmptyBasket, OrderDto } from "common-types/serviceRequests/orderDto";
import ConfirmRemovalDialog from "./Dialogs/ConfirmRemovalDialog";
import { cloneDeep } from "lodash";
import ServiceRequestErrorDialog from "./Dialogs/ServiceRequestErrorDialog";
import { verifyIfOrderStatusChanged } from "./Util/ServiceRequestUtil";
import { orderReducer } from "./Context/ServiceRequestOrderReducer";

export const ServiceRequestContext: React.Context<ServiceRequestContextType> =
  createContext<ServiceRequestContextType>(DefaultServiceRequestContext);

const ServiceRequestRoot = (props: any) => {
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const [serviceRequestItems, setServiceRequestItems] = useState<ServiceRequestItemDto[]>();
  const [order, orderDispatch] = useReducer(orderReducer, buildEmptyBasket());
  const [toAddToBasket, setToAddToBasket] = useState<BasketItemDto>();
  const [updatedBasketItem, setUpdatedBasketItem] = useState<BasketItemDto | undefined>();
  const [showConfirmDeleteOrder, setShowConfirmDeleteOrder,] = useState(false);
  const [showServiceRequestError, setShowServiceRequestError] = useState<boolean>(false);
  const [showRemovalDialogue, setShowRemovalDialogue] = useState<boolean>(false);
  const url = window.location.pathname;
  const navigate = useNavigate();
  const serviceRequestUrl = `/entities/${entityId}/servicerequests/`;

  const getServiceRequestItems = useMemo(() => async (entityId: number): Promise<ServiceRequestItemDto[]> => {
    return await ServiceRequestApiModule.fetchRequestItems(entityId)
      .then((response) => {
        return !response ? [] : response;
      });
  }, []);

  /*
    Loads the service request items on page load.
  */
  useEffect(() => {
    getServiceRequestItems(entityId)
      .then((result) => {
        setServiceRequestItems(result)
      }).catch(async () => {
        await addToast(ServiceRequestsCopy.OrderItems.PromiseErrorTitle, ServiceRequestsCopy.OrderItems.PromiseErrorTitle, "error");
        setServiceRequestItems([]);
      });

    ServiceRequestApiModule.fetchBasket(entityId)
      .then((result: OrderDto) => {
        orderDispatch({
          type: "update_order",
          order: !result ? buildEmptyBasket() : result
        });
      })
      .catch(() => {
        orderDispatch({
          type: "update_order",
          order: buildEmptyBasket()
        });
      })
  }, [url]);

  /*
    When order item quantity is set to 0, pop-up to confirm removal.
  */
  useEffect(() => {
    if (order.orderItems.find(a => a.quantity === 0) !== undefined)
      setShowRemovalDialogue(true)
  }, [order])

  /*
    Add / updates item in the basket, calls API, when item to add to basket is added to the state from a child.
  */
  useEffect(() => {
    if (!toAddToBasket)
      return;

    setToAddToBasket(undefined);
    let existingBasketItem = basketUtils.findMatch(order.orderItems, toAddToBasket);

    if (existingBasketItem) {
      const newQuantity = existingBasketItem.quantity + toAddToBasket.quantity;
      const updatedBasketItem = {
        ...existingBasketItem,
        quantity: newQuantity > 10 ? 10 : newQuantity,
        requestedQuantity: newQuantity,
        isExpress: toAddToBasket.isExpress
      }
      setUpdatedBasketItem(updatedBasketItem);
      return;
    }

    orderDispatch({
      type: 'update_order_item',
      changedOrderItem: { ...toAddToBasket, inDb: false }
    });

    saveBasketItem(toAddToBasket);
  }, [toAddToBasket]);

  const tryRedirect = () => {
    if (url !== serviceRequestUrl)
      setTimeout(() => navigate(serviceRequestUrl), 500);
  }

  const saveBasketItem = useCallback(async (basketItem: BasketItemDto, reset: boolean = true, cancelPrevious: boolean = false, updateStateWhileCallingApi: boolean = true): Promise<void> => {
    try {
      if (updateStateWhileCallingApi)
        orderDispatch({
          type: 'update_order_item',
          changedOrderItem: { ...basketItem, inDb: false }
        });

      const result = await verifyIfOrderStatusChanged(order, entityId);
      setShowServiceRequestError(result);
      if (result)
        return;

      const added = await ServiceRequestApiModule.updateBasket(entityId, basketItem, cancelPrevious);
      if (reset)
        orderDispatch({
          type: 'update_order_item',
          changedOrderItem: { ...added, inDb: true }
        });

      if (basketItem.serviceRequestOrderId === 0)
        orderDispatch({
          type: 'order_created',
          orderId: added.serviceRequestOrderId
        })
    } catch {
      addToast("Error", ServiceRequestsCopy.OrderItems.PromiseErrorTitle, 'error')
    }
  }, [order]);

  const updateItemQuantity = useCallback((item: BasketItemDto, quantity: number, persist: boolean = true) => {
    if (!item)
      return;

    const existingBasketItem = basketUtils.findById(order.orderItems, item.id);
    if (!existingBasketItem)
      return;

    orderDispatch({
      type: 'update_order_item',
      changedOrderItem: {
        ...existingBasketItem,
        quantity: quantity,
        previousQuantity: existingBasketItem.quantity,
        inDb: true
      }
    });

    if (persist && quantity > 0)
      saveBasketItem({ ...existingBasketItem, quantity, previousQuantity: existingBasketItem.quantity }, false, true, false);

  }, [order, saveBasketItem, orderDispatch]);

  const removeItemFromBasket = useCallback(async (item: BasketItemDto, updateStateFirst: boolean = true) => {
    const removeItemFromBasketState = (orderId: number) => {
      if (!item)
        return;

      let index = order.orderItems.findIndex(a => a.id == item.id);
      let newBasket = cloneDeep({ ...order, id: orderId });
      newBasket.orderItems.splice(index, 1);
      orderDispatch({
        type: 'update_order',
        order: newBasket
      });
    };

    const revertBasketState = () => {
      if (!item)
        return;

      let index = order.orderItems.findIndex(a => a.id == item.id);
      let updatedExistingItem = cloneDeep(item);
      updatedExistingItem.quantity = updatedExistingItem.previousQuantity ?? 1;
      let revertedBasket = cloneDeep({ ...order });
      revertedBasket.orderItems.splice(index, 1, updatedExistingItem);
      orderDispatch({
        type: 'update_order',
        order: revertedBasket
      });
    }
    
    try {
      const deleteWholeOrderMode = order.orderItems.length === 1;
      if (updateStateFirst)
        removeItemFromBasketState(deleteWholeOrderMode ? 0 : order.id);

      const orderStatusChanged = await verifyIfOrderStatusChanged(order, entityId);
      setShowServiceRequestError(orderStatusChanged);

      if (orderStatusChanged) {
        revertBasketState();
        return;
      }
      
      if (deleteWholeOrderMode) {
        await ServiceRequestApiModule.deleteOrder(entityId, order.id);
        tryRedirect();
      }
      else
        await ServiceRequestApiModule.removeFromBasket(entityId, item);

      if (!updateStateFirst)
        removeItemFromBasketState(deleteWholeOrderMode ? 0 : order.id);
    } catch {
      addToast("Error", ServiceRequestsCopy.OrderItems.PromiseErrorTitle, 'error');
    }
    
    setShowRemovalDialogue(false);
  }, [order]);

  const deleteOrder = useCallback(() => {
    setShowConfirmDeleteOrder(true);
  }, [order])

  const cancelDeleteOrder = useCallback(() => {
    setShowConfirmDeleteOrder(false);
  }, [order]);

  const confirmReturnToOrderHistory = useCallback(() => {
    setShowServiceRequestError(false);
    setShowRemovalDialogue(false)
    setTimeout(() => navigate('/entities/' + entityId + "/servicerequests/orderhistory", { state: { reset: true } }), 500);
  }, [order]);

  const confirmDeleteOrder = useCallback(async (orderId: number, entityId: number) => {
    const result = await verifyIfOrderStatusChanged(order, entityId);
    setShowServiceRequestError(result);

    if (result) {
      setShowConfirmDeleteOrder(false);
      return;
    }
    
    try {
      await ServiceRequestApiModule.deleteOrder(entityId, orderId);
      setShowConfirmDeleteOrder(false)
      addToast("Order Deleted", "Your order has been successfully deleted", "success");
      tryRedirect();

    } catch {
      addToast("Error", ServiceRequestsCopy.OrderItems.PromiseErrorTitle, 'error');
    }
  }, [order]);

  const serviceRequestContext = useMemo(() => {
    return {
      serviceRequestItems,
      order,
      addToBasket: setToAddToBasket,
      updateQuantity: updateItemQuantity,
      setOrder: orderDispatch,
      setUpdatedBasketItem,
      updatedBasketItem,
      deleteOrder
    };
  }, [serviceRequestItems, order, updatedBasketItem, orderDispatch]);

  const getRemovalMessage = () => {
    if (order.orderItems.length > 1)
      return 'This will remove the item from your order, would you like to proceed?';

    return 'Your order and any notes added will be deleted if you remove this last item from this order. Would you like to proceed?';
  };

  const handleCloseDialogue = (item: BasketItemDto) => {
    setShowRemovalDialogue(false)
    updateItemQuantity(item, item?.previousQuantity ?? 1, false)
  };

  return (
      <ServiceRequestContext.Provider value={serviceRequestContext}>
        {props.children}
        <BasketItemUpdatedDialog
          basketItem={updatedBasketItem}
          setBasketItem={setUpdatedBasketItem}
          confirm={async (basketItem: BasketItemDto) => saveBasketItem(basketItem) }
          order={order}
        />
        <ConfirmRemovalDialog
          basketItem={order.orderItems.find(a => a.quantity === 0)}
          show={showRemovalDialogue}
          confirm={async (a: any) => { await removeItemFromBasket(a, false); }}
          close={(item: BasketItemDto) => handleCloseDialogue(item)}
          message={getRemovalMessage()}
        />
        <ConfirmRemovalDialog
          basketItem={order.orderItems.find(a => a.quantity === 0)}
          show={showConfirmDeleteOrder}
          confirm={() => confirmDeleteOrder(order.id, entityId)}
          close={() => cancelDeleteOrder()}
          message="Your Order will be deleted. Do you wish to continue?"
        />
        <ServiceRequestErrorDialog
          confirm={() => confirmReturnToOrderHistory()}
          close={() => setShowServiceRequestError(false)}
          show={showServiceRequestError}
        />
      </ServiceRequestContext.Provider>
  );
}

export default ServiceRequestRoot;
export const useServiceRequestContext = () => useContext(ServiceRequestContext);