import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "modules/entity/entity-selectors";
import Officers from "./Officers";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import { OfficerDto } from "common-types";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import PagedListKeys from "constants/pagedListKeys";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import { officersGridColumns } from "./officersGridColumns";
import headerText from "constants/copy/registers/headerText";

const stateSelector = (state: AppState): GenericPagedListState<OfficerDto> => state.officers;

const getOfficers = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<OfficerDto> & ServerTableContainerOwnProps => {
  return {
    list: getOfficers(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state), 
    header: headerText.Officers,
    columns: officersGridColumns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 31.25,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.OFFICERS);

export default connect(mapStateToProps, mapDispatchToProps)(Officers);
