
const getFileName = (headers: any): string => {
  const fallback = () => `MaplesDownload_${new Date()}`;

  if (!headers["content-disposition"])
    return fallback();

  const fileNameMatch = headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);

  if (!fileNameMatch || fileNameMatch.length < 2)
    return fallback();

  return fileNameMatch[1]?.replace(/['"]+/g, '') ?? fallback();
}

export { getFileName };
