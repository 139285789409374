import { fetchSearchResults } from "api/entity.api";
import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { CANCELLED_MESSAGE } from "helpers/userActions/userPageActions";
import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FetchSearchResultsAction } from "../types/entities-actions-types";
import {
  SearchResultActionTypes,
  SearchResultActions,
} from "./searchResults-actions";

export function* watchFetchSearchResults(): SagaIterator {
  yield takeEvery(
    SearchResultActionTypes.FETCH_SEARCHRESULTS,
    function* (action: FetchSearchResultsAction) {
      try {
        const { pageNumber, pageSize, searchTerm } = action;
        let searchResults: PagedListDto<EntityDto> = yield call(
          fetchSearchResults,
          pageNumber,
          pageSize,
          searchTerm
        );
        yield put(SearchResultActions.fetchSearchResultsSuccess(searchResults));
      } catch (e: any) {
        if (e.message === CANCELLED_MESSAGE) return;
        yield put(SearchResultActions.fetchSearchResultsFailure(e));
      }
    }
  );
}

export default function* SearchResultsSaga(): SagaIterator {
  yield all([fork(watchFetchSearchResults)]);
}
