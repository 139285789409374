import produce from "immer";
import { FetchStatus } from "../../common-types/fetchStatusEnum";
import { EntityIdentifiersActionTypes } from "./entity-identifiers-actions";
import { FetchEntityIdentifiersSuccessAction } from "./types/entity-identifiers-actions-types";
import { EntityIdentifiersState } from "./types/entity-identifiers-state";

const initialState: EntityIdentifiersState = {
  entityId: 0,
  entityIdentifiers: undefined,
  fetchEntityIdentifiersStatus: FetchStatus.InProgress
};

export default (
  state = initialState,
  action: FetchEntityIdentifiersSuccessAction = { entityIdentifiers: undefined, type: "" }): EntityIdentifiersState =>
  produce(state, (draft) => {
    switch (action.type) {
      case EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS: {
        draft.fetchEntityIdentifiersStatus = FetchStatus.InProgress;
        return;
      }
      case EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS_SUCCESS: {
        const { entityIdentifiers } = action;
        draft.entityIdentifiers = entityIdentifiers;
        draft.fetchEntityIdentifiersStatus = entityIdentifiers ? FetchStatus.Complete : FetchStatus.NoContent;
        return;
      }
      case EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS_FAILURE: {
        draft.entityIdentifiers = undefined;
        draft.fetchEntityIdentifiersStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
