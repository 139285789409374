import { CoreEntityDetailsDto } from "common-types/coreEntityDetailsDto"

export const CoreEntityDetailsActionTypes = {
    FETCH_CORE_ENTITY_DETAILS: "FETCH_CORE_ENTITY_DETAILS",
    FETCH_CORE_ENTITY_DETAILS_SUCCESS: "FETCH_CORE_ENTITY_DETAILS_SUCCESS",
    FETCH_CORE_ENTITY_DETAILS_FAILURE: "FETCH_CORE_ENTITY_DETAILS_FAILURE",
    CANCEL_FETCH: "CANCEL_FETCH"
}

export const CoreEntityDetailsActions = {
    fetchCoreEntityDetails: (entityId: number) => ({
        type: CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS,
        entityId
    }),

    fetchCoreEntityDetailsSuccess: (coreEntityDetails: CoreEntityDetailsDto) => ({
        type: CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS_SUCCESS,
        coreEntityDetails
    }),

    fetchCoreEntityDetailsFailure: (error: any) => ({
        type: CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS_FAILURE,
        error
    }),

    cancel: () => ({
        type: CoreEntityDetailsActionTypes.CANCEL_FETCH
    })
};