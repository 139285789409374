import { addToast, removeToast, updateToast } from "../../../common/Toaster";
import { AxiosResponse } from "axios";
import { browserDownloadResult } from "../../../../modules/download/fileDownload";
import { getFileName } from "../../../../modules/download/fileResult";
import { registerDownloadOptions } from "./registerDownloadOptions";
import { RegisterManifestDto } from "../../../../common-types";
import { download } from "api/register-download-api";

export const downloadRegisters = async (
  entityId: number,
  register: RegisterManifestDto,
  options: registerDownloadOptions
) => {
  let toastId = await addToast("Downloading", options.toastMessage, "spinner");

  const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
    browserDownloadResult(
      new Blob([axiosResponse.data]),
      getFileName(axiosResponse.headers)
    );
  };

  let downloadFunction: Function = download;

  downloadFunction(
    entityId,
    register.clientNumber,
    register.internalCode,
    register.derived,
    blobHandler
  ).then((a: any) => {
    if (a.statusCode < 500 && a.statusCode >= 400) {
      removeToast(toastId);
      return;
    }

    if (!a.success) {
      let error = a.errors ? a.errors[0] : "Unkown Error";
      updateToast(toastId, "Register Download failed", error, "error");
      return;
    }

    updateToast(
      toastId,
      "Register Download complete",
      options.toastCompleteMessage ?? "",
      "success"
    );
  });
};
