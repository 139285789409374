export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || '';
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || window.location.origin;
export const IS_APPINSIGHTS_ACTIVE = process.env.REACT_APP_IS_APPINSIGHTS_ACTIVE || false;
export const APPINSIGHTS_INSTRUMENTATION_KEY = '#{APPINSIGHTS_INSTRUMENTATION_KEY}';
export const ENVIRONMENT_NAME = '#{APPINSIGHTS_ENVIRONMENT_NAME}' || 'local';
export const RELEASE_NAME = '#{APPINSIGHTS_RELEASE_NAME}' || 'locally-run';
export const CLOUD_ROLE = '#{APPINSIGHTS_CLOUD_ROLE}' || 'local';
export const MAPLES_TERM_URL = '#{MAPLES_TERM_URL}' || window.location.origin;
export const MAPLES_PRIVACY_POLICY_URL = '#{MAPLES_PRIVACY_POLICY_URL}' || window.location.origin;
export const PHOENIX_USER_GUIDE_DOC = '#{PHOENIX_USER_GUIDE_DOC}';
export const FIXEDFEE_SCHEDULE_CAYMANISLAND_FEES_DOC = '#{FIXEDFEE_SCHEDULE_CAYMANISLAND_FEES_DOC}';
export const FIXEDFEE_SCHEDULE_BVI_FEES_DOC = '#{FIXEDFEE_SCHEDULE_BVI_FEES_DOC}';
export const FIXEDFEE_SCHEDULE_AUTHORISED_REPRESENTATIVE_BVI_FEES_DOC = '#{FIXEDFEE_SCHEDULE_AUTHORISED_REPRESENTATIVE_BVI_FEES_DOC}';
