import React, { useEffect, useRef } from "react";
import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { kirbyCertificate } from "maples-kirby-icons";
import { KirbyIcon, KirbyTextfield } from "maples-kirby-react";
import styles from "./ServiceRequestItem.module.scss";
import { QuantityField } from "./Fields/QuantityField";
import { AddButton } from "./Fields/AddButton";

type ServiceRequestItemOtherProps = {
    requestItem: ServiceRequestItemDto;
    otherDescription: string | undefined;
    setOtherDescription: Function;
    otherError: string | undefined;
    setOtherError: Function;
    quantity: number | undefined;
    setNewQuantity: Function;
    quantityError: string | undefined;
    setQuantityError: Function;
    addHandleClick: Function;    
}

const ServiceRequestItemOther = (props : ServiceRequestItemOtherProps) => {
    const { requestItem, otherDescription, setOtherDescription, otherError, setOtherError, quantity, quantityError, setQuantityError, addHandleClick, setNewQuantity } = props;
    const requestItemEl = useRef(null);

    const validateOtherDescription = (field: any) => {
        let error = '';

        if (field.target.value.length > 100)
            error = "Max length 100 characters.";
        if ((field.target.value as string).trim() === '')
            error = "Please provide a description.";

        setOtherError(error);
    };

    useEffect(() => {
      function focusChange(e: any) {
        if (requestItemEl.current !== document.querySelector(`.${styles.otherItem}:focus-within`)) {
          if (otherError) {
            setOtherError(undefined);
            setOtherDescription(undefined);
          }
          if (quantityError) {
            setQuantityError(undefined);
            setNewQuantity(undefined); 
          }
        }
      };
      
      document.addEventListener('focus', focusChange, true)

      return () => document.removeEventListener("focus", focusChange, true);
    }, [otherError, quantityError, otherDescription, quantity]);

    return (
        <div data-testid={`service-requests-item-component-other-${requestItem.id}`} className={styles.otherItem} ref={requestItemEl}>
            <div className={styles.requestName}>
                <KirbyIcon icon={kirbyCertificate}></KirbyIcon>
                {
                    requestItem.nameOverride ?
                    <KirbyTextfield 
                        label={requestItem.name} 
                        required
                        value={otherDescription}
                        handleChange={(field: any) => setOtherDescription(field.target.value)}
                        onKeyUp={validateOtherDescription}
                        error={otherError}
                    />
                    : requestItem.name
                }
            </div>                   
            <div className={styles.quantity}>
                <QuantityField 
                    requestItem={requestItem} 
                    quantity={quantity} 
                    setQuantity={(quantity) => setNewQuantity(quantity)} 
                    maxQuantity={10}
                    error={quantityError}
                />
            </div>
            <div className={styles.addButton}>
                <AddButton 
                    enabled={true}
                    handleClick={addHandleClick}
                    requestItem={requestItem}
                />
            </div>
        </div>
    );
}

export default ServiceRequestItemOther;
