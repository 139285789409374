import logo from "./darkLogo.svg";
import styles from "./maintenance.module.scss";

export default function Maintenance() {
  return (
    <div className={styles.container}>
        <div>
            <img className={styles.logo} src={logo}/>
            <h2>Under Maintenance</h2>
            <h3>This page is currently unavailable. Please try again shortly.</h3>
        </div>
        <footer>© Maples 2023</footer>
    </div>
  )
}
