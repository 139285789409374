import { useState, useEffect, useCallback, useRef } from "react";
import styles from "./DocumentSearchResult.module.scss";
import { DocumentSearchResultDto } from "common-types/documentSearchResultDto";
import {
  kirbyEdit,
  kirbyRemove,
  kirbyMore,
  kirbyCopy,
} from "maples-kirby-icons";
import { KirbyIcon, KirbyMenu, KirbyMenuItem } from "maples-kirby-react";
import { useGlobalContext } from "../DocManagement";
import RequireAction from "components/common/RequireActionContainer";
import { DocumentDto } from "common-types/documentDto";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { getContentIcon } from "../Common/documentUtilities";
import { onCopyCut } from "../Copy/CopyFunctions";

export interface DocSearchResultProps {
  item: DocumentSearchResultDto;
  select: (key: string) => void;
  delete: (key: string) => void;
  rename: (document: DocumentDto) => void;
  gridView?: boolean;
  setDocClipboard: Function;
  setClipboardCut: Function;
}

export function DocumentSearchResult(props: DocSearchResultProps) {
  const { item, select, setDocClipboard, setClipboardCut } =
    props;
  const { resetSearch, downloadDocuments, setSelectedFolder } =
    useGlobalContext();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLKirbyMenuElement>(null);

  useEffect(() => {
    if (menuRef.current?.children && menuRef.current.children.length > 0) {
      setShowMenu(true);
    }
  }, [setShowMenu, menuRef]);

  const handleDownloadClick = useCallback(
    (doc: DocumentSearchResultDto) => {
      let options = {
        forceZip: false,
        toastMessage: doc.document.displayName,
        toastCompleteMessage: doc.document.displayName,
      };
      downloadDocuments([item.document], options);
    },
    [item, downloadDocuments]
  );

  const handleSearchResultParentFolderClick = (
    parentKey: string,
    folderName: string
  ) => {
    resetSearch();

    let parentDocument: Partial<DocumentFolderDto> = {
      id: parentKey,
      name: folderName,
      displayName: folderName,
      description: folderName,
    };
    setSelectedFolder(parentDocument);
  };

  const handleDeleteClick = useCallback(() => {
    props.delete(props.item.document.id);
  }, [props]);

  const handleRenameClick = useCallback(() => {
    props.rename(props.item.document);
  }, [props]);

  const handleCopyClick = useCallback(() => {
    onCopyCut(
      [props.item.document],
      props.item.location.split(" / ").slice(-1),
      setDocClipboard,
      setClipboardCut,
      false
    );
  }, [props, setDocClipboard, setClipboardCut]);

  const formatLocation = (document: DocumentSearchResultDto) => {
    const location = document.location;
    const splitMe = location.split(" / ");
    return (
      <>
        {splitMe.map((node: string, index: number) =>
          index < splitMe.length - 1 ? (
            <span key={`node_${document.document.id}_`}>{`${node} > `}</span>
          ) : (
            <button
              className={styles.buttonlink}
              title={location}
              key={`node_${document.document.id}`}
              onClick={(e) => {
                handleSearchResultParentFolderClick(
                  item.document.parentId,
                  node
                );
                e.preventDefault();
              }}>
              {node}
            </button>
          )
        )}
      </>
    );
  };

  const moreOnClick = useCallback(() => {
    setMenuOpen(true);
  }, [setMenuOpen]);

  const menuHandleClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  return (
    <>
      <div
        className={`${styles.document} ${
          item.selected ? styles.selected : ""
        } ${props.gridView ? styles.grid : styles.list}`}
        data-itemid={props.item.document.id}>
        <div
          className={styles.selector}
          onClick={() => select(item.document.id)}>
          <KirbyIcon
            icon={getContentIcon(item.document)}
            title={item.document.displayName}
            data-testid={`item-icon-${item.document.id}`}></KirbyIcon>
        </div>
        <div className={styles.details}>
          <div className={styles.buttonwrap}>
            <span
              className={styles.buttonlink}
              title={`Search matched on ${item.searchMatchTypes}`}
              onClick={() => handleDownloadClick(item)}>
              {item.document.displayName}
            </span>
            <div className={styles.path}>{formatLocation(item)}</div>
          </div>
        </div>
        <div className={`${styles.buttonpanel}`}>
          {showMenu && (
            <KirbyIcon
              icon={kirbyMore}
              className={`${styles.actionsBut} actionsBut`}
              onClick={moreOnClick}
            />
          )}
          <KirbyMenu
            ref={menuRef}
            className={`${menuOpen ? "open" : ""}`}
            target={`[data-itemid="${props.item.document.id}"] .actionsBut`}
            open={menuOpen}
            handleClose={menuHandleClose}>
            <RequireAction
              actionName={`Document.${props.item.document.source}.${props.item.document.documentType}.Update`}>
              <KirbyMenuItem
                data-testid={`document-update-${props.item.document.id}`}
                title="rename"
                handleClick={handleRenameClick}>
                <KirbyIcon icon={kirbyEdit} />
                <span>Rename</span>
              </KirbyMenuItem>
            </RequireAction>
            <RequireAction
              actionName={`Document.${props.item.document.source}.${props.item.document.documentType}.Delete`}>
              <KirbyMenuItem
                data-testid={`document-delete-${props.item.document.id}`}
                handleClick={handleDeleteClick}>
                <KirbyIcon icon={kirbyRemove} />
                <span>Delete</span>
              </KirbyMenuItem>
            </RequireAction>
            <KirbyMenuItem
              data-testid={`document-copy-${props.item.document.id}`}
              handleClick={handleCopyClick}>
              <KirbyIcon icon={kirbyCopy} />
              <span>Copy</span>
            </KirbyMenuItem>
          </KirbyMenu>
        </div>
      </div>
    </>
  );
}
