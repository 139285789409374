import { HomePageType } from "common-types/homePageTypeEnum";
import { EntitiesActions } from "modules/entities/entities-actions";
import { SearchResultActions } from "modules/entities/searchResults/searchResults-actions";
import { SetHomePageType, SetSearchTermAction } from "modules/entities/types/entities-actions-types";
import { connect } from "react-redux";
import Navigation from "./Navigation";
import { AppState } from "store/AppState";
import { getAuthenticatedUser, getAuthenticatedUserFetchInProgress, getAuthenticatedUserFetchError, getIsAuthenticated } from "modules/auth/auth-selectors";

const mapStateToProps = (state: AppState) => {
  return {
    user: getAuthenticatedUser(state),
    isFetchInProgress: getAuthenticatedUserFetchInProgress(state),
    isFetchError: getAuthenticatedUserFetchError(state),
    isAuthenticated: getIsAuthenticated(state)
  }
};

const mapDispatchToProps = {
  searchAction: (searchTerm: string): SetSearchTermAction => SearchResultActions.setEntitiesSearchTerm(searchTerm),
  setHomePageType: (type: HomePageType): SetHomePageType => 
    EntitiesActions.setHomePageType(type),
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
