import { CSSProperties, useState } from "react";
import styles from "./ServiceRequestManagement.module.scss";
import { useServiceRequestContext } from "./ServiceRequestRoot";
import EntityViewListeners from "../../common/EntityViewUtils";
import FixPreviewHandler from "./ServiceRequestManagementUtils";
import ServiceRequestManagementContent from "./ServiceRequestManagementContent";

export default function ServiceRequestManagement() {
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [maxHeaderHeight, setMaxHeaderHeight] = useState<number>(0);
    const [fixPreview, setFixPreview] = useState<boolean>(false);
    const { order } = useServiceRequestContext();

    EntityViewListeners(headerHeight, setHeaderHeight, maxHeaderHeight, setMaxHeaderHeight, order);
    FixPreviewHandler(setFixPreview);

    return (
      <div data-testid="service-request-management-component" className={`${styles.serviceRequestManagement} ${fixPreview ? "fixed" : ""}`}
        style={{ "--entity-page-header-height": headerHeight + "px", "--entity-page-max-header-height": maxHeaderHeight + "px" } as CSSProperties}
      >
        <ServiceRequestManagementContent />
      </div>
    );
  }
