import {
  AmlOfficerDto,
  AuthorisedShareCapitalBviDto,
  AuthorisedShareCapitalDto,
  CurrentManagerDto,
  MemberDto,
  NameHistoryDto,
  OfficerDto,
  PartnershipDto,
  SecurityInterestsOverLlcInterestsDto,
  SegregatedPortfolioDto,
  ShareholderDto,
  UnitPartnershipInterestDto,
  VesselDto,
} from "common-types";
import pagedListKeys from "constants/pagedListKeys";
import cimaRegistrations from "modules/cimaRegistrations/cima-registrations-reducer";
import genericPagedListReducer from "modules/common/paged-list/paged-list-reducer";
import coreEntityDetails from "modules/coreEntityDetails/core-entity-details-reducer";
import allEntities from "modules/entities/allEntities/allEntities-reducer";
import entities from "modules/entities/entities-reducer";
import recentlyViewedEntities from "modules/entities/recentlyViewedEntities/recentlyViewedEntities-reducer";
import searchResults from "modules/entities/searchResults/searchResults-reducer";
import entity, { entityDetailReducer } from "modules/entity/entity-reducer";
import entityIdentifiers from "modules/entityIdentifiers/entity-identifiers-reducer";
import entityTermination from "modules/entityTermination/entityTermination-reducer";
import fscLicence from "modules/fscLicence/fscLicence-reducer";
import invoice from "modules/invoice/invoice-reducer";
import maplesTeam from "modules/maplesTeam/maples-team-reducer";
import registerProperties from "modules/registerProperties/registerProperties-reducer";
import registers from "modules/registers/registers-reducer";
import shippingDetails from "modules/shippingDetails/shipping-details-reducer";
import userSettings from "modules/user/user-reducer";
import { DirectorOfficerBviDto } from "../common-types/directorOfficerBviDto";
import { DirectorOfficerDto } from "../common-types/directorOfficerDto";
import { TrademarkClassDto } from "../common-types/trademarkClassDto";
import auth from "../modules/auth/auth-reducer";
import order from "../modules/order/order-reducer";
import entityFilings from "../modules/entityfilings/entityFilings-reducer";

export default {
  auth,
  entities,
  allEntities,
  recentlyViewedEntities,
  searchResults: searchResults,
  activeEntity: entity,
  entityDetail: entityDetailReducer,
  amlOfficers: genericPagedListReducer<AmlOfficerDto>(
    pagedListKeys.AMLOFFICERS
  ),
  authorisedShareCapital: genericPagedListReducer<AuthorisedShareCapitalDto>(
    pagedListKeys.AUTHORISEDSHARECAPITAL
  ),
  authorisedShareCapitalBvi:
    genericPagedListReducer<AuthorisedShareCapitalBviDto>(
      pagedListKeys.AUTHORISEDSHARECAPITALBVI
    ),
  vessels: genericPagedListReducer<VesselDto>(pagedListKeys.VESSELS),
  partnerships: genericPagedListReducer<PartnershipDto>(
    pagedListKeys.PARTNERSHIPS
  ),
  unitpartnershipInterests: genericPagedListReducer<UnitPartnershipInterestDto>(
    pagedListKeys.UNIT_PARTNERSHIP_INTERESTS
  ),
  officers: genericPagedListReducer<OfficerDto>(pagedListKeys.OFFICERS),
  shareholders: genericPagedListReducer<ShareholderDto>(
    pagedListKeys.SHAREHOLDERS
  ),
  segregatedPortfolios: genericPagedListReducer<SegregatedPortfolioDto>(
    pagedListKeys.SEGREGATED_PORTFOLIOS
  ),
  nameHistory: genericPagedListReducer<NameHistoryDto>(
    pagedListKeys.NAME_HISTORY
  ),
  currentManagers: genericPagedListReducer<CurrentManagerDto>(
    pagedListKeys.CURRENT_MANAGERS
  ),
  members: genericPagedListReducer<MemberDto>(pagedListKeys.MEMBERS),
  currentPartners: genericPagedListReducer<MemberDto>(
    pagedListKeys.CURRENT_PARTNERS
  ),
  currentDirectorsAndOfficers: genericPagedListReducer<DirectorOfficerDto>(
    pagedListKeys.CURRENT_DIRECTORSANDOFFICERS
  ),
  currentDirectorsAndOfficersBvi:
    genericPagedListReducer<DirectorOfficerBviDto>(
      pagedListKeys.CURRENT_DIRECTORSANDOFFICERSBVI
    ),
  registers,
  registerProperties,
  securityInterestsOverLlcInterests:
    genericPagedListReducer<SecurityInterestsOverLlcInterestsDto>(
      pagedListKeys.SECURITYINTERESTSOVERLLCINTERESTS
    ),
  trademarkClasses: genericPagedListReducer<TrademarkClassDto>(
    pagedListKeys.TRADEMARK_CLASSES
  ),
  cimaRegistrations,
  coreEntityDetails,
  entityIdentifiers,
  entityTermination,
  fscLicence,
  userSettings,
  maplesTeam,
  shippingDetails,
  invoice,
  order,
  entityFilings
};
