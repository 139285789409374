import { Component, FunctionComponent, ReactElement, useCallback } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { kirbyAlert } from "maples-kirby-icons";
import { KirbyIcon, KirbyButton } from "maples-kirby-react";
import styles from "./Catch.module.scss";

const defaultErrorMessage = "Something went wrong, please try again shortly.";

function errorContent(props: FallbackProps, message: string | undefined) {
  const { error } = props;

  return (
    <div role="alert" className={styles.errorPage}>
      <KirbyIcon icon={kirbyAlert}></KirbyIcon>
      <h3 title={error?.message}>An error has occurred</h3>
      <span>{message ?? defaultErrorMessage}</span>
      <KirbyButton variant="secondary" handleClick={props.resetErrorBoundary}>
        Try Again
      </KirbyButton>
    </div>
  );
}

export interface CatchProps {
  message: string | undefined;
  children: JSX.Element;
}

export function Catch(props: CatchProps) {
  const { message, children } = props;

  const fallbackRender = useCallback(
    (
      fallbackProps: FallbackProps
    ): ReactElement<
      unknown,
      string | typeof Component | FunctionComponent<{}>
    > | null => {
      return errorContent(fallbackProps, message);
    },
    [message]
  );

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
  );
}

export default Catch;
