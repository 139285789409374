import { BASE_API_URL } from "./env";

const MERCURY_SVC_URL = `${BASE_API_URL}/api/v1.0`;

export const AUTH_ENDPOINT = `/auth`;
export const ENTITIES_ENDPOINT = `${MERCURY_SVC_URL}/entities`;
export const RECENTLYVIEWEDENTITIES_ENDPOINT = `${MERCURY_SVC_URL}/recentlyviewedentities`;
export const ENTITYTERMINATION_ENDPOINT = `/entitytermination`;
export const AMLO_ENDPOINT = `/amlofficers`;
export const AUTHORISEDSHARECAPITAL_ENDPOINT = `/authorisedsharecapital`;
export const AUTHORISEDSHARECAPITALBVI_ENDPOINT = `/authorisedsharecapitalbvi`;
export const PARTNERSHIPS_ENDPOINT = `/partnerships`;
export const VESSELS_ENDPOINT = `/vessels`;
export const OFFICERS_ENDPOINT = `/officers`;
export const SHAREHOLDERS_ENDPOINT = `/shareholders`;
export const SEGREGATEDPORTFOLIOS_ENDPOINT = `/segregatedportfolios`;
export const NAMEHISTORY_ENDPOINT = `/namehistory`;
export const CURRENTMANAGERS_ENDPOINT = `/currentmanagers`;
export const MEMBERS_ENDPOINT = `/members`;
export const CIMAREGISTRATIONS_ENDPOINT = `/cimaregistrations`;
export const COREENTITYDETAILS_ENDPOINT = `/coreentitydetails`;
export const CURRENTPARTNERS_ENDPOINT = `/partners`;
export const CURRENTDIRECTORSOFFICERS_ENDPOINT = `/directorofficer`;
export const CURRENTDIRECTORSOFFICERSBVI_ENDPOINT = `/directorofficerbvi`;
export const REGISTERS_ENDPOINT = `/registers`;
export const REGISTERDOWNLOAD_ENDPOINT = `/registerdownload`;
export const REGISTERPROPERTIES_ENDPOINT = `/registerproperties`;
export const SECURITYINTERESTS_ENDPOINT = `/securityinterestsoverllcinterests`;
export const TRADEMARKCLASSES_ENDPOINT = `/trademarks`;
export const ENTITYIDENTIFIERS_ENDPOINT = `/entityidentifiers`;
export const FSCLICENCE_ENDPOINT = `/fsclicence`;
export const UNITPARTNERSHIPINTERESTS_ENDPOINT = `/unitpartnershipinterests`;
export const USERSETTINGS_ENDPOINT = `${MERCURY_SVC_URL}/user/settings`;
export const USERACCEPTTERMS_ENDPOINT = `${MERCURY_SVC_URL}/user/acceptterms`;
export const ENTITIES_DETAILS_ENDPOINT = `/entitydetails`;
export const DOC_ENDPOINT = `/documents`;
export const MAPLES_TEAM_ENDPOINT = `/maplesteam`;
export const SHIPPING_DETAILS_ENPOINT = `/shippingdetails`;
export const INVOICES_ENDPOINT = `${MERCURY_SVC_URL}/invoices`;
export const REPORTS_ENDPOINT = `${MERCURY_SVC_URL}/report`;
export const TERMSOFUSE_ENDPOINT = `${MERCURY_SVC_URL}/termsofuse`;
export const OUTSTANDINGINVOICES_ENDPOINT = `invoices/outstanding`;
export const PAGEVISIT_ENDPOINT = `${MERCURY_SVC_URL}/audit/pageview`;
export const SERVICEREQUESTITEMS_ENDPOINT = `/servicerequestitems`;
export const SERVICEREQUESTBASKET_ENDPOINT = `/servicerequest/basket`;
export const SERVICEREQUESTHISTORY_ENDPOINT = `/servicerequest/order/history`;
export const FEATUREMANAGEMENT_ENDPOINT = `${MERCURY_SVC_URL}/featuremanagement`;
export const SERVICEREQUESTORDER_ENDPOINT = `/servicerequest/order/`;
export const SERVICEREQUESTSUMMARIES_ENDPOINT = `${MERCURY_SVC_URL}/servicerequestoverview/`;
export const AUDITBLOB_ENDPOINT = `${MERCURY_SVC_URL}/audit/uploadfiletoblob`;
export const ECONOMIC_SUBSTNCE_FILINGS_ENDPOINT = `${MERCURY_SVC_URL}/economicsubstance/filings`;
export const RELEASES_ENDPOINT = `${MERCURY_SVC_URL}/releases`;

