import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  EffectCallback,
  CSSProperties,
} from "react";
import { KirbyResizer, KirbyIcon } from "maples-kirby-react";
import { kirbyClose } from "maples-kirby-icons";
import FolderTree from "./FolderTree";
import styles from "./DocManagement.module.scss";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import { ToTreeItem } from "common-types/converters/iTreeItemConverters";
import { createFolder } from "api/doc-management-api";
import { DocumentDto, DocumentFeatures } from "common-types/documentDto";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { useParams } from "react-router";
import { emptyFn } from "modules/common/fn-helpers";
import DocumentDisplay from "./DocumentDisplay";
import { downloadDocs } from "./Actions/doc-management-actions";
import {
  DocumentContextType,
  UpdatedDocument,
} from "./Context/DocumentContext";
import DocActionApi from "helpers/userActions/documentActions";
import * as DocManagementApiModule from "api/doc-management-api";
import DocManagementListeners from "./DocManagementUtils";

export const DocumentContext = createContext<DocumentContextType>({
  folderDeletedKey: "",
  setFolderDeletedKey: emptyFn,
  folderRenamed: undefined,
  setFolderRenamed: emptyFn,
  folderAdded: undefined,
  setFolderAdded: emptyFn,
  selectedFolder: undefined,
  setSelectedFolder: emptyFn,
  resetSearch: emptyFn,
  downloadDocuments: emptyFn,
  entityFolders: [],
});

export default function DocManagement() {
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const [currentSearchTerm, setCurrentSearchTerm] = useState<
    string | undefined
  >(undefined);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<
    Partial<DocumentFolderDto> | undefined
  >(undefined);
  const [allowCreate, setAllowCreate] = useState<boolean>(false);
  const [folderDeletedKey, setFolderDeletedKey] = useState<string>("");
  const [folderAdded, setFolderAdded] = useState<TreeItem | undefined>(
    undefined
  );
  const [folderRenamed, setFolderRenamed] = useState<
    UpdatedDocument | undefined
  >(undefined);
  const [entityFolders, setEntityFolders] = useState<
    DocumentFolderDto[] | undefined
  >(undefined);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [layout, setLayout] = useState<string>("full");
  const [mobileOpen, setMobileOpen] = useState<boolean>(true);

  const memoEntityFolders = useMemo(() => entityFolders, [entityFolders]);

  const sortFolders = () => {
    let newFolders = entityFolders
      ?.sort((a, b) => {
        const nameA = a.displayName.toUpperCase();
        const nameB = b.displayName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map((x) => x);
    return newFolders;
  };

  const useEntitiesChangedEffect = (handler: EffectCallback) =>
    useEffect(handler, [folderAdded, folderDeletedKey, folderRenamed]);

  const sortAndRefreshFolders = useCallback(() => {
    let newFolders = entityFolders
      ?.sort((a, b) => {
        const nameA = a.displayName.toUpperCase();
        const nameB = b.displayName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map((x) => x);
    setEntityFolders(newFolders);
  }, [entityFolders]);

  useEntitiesChangedEffect(() => {
    const sortAndRefreshFolders = () => {
      let sortedFolders = sortFolders();
      setEntityFolders(sortedFolders);
    };

    if (folderAdded !== undefined && entityFolders !== undefined) {
      entityFolders?.push(folderAdded.value);
      setSelectedFolder(folderAdded.value);
      setFolderAdded(undefined);
    }

    if (folderDeletedKey !== "" && entityFolders !== undefined) {
      let found = entityFolders.find((x) => x.id === folderDeletedKey);
      if (found) {
        const index = entityFolders.indexOf(found, 0);
        if (index > -1) {
          entityFolders.splice(index, 1);
        }
      }
      setFolderDeletedKey("");
    }

    if (folderRenamed !== undefined && entityFolders !== undefined) {
      let found = entityFolders.find((x) => x.id === folderRenamed.document.id);
      if (found) {
        found.name = folderRenamed.document.name;
        found.description = folderRenamed.document.description;
        found.displayName = folderRenamed.document.displayName;
      }
      setFolderRenamed(undefined);
    }
    sortAndRefreshFolders();
  });

  useEffect(() => {
    if (entityFolders === undefined) {
      getEntityFolders(entityId).then((result) => {
        setEntityFolders(result);
      });
    }
    setHeaderHeight(
      document.querySelector("header")?.getBoundingClientRect().height || 0
    );
  }, [entityId, entityFolders]);

  useEffect(() => {
    if (folderRenamed && entityFolders) {
      let found = entityFolders.find((x) => x.id === folderRenamed.document.id);
      if (found) {
        found.name = folderRenamed.document.name;
        found.description = folderRenamed.document.description;
        found.displayName = folderRenamed.document.displayName;
      }
      sortAndRefreshFolders();
    }
  }, [folderRenamed, entityFolders, sortAndRefreshFolders]);

  useEffect(() => {
    if (folderDeletedKey && entityFolders) {
      let found = entityFolders.find((x) => x.id === folderDeletedKey);
      if (found) {
        const index = entityFolders.indexOf(found, 0);
        if (index > -1) {
          entityFolders.splice(index, 1);
        }
      }
      sortAndRefreshFolders();
    }
  }, [folderDeletedKey, entityFolders, sortAndRefreshFolders]);

  const getEntityFolders = async (
    entityId: number
  ): Promise<DocumentFolderDto[]> => {
    return DocManagementApiModule.fetchFolders(entityId).then((response) => {
      return !response ? [] : response;
    });
  };

  const resetSearch = useCallback(() => {
    setIsSearchActive(false);
    setCurrentSearchTerm(undefined);
  }, [setIsSearchActive, setCurrentSearchTerm]);

  const downloadDocuments = useCallback(
    (documents: DocumentDto[], options: any) => {
      downloadDocs(entityId, documents, options);
    },
    [entityId]
  );

  const folderSelected = useCallback(
    (value: DocumentFolderDto) => {
      setAllowCreate(
        DocActionApi.hasCreateAction(value.source, value.documentType) &&
          (value?.features.includes(DocumentFeatures.CanCreateSubFolder) ??
            false)
      );
      setSelectedFolder(value);
      resetSearch();
    },
    [setAllowCreate, setSelectedFolder, resetSearch]
  );

  const createFolderAsync = useCallback(
    async (
      entity_Id: number,
      parentId: string,
      folderProperties: { name: string }
    ): Promise<{
      success: boolean;
      error: string;
      value?: TreeItem;
      statusCode: number;
    }> => {
      const docResponse = await createFolder(entity_Id, {
        parentId,
        name: folderProperties.name,
      });
      return {
        success: docResponse.success,
        statusCode: docResponse.statusCode,
        error:
          docResponse?.errors && docResponse?.errors.length > 0
            ? docResponse?.errors[0]
            : "",
        value: docResponse.value ? ToTreeItem(docResponse.value) : undefined,
      };
    },
    []
  );

  const documentContext = useMemo(() => {
    return {
      folderDeletedKey,
      setFolderDeletedKey,
      folderRenamed,
      setFolderRenamed,
      folderAdded,
      setFolderAdded,
      selectedFolder,
      setSelectedFolder,
      resetSearch,
      downloadDocuments,
      entityFolders: memoEntityFolders,
    };
  }, [
    folderDeletedKey,
    setFolderDeletedKey,
    folderRenamed,
    setFolderRenamed,
    folderAdded,
    setFolderAdded,
    selectedFolder,
    setSelectedFolder,
    resetSearch,
    downloadDocuments,
    memoEntityFolders,
  ]);

  DocManagementListeners(setLayout, setHeaderHeight);

  return (
      <DocumentContext.Provider value={documentContext}>
        <div
          className={styles.docManagement}
          style={
            {
              "--entity-page-header-height": headerHeight + "px",
            } as CSSProperties
          }>
          <div className={`${styles.docManagementContent}`}>
            {layout === "full" && (
              <KirbyResizer panel1default="33%" panel1min="280" panel2min="435">
                <div slot="panel1">
                  <FolderTree
                    selected={selectedFolder}
                    selectFolder={folderSelected}
                    folderIdentifier={"root"}
                    allowCreate={allowCreate}
                    create={createFolderAsync}
                    setIsSearchActive={setIsSearchActive}
                    setCurrentSearchTerm={setCurrentSearchTerm}
                  />
                </div>
                <div slot="panel2">
                  <DocumentDisplay
                    selectedFolder={selectedFolder}
                    searchTerm={currentSearchTerm ?? ""}
                    resetSearch={resetSearch}
                    isSearchActive={isSearchActive}
                  />
                </div>
              </KirbyResizer>
            )}
            {layout === "mobile" && (
              <>
                <div
                  className={`${styles.folderTreePanel} ${
                    mobileOpen ? styles.mobileOpen : ""
                  }`}>
                  <KirbyIcon
                    icon={kirbyClose}
                    className={styles.closePanel}
                    onClick={() => {
                      setMobileOpen(false);
                    }}></KirbyIcon>
                  <FolderTree
                    selected={selectedFolder}
                    selectFolder={folderSelected}
                    folderIdentifier={"root"}
                    allowCreate={allowCreate}
                    create={createFolderAsync}
                    setIsSearchActive={setIsSearchActive}
                    setCurrentSearchTerm={setCurrentSearchTerm}
                    panelOpenFunc={setMobileOpen}
                  />
                </div>
                <DocumentDisplay
                  selectedFolder={selectedFolder}
                  searchTerm={currentSearchTerm ?? ""}
                  resetSearch={resetSearch}
                  isSearchActive={isSearchActive}
                  setMobileOpen={setMobileOpen}
                />
              </>
            )}
          </div>
        </div>
      </DocumentContext.Provider>
  );
}

export const useGlobalContext = () => useContext(DocumentContext);
