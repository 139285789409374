import { DateCell } from "helpers/gridCells";

const gridColumns: Array<any> = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Contact Type',
    field: 'contactType'
  },
  {
    title: 'Office Held',
    field: 'officeHeld'
  },
  {
    title: 'Date Appointed',
    field: 'dateAppointed',
    formatter: DateCell
  }
];

export { gridColumns };
