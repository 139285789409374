import { ShareCapitalCell, ParValueCell, TotalUnitsBviCell, IssuedSharesCell, RemainingSharesCell } from 'helpers/gridCells';
import { RegisterManifestDto } from 'common-types';

const authorisedShareCapitalBviGridColumns: Array<any> = [
  {
    title: "Class of Shares",
    field: "classOfShare"
  },
  {
    title: "Voting Rights",
    field: "votingRights"
  },
  {
    title: "Type of Share",
    field: "shareType"
  },
  {
    title: "Total Number of Shares",
    field: "totalUnits",
    formatter: TotalUnitsBviCell,
    headerHozAlign: 'right',
    hozAlign :'right'
  },
  {
    title: "Issued Shares",
    field: "allocatedUnits",
    formatter: IssuedSharesCell,
    headerHozAlign: 'right',
    hozAlign :'right'
  },
  {
    title: "Remaining Shares",
    field: "remainingUnits",
    formatter: RemainingSharesCell,
    headerHozAlign: 'right',
    hozAlign :'right'
  },
  {
    title: "Currency",
    field: "currencyCode"
  },
  {
    title: "Par Value",
    field: "parValue",
    formatter: ParValueCell,
    headerHozAlign: 'right',
    hozAlign: 'right'
  },
  {
    title: "Share Capital",
    field: "shareCapital",
    formatter: ShareCapitalCell,
    headerHozAlign: 'right',
    hozAlign: 'right'
  }
];

export const getAuthorisedShareCapitalBviGridColumns = (bcActTransProvApplicable: boolean, registers: RegisterManifestDto[] | undefined) => {
  const filteredColumns = bcActTransProvApplicable ? 
    authorisedShareCapitalBviGridColumns :
      authorisedShareCapitalBviGridColumns.filter(gc => gc.field !== "shareCapital");

    if (!registers)
      return filteredColumns;
  
    const registerOfMembers = registers.find(r => r.internalCode === "Shareholders");
  
    if (registerOfMembers?.active) 
      return filteredColumns;
  
    return filteredColumns
      .filter(gc => gc.field !== "allocatedUnits" && gc.field !== "remainingUnits");
};