import { FullDataRequest, PagedDataRequest } from "api/interfaces/pagedDataRequest";
import { AxiosResponse } from "axios";
import { PagedListDto } from "common-types";
import { EconomicSubstanceFilingDto } from "common-types/economicSubstanceFilingDto";
import { ECONOMIC_SUBSTNCE_FILINGS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { ApiResponse } from "helpers/apiCaller/ApiResponse";
import { browserDownloadResult } from "modules/download/fileDownload";
import { getFileName } from "modules/download/fileResult";

let economicSubstanceController: AbortController;

export const getEconomicSubstanceFilings = async(request: PagedDataRequest | undefined): Promise<PagedListDto<EconomicSubstanceFilingDto>> => {
  if (economicSubstanceController)
    economicSubstanceController.abort();

  economicSubstanceController = new AbortController();

  return ApiCaller.post(`${ECONOMIC_SUBSTNCE_FILINGS_ENDPOINT}`, request ?? {}, { signal: economicSubstanceController.signal });
}

const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
  browserDownloadResult(new Blob([axiosResponse.data]), getFileName(axiosResponse.headers));
};

export const downloadEconomicSubstanceFilings = async (request: FullDataRequest, callBack: (response: ApiResponse<any>) => void): Promise<ApiResponse<any>> => {
  return ApiCaller.downloadPost(`${ECONOMIC_SUBSTNCE_FILINGS_ENDPOINT}/download`, blobHandler, request)
    .then((response: ApiResponse<any>) => { callBack(response); return response; });
}
