import { NotificationSettingDto } from './../../common-types/user/userSettingsDto';
import { NotificationType } from './types/component-types';
import produce from "immer";
import { UserActionTypes } from "./user-actions";
import { FetchSettingsSuccessAction, UpdateFormStateAction, SaveSettingsStatusAction } from "./types/user-actions";
import { UserSettingsState } from "./types/userSettingsState";
import { FormState } from "common-types/forms/FormState";
import { SaveSettingsState } from "common-types/user/SaveSettingsState";

const initialState: UserSettingsState = {
  settings: undefined,
  formState: FormState.Active,
  saveSettingsStatus: undefined,
};

export default (
  state = initialState,
  action: FetchSettingsSuccessAction & UpdateFormStateAction & SaveSettingsStatusAction  | undefined = undefined
): UserSettingsState =>
  produce(state, (draft) => {
    switch (action?.type) {
      case UserActionTypes.FETCH_SETTINGS_SUCCESS: {
        let settings = action.settings;
        settings.notificationSettings.sort(notificationSettingSorter);
        draft.settings = settings;
        draft.formState = FormState.Active;
        draft.saveSettingsStatus = undefined;
        return;
      }
      case UserActionTypes.SET_FORM_STATE: {
        draft.formState = action.formState;
        draft.saveSettingsStatus = undefined;
        return;
      }
      case UserActionTypes.SAVE_SETTINGS_FAILURE: {
        draft.formState = FormState.Active;
        draft.saveSettingsStatus = SaveSettingsState.Failed;
        return;
      }
      case UserActionTypes.SAVE_SETTINGS_SUCCESS: {
        draft.formState = FormState.Active;
        draft.saveSettingsStatus = SaveSettingsState.Success;
        return;
      }
      default:
        return state;
    }
  });

  
const notificationSettingSorter = (first: NotificationSettingDto, second: NotificationSettingDto): number => 
  notificationSettingsDisplayOrder(first.type) - notificationSettingsDisplayOrder(second.type);

const notificationSettingsDisplayOrder = (type: NotificationType): number => {
  switch(type) {
    case NotificationType.MinuteBook: 
      return 10;
    case NotificationType.Opinions: 
      return 20;
    case NotificationType.ExecutedDocuments: 
      return 30;
    case NotificationType.Invoices:
      return 40;
    default: return 100;
  }
}
