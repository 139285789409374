import React, { useCallback, useRef } from 'react'
import { RequireAuthProps } from "modules/auth/auth-component-types";
import { FetchStatus } from 'common-types/fetchStatusEnum';
import store from 'store/configureStore';
import { AuthActionTypes } from 'modules/auth/auth-actions';
import { UserAuthenticatedDto } from '../../common-types/user/userAuthenticatedDto';

export function RequireAuth(props: RequireAuthProps) {

  const { isAuthenticated, isAuthenticatedFetchStatus, user, userFetchStatus, fetchIsAuthenticated, navigateToLogin, navigateToPage, children } = props;
  const authenticated = isAuthenticatedFetchStatus === FetchStatus.Complete && isAuthenticated;

  const callFetchIsAuthenticated = useCallback(async (): Promise<boolean> => {
      return await fetchIsAuthenticated();
  }, [fetchIsAuthenticated]);

  const callNavigateToLogin = useCallback(() => {
    navigateToLogin();
  }, [navigateToLogin]);

  const callNavigateToPage = useCallback((page: string) => {
    navigateToPage(page);
  }, [navigateToPage]);

  const isRedirectingToLoginRef = useRef(false);

  callFetchIsAuthenticated()
    .then((isAuthenticated: boolean) => {
      // Notify the rest of the app
      store.dispatch({ type: AuthActionTypes.FETCH_IS_AUTHENTICATED_SUCCESS, isAuthenticated });

      if (!isAuthenticated && !isRedirectingToLoginRef.current) {
        if (userInState(userFetchStatus, user)) {
          // User has resumed in the UI and didn't hit the app fresh, show session expiry
          callNavigateToPage("401");
        }
        else {
            isRedirectingToLoginRef.current = true;
            callNavigateToLogin();
        }
      }
    })
    .catch(() => {
      callNavigateToPage("403");
    });

  if (shouldShowChildren(authenticated, user)) {
    return children;
  }

  return null;
}

function userInState(userFetchStatus: FetchStatus, user: UserAuthenticatedDto | null | undefined) {
  return userFetchStatus === FetchStatus.Complete && user;
}

function shouldShowChildren(isAuthenticated: boolean, user: UserAuthenticatedDto | null | undefined) {
  return isAuthenticated && user;
}
