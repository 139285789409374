import { OrderDto } from "common-types/serviceRequests/orderDto";
import { distinct } from "helpers/array";
import utils from "../utils";

export class OrderForTable extends OrderDto {
  orderItemNames: string[];
  orderDisplayDate?: Date;
  orderedByDisplay: string;
}

export const toOrderForTable = (orderDto: OrderDto): OrderForTable => {
  return {
    ...orderDto,
    orderItemNames: distinct(orderDto.orderItems.map(a => utils.docTypeDisplayName(a))).sort((a, b) => (a ?? '').localeCompare(b ?? '')),
    orderDisplayDate: (orderDto.submittedDate?.toString() === '0001-01-01T00:00:00' || orderDto.submittedDate === null) ? orderDto.createdDate : orderDto.submittedDate,
    orderedByDisplay: orderDto.submittedByName ?? orderDto.createdByName ?? ''
  };
}