import { Component } from "react";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";
import { NameHistoryDto } from "common-types";

class NameHistory extends Component<SimplePagedSectionProps<NameHistoryDto>> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default NameHistory;
