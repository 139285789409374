import { HomePageType } from "common-types/homePageTypeEnum";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Actions, hasAction } from "../../../helpers/userActions";
import AllEntities from "./AllEntities/AllEntitiesContainer";
import RecentlyViewedEntities from "./RecentlyViewedEntities/RecentlyViewedEntitiesContainer";
import SearchResults from "./SearchResults/SearchResultsContainer";

export interface EntitiesProps {
    homePageType: HomePageType,
    setCurrentPage: (pageNumber: number) => void;
    setPageSize: (pageSize: number) => void;
    clearEntitiesSearchTerm: () => void;
    setHomePageType: (type: HomePageType) => void;
}

export default function Entities({
  homePageType,
  setCurrentPage,
  setPageSize,
  clearEntitiesSearchTerm,
  setHomePageType,
}: EntitiesProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [routeLoading, setRouteLoading] = useState(false);

  useEffect(() => {
    if (location.state?.reset) {
      navigate(".", { replace: true });
      clearEntitiesSearchTerm();
      setCurrentPage(1);
      setPageSize(20);
      clearEntitiesSearchTerm();
      setHomePageType(HomePageType.RecentlyViewedEntities)
      setRouteLoading(true);
      setTimeout(() => {
        setRouteLoading(false);
      }, 100)
    }
  }, [location]);

  useEffect(() => {
    setRouteLoading(false);
  }, [routeLoading]);

  useEffect(() => {
    document.title = "My Entities";
  });

  return (
    <>
      {hasAction(Actions.ViewEntity) && homePageType === HomePageType.SearchResults && <SearchResults />}
      {hasAction(Actions.ViewEntity) && homePageType === HomePageType.RecentlyViewedEntities && <RecentlyViewedEntities />}
      {hasAction(Actions.ViewEntity) && homePageType === HomePageType.AllEntities && <AllEntities />}
      </>
  );
}
