import { KirbyCollapsible } from "maples-kirby-react";
import styles from "./Collapsible.module.scss";

export interface CollapsibleProps {
  children: any;
  headerText: string;
  open?: boolean;
  title?: string;
  handleOpenToggle?: Function;
}

export default function Collapsible(props: CollapsibleProps) {
  return (
    <>
      <KirbyCollapsible
        className={styles.kirbyCollapsible}
        title={props.title}
        open={props.open}
        handleOpenToggle={props.handleOpenToggle}>
        <div className={styles.header} slot="collapsible-header">
          <h5>{props.headerText}</h5>
        </div>
        <div className={styles.content} slot="collapsible-content">
          {props.children}
        </div>
      </KirbyCollapsible>
    </>
  );
}
