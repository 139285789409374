import { ENTITIES_ENDPOINT, REGISTERDOWNLOAD_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";

export const download = async (
  entityId: number,
  clientNumber: string,
  registerInternalCode: string,
  derived: boolean,
  blobHandler: Function
): Promise<any> => {
  return ApiCaller.download(
    `${ENTITIES_ENDPOINT}/${entityId}${REGISTERDOWNLOAD_ENDPOINT}?ClientNumber=${clientNumber}&RegisterInternalCode=${registerInternalCode}&Derived=${derived}`,
    blobHandler
  );
};
