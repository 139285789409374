import { RegisterManifestDto } from "common-types";
import { ENTITIES_ENDPOINT, REGISTERS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchRegisters";

export const fetchRegisters = async (
  entityId: number
): Promise<RegisterManifestDto[]> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${REGISTERS_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchRegisters = (): void => tryCancelPending(signalKey);