import { DateCell } from "helpers/gridCells";

export const gridColumns: Array<any> = [
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Date Admitted",
    field: "dateAdmitted",
    formatter: DateCell,
  },
];
