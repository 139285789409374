import {
  NotificationSettingDto,
  UserSettingsDto,
} from "common-types/user/userSettingsDto";
import {
  FormikNotificationSettings,
  NotificationOccurrence,
  NotificationType,
} from "./types/component-types";

export const notificationTypeDic = new Map<string, NotificationType>([
  [NotificationType.ExecutedDocuments, NotificationType.ExecutedDocuments],
  [NotificationType.Invoices, NotificationType.Invoices],
  [NotificationType.MinuteBook, NotificationType.MinuteBook],
  [NotificationType.Opinions, NotificationType.Opinions],
]);

export const formikNotificationSettingsToUserSettingsDto = (
  settings: FormikNotificationSettings
): UserSettingsDto => {
    const notificationSettings: NotificationSettingDto[] = Object.keys(settings)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc: NotificationSettingDto[], value: string) => {
      if (notificationTypeDic.has(value)) {
        const notificationType = notificationTypeDic.get(value)!;
        const notificationOccurrence = settings[notificationType];
        const notificationTypeUserSetting = settings.UserSettings?.notificationSettings.find(s => s.type == notificationType);

        acc.push({
          type: notificationType,
          occurrence: notificationOccurrence,
          isNotificationTypeEnabled: notificationTypeUserSetting ? notificationTypeUserSetting.isNotificationTypeEnabled : false
        });
      }

      return acc;
    }, []);

  return {
    notificationSettings: notificationSettings,
  };
};

export const userSettingsDtoToFormikNotificationSettings = (
  settings: UserSettingsDto | undefined
): FormikNotificationSettings => {
  const defaultValue: FormikNotificationSettings = {
    ExecutedDocuments: NotificationOccurrence.Never,
    MinuteBook: NotificationOccurrence.Never,
    Invoices: NotificationOccurrence.Never,
    Opinions: NotificationOccurrence.Never,
    UserSettings: settings
  };
  return (
    settings?.notificationSettings.reduce(
      (acc: FormikNotificationSettings, value) => {
        acc[value.type] = value.occurrence;
        return acc;
      },
      defaultValue
    ) ?? defaultValue
  );
};
