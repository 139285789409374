import { comparisonOperatorEnum } from "api/interfaces/comparisonOperatorEnum";
import { invoiceActions } from "modules/invoice/invoice-actions";
import { AppState } from "store/AppState";
import { getEntity } from "modules/entity/entity-selectors";
import { connect } from "react-redux";
import OutstandingInvoices, { OutstandingInvoicesDispatchProps, OutstandingInvoicesStateProps } from "./OutstandingInvoices";

const mapStateToProps = (state: AppState): OutstandingInvoicesStateProps => ({ 
    entity: getEntity(state)
  });
  
  const mapDispatchToProps: OutstandingInvoicesDispatchProps = {  
    setInvoicesFilter: (clientNumber: string, clientName: string) => invoiceActions.setFilters(
      [{
        comparison: comparisonOperatorEnum.EQUAL,
        fieldName: "ClientNumber",
        value1: clientNumber
      },
      {
        comparison: comparisonOperatorEnum.EQUAL,
        fieldName: "EntityName",
        value1: clientName
      },
      {
        comparison: comparisonOperatorEnum.EQUAL,
        fieldName: "statuscode",
        value1: "O"
      }
    ]
    )
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(OutstandingInvoices);