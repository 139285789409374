//
// From DB Query:
// SELECT DataTypeName + ': "' + DataTypeName + '",' FROM dbo.EntityDataTypes
//
export const EntityDataTypeKeys = {
  AMLOfficers: "AMLOfficers",
  AuthorisedCapital: "AuthorisedCapital",
  AuthorisedCapitalBvi: "AuthorisedCapitalBvi",
  BeneficialOwner: "BeneficialOwner",
  Directors: "Directors",
  DirectorsBvi: "DirectorsBvi",
  EntityNameHistory: "EntityNameHistory",
  EntityTermination: "EntityTermination",
  CurrentManagers: "Managers",
  CurrentPartners: "Partners",
  MortgagesAndCharges: "MortgagesAndCharges",
  Offices: "Offices",
  Charges: "Charges",
  Members: "Members",
  Partnerships: "Partnerships",
  SecurityInterests: "SecurityInterests",
  SecurityInterestsLLC: "SecurityInterestsLLC",
  UnitPartnershipInterests: "UnitPartnershipInterests",
  Registers: "Registers",
  SegregatedPortfolios: "SegregatedPortfolios",
  Shareholders: "Shareholders",
  TrademarkClasses: "TrademarkClasses",
  Vessel: "Vessel",
  MaplesTeam: "MaplesTeam",
  ShippingDetails: "ShippingDetails"
};
