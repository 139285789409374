import { EntityHyperLinkCell, MailToCell, IconCell, LinkClick } from "helpers/gridCells";
import { EntityDto } from 'common-types';
import { propertyNames } from 'constants/copy/entity/entityPropertiesText';
import { getGoodstandingSetting } from 'constants/copy/entity/goodStandingText';
import { renderToStaticMarkup } from "react-dom/server";
import { kirbyMenu, kirbyGo } from "maples-kirby-icons";
import { overlayMessage } from "maples-kirby";

const quickMenuPrefix = 'quickMenu';

export const buildEntityLinkTitle = (entity: EntityDto) : string => {
  let linkTitle = 'View Entity';
  let { searchMatchInfo } = entity;

  if (searchMatchInfo && searchMatchInfo?.matchedFields.length > 0) 
    linkTitle = `Matched on: ${searchMatchInfo.matchedFields.map(i => propertyNames[i] ?? i).join(', ')}`;

  return linkTitle;
}

export function entityGridColumns(serviceRequestsEnabled: boolean) : Array<any> {

  return [
    {
      field: 'showInPortal',
      hozAlign: 'center',
      resizable: false,
      visible: false,
      hideFromColManagement: true,
      formatter: overlayMessage,
      formatterParams: {
        showCondition: (cell: any) => !cell.getRow().getData().showInPortal && !cell.getRow().getData().closed,
        message: 'Entity is offline'
      },
    },
    {
      field: 'closed',
      hozAlign: 'center',
      resizable: false,
      visible: false,
      hideFromColManagement: true,
      formatter: overlayMessage,
      formatterParams: {
        showCondition: (cell: any) => !!cell.getRow().getData().closed && cell.getRow().getData().showInPortal,
        message: 'Entity is closed'
      },
    },
    {
      hozAlign: 'center',
      field: 'closedandoffline',
      resizable: false,
      visible: false,
      hideFromColManagement: true,
      formatter: overlayMessage,
      formatterParams: {
        showCondition: (cell: any) => !!cell.getRow().getData().closed && !cell.getRow().getData().showInPortal,
        message: 'Entity is closed and offline'
      },
    },
    {
      title: '',
      field: 'goodStanding',
      hideFromColManagement: true,
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        const { goodStanding } = cell.getRow().getData();
        let setting = getGoodstandingSetting(goodStanding);
        onRendered(() => {
          cell.getElement().querySelector('kirby-icon').icon = setting.icon;
        })
        return renderToStaticMarkup(IconCell(setting.title, setting.icon, undefined, setting.color))
      },
      width: 2.625,
      cssPart: 'goodStandingCell'
    },
    {
      title: '',
      field: 'menu',
      hideFromColManagement: true,
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          let iconButton = cell.getElement().querySelector('kirby-button');
          let menu = cell.getElement().querySelector('kirby-menu');
          let topStart = 0;
          let buttonTopStart = 0;
          iconButton.icon = kirbyMenu;
          menu.target = iconButton;
          iconButton.handleClick = () => {
            menu.open = true;
            buttonTopStart = topStart;
            iconButton.disabled = true;
          }
          menu.handleClose = () => {
            menu.open = false;
            iconButton.disabled = false;
          }
          cell.getTable().on("scrollVertical", (top: number) => {
            topStart = top;
            if (document.querySelector(`sp-overlay #${quickMenuPrefix}${cell.getRow().getPosition()}`)) {
              let buttonPos = iconButton.getBoundingClientRect().y - cell.getTable().element.getBoundingClientRect().y;
              if (buttonPos > 0 && buttonPos < cell.getTable().element.getBoundingClientRect().height) {
                (document.querySelector('sp-overlay') as HTMLElement).style.top = (buttonTopStart - top) + 'px';
              } else {
                menu.open = false;
                iconButton.disabled = false;
              }
            }
          });
        })
        return `
          <kirby-button></kirby-button>
          <kirby-menu id="${quickMenuPrefix}${cell.getRow().getPosition()}">
            ${renderToStaticMarkup(EntityHyperLinkCell(cell.getRow().getData().id, 'Overview', 'Overview'))}
            ${renderToStaticMarkup(EntityHyperLinkCell(cell.getRow().getData().id, 'Document Management', 'Document Management', 'docs'))}
            ${serviceRequestsEnabled ? renderToStaticMarkup(EntityHyperLinkCell(cell.getRow().getData().id, 'Service Requests', 'Service Requests', 'servicerequests')) : ''}
          </kirby-menu>
        `
      },
      width: 2.625,
      cssPart: 'menuCell'
    },
    {
      title: 'Entity Name',
      field: 'name',
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          cell.getElement().querySelector('a').onclick = LinkClick;
        })
        return renderToStaticMarkup(EntityHyperLinkCell(cell.getRow().getData().id, cell.getRow().getData().name, buildEntityLinkTitle(cell.getRow().getData())))
      }
    },
    {
      title: 'Client Number',
      field: 'clientNumber',
    },
    {
      title: 'Relationship Contact',
      field: 'relationshipContact',
      formatter: (cell: any) => {
        return renderToStaticMarkup(MailToCell(cell.getRow().getData().relationshipContact, cell.getRow().getData().relationshipContactEmail))
      }
    },
    {
      title: 'Jurisdiction',
      field: 'jurisdictionEntityType.jurisdiction.name',
    },
    {
      title: 'Entity Type',
      field: 'jurisdictionEntityType.name',
    },
    {
      title: '',
      field: 'buttonCell',
      cssClass: 'sticky right',
      headerSort: false,
      width: 3.125,
      hideFromColManagement: true,
      formatter: (cell: any, formatterParams: any, onRendered: Function) => {
          onRendered(() => {
              cell.getElement().querySelector('kirby-button').anchor={ href: `/entities/${cell.getRow().getData().id}`, target: "_blank"}
              cell.getElement().querySelector('kirby-button').icon = kirbyGo;
              cell.getElement().querySelector('kirby-button').style.height = "100%";
          })
          return `<kirby-button title="Open in new window" style="display:block"></kirby-button>`
      }
  },
  ];
}
