import { FscLicenceDto } from "common-types/FscLicenseDto";

export const FscLicenceActionTypes = {
  FETCH_FSC_LICENCE: "FETCH_FSC_LICENCE",
  FETCH_FSC_LICENCE_SUCCESS: "FETCH_FSC_LICENCE_SUCCESS",
  FETCH_FSC_LICENCE_FAILURE: "FETCH_FSC_LICENCE_FAILURE",
  CANCEL_FETCH: "CANCEL_FETCH"
}

export const FscLicenceActions = {
  fetchFscLicence: (entityId: number) => ({
    type: FscLicenceActionTypes.FETCH_FSC_LICENCE,
    entityId,
  }),

  fetchFscLicenceSuccess: (licence: FscLicenceDto) => ({
    type: FscLicenceActionTypes.FETCH_FSC_LICENCE_SUCCESS,
    licence,
  }),

  fetchFscLicenceFailure: (error: any) => ({
    type: FscLicenceActionTypes.FETCH_FSC_LICENCE_FAILURE,
    error
  }),

  cancel: () => ({
    type: FscLicenceActionTypes.CANCEL_FETCH
  })
};
