import { DateCell } from 'helpers/gridCells';

export const gridColumns: Array<any> = [
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Partner Type",
    field: "partnerType"
  },
  {
    title: "Date of Admission",
    field: "dateOfAdmission",
    formatter: DateCell
  }
];
