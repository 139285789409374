import { Component } from "react";
import { AmlOfficerDto } from "common-types/amlOfficerDto";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";

class AmlOfficers extends Component<SimplePagedSectionProps<AmlOfficerDto>> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default AmlOfficers;
