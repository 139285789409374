import { TreeItem } from 'maples-kirby/dist/types/types/tree';
import { DocumentSearchResultDto } from 'common-types/documentSearchResultDto';
import { currentEntity } from "helpers/entity";

const multiDownloadMessage: string = 'Downloading multiple files...';
const multiDownloadCompleteMessage: string = '';

const pad = (i: number) => {
  return (i < 10) ? '0' + i.toString() : i.toString();
}
export const entityDocsFileName = (): string => {
  const now = new Date();
  return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())} - Entity documents - [${currentEntity()}]`;
}

export const downloadMessage = (toDownload: TreeItem[]) : string => {
  return toDownload.length === 1 ? toDownload[0].value.displayName : multiDownloadMessage;
}

export const downloadCompleteMessage = (toDownload: TreeItem[]) : string => {
  return toDownload.length === 1 ? toDownload[0].value.displayName : multiDownloadCompleteMessage;
}

export const downloadMessageSearch = (toDownload: DocumentSearchResultDto[]) : string => {
  return toDownload.length === 1 ? toDownload[0].document.displayName : multiDownloadMessage;
}

export const downloadCompleteMessageSearch = (toDownload: DocumentSearchResultDto[]) : string => {
  return toDownload.length === 1 ? toDownload[0].document.displayName : multiDownloadCompleteMessage;
}
