import { NameHistoryDto } from 'common-types';
import { ENTITIES_ENDPOINT, NAMEHISTORY_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from './interfaces/entityPageRequest';
import { getAbortSignalTryCancelPending, tryCancelPending } from './utils/requestAbortion';

const signalKey = "fetchNameHistory";

export const fetchNameHistory = async(request: EntityPageRequest): Promise<PagedListDto<NameHistoryDto>> => 
    ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${NAMEHISTORY_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchNameHistory = (): void => tryCancelPending(signalKey);
  
  
