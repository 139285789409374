import { DocumentCopyResultDto, DocumentDto } from 'common-types/documentDto';
import { addToast, updateToast } from "components/common/Toaster";
import { copy, copyMany } from 'api/doc-management-api';
import { ApiResponse } from 'helpers/apiCaller/ApiResponse';
import { DocumentFolderDto } from 'common-types/documentFolderDto';
import { Copy } from 'constants/copy/document-management/copy';
import initToastProgress from 'components/common/Toaster-progress';
import { IProgressRequestComplete } from 'components/common/types/Toaster';

const toastFailTitle: string = 'Copy Failed';
const toastStatus: { error: string, success: string, spinner: string, progress: string } = { success: "success", error: "error", spinner: "spinner", progress: "progress" };

const getError = (documentCopyResult: DocumentCopyResultDto) : string => {
  return documentCopyResult.statusCode === 404 ? Copy.ItemNotFoundMessage : documentCopyResult.message ?? Copy.GenericErrorMessage;
}

const getActionString = (moveAction: boolean, past?: boolean): string => {
  let actionCurrent = moveAction ? 'Moving' : 'Copying';
  let actionPast = moveAction ? 'moved' : 'copied';
  return past ? actionPast : actionCurrent;
}

const buildError = (documentCopyResult: ApiResponse<DocumentCopyResultDto[]>) : string => {
  if (documentCopyResult?.errors)
    return documentCopyResult.errors[0];

  const errorsJoined = documentCopyResult.value?.filter(a => !a.isSuccess)
    .map(a => getError(a)).join(', ');
    
  return errorsJoined ?? Copy.GenericErrorMessage;
}

const buildToastTitle = (folderCount: number, documentCount: number, moveAction?: boolean): string => {
  let title: string = getActionString(!!moveAction) + ' ';

  if (folderCount >= 1) {
    title += 'Folder';
    if (folderCount > 1)
      title += 's';
  } 
  
  if (documentCount >= 1) {
    if (folderCount >= 1)
      title += ' and ';  
    
    title += 'Document';
    if (documentCount > 1)
      title += 's';
  } 

  return title;
}

const copyToFolder = async (entityId: number, document: DocumentDto, targetFolder: DocumentFolderDto): Promise<any> => {
  let result = await copyMany(entityId, {
    targetDocumentId: targetFolder.id,
    document: { id: document.id, name: document.displayName, isFolder: document.isFolder }
  });

  const success: boolean = result.success && ((result.value && result.value.every(a => a.isSuccess)) ?? false);

  if (!success) {
    return { isSuccess: false, message: buildError(result), document: document, statusCode: result.statusCode };
  }

  return { isSuccess: success, document: document, statusCode: result.statusCode };
}

export const copyDocuments = async (entityId: number, documents: DocumentDto[], targetFolder: DocumentFolderDto, moveAction?: boolean) => {
  if (documents.length === 1 && !documents[0].isFolder) {
    return await copyDocument(entityId, documents[0], targetFolder, moveAction);
  }

  const toastTitle: string = buildToastTitle(
    documents.filter((a: DocumentDto) => a.isFolder).length, 
    documents.filter((a: DocumentDto) => !a.isFolder).length,
    moveAction
  );

  const copyNextDocument = async <DocumentDto, DocumentCopyResultDto>(document: DocumentDto): Promise<IProgressRequestComplete<DocumentCopyResultDto>> => {
    let copyResult = await copyToFolder(entityId, document as any, targetFolder);

    if (!copyResult)
      return { value: {}, message: Copy.GenericErrorMessage, success: false  } as IProgressRequestComplete<DocumentCopyResultDto>;
    
    return { value: copyResult, message: copyResult.message, success: copyResult.isSuccess  } as IProgressRequestComplete<DocumentCopyResultDto>;
  }

  const copyProcessor = await initToastProgress<DocumentDto, DocumentCopyResultDto>({
    title: toastTitle,
    message: `${getActionString(!!moveAction)} selected documents to folder '${targetFolder.displayName}'.`,
    totalItemsToProcess: documents.length,
    action: copyNextDocument
  });

  const getCompletedToastSettings = (targetFolder: DocumentFolderDto, results: DocumentCopyResultDto[], moveAction?: boolean) => {
    let settings = {
      status: toastStatus.progress,
      message: '',
      title: ''
    };
  
    let messageSuffix: string = 'with errors';
  

    if (results.every((a: DocumentCopyResultDto) => a.isSuccess)) {
      messageSuffix = 'successfully'
    }
    else if (results.every((a: DocumentCopyResultDto) => !a.isSuccess)) {
      messageSuffix = `unsuccessfully`;
    }
  
    settings.message = `${!!moveAction ? 'Move' : 'Copy'} to '${targetFolder.displayName}' completed ${messageSuffix}.`;
    settings.title = buildToastTitle(
      results.filter((a: DocumentCopyResultDto) => a.document?.isFolder).length, 
      results.filter((a: DocumentCopyResultDto) => !a.document?.isFolder).length,
      !!moveAction
    );
  
    return settings;
  }
  
  let results: DocumentCopyResultDto[] = [];
  for (const document of documents) {
    results.push(await copyProcessor.processNextItem(document));
  }

  let finishedToastSettings = getCompletedToastSettings(targetFolder, results, moveAction);
  return copyProcessor.onAllProcessed(finishedToastSettings.title, finishedToastSettings.message);
}

async function copyDocument(entityId: number, document: DocumentDto, targetFolder: DocumentFolderDto, moveAction?: boolean) {
  let toastId = await addToast(getActionString(!!moveAction), `${getActionString(!!moveAction)} document '${document.displayName}' to folder '${targetFolder.displayName}'.`, toastStatus.spinner);

  const request = {documentId: document.id, targetDocumentId: targetFolder.id};
  const response = await copy(entityId, request);

  if (response.success) {
    updateToast(toastId, getActionString(!!moveAction), `${moveAction ? 'Move' : 'Copy'} to '${targetFolder.displayName}' completed successfully.`, toastStatus.success);
  } else {
    let errMsg = "Unknown error";
    if (response.statusCode === 404)
      errMsg = Copy.ItemNotFoundMessage;
    else if (response?.errors)
      errMsg = response.errors[0];

    updateToast(toastId, toastFailTitle, errMsg, toastStatus.error);
  }

  return constructResponse(response);
}

const constructResponse = (response: ApiResponse<any>) => {
  return {
    success: response.success,
    statusCode: response.statusCode,
    error: response.errors?.join(' - ') ?? "",
    value: response.value ? response.value : undefined
  }
}
