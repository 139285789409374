import { DocumentDto } from 'common-types/documentDto';
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { TreeItem } from 'maples-kirby/dist/types/types/tree';
import DocActionApi from "helpers/userActions/documentActions";

export const ToTreeItem = (doc: DocumentDto) => {
  let treeItem: TreeItem = {
      key: doc.id,
      label: `${doc.displayName}${doc.isFolder ? '' : '.' + doc.fileType}`,
      hasChildren: doc.hasChildFolders,
      children: [],
      type: doc.isFolder ? "folder" : "document",
      value: doc
  };

  return treeItem;
}
export const ToTreeFolderItem = (doc: DocumentFolderDto) => {
  let treeItem: TreeItem = {
      key: doc.id,
      label: doc.displayName,
      children: [],
      type: DocActionApi.hasCreateAction(doc.source, doc.documentType) ? "folder" : "readOnlyFolder",
      value: doc
  };

  return treeItem;
}
