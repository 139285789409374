import { CurrencyCell, DateCell } from "helpers/gridCells";

const gridColumns: Array<any> = [
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Nature of Interest",
    field: "natureOfInterest"
  },
  {
    title: "Date of Admission",
    field: "dateOfAdmission",
    formatter: DateCell
  },
  {
    title: "Currency",
    field: "capitalCommitmentCurrencyCode"
  },
  {
    title: "Capital Commitment",
    field: "amountofCapitalCommitment",
    formatter: CurrencyCell,
    hozAlign: "right"
  },  
  {
    title: "Commitment Balance",
    field: "balanceOfCapitalCommitment",
    formatter: CurrencyCell,
    hozAlign: "right"
  },
  {
    title: "Capital Account Balance",
    field: "balanceOfCapitalAccount",
    formatter: CurrencyCell,
    hozAlign: "right"
  },
];

export { gridColumns };
