import { EntityHyperLinkCell, LinkClick } from "helpers/gridCells";
import { renderToStaticMarkup } from "react-dom/server";

const enableLink = (row: any): boolean =>
  row.entityId !== null && row.entityId > 0;

const gridColumns: Array<any> = [
  {
    title: "Entity",
    field: "entityName",
    formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
      let rowData = cell.getData();
      if (enableLink(rowData)) {
        onRendered(() => {
          cell.getElement().querySelector("a").onclick = LinkClick;
        });
        return renderToStaticMarkup(
          EntityHyperLinkCell(rowData.entityId, rowData.entityName)
        );
      }
      return rowData.entityName;
    },
  },
  {
    title: "Relationship Type",
    field: "partnershipType",
  },
];

export { gridColumns };
