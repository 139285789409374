import React, { useEffect, useState, useRef, useCallback } from 'react';
import { KirbySection } from 'maples-kirby-react';
import styles from "./Errors.module.scss";
import { useLocation } from 'react-router';
import { AUTH_ENDPOINT } from 'constants/urls';

type RedirectLocationState = {
    redirectTo: string;
};

export function Page401() {
    const { state: locationState } = useLocation();
    const [timer, setTimer] = useState(5);
    const timerRef = useRef(timer);
    timerRef.current = timer;

    const redirectToLogin = useCallback(() => {
        const current = validUrlOrHomepage(window.location.href);
        const cacheBuster = new Date().getTime();
        const baseUrl = validUrlOrHomepage(`${current.protocol}//${current.host}/?ms=${cacheBuster}`);
        let redirectUrl = baseUrl;

        if (locationState) {
            const { redirectTo } = locationState as RedirectLocationState;
            redirectUrl = validUrlOrHomepage(redirectTo);
            
            if(redirectUrl.searchParams.has("ms"))
               redirectUrl.searchParams.delete("ms");

            redirectUrl.searchParams.append("ms", new Date().getTime().toString())
        }

        let loginUrl = AUTH_ENDPOINT + "/login?returnUrl=" + encodeURI(redirectUrl.toString());
        window.location.href = loginUrl;

    }, [locationState]);

    const countdown = useCallback(() => {
        setTimeout(()=>{
            setTimer(previous => previous - 1);
            countdown();
        }, 1000)

        if (timerRef.current === 0) {
            redirectToLogin();
        }
    }, [redirectToLogin]);

    useEffect(() => { 
        countdown();
    }, [countdown]);

    const validUrlOrHomepage = (url: string) => {
        try { 
            return new URL(url); 
        }
        catch(e){ 
            return new URL(window.location.host); 
        }
    }

    let message = timerRef.current > 0 ? <Login redirectToLogin={redirectToLogin} /> : <Redirecting />

    return (
        <div className={styles.errorPage}>
            <KirbySection sectionTitle="Session Expired">
                <div className={styles.content}>
                    {message}
                </div>
            </KirbySection>
        </div>
    );
}

const Login = (props: any) => {
    const login = <a onClick={props.redirectToLogin} title="Login">login</a>;

    return (
        <p>
            Your session has ended due to inactivity. Please {login} again.
        </p>
    );
}

const Redirecting = () => {
    return (
        <p>Redirecting to login...</p>
    );
}