import { HomePageType } from "common-types/homePageTypeEnum";
import UserMenu from "components/user/UserMenuContainer";
import Settings from "components/user/settings/SettingsContainer";
import { navigateToPage } from "helpers/history";
import { kirbyDocument, kirbyHome, kirbyInvoice, kirbyGlobe, kirbyNote, kirbyQuestion } from "maples-kirby-icons";
import { KirbyIcon, KirbyNavigation } from "maples-kirby-react";
import React, { useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Navigation.module.scss";
import { UserAuthenticatedDto } from "common-types/user/userAuthenticatedDto";
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";
import { Actions, hasAction } from "../../../helpers/userActions";
import { browserDownloadResult } from "../../../modules/download/fileDownload";
import { AxiosResponse } from "axios";
import { PHOENIX_USER_GUIDE_DOC } from "../../../constants/env";
import { ApiCaller } from "../../../helpers";

export interface NavigationProps {
  searchAction: (event: any) => void;
  setHomePageType: (homePageType: HomePageType) => void;
  refreshHome: Function;
  user: UserAuthenticatedDto | undefined | null;
  isFetchInProgress: boolean;
  isFetchError: boolean;
  isAuthenticated: boolean;
}

interface INavItem {
  path: string;
  label: string;
  icon: INavIcon;
}

interface INavIcon {
  name: string;
  data: string;
}

export default function Navigation(props: NavigationProps) {

  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const [serviceRequestsEnabled] = useFeatureFlag('ServiceRequests');
  const [entityFilingsEnabled] = useFeatureFlag('EntityFilings');
  const [downloadUserGuideEnabled] = useFeatureFlag('DownloadUserGuide');

  const navigationDetails = () => {
    let navList: Array<INavItem> = [];
    if (hasAction(Actions.ViewEntity)) {
      navList.push(
        {
          path: "/",
          label: "Home",
          icon: kirbyHome
        },
        {
          path: "/invoices",
          label: "Invoices",
          icon: kirbyInvoice
        },
        {
          path: "/reports",
          label: "Reports",
          icon: kirbyDocument
        }
      )
    };

    if (entityFilingsEnabled && hasAction(Actions.ReadEconomicSubstanceFiling)) {
      navList.push({
        path: "/entityfilings",
        label: "Entity Filings",
        icon: kirbyNote
      })
    };
    if (serviceRequestsEnabled && hasAction(Actions.ViewEntity)) {
      navList.push({
        path: "/servicerequests",
        label: "Service Request Overview",
        icon: kirbyGlobe
      })
    }
    return navList;
  }

  const doSearch = (searchTerm: string) => {
    if (!searchTerm)
      return;

    props.searchAction(searchTerm);
    props.setHomePageType(HomePageType.SearchResults);
    navigateToPage("/");
  }

  const searchParams = {
    searchFunction: (result: string) => {
      doSearch(result)
    },
    title: "Search for entities by name, number or former name",
    placeholder: "Entity Search",
  }

  const [unpFeatureEnabled] = useFeatureFlag("UserNotificationPreferences");

  useEffect(() => {
    if (unpFeatureEnabled) {
      const timeoutId = setTimeout(() => {
        if (props.user &&
          props.isAuthenticated &&
          !props.user.isInternal &&
          !props.isFetchInProgress &&
          !props.user.notificationPreferencesAccepted &&
          hasAction(Actions.ViewEntity)) {

          setNotificationsOpen(true);
          clearTimeout(timeoutId);
        }
      }, 2000);
    }
  }, [props.user, props.isAuthenticated, props.isFetchInProgress, unpFeatureEnabled]);

  const downloadFile = useCallback(() => {
    const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
      browserDownloadResult(new Blob([axiosResponse.data]), PHOENIX_USER_GUIDE_DOC);
    }
    ApiCaller.download(`/static/footer/${PHOENIX_USER_GUIDE_DOC}`, blobHandler)
  }, []);

  return (
    <>
      <KirbyNavigation
        data-testid="navigation"
        logoFunc={props.refreshHome}
        logo={`<img src="/Logo.svg" alt="Logo" />`}
        darkLogo={`<img src="/Logo_Dark.svg" alt="Logo" />`}
        searchParams={hasAction(Actions.ViewEntity) ? searchParams : undefined} >
        <>
          {navigationDetails().map((navigationItem) => (
            <NavLink key={navigationItem.label} to={navigationItem.path} title={navigationItem.label} state={{ reset: true }}>
              <KirbyIcon className={styles.icon} icon={navigationItem.icon} />
              <span className={'navlabel'}>{navigationItem.label}</span>
            </NavLink>
          ))}
        </>
        <div slot="user-details">
          { downloadUserGuideEnabled && <button className={styles.userGuideButton} onClick={()=> downloadFile()} title="User Guide"><KirbyIcon icon={kirbyQuestion}></KirbyIcon>User Guide</button> }
          <UserMenu actions={setNotificationsOpen} />
        </div>
      </KirbyNavigation>
      <Settings setOpen={setNotificationsOpen} open={notificationsOpen} acceptMode={!props.user?.notificationPreferencesAccepted}></Settings>
    </>
  )
}
