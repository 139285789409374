import { Filter } from "api/interfaces/filter";
import { InvoiceRequest } from "api/interfaces/invoiceRequest";
import { invoiceActions } from "modules/invoice/invoice-actions";
import { getActiveFilterState, getCurrentPage, getFilters, getInvoiceFetchStatus, getInvoices, getPageSize, getSorting } from "modules/invoice/invoice-selectors";
import { FilterAndSortAction } from "modules/invoice/types/invoice-actions-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import Invoices from "./Invoices";
import { SortInfo } from "api/interfaces/sortInfo";

const mapStateToProps = (state: AppState) => ({
    invoices: getInvoices(state),
    fetchStatus: getInvoiceFetchStatus(state),
    filters: getFilters(state),
    activeFilterState: getActiveFilterState(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    sorting: getSorting(state)
});

const mapDispatchToProps = {
    fetchInvoices: () => invoiceActions.fetchInvoices(),
    filterAndSort: (invoiceRequest: InvoiceRequest): FilterAndSortAction => invoiceActions.filterAndSort(invoiceRequest),
    clearFilters: () => invoiceActions.clearFilters(),
    setFilters: (filters: Filter[]) => invoiceActions.setFilters(filters),
    reset: () => invoiceActions.resetFilters(),
    setCurrentPage: (pageNumber: number) =>
        invoiceActions.setCurrentPage(pageNumber),
    setPageSize: (pageSize: number) =>
        invoiceActions.setPageSize(pageSize),
    setSorting: (sorting: SortInfo) => invoiceActions.setSorting(sorting)
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
