export const date = (value: Date | null | undefined, defaultString: string = ""): string => {
  if (!(value instanceof Date) || isNaN(value.getTime())) {
    return defaultString;
  }

  let day   = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(value);
  let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(value);
  let year  = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(value);

  return `${day}-${month}-${year}`;
};