import { FetchStatus } from "common-types/fetchStatusEnum";
import produce from "immer";
import { FetchRecentlyViewedEntitiesSuccessAction } from "../types/entities-actions-types";
import { RecentlyViewedEntitiesState } from "../types/entities-state";
import { RecentlyViewedEntitiesActionTypes } from "./recentlyViewedEntities-actions";

const initialState: RecentlyViewedEntitiesState = {
  recentlyViewedEntities: undefined,
  fetchStatus: FetchStatus.InProgress,
};

export default (
  state = initialState,
  action: FetchRecentlyViewedEntitiesSuccessAction
): RecentlyViewedEntitiesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES_SUCCESS: {
        const { recentlyViewedEntities } = action;
        draft.recentlyViewedEntities = recentlyViewedEntities;
        draft.fetchStatus = FetchStatus.Complete;
        return;
      }
      case RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES_FAILURE: {
        draft.recentlyViewedEntities = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      case RecentlyViewedEntitiesActionTypes.FETCH_RECENTLYVIEWEDENTITIES: {
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      default:
        return state;
    }
  });
