import { FetchStatus } from "common-types/fetchStatusEnum";
import { CoreEntityDetailsState } from "./types/core-entity-details-state";
import { FetchCoreEntityDetailsSuccessAction } from "./types/core-entity-details-actions-types"
import produce from "immer";
import { CoreEntityDetailsActionTypes } from "./core-entity-details-actions";

const initialState: CoreEntityDetailsState = {
    entityId: 0,
    coreEntityDetails: undefined,
    fetchCoreEntityDetailsStatus: FetchStatus.InProgress
}

export default (
    state = initialState,
    action: FetchCoreEntityDetailsSuccessAction = { coreEntityDetails: undefined, type: "" }): CoreEntityDetailsState =>
    produce(state, (draft) => {
        switch (action.type) {
            case CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS: {
                draft.fetchCoreEntityDetailsStatus = FetchStatus.InProgress;
                return;
            }
            case CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS_SUCCESS: {
                const { coreEntityDetails } = action;
                draft.coreEntityDetails = coreEntityDetails;
                draft.fetchCoreEntityDetailsStatus = coreEntityDetails ? FetchStatus.Complete : FetchStatus.NoContent;
                return;
            }
            case CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS_FAILURE: {
                draft.coreEntityDetails = undefined;
                draft.fetchCoreEntityDetailsStatus = FetchStatus.NotFound;
                return;
            }
            default:
                return state;
        }
    });