
const browserDownloadResult = (blob: Blob, name: string) => {
  const bloburl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = bloburl;
  link.setAttribute('download', name)
  link.click();
}

export { browserDownloadResult };
