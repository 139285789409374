import { DirectorOfficerBviDto } from "../../../../common-types/directorOfficerBviDto";
import { PagedListDispatchPropsActions } from "../../../../modules/common/paged-list/paged-list-actions";
import pagedListSelectors from "../../../../modules/common/paged-list/paged-list-selectors";
import { GenericPagedListState } from "../../../../modules/common/paged-list/paged-list-state";
import { getEntity } from "../../../../modules/entity/entity-selectors";
import { AppState } from "../../../../store/AppState";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "../../../common/types";
import PagedListKeys from "constants/pagedListKeys";
import CurrentDirectorsAndOfficersBvi from "../CurrentDirectorsAndOfficersBvi/CurrentDirectorsAndOfficersBvi";
import { connect } from "react-redux";
import { gridColumns } from "../CurrentDirectorsAndOfficersBvi/GridColumns";
import headerText from "../../../../constants/copy/registers/headerText";

const stateSelector = (state: AppState): GenericPagedListState<DirectorOfficerBviDto> => state.currentDirectorsAndOfficersBvi;

const getCurrentDirectorsAndOfficersBvi = pagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = pagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = pagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<DirectorOfficerBviDto> & ServerTableContainerOwnProps => {
  return {
    id: getEntity(state).id,
    list: getCurrentDirectorsAndOfficersBvi(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state),
    columns: gridColumns,
    header: headerText.CurrentDirectorsAndOfficersBvi,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 60,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.CURRENT_DIRECTORSANDOFFICERSBVI);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDirectorsAndOfficersBvi);
