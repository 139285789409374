import { Component } from "react";
import { FetchStatus } from "common-types/fetchStatusEnum";
import headerText from "constants/copy/registers/headerText";
import { EntityTerminationComponentProps } from "modules/entityTermination/types/entityTermination-component-types";
import EntityProperty from "../EntityProperty";
import Collapsible from "components/controls/collapsible/Collapsible";
import { EntityTerminationDto } from "common-types/entityTerminationDto";
import {
  getLocalStorage,
  updateLocalStorage,
} from "../utils/EntityDetailsUtils";
import { getFormattedDate } from "../../../../helpers/formatting/dateConverter";
import {
  AccordionObject,
  AccordionState,
} from "components/common/types/overviewLocalStorage";

export type EntityTerminationProps = EntityTerminationComponentProps;

class EntityTermination extends Component<EntityTerminationProps> {
  entityType: string = this.props.entity?.jurisdictionEntityType
    ?.code as string;
  state: AccordionState = {
    open: false,
  };

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetchEntityTermination(this.props.entity.id);
    }
    getLocalStorage().then((store: any) => {
      const entityTypeDetails = store[this.entityType];
      if (entityTypeDetails?.details) {
        let accordionFound = entityTypeDetails.details.find(
          (o: AccordionObject) => o.EntityTermination !== undefined
        );
        accordionFound &&
          this.setState({
            open: accordionFound.EntityTermination,
          });
      }
    });
  }

  componentWillUnmount(): void {
    this.props.cancel();
  }

  toggleOpen() {
    this.setState((prevState: AccordionState) =>
      ({
        open: !prevState.open,
      }),
      () =>
        updateLocalStorage(
          this.props.entity?.jurisdictionEntityType?.code as string,
          "EntityTermination",
          this.state.open
        )
    );
  }

  renderEntityTerminationProperty(entityTerminationDto: EntityTerminationDto) {
    return (
      <>
        <EntityProperty
          key={0}
          title={"Notice of Resignation Date"}
          value={
            entityTerminationDto.resignationNotificationDate
              ? getFormattedDate(
                  entityTerminationDto.resignationNotificationDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={1}
          title={"Resignation Date"}
          value={
            entityTerminationDto.resignationDate
              ? getFormattedDate(
                  entityTerminationDto.resignationDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={2}
          title={"Entity Resigned Date"}
          value={
            entityTerminationDto.entityResignedDate
              ? getFormattedDate(
                  entityTerminationDto.entityResignedDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={3}
          title={"De-registration Date"}
          value={
            entityTerminationDto.deregistrationDate
              ? getFormattedDate(
                  entityTerminationDto.deregistrationDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={4}
          title={"Registrar of Trusts De-registration Date"}
          value={
            entityTerminationDto.trustDeregistrationDate
              ? getFormattedDate(
                  entityTerminationDto.trustDeregistrationDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={5}
          title={"Liquidation Commenced Date"}
          value={
            entityTerminationDto.dateLiquidationCommenced
              ? getFormattedDate(
                  entityTerminationDto.dateLiquidationCommenced,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={6}
          title={"Liquidation Date"}
          value={
            entityTerminationDto.liquidationDate
              ? getFormattedDate(
                  entityTerminationDto.liquidationDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={7}
          title={"Transferred Out Date"}
          value={
            entityTerminationDto.transferredOutDate
              ? getFormattedDate(
                  entityTerminationDto.transferredOutDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
        <EntityProperty
          key={8}
          title={"Merged Date"}
          value={
            entityTerminationDto.dateMerged
              ? getFormattedDate(entityTerminationDto.dateMerged, "dd-LLL-yyyy")
              : undefined
          }
        />
        <EntityProperty
          key={9}
          title={"Struck Off Date"}
          value={
            entityTerminationDto.struckOffDate
              ? getFormattedDate(
                  entityTerminationDto.struckOffDate,
                  "dd-LLL-yyyy"
                )
              : undefined
          }
        />
      </>
    );
  }

  hasProperties = (entityTerminationDto: EntityTerminationDto): boolean =>
    entityTerminationDto.dateLiquidationCommenced !== null ||
    entityTerminationDto.dateMerged !== null ||
    entityTerminationDto.deregistrationDate !== null ||
    entityTerminationDto.entityResignedDate !== null ||
    entityTerminationDto.liquidationDate !== null ||
    entityTerminationDto.resignationDate !== null ||
    entityTerminationDto.resignationNotificationDate !== null ||
    entityTerminationDto.struckOffDate !== null ||
    entityTerminationDto.transferredOutDate !== null ||
    entityTerminationDto.trustDeregistrationDate !== null;

  render() {
    const { entityTerminationDto, fetchEntityTerminationStatus } = this.props;

    return fetchEntityTerminationStatus === FetchStatus.Complete &&
      entityTerminationDto !== undefined &&
      this.hasProperties(entityTerminationDto) ? (
      <Collapsible
        title={headerText.EntityTermination}
        headerText={headerText.EntityTermination}
        open={this.state.open}
        handleOpenToggle={() => this.toggleOpen()}>
        <div className={"fields"}>
          {this.renderEntityTerminationProperty(entityTerminationDto)}
        </div>
      </Collapsible>
    ) : null;
  }
}

export default EntityTermination;
