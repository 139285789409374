import { connect } from "react-redux";
import { getAuthenticatedUserFetchStatus } from "modules/auth/auth-selectors";
import { AuthActions } from "modules/auth/auth-actions";
import { FetchAuthenticatedUserAction } from "modules/auth/auth-action-types";
import { AppState } from "store/AppState";
import { ContentDenied } from "./ContentDenied";

function mapStateToProps(state: AppState) {
  return {
    authenticatedUserFetchStatus: getAuthenticatedUserFetchStatus(state),
  };
}

const mapDispatchToProps = {
  fetchAuthenticatedUser: (): FetchAuthenticatedUserAction =>
    AuthActions.fetchAuthenticatedUser(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentDenied);
