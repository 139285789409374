import { MEMBERS_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { MemberDto } from "common-types";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchMembers";

export const fetchMembers = async(request: EntityPageRequest): Promise<PagedListDto<MemberDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${MEMBERS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchMembers = (): void => tryCancelPending(signalKey);