import { useState, useEffect, useCallback, useRef } from "react";
import styles from "./ContentItem.module.scss";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import { KirbyIcon, KirbyMenu, KirbyMenuItem } from "maples-kirby-react";
import {
  kirbyEdit,
  kirbyRemove,
  kirbyMore,
  kirbyCopy,
} from "maples-kirby-icons";
import { useGlobalContext } from "./DocManagement";
import RequireAction from "components/common/RequireActionContainer";
import { DocumentDto } from "common-types/documentDto";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { getContentIcon } from "./Common/documentUtilities";

export interface ContentItemProps {
  file: TreeItem;
  toggleSelected: Function;
  deleteDocument: (item: any) => void;
  renameDocument: (item: DocumentDto) => void;
  copyDocument: (item: DocumentDto) => void;
  gridView?: boolean;
}

export function ContentItem(props: ContentItemProps) {
  const { setSelectedFolder, downloadDocuments } = useGlobalContext();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLKirbyMenuElement>(null);

  useEffect(() => {
    if (menuRef.current?.children && menuRef.current.children.length > 0) {
      setShowMenu(true);
    }
  }, [setShowMenu, menuRef]);

  const toggleClass = (item: TreeItem) =>
    item.selected ? styles.selected : "";

  const handleDocumentSelect = useCallback(
    (item: TreeItem) => {
      props.toggleSelected(item);
    },
    [props]
  );

  const handleFolderClick = useCallback(
    (item: DocumentFolderDto) => {
      setSelectedFolder(item);
    },
    [setSelectedFolder]
  );

  const handleDownloadClick = (document: TreeItem) => {
    downloadDocuments([document.value], {
      toastMessage: document.value.displayName,
      toastCompleteMessage: document.value.displayName,
    });
  };

  const moreOnClick = useCallback(() => {
    setMenuOpen(true);
  }, [setMenuOpen]);

  const menuClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const renameHandleClick = useCallback(() => {
    props.renameDocument(props.file.value);
  }, [props]);

  const deleteHandleClick = useCallback(() => {
    props.deleteDocument(props.file);
  }, [props]);

  const copyHandleClick = useCallback(() => {
    props.copyDocument(props.file.value);
  }, [props]);

  return (
    <div
      className={`${styles.folderItem} ${toggleClass(props.file)} ${
        props.gridView ? styles.grid : styles.list
      }`}
      data-itemid={props.file.key}
      data-testid="folder-content-item">
      <div
        className={styles.selector}
        onClick={() => handleDocumentSelect(props.file)}>
        <KirbyIcon
          icon={getContentIcon(props.file.value)}
          data-testid={`item-icon-${props.file.key}`}
          title={props.file.value.displayName}></KirbyIcon>
      </div>
      <div className={styles.buttonwrap}>
        {props.file.type === "folder" ? (
          <span
            className={styles.buttonlink}
            title={props.file.value.displayName}
            onClick={() => handleFolderClick(props.file.value)}
            data-testid="folder-content-item-folderlink">
            {props.file.value.displayName}
          </span>
        ) : (
          <span
            className={styles.buttonlink}
            title={`Download ${props.file.value.displayName}`}
            data-testid="folder-content-item-filelink"
            onClick={() => handleDownloadClick(props.file)}>
            {props.file.value.displayName}
          </span>
        )}
      </div>
      <div className={styles.actions} data-testid="folder-content-item-file">
        {showMenu ? (
          <KirbyIcon
            icon={kirbyMore}
            className={`${styles.actionsBut} actionsBut`}
            onClick={moreOnClick}
          />
        ) : null}
        <KirbyMenu
          position="left-start"
          ref={menuRef}
          className={`${menuOpen ? "open" : ""}`}
          target={`[data-itemid="${props.file.key}"] .actionsBut`}
          open={menuOpen}
          handleClose={menuClose}>
          <RequireAction
            actionName={`Document.${props.file.value.source}.${props.file.value.documentType}.Update`}>
            <KirbyMenuItem
              data-testid={`document-rename-${props.file.key}`}
              handleClick={renameHandleClick}>
              <KirbyIcon icon={kirbyEdit} />
              <span>Rename</span>
            </KirbyMenuItem>
          </RequireAction>
          <RequireAction
            actionName={`Document.${props.file.value.source}.${props.file.value.documentType}.Delete`}>
            <KirbyMenuItem
              data-testid={`document-delete-${props.file.key}`}
              handleClick={deleteHandleClick}>
              <KirbyIcon icon={kirbyRemove} />
              <span>Delete</span>
            </KirbyMenuItem>
          </RequireAction>
          <KirbyMenuItem
            data-testid={`document-copy-${props.file.key}`}
            handleClick={copyHandleClick}>
            <KirbyIcon icon={kirbyCopy} />
            <span>Copy</span>
          </KirbyMenuItem>
        </KirbyMenu>
      </div>
    </div>
  );
}
