import { KirbyButton, KirbyNumericfield, KirbyIcon, KirbySpinner } from "maples-kirby-react";
import { kirbyBooleanTick, kirbyRemove, kirbyCertificate } from "maples-kirby-icons";
import styles from "./OrderPreview.module.scss";
import { ServiceRequestIdOther } from "common-types/serviceRequests/utils/basketUtils";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { useEffect, useState } from "react";
import { useServiceRequestContext } from "../ServiceRequestRoot";

type OrderItemProps = {
  item: BasketItemDto;
}

export default function OrderItem(props: Readonly<OrderItemProps>) {
  const { updateQuantity } = useServiceRequestContext();
  const { item } = props;
  const [quantity, setQuantity] = useState<number | undefined>(item.quantity);
  const [quantityError, setQuantityError] = useState<string | undefined>();

  const quantityInRange = (i: number) => i >= 0 && i <= 10;

  /*
    Triggered when the item is updated from <ServiceRequestItems />
  */
  useEffect(() => {
    setQuantity(item.quantity);
  }, [item])

  return (
    <div className={styles.listItem} key={item.id} data-testid={`${item.serviceRequestItemId}-${item.serviceRequestCertificationId}-${item.otherDescription}`}>
      <span className={styles.itemTitle}>
        <KirbyIcon icon={kirbyCertificate}></KirbyIcon><span>{item.serviceRequestItemId === ServiceRequestIdOther && <span className={styles.other}>Other -</span>} <span dangerouslySetInnerHTML={{ __html: item.name.replace('/', '/<wbr>') }}></span></span>
      </span>
      {item.serviceRequestCertificationType && item.serviceRequestCertificationType != "N/A" &&
        <div data-testid={`certtype-${item.id}`} className={styles.certtype}>
          <span className={styles.typelabel}>Certification Type</span>
          <span className={styles.typeval}>{item.serviceRequestCertificationType}</span>
        </div>
      }
      {item.isExpress &&
        <span data-testid={`is-express-${item.id}`} className={styles.express}><KirbyIcon icon={kirbyBooleanTick}></KirbyIcon> Express</span>
      }
      <div className={styles.quantityLine}>
        <div className={styles.quantityInputWrap}>
          <KirbyNumericfield
            data-testid={`order-item-quantity-${item.id}`}
            label="Quantity"
            value={quantity}
            disabled={!(item.inDb === undefined || item.inDb)}
            min={0}
            max={10}
            posOnly
            wholeIntOnly
            onBlur={() => {
            if (!quantity) {
                updateQuantity(item, 0, true);
                setQuantityError(undefined);
            }
            }}
            handleChange={(e: any) => {
            const newQuantity = e.target.value !== '' ? Number(e.target.value) : undefined;
            setQuantity(newQuantity && newQuantity < 0 ? 0 : newQuantity);
            if (newQuantity && quantityInRange(newQuantity)) {
                setQuantityError(undefined);
                updateQuantity(item, newQuantity, true);
            } else if (!newQuantity) {
                setQuantityError(`Please provide a quantity`);
            } else {
                updateQuantity(item, quantity ?? 0, true);
                setQuantityError(`Quantity must be between 1 and 10.`);
                setTimeout(() => {
                setQuantityError(undefined);
                }, 3000);
            }
            }}
            name={item.id + 'quantity'}
            error={quantityError}
          />
        </div>
        {!(item.inDb === undefined || item.inDb) ?
          <KirbySpinner single /> :
          <KirbyButton
            data-testid={`remove-button-${item.id}`}
            title="Remove"
            icon={kirbyRemove}
            variant="tertiary"
            data-itemid={item.id}
            handleClick={() => updateQuantity(item, 0, true)}
          />}
      </div>
    </div>
  )
}
