import { ReactElement, useCallback, useState } from "react";
import { KirbyButton, KirbyMenu, KirbyMenuItem } from "maples-kirby-react";
import styles from "./DocManagementActionPanel.module.scss";

export interface DocManagementActionPanelProps {
  showButtons: boolean;
  selectAll: (value: boolean) => void;
  deleteClick: () => void;
  downloadClick: () => void;
  copyClick: (item: any) => void;
  cutClick: (item: any) => void;
  clearAllDisabled: boolean;
  selectAllDisabled: boolean;
  deleteDisabled: boolean;
  downloadDisabled: boolean;
  copyDisabled: boolean;
  pasteClick?: (item: any) => void;
  pasteDisabled?: boolean;
  pasteHidden?: boolean;
  custom?: ReactElement<typeof KirbyButton>[];
  menuMode: boolean;
}

export default function DocManagementActionPanel(
  props: DocManagementActionPanelProps
) {
  const {
    showButtons,
    selectAll,
    deleteClick,
    downloadClick,
    copyClick,
    cutClick,
    pasteClick,
    selectAllDisabled,
    deleteDisabled,
    downloadDisabled,
    clearAllDisabled,
    copyDisabled,
    pasteDisabled,
    pasteHidden,
    menuMode,
  } = props;

  const selectAllHandler = useCallback(() => {
    selectAll(true);
  }, [selectAll]);

  const deSelectAllHandler = useCallback(() => {
    selectAll(false);
  }, [selectAll]);

  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false);

  return (
    <>
      {menuMode &&
      !(
        selectAllDisabled &&
        clearAllDisabled &&
        deleteDisabled &&
        copyDisabled &&
        (pasteHidden || pasteDisabled)
      ) ? (
        <>
          <KirbyButton
            className="actionMenuButton"
            onClick={() => setActionMenuOpen(true)}>
            Actions
          </KirbyButton>
          <KirbyMenu
            position="bottom"
            open={actionMenuOpen}
            handleClose={() => setActionMenuOpen(false)}
            target=".actionMenuButton"
            data-testid="folder-content-action-panel">
            {showButtons && (
              <>
                {!selectAllDisabled && (
                  <KirbyMenuItem
                    title="Select All"
                    handleClick={selectAllHandler}>
                    <span>Select All</span>
                  </KirbyMenuItem>
                )}
                {!clearAllDisabled && (
                  <KirbyMenuItem
                    title="Clear All"
                    handleClick={deSelectAllHandler}>
                    <span>Clear All</span>
                  </KirbyMenuItem>
                )}
                {!deleteDisabled && (
                  <KirbyMenuItem title="Delete" handleClick={deleteClick}>
                    <span>Delete</span>
                  </KirbyMenuItem>
                )}
                {!deleteDisabled && (
                  <KirbyMenuItem title="Download" handleClick={downloadClick}>
                    <span>Download</span>
                  </KirbyMenuItem>
                )}
                {!copyDisabled && (
                  <KirbyMenuItem title="Copy" handleClick={copyClick}>
                    <span>Copy</span>
                  </KirbyMenuItem>
                )}
                {!copyDisabled && !deleteDisabled && (
                  <KirbyMenuItem title="Cut" handleClick={cutClick}>
                    <span>Cut</span>
                  </KirbyMenuItem>
                )}
                {!pasteHidden && !pasteDisabled && (
                  <KirbyMenuItem title="Paste" handleClick={pasteClick}>
                    <span>Paste</span>
                  </KirbyMenuItem>
                )}
                {props.custom?.map((a) => a)}
              </>
            )}
            {!showButtons && !pasteHidden && !pasteDisabled && (
              <KirbyMenuItem title="Paste" handleClick={pasteClick}>
                <span>Paste</span>
              </KirbyMenuItem>
            )}
          </KirbyMenu>
        </>
      ) : (
        <>
          {showButtons && (
            <div
              className={`${styles.actionPanel} actionpanel`}
              data-testid="folder-content-action-panel">
              <KirbyButton
                disabled={selectAllDisabled}
                title="Select All"
                handleClick={selectAllHandler}>
                Select All
              </KirbyButton>
              <KirbyButton
                disabled={clearAllDisabled}
                title="Clear All"
                handleClick={deSelectAllHandler}>
                Clear All
              </KirbyButton>
              <KirbyButton
                disabled={deleteDisabled}
                title="Delete"
                handleClick={deleteClick}>
                Delete
              </KirbyButton>
              <KirbyButton
                disabled={downloadDisabled}
                title="Download"
                handleClick={downloadClick}>
                Download
              </KirbyButton>
              <KirbyButton
                disabled={copyDisabled}
                title="Copy"
                handleClick={copyClick}>
                Copy
              </KirbyButton>
              <KirbyButton
                disabled={copyDisabled || deleteDisabled}
                title="Cut"
                handleClick={cutClick}>
                Cut
              </KirbyButton>
              {!pasteHidden && (
                <KirbyButton
                  disabled={pasteDisabled}
                  title="Paste"
                  handleClick={pasteClick}>
                  Paste
                </KirbyButton>
              )}
              {props.custom?.map((a) => a)}
            </div>
          )}
          {!showButtons && !pasteHidden && !pasteDisabled && (
            <div
              className={`${styles.actionPanel} actionpanel`}
              data-testid="folder-content-action-panel">
              <KirbyButton title="Paste" handleClick={pasteClick}>
                Paste
              </KirbyButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
