import { UserAuthenticatedDto } from "common-types/user/userAuthenticatedDto";
import { AUTH_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { sleep } from "./utils/sleep";

export const fetchAuthenticatedUser = async (): Promise<UserAuthenticatedDto> => {
  return ApiCaller.get(`${AUTH_ENDPOINT}/user`);
}

let inProgress: boolean = false;
let lastResult: boolean;
let lastResultTime: Date;

const cacheValid = (): boolean => lastResultTime && lastResultTime.getTime() > new Date().getTime() - (1000 * 10);
const cache = (value: any) => {
  lastResultTime = new Date();
  lastResult = value;
  inProgress = false;
}
const waitAndReturnFromCache = async () => {
  do {
    await sleep();
  } while (inProgress);

  return lastResult;
}

export const fetchIsAuthenticated = async (): Promise<boolean> => {
  if (cacheValid())
    return lastResult;

  if (inProgress)
    return await waitAndReturnFromCache();

  inProgress = true;
  return ApiCaller.get(`${AUTH_ENDPOINT}`)
    .then((result: any) => {
      cache(result);
      return result;
    })
    .catch(e => {
      inProgress = false;
      throw e;
    });
}