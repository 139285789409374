import { takeEvery } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import { all, call, fork, put } from "redux-saga/effects";
import { ShippingDetailsDto } from "common-types/shippingDetailsDto";
import { FetchShippingDetailsAction } from "./types/shipping-details-actions-types";
import {
  ShippingDetailsActions,
  ShippingDetailsActionTypes,
} from "./shipping-details-actions";
import { cancelFetchShippingDetails, fetchShippingDetails } from "./shipping-details.api";

export function* watchFetchShippingDetails(): SagaIterator {
  yield takeEvery(
    ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS,
    function* (action: FetchShippingDetailsAction): any {
      try {
        const { entityId } = action;
        let shippingDetailsDto: ShippingDetailsDto = yield call(
          fetchShippingDetails,
          entityId
        );
        yield put(
          ShippingDetailsActions.fetchShippingDetailsSuccess(
            shippingDetailsDto
          )
        );
      } catch (e) {
        yield put(ShippingDetailsActions.fetchShippingDetailsFailure(e));
      }
    }
  );

  yield takeEvery(
    ShippingDetailsActionTypes.CANCEL_FETCH_SHIPPING_DETAILS,
    function* (): any {
        yield call(cancelFetchShippingDetails);
    }
  );
}

export default function* ShippingDetailsSaga(): SagaIterator {
  yield all([fork(watchFetchShippingDetails)]);
}
