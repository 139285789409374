import { Component } from "react";
import { format } from "helpers";
import { FetchStatus } from "common-types/fetchStatusEnum";
import headerText from "constants/copy/registers/headerText";
import { EntityIdentifiersComponentProps } from "modules/entityIdentifiers/types/entity-identifiers-component-types";
import EntityProperty from "../EntityProperty";
import Collapsible from "components/controls/collapsible/Collapsible";
import EntityBooleanProperty from "../EntityBooleanProperty";
import {
  getLocalStorage,
  updateLocalStorage,
} from "../utils/EntityDetailsUtils";
import {
  AccordionObject,
  AccordionState,
} from "components/common/types/overviewLocalStorage";

export type EntityIdentifiersProps = EntityIdentifiersComponentProps;

class EntityIdentifiers extends Component<EntityIdentifiersProps> {
  entityType: string = this.props.entity?.jurisdictionEntityType?.code as string;
  state: AccordionState = {
    open: false,
  };

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetchEntityIdentifiers(this.props.entity.id);
    }
    getLocalStorage().then((store: any) => {
      const entityTypeDetails = store[this.entityType];
      if (entityTypeDetails?.details) {
        let accordionFound = entityTypeDetails.details.find(
          (o: AccordionObject) => o.EntityIdentifiers !== undefined
        );
        accordionFound &&
          this.setState({
            open: accordionFound.EntityIdentifiers,
          });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<EntityIdentifiersProps>): void {
    if (this.props.entity) {
      if (this.props.entity.id !== prevProps?.entity?.id) {
        this.props.fetchEntityIdentifiers(this.props.entity.id);
      }
    }
  }

  componentWillUnmount(): void {
    this.props.cancel();
  }

  switchContentOnFetchStatus() {
    const { fetchEntityIdentifiersStatus } = this.props;
    return fetchEntityIdentifiersStatus === FetchStatus.Complete
      ? this.entityIdentifiersContent()
      : null;
  }

  toggleOpen() {
    this.setState((prevState: AccordionState) => 
      ({
        open: !prevState.open,
      }),
      () =>
        updateLocalStorage(
          this.props.entity?.jurisdictionEntityType?.code as string,
          "EntityIdentifiers",
          this.state.open
        )
    );
  }

  entityIdentifiersContent() {
    const { entityIdentifiers } = this.props;
    const {
      csxListingDate,
      csxListingNumber,
      giinNumber,
      einNumber,
      msnNumber,
      fiNumber,
      lcclNumber,
      hasSecuritiesOnStockExchange,
      stockExchangeName,
    } = entityIdentifiers || {};

    return (
      <Collapsible
        open={this.state.open}
        handleOpenToggle={() => this.toggleOpen()}
        title={headerText.EntityIdentifiers}
        headerText={headerText.EntityIdentifiers}>
        <div className={"fields"}>
          <EntityProperty title="CSX Listing Number" value={csxListingNumber} />
          <EntityProperty
            title="CSX Listing Date"
            value={format.date(new Date(csxListingDate ?? ""))}
          />
          <EntityProperty title="GIIN Number" value={giinNumber} />
          <EntityProperty title="EIN Number" value={einNumber} />
          <EntityProperty title="MSN Number" value={msnNumber} />
          <EntityProperty title="FI Number" value={fiNumber} />
          <EntityProperty title="LCCL Number" value={lcclNumber} />
          <EntityBooleanProperty
            title="Has Securities on Stock Exchange"
            value={hasSecuritiesOnStockExchange}
          />
          <EntityProperty
            title="Stock Exchange Name"
            value={stockExchangeName}
          />
        </div>
      </Collapsible>
    );
  }

  render() {
    return this.switchContentOnFetchStatus();
  }
}

export default EntityIdentifiers;
