import { OrderDto } from "common-types/serviceRequests/orderDto";
import { KirbyDialog } from "maples-kirby-react";
import { useState } from "react";

interface SubmitConfirmationDialogProps {
    order: OrderDto | undefined;
    confirm: Function;
    close: Function;
    show: boolean;
}

const SubmitConfirmationDialog = (props: SubmitConfirmationDialogProps) => {
    const { order, confirm, close, show } = props;
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
    const [isCancelButtonDisabled, setIsCancelButtonDisabled] = useState(false);
  
    return (
      <div data-testid={`order-confirmation-dialog`} >
        <KirbyDialog
          open={show}
          disablebackdropclose={true}
          nocancel={true}
          actions={[
            {
              action: 'Cancel',
              title: 'Cancel',
              func: () => {
                close();
              },
              disabled: isCancelButtonDisabled
            },
            {
              action: 'Confirm',
              primary: true,
              func: async () => {
                setIsConfirmButtonDisabled(true);
                setIsCancelButtonDisabled(true);
                await confirm(order);
              },
              testIdPrefix: 'confirm-removal',
              title: 'Confirm',
              disabled: isConfirmButtonDisabled
            }
          ]}
        >
          <p>This order will be submitted. Do you want to continue?</p>
        </KirbyDialog>
      </div> 
    );
  };
  
  export default SubmitConfirmationDialog;