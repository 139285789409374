import { useState, CSSProperties } from "react";
import styles from "./OrderReview.module.scss";
import { useServiceRequestContext } from "../ServiceRequestRoot";
import EntityViewListeners from "../../../common/EntityViewUtils";
import OrderReviewContent from "./OrderReviewContent";

export default function OrderReview() {
  const { order } = useServiceRequestContext();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [maxHeaderHeight, setMaxHeaderHeight] = useState<number>(0);

  EntityViewListeners(headerHeight, setHeaderHeight, maxHeaderHeight, setMaxHeaderHeight, order);

  return (
    <div className={styles.container} style={{ "--entity-page-header-height": headerHeight + "px", "--entity-page-max-header-height": maxHeaderHeight + "px" } as CSSProperties}>
      <OrderReviewContent {...useServiceRequestContext()} />
    </div>
  );
}
