import { createSelector } from "reselect";
import { AppState } from "../../store/AppState";
import { FscLicenceState } from "./types/fscLicense-state";

const fscLicenceStateSelector = (state: AppState): FscLicenceState => state.fscLicence;

export const getFscLicence = createSelector(
  fscLicenceStateSelector,
  (fscLicenceState) => {
    if (!fscLicenceState || !fscLicenceState.licence) return undefined;
    return fscLicenceState.licence;
  }
);

export const getFscLicenceFetchStatus = createSelector(
  fscLicenceStateSelector,
  (fscLicenceState) => fscLicenceState?.fetchStatus
);
