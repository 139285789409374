import { HomePageType } from "common-types/homePageTypeEnum";
import { AllEntitiesActions } from "modules/entities/allEntities/allEntities-actions";
import {
  getAllEntities,
  getAllEntitiesFetchStatus,
  getCurrentPage,
  getPageSize,
} from "modules/entities/allEntities/allEntities-selectors";
import { EntitiesActions } from "modules/entities/entities-actions";
import { SetHomePageType } from "modules/entities/types/entities-actions-types";
import {
  FetchEntitiesAction,
  SetCurrentPageAction,
  SetPageSizeAction
} from "modules/entity/types/entity-actions-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import AllEntities from "./AllEntities";

const mapStateToProps = (state: AppState) => {
  return {    
    allEntities: getAllEntities(state),
    fetchStatus: getAllEntitiesFetchStatus(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
  };
};

const mapDispatchToProps = {
  fetchAllEntities: (pageNumber: number, pageSize: number): FetchEntitiesAction =>
    AllEntitiesActions.fetchAllEntities(pageNumber, pageSize),
  setCurrentPage: (pageNumber: number): SetCurrentPageAction =>
    AllEntitiesActions.setCurrentPage(pageNumber),
  setPageSize: (pageSize: number): SetPageSizeAction =>
    AllEntitiesActions.setPageSize(pageSize),  
  setHomePageType: (type: HomePageType): SetHomePageType => 
    EntitiesActions.setHomePageType(type),
};

export default connect(mapStateToProps, mapDispatchToProps)(AllEntities);
