import { fetchAllEntities } from "api/entity.api";
import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { CANCELLED_MESSAGE } from "helpers/userActions/userPageActions";
import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FetchAllEntitiesAction } from "../types/entities-actions-types";
import {
  AllEntitiesActionTypes,
  AllEntitiesActions,
} from "./allEntities-actions";

export function* watchFetchAllEntities(): SagaIterator {
  yield takeEvery(
    AllEntitiesActionTypes.FETCH_ALLENTITIES,
    function* (action: FetchAllEntitiesAction) {
      try {
        const { pageNumber, pageSize } = action;
        let allEntities: PagedListDto<EntityDto> = yield call(
          fetchAllEntities,
          pageNumber,
          pageSize
        );
        yield put(AllEntitiesActions.fetchAllEntitiesSuccess(allEntities));
      } catch (e: any) {
        if (e.message === CANCELLED_MESSAGE) return;
        yield put(AllEntitiesActions.fetchAllEntitiesFailure(e));
      }
    }
  );
}

export default function* EntitySaga(): SagaIterator {
  yield all([fork(watchFetchAllEntities)]);
}
