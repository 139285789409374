import { useCallback } from "react";
import { NotificationOccurrence } from "modules/user/types/component-types";
import { Field } from "formik";
import { KirbyRadioList } from "maples-kirby-react";
import styles from "./NotificationSettingsRadio.module.scss";

export interface NotificationSettingsRadioProps {
  header: string;
  propertyName: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const NotificationSettingsRadio = (props: NotificationSettingsRadioProps) => {
  const { header, setFieldValue, propertyName } = props;

  const radioListOptions = [
    { value: NotificationOccurrence.Never, label: "None" },
    { value: NotificationOccurrence.Daily, label: "Daily" },
    { value: NotificationOccurrence.Weekly, label: "Weekly" },
    { value: NotificationOccurrence.Monthly, label: "Monthly" },
  ];

  const handleChange = useCallback(
    (event: any) => {
      setFieldValue(propertyName, event.target.value);
    },
    [setFieldValue, propertyName]
  );

  return (
    <div className={styles.radio}>
      <h5>{header}</h5>
      <Field
        component={KirbyRadioList}
        options={radioListOptions}
        handleChange={handleChange}
        name={propertyName}
      />
    </div>
  );
};

export default NotificationSettingsRadio;
