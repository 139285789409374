import { Component } from "react";
import { EntityPropertyProps } from "modules/entity/types/component-types";

class EntityProperty extends Component<EntityPropertyProps> {
  render() {
    const { title, value, displayIfEmpty } = this.props || {};

    return (
      (value || (displayIfEmpty ?? false)) && (
        <div>
          <label>{title}</label>
          <span>{value}</span>
        </div>
      )
    );
  }
}

export default EntityProperty;
