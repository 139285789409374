import { connect } from "react-redux";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import { getEntity } from "modules/entity/entity-selectors";
import { AppState } from "store/AppState";
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import PagedListKeys from "constants/pagedListKeys";
import { MemberDto } from "common-types";
import LLCMembers from "./LLCMembers";
import headerText from "constants/copy/registers/headerText";
import { gridColumns } from "./GridColumns";
import { subTableFormatter } from "maples-kirby"

const stateSelector = (state: AppState): GenericPagedListState<MemberDto> => state.members;

const getMembers = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);
const membersRowFormatter = (row: any) => { 
  const subTableCols = [
    {
      title: "Class / Group",
      field: "name"
    },
    {
      title: "Voting Rights",
      field: "votingRights"
    }
  ];

  const rowData = row.getData();

  if (rowData && Array.isArray(rowData.classGroups) && rowData.classGroups.length > 0)
    subTableFormatter(row, "classGroups", subTableCols, 0, "Class / Group", false);
};

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<MemberDto> & ServerTableContainerOwnProps => {
  return {
    list: getMembers(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state),
    header: headerText.CurrentLlcMembers,
    columns: gridColumns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    responsiveWidth: 31.25,
    rowFormatter: membersRowFormatter
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.MEMBERS);

export default connect(mapStateToProps, mapDispatchToProps)(LLCMembers);
