import { Component } from "react";
import { DirectorOfficerBviDto } from "../../../../common-types/directorOfficerBviDto";
import ServerTableContainer from "../../../common/ServerTableContainer";
import { SimplePagedSectionProps } from "../../../common/types";

class CurrentDirectorsAndOfficersBvi extends Component<
  SimplePagedSectionProps<DirectorOfficerBviDto>
> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default CurrentDirectorsAndOfficersBvi;
