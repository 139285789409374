import { DateCell, SharesCell } from "helpers/gridCells";

export const shareholdersGridColumns: Array<any> = [
  {
    title: "Name of Member",
    field: "nameOfMember"
  },
  {
    title: "Class of Shares",
    field: "classOfShares"
  },
  {
    title: "Date of Entry as Member",
    field: "dateOfEntryAsMember",
    formatter: DateCell
  },
  {
    title: "Number of Shares",
    field: "numberOfShares",
    formatter: SharesCell
  },
  {
    title: "Par Value",
    field: "parValue"
  }
];