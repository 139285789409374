import { JurisdictionEntityTypeDto } from "common-types";
import { getGoodstandingSetting } from "constants/copy/entity/goodStandingText";
import { IconCell } from "helpers/gridCells";
import { kirbyClose } from "maples-kirby-icons";
import { KirbyButton } from "maples-kirby-react";
import { EntityDetailComponentProps } from "modules/entity/types/component-types";
import { format } from "../../../../helpers";
import CimaRegistrations from "../CimaRegistrations/CimaRegistrationsContainer";
import CoreEntityDetails from "../CoreEntityDetails/CoreEntityDetailsContainer";
import EntityIdentifiers from "../EntityIdentifiers/EntityIdentifiersContainer";
import EntityProperty from "../EntityProperty";
import EntityTermination from "../EntityTermination/EntityTerminationContainer";
import FscLicence from "../FscLicence/FscLicenceContainer";
import OutstandingInvoices from "../OutstandingInvoices/OutstandingInvoicesContainer";
import ShippingDetails from "../ShippingDetails/ShippingDetailsContainer";
import styles from "./EntityDetail.module.scss";

export type EntityDetailProps = EntityDetailComponentProps;

const EntityDetail = (props: EntityDetailProps) => {

  const getGoodstandingDisplay = (goodstanding: boolean | null) => {
    let setting = getGoodstandingSetting(goodstanding);
    return (
      <div>
        <label className={styles.inlineLabel}>
          <span className={styles.inlineLabelText}>Registry Good Standing</span>{" "}
          {IconCell(setting.title, setting.icon, undefined, setting.color)}
        </label>
      </div>
    );
  };

  const canDisplayAuthorisedCapital = (
    bcActTransProvApplicable: boolean | undefined,
    authorisedShareCapitalInUSD: number | undefined
  ) => {
    return (
      (bcActTransProvApplicable === null || bcActTransProvApplicable) &&
      authorisedShareCapitalInUSD !== undefined &&
      authorisedShareCapitalInUSD !== null
    );
  };

  const canDisplayAuthorisedNumberOfShares = (
    bcActTransProvApplicable: boolean | undefined,
    numberOfAuthorisedShares: number | undefined
  ) => {
    return (
      bcActTransProvApplicable !== null &&
      !bcActTransProvApplicable &&
      numberOfAuthorisedShares !== undefined &&
      numberOfAuthorisedShares !== null
    );
  };

  const isBviEntity = (jurisdictionEntityType: JurisdictionEntityTypeDto) => {
    return (
      jurisdictionEntityType.jurisdiction.name.toLowerCase() ===
      "british virgin islands"
    );
  };

  const getAuthorisedCapitalDisplayValue = (
    jurisdictionEntityType: JurisdictionEntityTypeDto,
    authorisedShareCapital: number,
    hasNoParValueShareClass: boolean | undefined,
    hasUnlimitedWithParValueShareClass: boolean | undefined,
    hasUnlimitedWithNoParValueShareClass: boolean | undefined
  ) => {
    if (isBviEntity(jurisdictionEntityType)) {
      if (
        hasUnlimitedWithNoParValueShareClass ||
        (hasUnlimitedWithParValueShareClass && hasNoParValueShareClass)
      ) {
        return "See Authorised Share Capital Ribbon";
      } else if (hasUnlimitedWithParValueShareClass) {
        return "Unlimited";
      } else if (hasNoParValueShareClass) {
        return "See Authorised Share Capital Ribbon";
      }
    }

    return getFormattedAuthorisedShareCapital(authorisedShareCapital);
  };

  const getAuthorisedNumberOfSharesDisplayValue = (
    jurisdictionEntityType: JurisdictionEntityTypeDto,
    numberOfAuthorisedShares: number,
    hasNoParValueShareClass: boolean | undefined,
    hasUnlimitedWithParValueShareClass: boolean | undefined,
    hasUnlimitedWithNoParValueShareClass: boolean | undefined
  ) => {
    if (isBviEntity(jurisdictionEntityType)) {
      if (
        hasUnlimitedWithNoParValueShareClass ||
        (hasUnlimitedWithParValueShareClass && hasNoParValueShareClass)
      ) {
        return "See Authorised Share Capital Ribbon";
      } else if (hasUnlimitedWithParValueShareClass) {
        return "Unlimited";
      } else if (hasNoParValueShareClass) {
        return numberOfAuthorisedShares.toLocaleString();
      }
    }

    return numberOfAuthorisedShares.toLocaleString();
  };

  const getFormattedAuthorisedShareCapital = (
    authorisedShareCapital: number
  ) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    return authorisedShareCapital === 0
      ? "No Par Value"
      : `USD ${authorisedShareCapital.toLocaleString("en", options)}`;
  };

  const { entity, entityDetail } = props;
  const { jurisdictionEntityType, goodStanding } =
    entity || {};
  const {
    companyMinutesAddress,
    companyRecordsAddress,
    registeredOfficeProviderName,
    registeredOfficeProviderAddress,
    bcActTransProvApplicable,
    authorisedShareCapitalInUSD,
    numberOfAuthorisedShares,
    reportingEntityType,
    registrationDate,
    registrationNumber,
    incorporationDate,
    incorporationNumber,
    natureOfBusiness,
    fiscalYearEnd,
    transferredInDate,
    continuationInDate,
    hasNoParValueShareClass,
    hasUnlimitedWithParValueShareClass,
    hasUnlimitedWithNoParValueShareClass,
  } = entityDetail || {};

  return (
    <div className="detailWrap">
      <div className={styles.titleSection}>
        <h3>Core Entity Details</h3>
        <KirbyButton
          className={styles.closeButton}
          handleClick={() => props.closeDetails()}
          icon={kirbyClose}></KirbyButton>
      </div>
      <div className={`${styles.column} column`}>
        <OutstandingInvoices />
        <div className="fields" data-testid="fields">
          {<EntityProperty title="Type" value={jurisdictionEntityType?.name} />}
          {
            <EntityProperty
              title="Jurisdiction"
              value={jurisdictionEntityType?.jurisdiction?.name}
            />
          }
          {(goodStanding === true || goodStanding === false) &&
            getGoodstandingDisplay(goodStanding)}
          {
            <EntityProperty
              title="Registered Office"
              value={registeredOfficeProviderName}
            />
          }
          {registeredOfficeProviderAddress && (
            <EntityProperty
              title="Registered Office Address"
              value={registeredOfficeProviderAddress}
            />
          )}
          {
            <EntityProperty
              title="Reporting Entity Type"
              value={reportingEntityType}
            />
          }
          {
            <EntityProperty
              title="Registration Date"
              value={format.date(new Date(registrationDate ?? ""))}
            />
          }
          {
            <EntityProperty
              title="Registration Number"
              value={registrationNumber}
            />
          }
          {
            <EntityProperty
              title="Incorporation Date"
              value={format.date(new Date(incorporationDate ?? ""))}
            />
          }
          {
            <EntityProperty
              title="Incorporation Number"
              value={incorporationNumber}
            />
          }
          {
            <EntityProperty
              title="Nature of Business"
              value={natureOfBusiness}
            />
          }
          {<EntityProperty title="Fiscal Year End" value={fiscalYearEnd} />}
          {
            <EntityProperty
              title="Transferred In Date"
              value={format.date(new Date(transferredInDate ?? ""))}
            />
          }
          {
            <EntityProperty
              title="Continuation In Date"
              value={format.date(new Date(continuationInDate ?? ""))}
            />
          }
          {canDisplayAuthorisedCapital(
            bcActTransProvApplicable,
            authorisedShareCapitalInUSD
          ) && (
            <EntityProperty
              title="Authorised Capital"
              value={getAuthorisedCapitalDisplayValue(
                jurisdictionEntityType,
                authorisedShareCapitalInUSD!,
                hasNoParValueShareClass,
                hasUnlimitedWithParValueShareClass,
                hasUnlimitedWithNoParValueShareClass
              )}
            />
          )}
          {canDisplayAuthorisedNumberOfShares(
            bcActTransProvApplicable,
            numberOfAuthorisedShares
          ) && (
            <EntityProperty
              title="Number of Authorised Shares"
              value={getAuthorisedNumberOfSharesDisplayValue(
                jurisdictionEntityType,
                numberOfAuthorisedShares!,
                hasNoParValueShareClass,
                hasUnlimitedWithParValueShareClass,
                hasUnlimitedWithNoParValueShareClass
              )}
            />
          )}
          {companyRecordsAddress && (
            <EntityProperty
              title="Company Records/Underlying Documents Maintained Address"
              value={companyRecordsAddress}
            />
          )}
          {companyMinutesAddress && (
            <EntityProperty
              title="Company Minutes/Resolutions Maintained Address"
              value={companyMinutesAddress}
            />
          )}
        </div>
        <CoreEntityDetails />
        <EntityIdentifiers />
        <CimaRegistrations />
        <FscLicence />
        <EntityTermination />
        <ShippingDetails />
      </div>
    </div>
  );
};

export default EntityDetail;
