import { SagaIterator } from "redux-saga";
import { orderActionTypes, orderActions } from "./order-actions";
import { CancelOrderAction, CompleteOrderAction, FetchOrderAction, FetchOrderHistoryAction, FetchOrderSummariesAction } from "./types/order-actions-types";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { cancelOrder, completeOrder, fetchOrder, fetchOrderHistory, fetchOrderSummaries } from "api/service-requests-api";
import { getOrders, getOrderSummary } from "./order-selectors";
import { OrderDto } from "common-types/serviceRequests/orderDto";
import { OrderSummaryDto } from "common-types/serviceRequests/orderSummaryDto";

export function* watchFetchOrder(): SagaIterator {
    yield takeEvery(orderActionTypes.FETCH_ORDER, function*(action: FetchOrderAction): any {
        try {
            const { entityId, orderId } = action;

            const existingOrders: OrderDto[] = yield select(getOrders);
            const existingOrder = existingOrders.find((anOrder: OrderDto) => anOrder.id === orderId);
            if (existingOrder)
                return yield put(orderActions.fetchOrderSuccess(existingOrder));

            const orderSummaries: OrderSummaryDto[] = yield select(getOrderSummary);
            const orderSummary = orderSummaries.find((order: OrderSummaryDto) => order.id === orderId);
            
            if (orderSummary)
                return yield put(orderActions.fetchOrderSuccess(orderSummary));

            const order = yield call(fetchOrder, entityId, orderId);

            yield put(orderActions.fetchOrderSuccess(order));
        } catch (e) {
            yield put(orderActions.fetchOrderFailure(e));
        }
    });
}

export function* watchCancelOrder(): SagaIterator {
    yield takeEvery(orderActionTypes.CANCEL_ORDER, function*(action: CancelOrderAction): any {
        try {
            const { entityId, orderId } = action;
            
            const order = yield call(cancelOrder, entityId, orderId);

            yield put(orderActions.cancelOrderSuccess(order));
        } catch (e) {
            yield put(orderActions.cancelOrderFailure(e));
        }
    });
}

export function* watchCompleteOrder(): SagaIterator {
    yield takeEvery(orderActionTypes.COMPLETE_ORDER, function*(action: CompleteOrderAction): any {
        try {
            const { entityId, orderId } = action;
            
            const order = yield call(completeOrder, entityId, orderId);

            yield put(orderActions.completeOrderSuccess(order));
        } catch (e) {
            yield put(orderActions.completeOrderFailure(e));
        }
    });
}

export function* watchFetchOrderHistory(): SagaIterator {
    yield takeEvery(orderActionTypes.FETCH_ORDER_HISTORY, function*(action: FetchOrderHistoryAction): any {
        try {
            const { entityId } = action;
            const orders = yield call(fetchOrderHistory, entityId);

            yield put(orderActions.fetchOrderHistorySuccess(orders));
        } catch (e) {
            yield put(orderActions.fetchOrderHistoryFailure(e));
        }
    });
}

export function* watchFetchOrderSummary(): SagaIterator {
    yield takeEvery(orderActionTypes.FETCH_ORDER_SUMMARIES, function*(action: FetchOrderSummariesAction): any {
        try {
            const orders = yield call(fetchOrderSummaries);

            yield put(orderActions.fetchOrderSummariesSuccess(orders));
        } catch (e) {
            yield put(orderActions.fetchOrderSummariesFailure(e));
        }
    });
}

export default function* orderSaga(): SagaIterator {
    yield all([
      fork(watchFetchOrder),
      fork(watchCancelOrder),
      fork(watchCompleteOrder),
      fork(watchFetchOrderHistory),
      fork(watchFetchOrderSummary)
    ]);
  }