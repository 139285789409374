import { DocumentCopyResultDto, DocumentDto } from "common-types/documentDto";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { DocumentSearchResultDto } from "common-types/documentSearchResultDto";
import { ENTITIES_ENDPOINT, DOC_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { ApiResponse } from "helpers/apiCaller/ApiResponse";
import { DocumentDownloadRequestItem } from "./interfaces/documentDownloadRequestItem";
import { getAbortSignalTryCancelPending } from "./utils/requestAbortion";

export const fetchFolders = async (entityId: number): Promise<DocumentFolderDto[]> => {
  return ApiCaller.get(`${baseUrl(entityId)}/folders`, { signal: getAbortSignalTryCancelPending("fetchFolders") });
}

export const fetchDocs = async (entityId: number, parentId: string | undefined): Promise<DocumentDto[]> => {
  if (!parentId) 
    return [];

  return ApiCaller.get(`${baseUrl(entityId)}?parentId=${parentId ?? ''}`, { signal: getAbortSignalTryCancelPending("fetchDocs") });
}

export const searchDocs = async (entityId: number, searchTerm: string): Promise<DocumentSearchResultDto[]> =>
  ApiCaller.get(`${baseUrl(entityId)}/search?searchTerm=${searchTerm}`, { signal: getAbortSignalTryCancelPending("searchDocs") });

export const createFolder = async (entityId: number, request: { parentId: string, name: string }): Promise<ApiResponse<DocumentDto>> =>
  ApiCaller.apiResponsePost(`${baseUrl(entityId)}/folder`, request, true);

export const deleteDocument = async (entityId : number, documentIdentifier : string): Promise<ApiResponse<any>> => {
  return ApiCaller.remove(`${baseUrl(entityId)}/${documentIdentifier}`);
};

export const download = async (entityId: number, documentId: string, blobHandler: Function): Promise<any> => {
  return ApiCaller.download(`${baseUrl(entityId)}/download?documentId=${documentId}`, blobHandler);
}
export const zip = async (entityId: number, data: { documentIds: DocumentDownloadRequestItem[], fileName: string }, blobHandler: Function): Promise<any> => {
  return ApiCaller.downloadPost(`${baseUrl(entityId)}/zip`, blobHandler, data);
}

export const renameDocument = async (entityId: number, request: {documentId: string, newName: string}): Promise<ApiResponse<DocumentDto>> => {
  return ApiCaller.patchFile(`${baseUrl(entityId)}`, request);
}

export const upload = async (entityId: number, request: { parentFolderId: string, file: File }, ): Promise<ApiResponse<DocumentDto>> => {
  return ApiCaller.postFile<DocumentDto>(`${baseUrl(entityId)}/upload?parentId=${request.parentFolderId}`, request.file);
}

export const copy = async (entityId: number, request: { documentId: string, targetDocumentId: string }): Promise<ApiResponse<DocumentDto>> => {
    return ApiCaller.apiResponsePost<DocumentDto>(`${baseUrl(entityId)}/copydocument`, request, true);
}

export const copyMany = async (entityId: number, request: { document: { id: string, name: string, isFolder: boolean }, targetDocumentId: string }): Promise<ApiResponse<DocumentCopyResultDto[]>> => {
  return ApiCaller.apiResponsePost<DocumentCopyResultDto[]>(`${baseUrl(entityId)}/copydocuments`, request, true);
}

const baseUrl = (entityId: number) => `${ENTITIES_ENDPOINT}/${entityId}${DOC_ENDPOINT}`;
