import { CimaRegistrationDto } from "../../common-types";


export const CimaRegistrationsActionTypes = {
  FETCH_CIMA_REGISTRATIONS: "FETCH_CIMA_REGISTRATIONS",
  FETCH_CIMA_REGISTRATIONS_SUCCESS: "FETCH_CIMA_REGISTRATIONS_SUCCESS",
  FETCH_CIMA_REGISTRATIONS_FAILURE: "FETCH_CIMA_REGISTRATIONS_FAILURE",
  CANCEL_CIMA_REGISTRATIONS: "CANCEL_CIMA_REGISTRATIONS"
}

export const CimaRegistrationsActions = {
  fetchCimaRegistrations: (entityId: number) => ({
    type: CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS,
    entityId,
  }),

  fetchCimaRegistrationsSuccess: (cimaRegistrations: CimaRegistrationDto) => ({
    type: CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS_SUCCESS,
    cimaRegistrations,
  }),

  fetchCimaRegistrationsFailure: (error: any) => ({
    type: CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS_FAILURE,
    error
  }),

  cancel: () => ({
    type: CimaRegistrationsActionTypes.CANCEL_CIMA_REGISTRATIONS
  })
};
