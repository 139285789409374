import { Component } from "react";
import { AuthorisedShareCapitalBviDto } from "common-types/authorisedShareCapitalBviDto";
import { SimplePagedSectionProps } from "components/common/types";
import ServerTableContainer from "components/common/ServerTableContainer";

export type AuthorisedShareCapitalBviProps =
  SimplePagedSectionProps<AuthorisedShareCapitalBviDto>;

class AuthorisedShareCapitalBvi extends Component<AuthorisedShareCapitalBviProps> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default AuthorisedShareCapitalBvi;
