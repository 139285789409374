import { Component } from "react";
import { CurrentManagerDto } from "common-types";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";

class CurrentManagers extends Component<
  SimplePagedSectionProps<CurrentManagerDto>
> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default CurrentManagers;
