import { KirbyButton, KirbyIcon } from "maples-kirby-react";
import { kirbyBasket } from "maples-kirby-icons";
import styles from "./OrderPreview.module.scss";
import { ServiceRequestsCopy } from "constants/copy/service-requests/service-request-copy";
import OrderItem from "./OrderItem";
import { useServiceRequestContext } from "../ServiceRequestRoot";
import { useNavigate, useParams } from "react-router-dom";
import { verifyIfOrderStatusChanged } from "../Util/ServiceRequestUtil";
import ServiceRequestErrorDialog from "../Dialogs/ServiceRequestErrorDialog";
import { useState } from "react";

export default function OrderPreview(props: any) {
  const { order } = useServiceRequestContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showServiceRequestError, setShowServiceRequestError] = useState<boolean>(false);

  const reviewOrderClick = () => {
    verifyIfOrderStatusChanged(order, id ? parseInt(id) : 0).then((result) => {
      setShowServiceRequestError(result)
      if (!result && order.orderItems.length > 0)
        navigate(`/entities/${id}/servicerequests/orderreview`)
    })
  }

  const confirmReturnToOrderHistory = () => {
    setShowServiceRequestError(false);
    setTimeout(() => navigate('/entities/' + id + "/servicerequests/orderhistory"), 500);
  };

  return (
    <>
      <div className={`${styles.container} previewContainer`}>
        <div className={styles.titleSection}>
          <KirbyIcon icon={kirbyBasket}></KirbyIcon>
          <h3>{ServiceRequestsCopy.OrderPreview.Title}</h3>
        </div>
        <KirbyButton
          disabled={order.orderItems.length === 0}
          variant="primary"
          onClick={() => {
            reviewOrderClick()
          }}>
          Review Order
        </KirbyButton>
        <p className={styles.note}>Notes can be added in the review order screen</p>
        <div className={styles.orderList}>
          {order.orderItems.map(item => <OrderItem item={item} key={`${item.id}_${item.name}`} />)}
        </div>
      </div>
      <ServiceRequestErrorDialog
        confirm={() => confirmReturnToOrderHistory()}
        close={() => setShowServiceRequestError(false)}
        show={showServiceRequestError}
      />
    </>
  );
}

