import { comparisonOperatorEnum } from "api/interfaces/comparisonOperatorEnum";
import { Filter } from "api/interfaces/filter";
import { FullDataRequest, PagedDataRequest } from "api/interfaces/pagedDataRequest";
import { SortInfo } from "api/interfaces/sortInfo";
import { SortOrderEnum } from "api/interfaces/sortOrderEnum";
import { ESFilingStatuses } from "common-types/economicSubstanceFilingDto";
import { HandleDataChangeData } from "common-types/kirby/serverTable/handleDataChangeData";
import { DateFilter, Sort } from "common-types/kirby/serverTable/switchCallbackData";
import { EntityFilingGridFilters } from "components/EntityFilings/types/EntityFilingGridFilters";

export const toPagedDataRequest = (data: HandleDataChangeData<EntityFilingGridFilters>): PagedDataRequest => {
    return {
        filters: buildFilters(data.filters),
        paging: {
            pageNumber: data.newPageIndex,
            pageSize: data.newPageSize
        },
        sorting: buildSorting(data.newSort)
    }
}

export const toFullDataRequest = (data: HandleDataChangeData<EntityFilingGridFilters>): FullDataRequest => {
    return {
        filters: buildFilters(data.filters),
        sorting: buildSorting(data.newSort)
    }
}

const buildFilters = (filters: EntityFilingGridFilters): Filter[] => {
    let newFilters: Filter[] = [];

    if (filters.clientNumber && filters.clientNumber.trim() !== '')
        newFilters.push({ fieldName: 'clientNumber', value1: filters.clientNumber, comparison: comparisonOperatorEnum.EQUAL });

    if (filters.entityName && filters.entityName.trim() !== '')
        newFilters.push({ fieldName: 'entityName', value1: filters.entityName, comparison: comparisonOperatorEnum.CONTAINS });

    if (filters.dueDate) {
        const dateFilter = buildDateApiFilter(filters.dueDate, 'dueDate');
        if (dateFilter)
            newFilters.push(dateFilter);
    }

    if (filters.filingType && filters.filingType.trim() !== '')
        newFilters.push({ fieldName: 'filingType', value1: filters.filingType, comparison: comparisonOperatorEnum.EQUAL });

    if (filters.status && filters.status.length > 0)
        newFilters.push({ fieldName: 'status', value1: filters.status.join(','), comparison: comparisonOperatorEnum.IN });
    else 
        newFilters.push(buildStatusesFilter(filters));
    
    return newFilters;
}

const buildSorting = (sorts: Sort[]): SortInfo => {
    
    if (sorts.length === 0)
        return {
            fieldName: "duedate",
            sortOrder: SortOrderEnum.ASC
        }
    
    return {
        fieldName: sorts[0].field,
        sortOrder: sorts[0].dir === 'desc' ? SortOrderEnum.DESC : SortOrderEnum.ASC
    }
}

const buildDateApiFilter = (filter: DateFilter, fieldName: string): Filter | undefined => {
    const dateStringValueSuffix: string = 'T00:00:00';

    if (filter.type === 'dateMatch')
        return { fieldName: fieldName, value1: filter.value + dateStringValueSuffix, comparison: comparisonOperatorEnum.EQUAL };

    if (filter.type === 'dateRangeMatch')
        return { 
            fieldName: fieldName, 
            value1: (filter.value as any).start + dateStringValueSuffix, 
            value2: (filter.value as any).end + dateStringValueSuffix, 
            comparison: comparisonOperatorEnum.RANGE 
        };

    if (filter.type === 'dateAfter')
        return { fieldName: fieldName, value1: filter.value + dateStringValueSuffix, comparison: comparisonOperatorEnum.GREATER_THAN_OR_EQUAL };

    return undefined;
}

const buildStatusesFilter = (filters: EntityFilingGridFilters): Filter => {
    let filingStatuses: string;
    if (filters.statuses && filters.statuses.length > 0)
        filingStatuses = filters.statuses.join(',');
    else
        filingStatuses = ESFilingStatuses.join(',');

    return { fieldName: 'status', value1: filingStatuses, comparison: comparisonOperatorEnum.IN };
}