import { createRoot } from "react-dom/client";
import { unregister } from './registerServiceWorker';
import "maples-kirby/dist/kirby/kirby.css";
import "maples-kirby/dist/kirby/brandglobals/kirby/index.css";
import App from "./App";

const root = createRoot(document.getElementById("root")!);
root.render(<App />);

// This has been added to cleanup any service worker registrations in dev.
// It can be removed in a few weeks, it just needs chance for the team to access the app with this change present.
unregister();
