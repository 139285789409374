import { AxiosResponse } from "axios";
import { REPORTS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { browserDownloadResult } from "modules/download/fileDownload";

export const downloadReport = async (reportName: string): Promise<any> => {
  const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
    browserDownloadResult(new Blob([axiosResponse.data]), `${reportName}.xlsx`);
  }

  return ApiCaller.download(`${REPORTS_ENDPOINT}/${reportName}`, blobHandler);
};
