import { useState } from "react";
import { KirbyButton, KirbySection, KirbySectionToolbar, KirbyTableClient } from "maples-kirby-react";
import styles from "./OrderReview.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import OrderNotes from "../Notes/OrderNotes";
import { buildEmptyBasket } from "common-types/serviceRequests/orderDto";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";
import { useCustomEventListener } from 'react-custom-events';
import { ServiceRequestContextType } from "../Context/ServiceRequestContext";
import { verifyIfOrderStatusChanged } from "../Util/ServiceRequestUtil";
import ServiceRequestErrorDialog from "../Dialogs/ServiceRequestErrorDialog";
import * as ServiceRequestApiModule from "api/service-requests-api";
import { orderReviewColumns } from "./OrderReviewColumns";
import { sortByDisplayName } from "common-types/serviceRequests/utils/basketItemDtoUtils";
import DeleteOrderDialog from "../Dialogs/DeleteOrderDialog";
import { addToast } from "components/common/Toaster";
import { deleteOrder } from "api/service-requests-api";
import { ServiceRequestsCopy } from "constants/copy/service-requests/service-request-copy";

export default function OrderReviewContent(props: Readonly<ServiceRequestContextType>) {
  const { order, updateQuantity, setOrder } = props;
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const navigate = useNavigate();
  const [isConfirmationDialogVisable, setIsConfirmationDialogVisable] = useState<boolean>(false);
  const [isDeleteDialogVisable, setIsDeleteDialogVisable] = useState<boolean>(false);
  const [showServiceRequestError, setShowServiceRequestError] = useState<boolean>(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(false);
  const url = window.location.pathname;
  const serviceRequestUrl = `/entities/${entityId}/servicerequests/`;

  const removeItem = (remItemEvent: any) => {
    updateQuantity(remItemEvent, 0, false)
  }

  useCustomEventListener('removeRow', removeItem);

  const tryRedirect = () => {
    if (url !== serviceRequestUrl)
      setTimeout(() => navigate(serviceRequestUrl), 500);
  }

  const deleteOrderClick = () => {
    if (deleteOrderButtonDisabled()) {
      return;
    }
    setIsButtonsDisabled(true);
    setIsDeleteDialogVisable(true);
  }

  const confirmClick = (a: any) => {
    if (confirmButtonDisabled()) { return; }
      setIsButtonsDisabled(true);
      setIsConfirmationDialogVisable(true);
  }

  const cancelClick = () => {
    setIsConfirmationDialogVisable(false);
    setIsButtonsDisabled(false);
  }

  const cancelDeleteOrder = () => {
    setIsButtonsDisabled(false);
    setIsDeleteDialogVisable(false);
  }

  const submitOrderAndNavigateToHistoryPage = async () => {
    const result = await verifyIfOrderStatusChanged(order, entityId);
    setShowServiceRequestError(result);
    if (!result) {
      const submittedOrder = await ServiceRequestApiModule.submitOrder(entityId, order.id);
      if (submittedOrder?.orderItems?.length > 0) {
        setTimeout(() => {
          setOrder({
            type: 'update_order',
            order: buildEmptyBasket()
          });
          navigate(`/entities/${entityId}/servicerequests/orderhistory`);
        }, 1000);
      }
    }
    setIsConfirmationDialogVisable(false);
  }

  const confirmReturnToOrderHistory = () => {
    setShowServiceRequestError(false);
    setTimeout(() => navigate('/entities/' + entityId + "/servicerequests/orderhistory"), 500);
  };

  const editOrderClick = async () => {
    setIsButtonsDisabled(true);
    const result = await verifyIfOrderStatusChanged(order, entityId);
    setShowServiceRequestError(result)
    if (!result) {
      navigate(`/entities/${entityId}/servicerequests`);
    }
  }

  const confirmDeleteOrder = async (orderId: number, entityId: number) => {
    const result = await verifyIfOrderStatusChanged(order, entityId);
    setShowServiceRequestError(result);

    if (result) {
      setIsDeleteDialogVisable(false);
      return;
    }
    
    try {
      await deleteOrder(entityId, orderId);
      setIsDeleteDialogVisable(false)
      addToast("Order Deleted", "Your order has been successfully deleted", "success");
      tryRedirect();

    } catch {
      addToast("Error", ServiceRequestsCopy.OrderItems.PromiseErrorTitle, 'error');
    }
  }

  const confirmButtonDisabled = (): boolean => order.orderItems.length === 0 || isConfirmationDialogVisable || isButtonsDisabled;
  const deleteOrderButtonDisabled = (): boolean => order.orderItems.length === 0 || isButtonsDisabled;

  return (
    <>
      <KirbySection sectionTitle="Review Order">
        <KirbySectionToolbar>
          <KirbyButton variant="primary" disabled={confirmButtonDisabled()} onClick={confirmClick} >Confirm</KirbyButton>
          <KirbyButton disabled={isButtonsDisabled} onClick={() => editOrderClick()}>Edit Order</KirbyButton>
          <KirbyButton disabled={deleteOrderButtonDisabled()} onClick={() => deleteOrderClick()}>Delete Order</KirbyButton>
        </KirbySectionToolbar>
        <div className={styles.sectionContent}>
          <div className={styles.notesContent}>
            <OrderNotes order={order} disabled={isButtonsDisabled}/>
          </div>
          <div data-testid={'order-items-container'} className={`${isButtonsDisabled && styles.disabled}`}>
            <KirbyTableClient
              data={sortByDisplayName(order.orderItems)}
              columns={orderReviewColumns}
              hidePagination
              widthBreakpoints={{
                responsiveWidth: 40,
                stickyColumnsActive: 20
              }}
            />
          </div>
        </div>
      </KirbySection>
      <SubmitConfirmationDialog
        order={order}
        close={cancelClick}
        confirm={submitOrderAndNavigateToHistoryPage}
        show={isConfirmationDialogVisable}
      />
      <DeleteOrderDialog
        basketItem={order.orderItems.find(a => a.quantity === 0)}
        show={isDeleteDialogVisable}
        confirm={() => confirmDeleteOrder(order.id, entityId)}
        close={() => cancelDeleteOrder()}
        message="Your Order will be deleted. Do you wish to continue?"
      />
      <ServiceRequestErrorDialog
        confirm={() => confirmReturnToOrderHistory()}
        close={() => setShowServiceRequestError(false)}
        show={showServiceRequestError}
      />
    </>
  );

}
