import { UserSettingsDto } from "common-types/user/userSettingsDto";

export enum NotificationOccurrence {
  Never = "Never",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export enum NotificationType {
  ExecutedDocuments = "ExecutedDocuments",
  Invoices = "Invoices",
  MinuteBook = "MinuteBook",
  Opinions = "Opinions",
}

export type FormikNotificationSettings = {
  ExecutedDocuments: NotificationOccurrence;
  Invoices: NotificationOccurrence;
  MinuteBook: NotificationOccurrence;
  Opinions: NotificationOccurrence;
  UserSettings: UserSettingsDto | undefined;
};
