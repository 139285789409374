import { FetchStatus } from 'common-types/fetchStatusEnum';
import { navigateToPage } from 'helpers/history';
import { useEffect } from 'react'
import { UserAuthenticatedDto } from 'common-types/user/userAuthenticatedDto';
import AccessDenied from "./accessDenied";

export interface Page403Props {
  currentUser: UserAuthenticatedDto | null | undefined;
  fetchAuthenticatedUser: Function;
  authenticatedUserFetchStatus: FetchStatus;
}

export function Page403(props: Page403Props) {
  const { currentUser, fetchAuthenticatedUser, authenticatedUserFetchStatus } = props;

  useEffect(() => {
    if (authenticatedUserFetchStatus === FetchStatus.Default) {
      fetchAuthenticatedUser();
      return;
    }

    if (authenticatedUserFetchStatus === FetchStatus.Complete && currentUser) {
      navigateToPage("/");
    }
  }, [currentUser, fetchAuthenticatedUser, authenticatedUserFetchStatus]);

  return (
    <AccessDenied></AccessDenied>
  );
}
