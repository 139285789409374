export enum ServiceRequestCertificationTypeEnum {
    CertificateFromRegistry = 1,
    CertificationByLawyer = 2,
    CertificationByNotaryPublic = 3,
    CertificationByRegisteredOffice = 4,
    CertificationByRegistrarOfCompanies = 5,
    IssuedByCIMA = 6,
    Other = 7
}




