import { kirbyBooleanCross, kirbyBooleanEmpty, kirbyBooleanTick } from "maples-kirby-icons"


const goodStandingTitles = {
  CORISGoodStandingPass: "Registry Good Standing: Pass",
  CORISGoodStandingFail: "Registry Good Standing: Fail",
  CORISGoodStandingUnknown: "Registry Good Standing: Unknown"
} as const;

type IconType = typeof kirbyBooleanTick | typeof kirbyBooleanEmpty | typeof kirbyBooleanCross;

export type GoodStandingTitlesValues = typeof goodStandingTitles[keyof typeof goodStandingTitles];

export type GoodStandingSettingValueType = {
  title: GoodStandingTitlesValues,
  color: string,
  icon: IconType
}

enum GoodStandingSettingKeysEnum {
  Pass,
  Fail,
  Unknown
}

const goodStandingSettings: { [key in GoodStandingSettingKeysEnum]: GoodStandingSettingValueType; } = {
  [GoodStandingSettingKeysEnum.Pass]: {
    title: goodStandingTitles.CORISGoodStandingPass,
    color: '#73C41B',
    icon: kirbyBooleanTick
  },
  [GoodStandingSettingKeysEnum.Fail]: {
    title: goodStandingTitles.CORISGoodStandingFail,
    color: 'var(--maples-error)',
    icon: kirbyBooleanCross
  },
  [GoodStandingSettingKeysEnum.Unknown]: {
    title: goodStandingTitles.CORISGoodStandingUnknown,
    color: '#999999',
    icon: kirbyBooleanEmpty
  }
}

export const getGoodstandingSetting = (goodstanding: boolean | null): GoodStandingSettingValueType => {
  if (goodstanding == null)
    return goodStandingSettings[GoodStandingSettingKeysEnum.Unknown];

  return goodstanding ? goodStandingSettings[GoodStandingSettingKeysEnum.Pass] : goodStandingSettings[GoodStandingSettingKeysEnum.Fail];
}
