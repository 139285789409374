import { comparisonOperatorEnum } from "api/interfaces/comparisonOperatorEnum";
import { SortOrderEnum } from "api/interfaces/sortOrderEnum";
import { FetchStatus } from "common-types/fetchStatusEnum";
import produce from "immer";
import { DateTime } from 'luxon';
import { getFormattedCurrentDateTime } from "../../helpers/formatting/dateConverter"
import { invoiceActionTypes } from "./invoice-actions";
import { AddFilterAction, FetchInvoicesSuccessAction, FilterAndSortAction, SetCurrentPageAction, SetFiltersAction, SetPageSizeAction, SetSortingAction } from "./types/invoice-actions-types";
import { InvoiceState } from "./types/invoice-state";
const hash = require('object-hash');

export const defaultFilters = [
  {
    fieldName: 'InvoiceDate',
    value1: DateTime.now().minus({ year: 1 }).toFormat('yyyy-MM-dd'),
    value2: getFormattedCurrentDateTime('yyyy-MM-dd'),
    comparison: comparisonOperatorEnum.RANGE,
    filterType: "dateRangeMatch"
  }
]

const initialState: InvoiceState = {
  invoices: undefined,
  invoiceRequest: {
    filters: defaultFilters,
    paging: {
      pageNumber: 1,
      pageSize: 20
    },
    sorting: {
      fieldName: 'InvoiceDate',
      sortOrder: SortOrderEnum.DESC,
    }
  },
  activeFilterState: "",
  fetchStatus: undefined,
  currentPage: 1,
  pageSize: 20,
  filters: defaultFilters,
  sorting: {
    fieldName: 'InvoiceDate',
    sortOrder: SortOrderEnum.DESC,
  }
}

type Action = (
  FetchInvoicesSuccessAction &
  FilterAndSortAction &
  AddFilterAction &
  SetFiltersAction &
  SetCurrentPageAction &
  SetPageSizeAction &
  SetSortingAction
) | undefined;

export default (
  state = initialState,

  action: Action = undefined
): InvoiceState =>
  produce(state, (draft) => {
    switch (action?.type) {
      case invoiceActionTypes.FETCH_INVOICES_CANCELLED:
      case invoiceActionTypes.FETCH_INVOICES: {
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case invoiceActionTypes.FETCH_INVOICES_SUCCESS: {
        const { invoices } = action;

        draft.invoices = invoices;
        draft.fetchStatus = invoices.page && invoices.page.length > 0 ? FetchStatus.Complete : FetchStatus.NoContent;
        draft.activeFilterState = hash(state.invoiceRequest);
        return;
      }
      case invoiceActionTypes.FETCH_INVOICES_FAILURE: {
        draft.invoices = undefined;
        draft.fetchStatus = FetchStatus.Error;
        return;
      }
      case invoiceActionTypes.FILTER_AND_SORT: {
        const { invoiceRequest } = action;
        draft.fetchStatus = FetchStatus.InProgress;
        draft.invoiceRequest = invoiceRequest;
        return;
      }
      case invoiceActionTypes.CLEAR_FILTERS: {
        if (draft?.invoiceRequest)
          draft.invoiceRequest.filters = [];
        return;
      }
      case invoiceActionTypes.SET_FILTERS: {
        const { filters } = action;

        if (draft.invoiceRequest)
          draft.invoiceRequest.filters = filters;
        return;
      }
      case invoiceActionTypes.RESET_FILTERS: {
        if (draft.invoiceRequest) {
          draft.invoiceRequest.filters = defaultFilters;
        }
        return;
      }
      case invoiceActionTypes.SET_PAGE_NUMBER: {
        const { pageNumber } = action;
        draft.currentPage = pageNumber;
        return;
      }
      case invoiceActionTypes.SET_PAGE_SIZE: {
        const { pageSize } = action;
        draft.pageSize = pageSize;
        return;
      }
      case invoiceActionTypes.SET_SORTING: {
        const { sorting } = action;
        draft.sorting = sorting;
        return;
      }
      default:
        return state;
    }
  });
