import { useEffect, useState, useCallback } from "react";
import { KirbyButton, KirbySection, KirbySectionToolbar, KirbyTableClient, KirbySpinner } from "maples-kirby-react";
import styles from "./OrderHistory.module.scss";
import { useNavigate, useParams } from "react-router";
import { distinct, selectMany } from "helpers/array";
import { toOrderForTable } from "./types/OrderForTable";
import utils from "./utils";
import { OrderHistoryComponentProps } from "modules/order/types/order-component-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { GridFilterOption } from "common-types/grids/gridFilterOption";
import { getOrderHistoryColumns } from "./OrderHistoryColumns";
import { maplesStandardXLSX } from "maples-kirby";
import { uploadFileToBlob } from "api/audit-api";
import { addToast, updateToast } from "components/common/Toaster";
import { ActionTypeEnum } from "../../../../common-types/actionTypeEnum";

export default function OrderHistoryContent(props: Readonly<OrderHistoryComponentProps>) {
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const navigate = useNavigate();
  const [docTypes, setDocTypes] = useState<GridFilterOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<GridFilterOption[]>([]);
  const [userOptions, setUserOptions] = useState<GridFilterOption[]>([]);

  const { orders, fetchStatus, fetchOrderHistory } = props;

  const excelExportFileName = 'Phoenix Order History.xlsx';

  useEffect(() => {
    fetchOrderHistory(entityId);
  }, [fetchOrderHistory, entityId]);

  useEffect(() => {
    const distinctDocTypesOrdered = distinct(
      selectMany(orders, a => a.orderItems).map(a => utils.docTypeDisplayName(a))
        .filter(a => a !== null && a !== undefined)
        .sort((a, b) => (a ?? '').localeCompare(b ?? ''))
    ).map((a: any) => utils.toOption(a, a)) ?? [];
    setDocTypes(distinctDocTypesOrdered);

    const distinctStatusIds = distinct(orders.map(a => a.serviceRequestOrderStatusId)).sort((ida: number, idb: number) => ida - idb);
    setStatusOptions(distinctStatusIds.map(statusId => utils.toOption(statusId.toString(), utils.getStatusLabel(statusId))));

    const users = distinct(orders.map(a => a.submittedByName as string))
      .filter(a => a !== null && a !== undefined)
      .sort((a, b) => (a ?? '').localeCompare(b ?? ''));
    setUserOptions(users.map(user => utils.toOption(user)));
  }, [orders]);

  const historyCols = useCallback(() => {
    return getOrderHistoryColumns(entityId, docTypes, statusOptions, userOptions);
  }, [docTypes, statusOptions, userOptions, fetchStatus]);

  return (
      <KirbySection sectionTitle="Order History" className={styles.orderHistorySection}>
        <KirbySectionToolbar>
          <KirbyButton onClick={() => navigate(`/entities/${entityId}/servicerequests`)} data-testid={'order-history-back-button'} >Back</KirbyButton>
        </KirbySectionToolbar>
        <div className={styles.sectionContent}>
          {fetchStatus === FetchStatus.InProgress ?
            <div className={styles.loadingSpinner}>
              <KirbySpinner />
              Fetching History
            </div>
            :
            <KirbyTableClient
              data={orders.map(toOrderForTable)}
              columns={historyCols()}
              showManageColumns
              widthBreakpoints={{
                responsiveWidth: 40,
                stickyColumnsActive: 20
              }}
              emptyMessage={'No orders have been submitted.'}
              allowXlsxExport={{
                fileName: excelExportFileName,
                placement: 'toolbar',
                icon: true,
                interceptDownload: async (fileContents: any, mimeType: any) => {
                  const toastID = await addToast("Exporting Order History", "", "spinner");
                  const excelBlob = new Blob([fileContents], { type: mimeType })
                  const excelFile = new File([excelBlob], excelExportFileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  await uploadFileToBlob(ActionTypeEnum.ExportServiceRequestOrderHistory, "Exporting Service Request Order History Excel", excelFile);
                  updateToast(toastID, "Order History Exported", '', 'success');
                  return true;
                },
                options: {
                  documentProcessing: maplesStandardXLSX
                }
              }}
            />}
        </div>
      </KirbySection>
    );
}
