import { connect } from "react-redux";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import { getEntity } from "modules/entity/entity-selectors";
import { AppState } from "store/AppState";
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import PagedListSelectors from "modules/common/paged-list/paged-list-selectors";
import PagedListKeys from "constants/pagedListKeys";
import { CurrentManagerDto } from "common-types";
import CurrentManagers from "./CurrentManagers";
import { gridColumns } from "./GridColumns";
import headerText from "constants/copy/registers/headerText";

const stateSelector = (
  state: AppState
): GenericPagedListState<CurrentManagerDto> => state.currentManagers;

const getCurrentManagers = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo =
  PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (
  state: AppState,
  ownProps: ServerTableContainerOwnProps
): ServerTableContainerProps<CurrentManagerDto> & ServerTableContainerOwnProps => {
  return {
    id: getEntity(state).id,
    list: getCurrentManagers(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state),
    columns: gridColumns,
    header: headerText.CurrentManagers,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 31.25,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
};

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.CURRENT_MANAGERS);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentManagers);
