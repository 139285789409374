import { comparisonOperatorEnum } from "api/interfaces/comparisonOperatorEnum";
import { Filter } from "api/interfaces/filter";
import { getFormattedCurrentDateTime } from "helpers/formatting/dateConverter";

const statusToCode = (status: string) : '' | 'O' | 'S' => {
    if (!status)
        return '';
    if (status.toLowerCase() === 'outstanding')
      return 'O';
    if (status.toLowerCase() === 'settled')
      return 'S';

    return status as ('' | 'O' | 'S');;
}

export const buildApiFilters = (kirbyFilters: any) : Filter[] => {
    const { clientNumber, invoiceDate, entityName, status } = kirbyFilters;
    let filterResponses: Filter[] = [];

    if (clientNumber) {
        filterResponses.push({
          fieldName: "ClientNumber",
          value1: clientNumber.toString(),
          comparison: comparisonOperatorEnum.EQUAL,
        });
    }

    if (entityName) {
        filterResponses.push({
            fieldName: "EntityName",
            value1: entityName,
            comparison: comparisonOperatorEnum.CONTAINS,
        });
    }

    if (status !== undefined && status !== "All") {
        filterResponses.push({
            fieldName: "statuscode",
            value1: statusToCode(status),
            comparison: comparisonOperatorEnum.EQUAL,
        });
    }

    if (invoiceDate) {
        if (invoiceDate.type === "dateRangeMatch") {
            filterResponses.push({
                fieldName: "InvoiceDate",
                value1: invoiceDate.value.start,
                value2: invoiceDate.value.end,
                comparison: comparisonOperatorEnum.RANGE,
                filterType: invoiceDate.type
            });
        } else if (invoiceDate.type === "dateAfter") {
            filterResponses.push({
                fieldName: "InvoiceDate",
                value1: invoiceDate.value,
                value2: getFormattedCurrentDateTime('yyyy-MM-dd'),
                comparison: comparisonOperatorEnum.RANGE,
                filterType: invoiceDate.type
            });
        } else {
            filterResponses.push({
                fieldName: "InvoiceDate",
                value1: invoiceDate.value,
                value2: invoiceDate.value,
                comparison: comparisonOperatorEnum.RANGE,
                filterType: invoiceDate.type
            });
        }
    }

    return filterResponses;
}

export default { buildApiFilters };