import { DateCell, EntityHyperLinkCell, LinkClick } from "helpers/gridCells";
import { renderToStaticMarkup } from "react-dom/server";
import { kirbyMsExcel, kirbyPdf } from "maples-kirby-icons";
import { ESFilingStatuses } from "common-types/economicSubstanceFilingDto";
import { SwitchCallbackData } from "common-types/kirby/serverTable/switchCallbackData";
import { EntityFilingGridFilters } from "./types/EntityFilingGridFilters";
import { auditPageVisit } from "api/audit-api";
import { hasAction, Actions } from "../../helpers/userActions";
import { GridFilterOption } from "../../common-types/grids/gridFilterOption";
import { date } from "helpers/formatting/date";
import { ActionTypeEnum } from "../../common-types/actionTypeEnum";

const getEntityNavConfig = (cell: any): { allowed: boolean, offlineTooltip: boolean } => {
  const result = (allowed: boolean, offlineTooltip: boolean) => {
    return { allowed, offlineTooltip };
  }
  const canViewEntityData = hasAction(Actions.ViewEntity);
  if (!canViewEntityData)
    return result(false, false);

  if (!hasAction(Actions.EntityFilingsNavigateToOfflineEntity) && !cell.getRow().getData().showInPortal && !cell.getRow().getData().closed)
    return result(false, canViewEntityData);

  return result(true, false);
}

export const getColumns = (setClicked: Function, filingTypeOptions: GridFilterOption[]) => {
  return [
    {
      title: "Entity Name",
      field: "entityName",
      headerFilter: "text",
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        const entityNavConfig = getEntityNavConfig(cell);
        if (!entityNavConfig.allowed) {
          if (entityNavConfig.offlineTooltip)
            return `<div part="statusCell" title='This entity is offline' >${cell.getRow().getData().entityName}</div>`;

          return `<div part="statusCell" >${cell.getRow().getData().entityName}</div>`;
        }
        
        onRendered(() => {
          cell.getElement().querySelector("a").onclick = LinkClick;
        });
        return renderToStaticMarkup(
          EntityHyperLinkCell(
            cell.getRow().getData().entityId,
            cell.getRow().getData().entityName, "View Entity"
          )
        );
      },
    },
    {
      title: "Client Number",
      field: "clientNumber",
      headerFilter: "text",
    },
    {
      title: "Filing Type",
      field: "filingType",
      headerFilter: 'select',
      headerFilterParams: {
        options: filingTypeOptions
      }
    },
    {
      title: "Filing Deadline",
      field: "dueDate",
      formatter: DateCell,
      sorter: "datetime",
      sorterParams: {
        format: "iso"
      },
      formatterParams: {
        dataFormat: "iso",
        displayFormat: 'dd/MM/yyyy',
        invalidPlaceholder: "(invalid date)",
      },
      advFilters: true,
      headerFilter: 'date',
      headerFilterParams: {
        featuresEnabled: {
          dateAfter: false,
          dateRangeMatch: true
        }
      },
      width: 12.5,
    },
    {
      title: "Status",
      field: "status",
      headerFilter: 'select',
      initialFilterValue: ['Pending', 'Overdue', 'Under Review'],
      headerFilterParams: {      
        multi:true,
        options: ESFilingStatuses.map((status: string) => { return { value: status, label: status }; }),
        countString: 'Active'
      },
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {

        async function statusClick(e: any){
          e.preventDefault();
          await auditPageVisit(ActionTypeEnum.NavigatetoSphere,
              `${cell.getRow().getData().filingType} | ${date(new Date(cell.getRow().getData().dueDate))} | ${cell.getRow().getData().status}`,
              cell.getRow().getData().clientNumber,
              cell.getRow().getData().entityId);
          window.open(cell.getRow().getData().externalLink, "_blank");
          setClicked();
        }

        onRendered(() => {
          if (cell.getElement().querySelector("a")) {
            cell.getElement().querySelector("a").onclick = statusClick;
            cell.getElement().querySelector("a").onauxclick = statusClick;
            }
        });

        return renderStatusLink(cell);
      },
    },
  ];
}

const renderStatusLink = (cell: any) => {
  if (cell.getRow().getData().status === "Under Review")
    return `<div title="The submission is currently under review. Once successful, the status will be updated to Complete.">
      ${cell.getRow().getData().status}
    </div>`

  return `<a part="statusCell" href="" title='Go to Filing' target="_blank" rel="noopener noreferrer" >
              ${cell.getRow().getData().status === "Complete" ? `<kirby-icon></kirby-icon>` : ''}
              ${cell.getRow().getData().status}
            </a>`

}

export const getActions = (exportDisabled: boolean,
  exportButtonClick: (state: any) => void,
  includeCompletedCallback: (data: SwitchCallbackData<EntityFilingGridFilters>) => void,
  enableCompletedToggle: boolean = false) => {

  let actions: any[] = [
    {
      label: "Export to Excel",
      disabled: exportDisabled,
      func: exportButtonClick,
      placement: "toolbar",
      field: "description",
      icon: {
        icon: kirbyMsExcel,
      }
    }];

  if (enableCompletedToggle) {
    actions.push({
      label: "Include completed",
      func: includeCompletedCallback,
      placement: "toolbar",
      field: "description",
      type: 'switch'
    });
  }

  return actions;
}
