import { connect } from "react-redux";
import { getEntity } from "modules/entity/entity-selectors";
import { CimaRegistrationsActions } from "modules/cimaRegistrations/cima-registrations-actions";
import { getCimaRegistrations, getCimaRegistrationsFetchStatus } from "modules/cimaRegistrations/cima-registrations-selectors";
import { FetchCimaRegistrationsAction } from "modules/cimaRegistrations/types/cima-registrations-actions-types";
import { AppState } from "store/AppState";
import CimaRegistrations, { CimaRegistrationsProps } from "./CimaRegistrations";
import { Action } from "typesafe-actions";

const mapStateToCimaProps = (state: AppState): Partial<CimaRegistrationsProps> => ({
    cimaRegistrations: getCimaRegistrations(state),
    fetchCimaRegistrationsStatus: getCimaRegistrationsFetchStatus(state),
    entity: getEntity(state),
});

const mapDispatchToCimaProps = {
  fetchCimaRegistrations: (entityId: number): FetchCimaRegistrationsAction => CimaRegistrationsActions.fetchCimaRegistrations(entityId),
  cancel: (): Action => CimaRegistrationsActions.cancel()
};

export default connect(mapStateToCimaProps, mapDispatchToCimaProps)(CimaRegistrations)
