import { PagedListDto } from "common-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { EmptyPagedList } from "common-types/paging/pagedListDto";
import { InvoiceDto } from "common-types/invoiceDto";
import { createSelector, Selector } from "reselect";
import { AppState } from "store/AppState";
import { InvoiceState } from "./types/invoice-state";
import { Filter } from "api/interfaces/filter";
import { SortOrderEnum } from "api/interfaces/sortOrderEnum";

const invoicesStateSelector = (state: AppState): InvoiceState => state.invoice;

export const getInvoices: Selector<AppState, PagedListDto<InvoiceDto>> = createSelector(
    invoicesStateSelector,
    (invoiceState: InvoiceState): PagedListDto<InvoiceDto> => {
        if (!invoiceState || !invoiceState.invoices || (invoiceState?.invoices?.page?.length ?? 0) <= 0) {
            return EmptyPagedList<InvoiceDto>();
        }

        return invoiceState.invoices;
    }
);

export const getInvoiceFetchStatus = createSelector(
    invoicesStateSelector,
    (invoiceState: InvoiceState): FetchStatus => invoiceState.fetchStatus || FetchStatus.NotFound
);

export const getFilters = createSelector(
    invoicesStateSelector,
    (invoiceState: InvoiceState): Filter[] => invoiceState.invoiceRequest?.filters ?? []
);

export const getActiveFilterState = createSelector(
    invoicesStateSelector,
    (invoiceState: InvoiceState): string => invoiceState.activeFilterState
);

export const getCurrentPage = createSelector(
    invoicesStateSelector,
    (invoiceState) => {
      if (!invoiceState?.currentPage) return 1;
  
      return invoiceState.currentPage;
    }
  );
  
export const getPageSize = createSelector(
    invoicesStateSelector,
    (invoiceState) => {
        if (!invoiceState?.pageSize) return 20;

        return invoiceState.pageSize;
    }
);

export const getSorting = createSelector(
    invoicesStateSelector,
    (invoiceState) => {
        if (!invoiceState?.sorting){ 
            return {
                fieldName: "InvoiceDate",
                sortOrder: SortOrderEnum.DESC,
            };
        }

        return invoiceState.sorting;
    }
);