export type FeatureFlagsContextType = {
    isInitialized: boolean;
    featureFlags: string[];
}
  
const DefaultFeatureFlagContext: FeatureFlagsContextType = {
    isInitialized: false,
    featureFlags: []
};

export { DefaultFeatureFlagContext };
  