import { FetchStatus } from 'common-types/fetchStatusEnum';
import { UserAuthenticatedDto } from 'common-types/user/userAuthenticatedDto';
import { navigateToPage } from 'helpers/history';
import { useEffect } from 'react';

export interface CurrentUserProps  {
  isAuthenticatedFetchStatus: FetchStatus;
  isAuthenticated: boolean;
  currentUser: UserAuthenticatedDto | null | undefined;
  fetchAuthenticatedUser: Function;
  authenticatedUserFetchStatus: FetchStatus;
}

export function CurrentUser(props: CurrentUserProps) {
  const { isAuthenticatedFetchStatus, isAuthenticated, currentUser, fetchAuthenticatedUser, authenticatedUserFetchStatus } = props;

  const isUserSet = authenticatedUserFetchStatus !== FetchStatus.Default;
  const isUserIssue = authenticatedUserFetchStatus === FetchStatus.Error || (authenticatedUserFetchStatus === FetchStatus.Complete && !currentUser);

  useEffect(() => {
    if (!isAuthenticated) {
      // The Authentication component will redirect the user to login any moment, we don't want this component to act in this case
      return;
    }

    if (!isUserSet) {
      fetchAuthenticatedUser();
      return;
    }

    if (isAuthenticatedFetchStatus === FetchStatus.Complete && isUserIssue) {
      navigateToPage("403");
    }
  }, [isAuthenticatedFetchStatus, isAuthenticated, currentUser, fetchAuthenticatedUser, authenticatedUserFetchStatus]);

  return null;
}
