import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "modules/entity/entity-selectors";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import PagedListKeys from "constants/pagedListKeys";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import headerText from "constants/copy/registers/headerText";
import { SecurityInterestsOverLlcInterestsDto } from "../../../../common-types/securityInterestsOverLlcInterestsDto";
import { securityInterestsOverLlcInterestsGridColumns } from "./securityInterestsOverLlcInterestsGridColumns";
import SecurityInterestsOverLlcInterests from "./SecurityInterestsOverLlcInterests";
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";

const stateSelector = (state: AppState): GenericPagedListState<SecurityInterestsOverLlcInterestsDto> => state.securityInterestsOverLlcInterests;

const getSecurityInterests = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<SecurityInterestsOverLlcInterestsDto> & ServerTableContainerOwnProps => {
  return {
    list: getSecurityInterests(state)?.page ?? [],
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state),
    header: headerText.SecurityInterestsOverLlcInterests,
    columns: securityInterestsOverLlcInterestsGridColumns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    responsiveWidth: 31.25,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.SECURITYINTERESTSOVERLLCINTERESTS);

export default connect(mapStateToProps, mapDispatchToProps)(SecurityInterestsOverLlcInterests);
