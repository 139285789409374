import { getAbortSignalTryCancelPending, tryCancelPending } from "api/utils/requestAbortion";
import { EntityIdentifierDto } from "../../common-types/entityIdentifierDto"
import { ENTITIES_ENDPOINT, ENTITYIDENTIFIERS_ENDPOINT } from "../../constants/urls"
import { ApiCaller } from "../../helpers"

const signalKey = "fetchEntityIdentifiers";

export const fetchEntityIdentifiers = async (entityId: number): Promise<EntityIdentifierDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${ENTITYIDENTIFIERS_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchEntityIdentifiers = (): void => tryCancelPending(signalKey);
