import { takeEvery } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import { FscLicenceDto } from "common-types/FscLicenseDto";
import { call, put, all, fork } from "redux-saga/effects";
import { FscLicenceActions, FscLicenceActionTypes } from "./fscLicence-actions";
import { cancelFetchFscLicense, fetchFscLicence } from "./fscLicence.api";
import { FetchFscLicenceAction } from "./types/fscLicense-actions-types";

export function* watchFetchFscLicence(): SagaIterator {
  yield takeEvery(FscLicenceActionTypes.FETCH_FSC_LICENCE, function* (action: FetchFscLicenceAction): any {
    try {
      const { entityId } = action;
      let licence: FscLicenceDto = yield call(fetchFscLicence, entityId);
      yield put(FscLicenceActions.fetchFscLicenceSuccess(licence));
    } catch (e) {
      yield put(FscLicenceActions.fetchFscLicenceFailure(e));
    }
  });

  yield takeEvery(FscLicenceActionTypes.CANCEL_FETCH, function* (): any {
      yield call(cancelFetchFscLicense);
  });
}

export default function* EntityFscLicenceSaga(): SagaIterator {
  yield all([
    fork(watchFetchFscLicence)
  ]);
}
