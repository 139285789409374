import { ENTITIES_ENDPOINT, OFFICERS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { OfficerDto } from "common-types/officerDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchOfficers";

export const fetchOfficers = async(request: EntityPageRequest): Promise<PagedListDto<OfficerDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${OFFICERS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchOfficers = (): void => tryCancelPending(signalKey);
