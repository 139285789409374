import * as React from "react";
import styles from "./Layout.module.scss";
import {
  KirbyConfigManager,
  KirbyFooter,
  KirbyToast,
  KirbyHeader
} from "maples-kirby-react";
import Navigation from "./controls/navigation/NavigationContainer";
import { useNavigate, Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import RequireTerms from "./common/RequireTermsContainer";
import CurrentUser from "./common/CurrentUserContainer";
import { useCallback } from "react";
import RequireAuth from "./common/RequireAuthContainer";

export interface LayoutProps {
  children?: React.ReactNode
}

export default(props: LayoutProps) => {

  const navigate = useNavigate();

  const refreshHome = useCallback(() => {
    navigate('/', { state: { reset: true } });
  }, [navigate]);

  const { children } = props;

  return content(refreshHome, children);
};

function content(refreshHome: () => void, children?: React.ReactNode) {
  return (
    <>
      <div className={styles.layout} data-testid="layout">
        <CurrentUser />
        <KirbyConfigManager></KirbyConfigManager>
        <KirbyToast></KirbyToast>

        <RequireAuth>
          <RequireTerms>
            <>
              <Navigation refreshHome={refreshHome} data-testid="layout-nav" />
              <main>
                <KirbyHeader
                  mobileonly
                  logo={`<img src="/Logo.svg" alt="Logo" />`}
                  logoFunc={refreshHome}
                  darkLogo={`<img src="/Logo_Dark.svg" alt="Logo" />`}
                  data-testid="layout-header">
                </KirbyHeader>
                {children}
                <Outlet />
              </main>
            </>
          </RequireTerms>
        </RequireAuth>
      </div>
      <RequireAuth>
        <KirbyFooter data-testid="layout-footer">
          <Footer />   
        </KirbyFooter>
      </RequireAuth>
    </>
  );
}
