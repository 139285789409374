import { connect } from 'react-redux'
import { getAuthenticatedUser, getAuthenticatedUserFetchStatus } from "modules/auth/auth-selectors";
import { AuthActions } from "modules/auth/auth-actions";
import { FetchAuthenticatedUserAction } from "modules/auth/auth-action-types";
import { AppState } from "store/AppState";
import { Page403 } from './Page403';

function mapStateToProps(state: AppState) {
  return {
    currentUser: getAuthenticatedUser(state),
    authenticatedUserFetchStatus: getAuthenticatedUserFetchStatus(state)
  }
}

const mapDispatchToProps = {
  fetchAuthenticatedUser: (): FetchAuthenticatedUserAction => AuthActions.fetchAuthenticatedUser()
};

export default connect(mapStateToProps, mapDispatchToProps)(Page403);