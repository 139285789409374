import { Component } from "react";
import { ShareholderDto } from "common-types/shareholderDto";
import { SimplePagedSectionProps } from "components/common/types";
import ServerTableContainer from "components/common/ServerTableContainer";

export type ShareholderProps = SimplePagedSectionProps<ShareholderDto>;

class Shareholders extends Component<ShareholderProps> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default Shareholders;
