import { ToastProgressData } from "./types/Toaster";

export function showSuccessToast (message: string) {
  addToast(undefined, message, "success");
};

export function showErrorToast (message: string) {
  addToast(undefined, message, "error");
};

export async function addToast(title: string | undefined, message: string, status: string) {
  let toaster = document.querySelector('kirby-toast') as any;
  if (!toaster) 
    return;

  return toaster.addToast({
      status: status,
      message: message,
      params: {
        title,
        showClose: true,
        duration: status === 'success' ? 5000 : undefined
      }
  }).then((response: string) => {
    return response;
  });
}

export async function updateToast(toastId: string, title: string, message: string, status: string, progress: ToastProgressData | undefined = undefined) {
  const toaster = document.querySelector('kirby-toast') as any;

  if (!toaster) 
    return;

  const selfClose = (status === 'success') || 
    (status === 'progress' && progress?.success 
      && progress?.total 
      && progress.success >= progress.total) ? 5000 : undefined;
  
  await toaster.editToast(toastId, {
    status: status,
    message: message,
    params: {
      title,
      showClose: true,
      duration: selfClose,
      progressData: progress
    }
  })
}

export async function removeToast(toastId: string) {
  const toaster = document.querySelector('kirby-toast') as any;

  if (!toaster) 
    return;

  await toaster.removeToast(toastId);
}
