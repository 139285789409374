import { EntityDataMappingDto } from './entityDataMappingDto';
import JurisdictionEntityTypeDto from './jurisdictionEntityTypeDto';
import RegisteredOfficeDto from './registeredOfficeDto';
import { SearchMatchInfoDto } from './searchMatchInfoDto';

export class EntityDto {
  id: number;
  clientNumber: string;
  name: string;
  jurisdictionEntityType: JurisdictionEntityTypeDto;
  entityType: string;
  registeredOffice: RegisteredOfficeDto;
  relationshipContact: string;
  relationshipContactEmail: string;
  showInPortal: boolean;
  searchMatchInfo?: SearchMatchInfoDto;
  dataMappings: EntityDataMappingDto[] = [];
  goodStanding: boolean | null;
  closed: Date | null;
}
