import { useEffect, useState, useCallback } from "react";
import styles from ".././FolderContent.module.scss";
import { useParams } from "react-router";
import { DocumentSearchResultDto } from "common-types/documentSearchResultDto";
import { DocumentSearchResult } from "./DocumentSearchResult";
import { KirbyButton, KirbySpinner, KirbyMenuItem } from "maples-kirby-react";
import { kirbyClose, kirbyStructureChart } from "maples-kirby-icons";
import { isWhiteSpace } from "helpers/string";
import DocManagementActionPanel from "../Panel/DocManagementActionPanel";
import {
  downloadDocs,
  searchDocuments,
  deleteDocuments,
} from "../Actions/doc-management-actions";
import DeleteDocumentsDialog from "../Delete/DeleteDocumentsDialog";
import { Copy } from "constants/copy/document-management/copy";
import DocActionApi from "helpers/userActions/documentActions";
import {
  downloadCompleteMessageSearch,
  downloadMessageSearch,
  entityDocsFileName,
} from "../Common/download";
import RenameFolderDialog from "../Rename/RenameFolderDialog";
import { DocumentDto } from "common-types/documentDto";
import { addToast } from "components/common/Toaster";
import ToggleViewButton from "../ToggleViewButton";
import localForage from "localforage";
import { onCopyCut } from "../Copy/CopyFunctions";

export interface DocumentSearchProps {
  searchTerm: string;
  resetSearch: () => void;
  setDocClipboard: Function;
  setClipboardCut: Function;
  setMobileOpen?: Function;
}

export function DocumentSearch(props: DocumentSearchProps) {
  const {
    searchTerm,
    resetSearch,
    setDocClipboard,
    setClipboardCut,
    setMobileOpen,
  } = props;
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const [searchResults, setSearchResults] = useState<DocumentSearchResultDto[]>(
    []
  );
  const [selectedSearchResults, setSelectedSearchResults] = useState<any[]>([]);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<
    string | undefined
  >(undefined);
  const [selectedItemForRename, setSelectedItemForRename] = useState<
    DocumentDto | undefined
  >(undefined);
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [deleteDocumentsDialogOpen, setDeleteDocumentsDialogOpen] =
    useState<boolean>(false);
  const [renameDocumentDialogOpen, setRenameDocumentDialogOpen] =
    useState<boolean>(false);
  const [gridView, setGridView] = useState<boolean>(false);
  const [layout, setLayout] = useState<string>("full");
  let ctrlKeyDown = false;

  const store = localForage.createInstance({
    name: "docStoreStates",
  });

  useEffect(() => {
    if (!searchTerm || isWhiteSpace(searchTerm)) return;

    setSearchInProgress(true);
    searchDocuments(
      parseInt(id!),
      searchTerm,
      (result: DocumentSearchResultDto[] | string) => {
        if (typeof result === "string") setSearchResults([]);
        else setSearchResults(result);
        setSearchInProgress(false);
      }
    );
  }, [searchTerm, id]);

  const updateLocalStore = useCallback(
    (isGrid: boolean) => {
      const docStore = { viewMode: isGrid ? "Grid" : "List" };
      store.setItem("view", docStore).catch(function (err: any) {
        console.log(err);
      });
    },
    [store]
  );

  const select = useCallback(
    (key: string) => {
      let selectedSearchResult = searchResults?.find(
        (a) => a.document.id === key
      );

      if (!selectedSearchResult) return;

      selectedSearchResult.selected = !selectedSearchResult.selected;
      setSearchResults(JSON.parse(JSON.stringify(searchResults)));
      let newSelectedSearchResults = searchResults
        .filter((a) => a.selected)
        .map((doc) => {
          return { label: doc.document.displayName, key: doc.document.id };
        });

      setSelectedSearchResults(
        JSON.parse(JSON.stringify(newSelectedSearchResults))
      );
    },
    [searchResults, setSearchResults, setSelectedSearchResults]
  );

  const results = () => {
    const noResults = !searchResults || searchResults?.length === 0;
    return noResults ? (
      <div>No documents found. Please refine your search</div>
    ) : (
      searchResults?.map((searchResult) => (
        <DocumentSearchResult
          key={searchResult.document.id}
          item={searchResult}
          select={select}
          delete={deleteItem}
          rename={renameItem}
          gridView={gridView}
          setDocClipboard={setDocClipboard}
          setClipboardCut={setClipboardCut}
        />
      ))
    );
  };

  const onSelectAll = useCallback(
    (value: boolean) => {
      searchResults?.forEach((result) => (result.selected = value));
      setSearchResults(JSON.parse(JSON.stringify(searchResults)));
      setSelectedSearchResults(
        JSON.parse(
          JSON.stringify(
            searchResults
              .filter((a) => a.selected)
              .map((doc) => {
                return {
                  label: doc.document.displayName,
                  key: doc.document.id,
                };
              })
          )
        )
      );
    },
    [searchResults, setSearchResults, setSelectedSearchResults]
  );

  const onDownloadClicked = useCallback((): void => {
    let selectedItems = searchResults.filter((a) => a.selected);
    let downloadOptions = {
      forceZip: selectedItems[0].document.isFolder,
      fileName: entityDocsFileName(),
      toastMessage: downloadMessageSearch(selectedItems),
      toastCompleteMessage: downloadCompleteMessageSearch(selectedItems),
    };
    downloadDocs(
      Number.parseInt(id as string),
      selectedItems.map((a) => a.document),
      downloadOptions
    );
  }, [searchResults, id]);

  const onDeleteClicked = useCallback(() => {
    setDeleteDocumentsDialogOpen(true);
  }, [setDeleteDocumentsDialogOpen]);

  const renameItem = (document: DocumentDto) => {
    setSelectedItemForRename(document);
    setRenameDocumentDialogOpen(true);
  };

  const deleteItem = (key: string) => {
    setSelectedItemForDelete(key);
    setDeleteDocumentsDialogOpen(true);
  };

  const DocumentDeleteSuccess = useCallback(
    (key: string) => {
      const docFound = searchResults.find((k) => k.document.id === key);
      if (docFound !== undefined) {
        const index = searchResults.indexOf(docFound, 0);
        searchResults.splice(index, 1);
        setSearchResults(JSON.parse(JSON.stringify(searchResults)));
      }
    },
    [searchResults, setSearchResults]
  );

  const confirmDeleteFunction = useCallback(() => {
    setDeleteDocumentsDialogOpen(false);

    if (selectedItemForDelete === undefined) {
      deleteDocuments(entityId, selectedSearchResults, DocumentDeleteSuccess);
    } else {
      const docFound: any = searchResults.find(
        (k) => k.document.id === selectedItemForDelete
      );

      if (docFound) {
        deleteDocuments(
          entityId,
          [{ label: docFound.document.displayName, key: docFound.document.id }],
          DocumentDeleteSuccess
        );
      }
    }
    setSelectedItemForDelete(undefined);
  }, [
    setDeleteDocumentsDialogOpen,
    selectedItemForDelete,
    entityId,
    selectedSearchResults,
    setSelectedItemForDelete,
    DocumentDeleteSuccess,
    searchResults,
  ]);

  const onDocumentRenamed = useCallback(
    (key: string, document: DocumentDto) => {
      const docFound: DocumentSearchResultDto | undefined = searchResults.find(
        (k) => k.document.id === key
      );

      if (docFound) {
        const newData: DocumentSearchResultDto[] = [];
        searchResults.forEach((doc) => {
          if (doc.document.id === key) {
            doc.document = document;
          }
          newData.push(Object.assign({}, doc));
        });
        setSearchResults(newData);
      }
      addToast(
        "Rename folder/document",
        "Selected item was renamed",
        "success"
      );
    },
    [searchResults, setSearchResults]
  );

  const removeDocumentFromView = useCallback(
    (key: string) => {
      const docFound: DocumentSearchResultDto | undefined = searchResults.find(
        (k) => k.document.id === key
      );
      if (docFound !== undefined) {
        const index = searchResults.indexOf(docFound, 0);
        searchResults.splice(index, 1);
        const newData: DocumentSearchResultDto[] = [];
        searchResults.forEach((doc) => {
          newData.push(Object.assign({}, doc));
        });
        setSearchResults(newData);
      }
    },
    [searchResults, setSearchResults]
  );

  const onDocumentNotFound = useCallback(
    (key: string) => {
      addToast(
        Copy.RenameDocumentToastTitle,
        Copy.ItemNotFoundMessage,
        "error"
      );
      removeDocumentFromView(key);
    },
    [removeDocumentFromView]
  );

  const toggleViewMode = useCallback(
    (isGrid: boolean) => {
      updateLocalStore(isGrid);
      setGridView(isGrid);
    },
    [setGridView, updateLocalStore]
  );

  const getLocalStore = useCallback(async () => {
    try {
      const viewMode: any = await store.getItem("view");
      setGridView(viewMode?.viewMode === "Grid");
    } catch (err) {
      console.log(err);
    }
  }, [store]);

  useEffect(() => {
    getLocalStore();
  }, [getLocalStore]);

  useEffect(() => {
    window.document.addEventListener("keydown", keyDownHandler);
    window.document.addEventListener("keyup", keyUpHandler);
    return () => {
      window.document.removeEventListener("keydown", keyDownHandler);
      window.document.removeEventListener("keyup", keyUpHandler);
    };
  });

  const keyDownHandler = async (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    // We only get one key at a time, so track if the ctrl key is down
    if (e.key === "Control") {
      ctrlKeyDown = true;
    }

    // If there's a selection and Ctrl + c is pressed
    if (
      selectedSearchResults.length > 0 &&
      ctrlKeyDown &&
      (e.key === "c" || e.key === "x")
    ) {
      onCopyCut(
        searchResults
          .filter((result) => result.selected)
          .map((result) => result.document),
        searchResults
          .filter((result) => result.selected)
          .map((result) => result.location.split(" / ").slice(-1)[0]),
        setDocClipboard,
        setClipboardCut,
        e.key === "x" ? true : false
      );
    }

    // If Del is pressed
    if (
      searchResults.filter((doc) => doc.selected).length > 0 &&
      searchResults
        .filter((a) => a.selected)
        .every((a) =>
          DocActionApi.hasDeleteAction(
            a.document.source,
            a.document.documentType
          )
        ) &&
      e.key === "Delete"
    ) {
      onDeleteClicked();
    }
  };

  const keyUpHandler = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === "Control") ctrlKeyDown = false;
  };

  const copyClickHandler = useCallback(() => {
    onCopyCut(
      searchResults
        .filter((result) => result.selected)
        .map((result) => result.document),
      searchResults
        .filter((result) => result.selected)
        .map((result) => result.location.split(" / ").slice(-1)[0]),
      setDocClipboard,
      setClipboardCut,
      false
    );
  }, [searchResults, setDocClipboard, setClipboardCut]);

  const cutClickHandler = useCallback(() => {
    onCopyCut(
      searchResults
        .filter((result) => result.selected)
        .map((result) => result.document),
      searchResults
        .filter((result) => result.selected)
        .map((result) => result.location.split(" / ").slice(-1)[0]),
      setDocClipboard,
      setClipboardCut,
      true
    );
  }, [searchResults, setDocClipboard, setClipboardCut]);

  const clearSearchClick = useCallback(() => {
    resetSearch();
  }, [resetSearch]);

  const deleteDocumentDialogHandleClose = useCallback(() => {
    setDeleteDocumentsDialogOpen(false);
  }, [setDeleteDocumentsDialogOpen]);

  const renameFolderDialogHandleClose = useCallback(() => {
    setRenameDocumentDialogOpen(false);
  }, [setRenameDocumentDialogOpen]);

  useEffect(() => {
    function updateLayout() {
      setLayout(window.innerWidth > 599 ? "full" : "mobile");
    }
    window.addEventListener("resize", updateLayout);
    updateLayout();
    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  return (
    <>
      <div
        className={`${styles.folderContent} searchContent ${
          gridView ? styles.gridview : styles.listview
        } ${searchInProgress && styles.searching}`}>
        <div className={`${styles.sectionTitle} sectiontitle`}>
          <h4>
            {searchInProgress
              ? `Searching for "${searchTerm}"`
              : `Search results for "${searchTerm}"`}
          </h4>
          {layout === "full" && (
            <ToggleViewButton
              gridView={gridView}
              setGridView={toggleViewMode}
            />
          )}
        </div>
        <div className={`${styles.toolbar} toolbar`}>
          {layout === "mobile" && (
            <KirbyButton
              icon={kirbyStructureChart}
              onClick={() => !!setMobileOpen && setMobileOpen(true)}>
              All Folders
            </KirbyButton>
          )}
          <DocManagementActionPanel
            showButtons={searchResults.length > 0}
            deleteDisabled={
              searchResults.filter((doc) => doc.selected).length === 0 ||
              !searchResults
                .filter((a) => a.selected)
                .every((a) =>
                  DocActionApi.hasDeleteAction(
                    a.document.source,
                    a.document.documentType
                  )
                )
            }
            downloadDisabled={
              searchResults.filter((doc) => doc.selected).length === 0
            }
            copyDisabled={
              searchResults.filter((doc) => doc.selected).length === 0
            }
            pasteHidden={true}
            selectAllDisabled={searchResults.every((x) => x.selected)}
            clearAllDisabled={
              searchResults.filter((doc) => doc.selected).length === 0
            }
            selectAll={onSelectAll}
            deleteClick={onDeleteClicked}
            downloadClick={onDownloadClicked}
            copyClick={copyClickHandler}
            cutClick={cutClickHandler}
            menuMode={layout === "mobile"}
            custom={[
              layout === "mobile" ? (
                <KirbyMenuItem
                  key={"clear-search-results"}
                  title="Clear Search Results"
                  handleClick={clearSearchClick}>
                  <span>Clear Search Results</span>
                </KirbyMenuItem>
              ) : (
                <KirbyButton
                  key={"clear-search-results"}
                  icon={kirbyClose}
                  handleClick={clearSearchClick}
                  title="Clear Search Results"
                  className={styles.clearButton}
                />
              ),
            ]}
          />
          {layout === "mobile" && (
            <ToggleViewButton
              gridView={gridView}
              setGridView={toggleViewMode}
            />
          )}
        </div>
        <div className={styles.itemList}>
          {searchInProgress ? (
            <div className={styles.spinner}>
              <KirbySpinner
                size="medium"
                data-testid="folder-content-spinner"
              />
            </div>
          ) : (
            results()
          )}
        </div>
      </div>
      <DeleteDocumentsDialog
        open={deleteDocumentsDialogOpen}
        confirmFunction={confirmDeleteFunction}
        handleClose={deleteDocumentDialogHandleClose}
        message={Copy.DeleteConfirmMessage}
      />

      <RenameFolderDialog
        open={renameDocumentDialogOpen}
        document={selectedItemForRename}
        documentUpdated={onDocumentRenamed}
        handleNotFound={onDocumentNotFound}
        handleClose={renameFolderDialogHandleClose}
      />
    </>
  );
}
