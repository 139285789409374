export enum comparisonOperatorEnum {
    EQUAL,
    NOT_EQUAL,
    CONTAINS,
    GREATER_THAN,
    GREATER_THAN_OR_EQUAL,
    LESS_THAN,
    LESS_THAN_OR_EQUAL,
    RANGE,
    ISNULL,
    IN
}