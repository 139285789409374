import { useState, CSSProperties } from "react";
import styles from "./OrderHistory.module.scss";
import { OrderHistoryComponentProps } from "modules/order/types/order-component-types";
import EntityViewListeners from "../../../common/EntityViewUtils";
import OrderHistoryContent from "./OrderHistoryContent";

export default function OrderHistory(props: OrderHistoryComponentProps) {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [maxHeaderHeight, setMaxHeaderHeight] = useState<number>(0);
  const { fetchStatus } = props;

  EntityViewListeners(headerHeight, setHeaderHeight, maxHeaderHeight, setMaxHeaderHeight, fetchStatus);

  return (
    <>
      <div className={styles.container} style={{ "--entity-page-header-height": headerHeight + "px", "--entity-page-max-header-height": maxHeaderHeight + "px" } as CSSProperties} data-testid={'service-request-order-history'}>
        <OrderHistoryContent {...props} />
      </div>
    </>);
}
