import { useCallback, useEffect } from "react";
import { MaplesTeamComponentProps } from "modules/maplesTeam/types/component-types";
import { KirbyGallery, KirbySection, KirbySpinner } from "maples-kirby-react";
import { useParams } from "react-router-dom";
import MaplesTeamMember from "./MaplesTeamMember";
import styles from "./MaplesTeam.module.scss";

export default function MaplesTeam({
  fetchMaplesTeam,
  maplesTeam,
  fetchStatus,
  header,
  collapsed,
  handleOpenToggle,
  title,
  cancel
}: MaplesTeamComponentProps) {
  const { id } = useParams();

  useEffect(() => {
    fetchMaplesTeam(id);
    return (() => {
      cancel();
    })
  }, [fetchMaplesTeam, id]);

  const openSection = useCallback(() => {
    handleOpenToggle && handleOpenToggle(header);
  }, [handleOpenToggle, header]);

  return (
    <KirbySection
      sectionTitle={header}
      open={!collapsed}
      title={title || header}
      handleOpenToggle={openSection}
    >
      {fetchStatus === 'NotFound' && "No team members found"}
      {fetchStatus === 'InProgress' &&
        <div className={styles.spinnerWrap}>
          <KirbySpinner single size="small"></KirbySpinner>
        </div>
      }
      {maplesTeam?.length > 0 && fetchStatus === 'Complete' && (
        <KirbyGallery scrollToHidden data-testid="maples-team-gallery">
          {maplesTeam.map((member, i) => (
            <MaplesTeamMember
              key={`member_${member.userId}_${member.role}_${member.office}`}
              member={member}
            />
          ))}
        </KirbyGallery>
      )}
    </KirbySection>
  );
}
