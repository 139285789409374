import { DateCell } from 'helpers/gridCells';

export const nameHistoryGridColumns: Array<any> = [
  {
    title: "Previous Name",
    field: "formerName"
  },
  {
    title: "Date of Name Change",
    field: "dateOfNameChange",
    formatter: DateCell
  }
];
