import { Component } from "react";
import { format } from "helpers";
import { CimaRegistrationsComponentProps } from "modules/cimaRegistrations/types/cima-registrations-component-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import headerText from "constants/copy/registers/headerText";
import EntityProperty from "../EntityProperty";
import Collapsible from "components/controls/collapsible/Collapsible";
import {
  getLocalStorage,
  updateLocalStorage,
} from "../utils/EntityDetailsUtils";
import {
  AccordionObject,
  AccordionState,
} from "components/common/types/overviewLocalStorage";

export type CimaRegistrationsProps = CimaRegistrationsComponentProps;

class CimaRegistrations extends Component<CimaRegistrationsProps> {
  entityType: string = this.props.entity?.jurisdictionEntityType
    ?.code as string;
  state: AccordionState = {
    open: false,
  };

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetchCimaRegistrations(this.props.entity.id);
    }
    getLocalStorage().then((store: any) => {
      const entityTypeDetails = store[this.entityType];
      if (entityTypeDetails?.details) {
        let accordionFound = entityTypeDetails.details.find(
          (o: AccordionObject) => o.CimaRegistrations !== undefined
        );
        accordionFound &&
          this.setState({
            open: accordionFound.CimaRegistrations,
          });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<CimaRegistrationsProps>): void {
    if (this.props.entity) {
      if (this.props.entity.id !== prevProps?.entity?.id) {
        this.props.fetchCimaRegistrations(this.props.entity.id);
      }
    }
  }

  componentWillUnmount(): void {
    this.props.cancel();
  }

  switchContentOnFetchStatus() {
    const { fetchCimaRegistrationsStatus } = this.props;

    return fetchCimaRegistrationsStatus === FetchStatus.Complete
      ? this.cimaRegistrationsContent()
      : null;
  }

  toggleOpen() {
    this.setState((prevState: AccordionState) => 
      ({
        open: !prevState.open,
      }),
      () =>
        updateLocalStorage(
          this.props.entity?.jurisdictionEntityType?.code as string,
          "CimaRegistrations",
          this.state.open
        )
    );
  }

  cimaRegistrationsContent() {
    const { cimaRegistrations } = this.props;
    const {
      cimaType,
      registrationNumber,
      cimaStatus,
      fundOperator,
      registrationDate,
      deRegistrationDate,
    } = cimaRegistrations || {};

    return (
      <Collapsible
        open={this.state.open}
        handleOpenToggle={() => this.toggleOpen()}
        title={headerText.CimaDetails}
        headerText={headerText.CimaDetails}>
        <div className={"fields"}>
          <EntityProperty title="CIMA Type" value={cimaType} />
          <EntityProperty title="CIMA Status" value={cimaStatus} />
          <EntityProperty
            title="Registration Number"
            value={registrationNumber}
          />
          <EntityProperty title="Fund Operator" value={fundOperator} />
          <EntityProperty
            title="Registration Date"
            value={format.date(new Date(registrationDate ?? ""))}
          />
          <EntityProperty
            title="De-Registration Date"
            value={format.date(new Date(deRegistrationDate ?? ""))}
          />
        </div>
      </Collapsible>
    );
  }

  render() {
    return this.switchContentOnFetchStatus();
  }
}

export default CimaRegistrations;
