import { SearchResultActions } from "modules/entities/searchResults/searchResults-actions";
import { ResetSearchFetchStatusAction } from "modules/entities/types/entities-actions-types";
import { EntityActions } from "modules/entity/entity-actions";
import { getEntity, getEntityFetchStatus } from "modules/entity/entity-selectors";
import { EntityComponentDispatchProps, EntityComponentProps } from "modules/entity/types/component-types";
import { ClearCurrentEntityAction, DownloadEntityDocumentAction, DownloadEntityInvoiceAction, FetchEntityAction } from "modules/entity/types/entity-actions-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import EntityOverview from "./EntityOverview";

const mapStateToProps = (state: AppState): EntityComponentProps => ({
  entity: getEntity(state),
  fetchStatus: getEntityFetchStatus(state),
  searchState: state.searchResults
});

const mapDispatchToProps: EntityComponentDispatchProps = {
  fetchEntity: (entityId: number): FetchEntityAction => EntityActions.fetchEntity(entityId),
  clearCurrentEntity: (): ClearCurrentEntityAction => EntityActions.clearCurrentEntity(),
  resetSearchFetchStatus: (): ResetSearchFetchStatusAction => SearchResultActions.resetFetchStatus(),
  downloadDocument: (entityId: number, docId: string): DownloadEntityDocumentAction => EntityActions.downloadDocument(entityId, docId),
  downloadInvoice: (entityId: number, invoiceId: string): DownloadEntityInvoiceAction => EntityActions.downloadInvoice(entityId, invoiceId)
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityOverview);
