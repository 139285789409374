import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { RegisterPropertiesState } from "./types/registerProperties-state";
import { RegisterPropertyDto } from "common-types/registerPropertiesDto";

const registerPropertiesStateSelector = (state: AppState): RegisterPropertiesState =>
  state.registerProperties;

export const getRegisterProperties = createSelector(
  registerPropertiesStateSelector,
  (registerPropertiesState) => {
    if (!registerPropertiesState || !registerPropertiesState.registerProperties) return {} as RegisterPropertyDto;

    return registerPropertiesState.registerProperties;
  }
);

export const getRegisterPropertiesFetchStatus = createSelector(
  registerPropertiesStateSelector,
  (registerProperties) => registerProperties.fetchStatus
);
