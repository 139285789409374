import { OrderDto } from "common-types/serviceRequests/orderDto";
import { KirbyDialog } from "maples-kirby-react";
import { useState } from "react";

interface OrderDetailsDialogProps {
    order: OrderDto | undefined;
    confirm: Function;
    close: Function;
    show: boolean;
    message: string;
}

const OrderDetailsDialog = (props: OrderDetailsDialogProps) => {
    const { order, confirm, close, show, message } = props;
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  
    return (
      <div data-testid={`order-details-dialog`} >
        <KirbyDialog
          open={show}
          disablebackdropclose={true}
          nocancel={true}
          actions={[
            {
              action: 'Cancel',
              title: 'Cancel',
              func: () => {
                close();
              }
            },
            {
              action: 'Confirm',
              primary: true,
              func: async () => {
                setIsConfirmButtonDisabled(true);
                await confirm(order);
              },
              testIdPrefix: 'confirm-removal',
              title: 'Confirm',
              disabled: isConfirmButtonDisabled
            }
          ]}
        >
          <p>{ message }</p>
        </KirbyDialog>
      </div> 
    );
  };
  
  export default OrderDetailsDialog;