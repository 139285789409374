import { Component } from "react";
import { format } from "helpers";
import { FetchStatus } from "common-types/fetchStatusEnum";
import headerText from "constants/copy/registers/headerText";
import { ShippingDetailsComponentProps } from "modules/shippingDetails/types/shipping-details-component-types";
import EntityProperty from "../EntityProperty";
import Collapsible from "components/controls/collapsible/Collapsible";
import {
  getLocalStorage,
  updateLocalStorage,
} from "../utils/EntityDetailsUtils";
import {
  AccordionObject,
  AccordionState,
} from "components/common/types/overviewLocalStorage";

export type ShippingDetailsProps = ShippingDetailsComponentProps;

class ShippingDetails extends Component<ShippingDetailsProps> {
  entityType: string = this.props.entity?.jurisdictionEntityType
    ?.code as string;
  state: AccordionState = {
    open: false,
  };

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetchShippingDetails(this.props.entity.id);
    }
    getLocalStorage().then((store: any) => {
      const entityTypeDetails = store[this.entityType];
      if (entityTypeDetails?.details) {
        let accordionFound = entityTypeDetails.details.find(
          (o: AccordionObject) => o.shippingDetails !== undefined
        );
        accordionFound &&
          this.setState({
            open: accordionFound.shippingDetails,
          });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<ShippingDetailsProps>): void {
    if (this.props.entity) {
      if (this.props.entity.id !== prevProps?.entity?.id) {
        this.props.fetchShippingDetails(this.props.entity.id);
      }
    }
  }

  componentWillUnmount(): void {
    this.props.cancel();
  }

  switchContentOnFetchStatus() {
    const { fetchShippingDetailsStatus } = this.props;
    return fetchShippingDetailsStatus === FetchStatus.Complete
      ? this.shippingDetailsContent()
      : null;
  }

  toggleOpen() {
    this.setState((prevState: AccordionState) =>
      ({
        open: !prevState.open,
      }),
      () =>
        updateLocalStorage(
          this.props.entity?.jurisdictionEntityType?.code as string,
          "shippingDetails",
          this.state.open
        )
    );
  }

  shippingDetailsContent() {
    const { shippingDetailsDto } = this.props;

    if (
      !shippingDetailsDto?.bviShippingRegistryNumber &&
      !shippingDetailsDto?.bviShippingRegistryDate
    )
      return null;

    return (
      <Collapsible
        open={this.state.open}
        handleOpenToggle={() => this.toggleOpen()}
        title={headerText.ShippingDetails}
        headerText={headerText.ShippingDetails}>
        <div className={"fields"}>
          <EntityProperty
            title="BVI Shipping Registry Number"
            value={shippingDetailsDto?.bviShippingRegistryNumber}
          />
          <EntityProperty
            title="BVI Shipping Registry Date"
            value={format.date(
              new Date(shippingDetailsDto?.bviShippingRegistryDate ?? "")
            )}
          />
        </div>
      </Collapsible>
    );
  }

  render() {
    return this.switchContentOnFetchStatus();
  }
}

export default ShippingDetails;
