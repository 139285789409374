import { GenericCopy } from "../generic-copy";

export const Copy = {
  DeleteDocumentTitle: "Delete Folders/Documents",
  DeleteConfirmMessage: "Are you sure you want to permanently delete the selected documents and folders?",
  ItemNotFoundMessage : GenericCopy.genericError,
  GenericErrorMessage : GenericCopy.genericError,
  DeleteItemNotAllowedMessage : "Requested action is not allowed",
  RenameDocumentToastTitle: "Rename Folders/Documents",
  RenameNoChange: "Please enter a different name to the current one",
  RenameFolderAlreadyExists: "A folder with this name already exists.",
  CopyDocumentTitle : "Copy Document",
  CopyFolderTitle : "Copy Folder",
  CopyDocumentsAndFolderTitle : "Copy Documents and Folders",
  CopyDocumentButtonText : "Copy",
  CopyDocumentNoFolderSelected : "Please select target folder",
  UploadFailTitle: 'Upload failed',
  UnknownError: "An unknown error occured, please try again."
}
