import { EntityTerminationDto } from "common-types/entityTerminationDto";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { EntityTerminationState } from "./types/entityTermination-state";

const entityTerminationStateSelector = (
  state: AppState
): EntityTerminationState => state.entityTermination;

export const getEntityTermination = createSelector(
  entityTerminationStateSelector,
  (entityTerminationState) => {
    if (!entityTerminationState || !entityTerminationState.entityTerminationDto)
      return {} as EntityTerminationDto;

    return entityTerminationState.entityTerminationDto;
  }
);

export const getEntityTerminationFetchStatus = createSelector(
  entityTerminationStateSelector,
  (entityTermination) => entityTermination.fetchEntityTerminationStatus
);
