import { getAbortSignalTryCancelPending, tryCancelPending } from "api/utils/requestAbortion";
import { ShippingDetailsDto } from "../../common-types/shippingDetailsDto";
import { ENTITIES_ENDPOINT, SHIPPING_DETAILS_ENPOINT } from "../../constants/urls"
import { ApiCaller } from "../../helpers"

const signalKey = "fetchShippingDetails";

export const fetchShippingDetails = async (entityId: number): Promise<ShippingDetailsDto> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${SHIPPING_DETAILS_ENPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchShippingDetails = (): void => tryCancelPending(signalKey);
