import { DateCell, GridLink, LinkClick } from "helpers/gridCells";
import { distinct } from "helpers/array";
import utils from "./utils";
import { renderToStaticMarkup } from "react-dom/server";
import { getFormattedCurrentDateTime, getFormattedDateFromString } from "../../../../helpers/formatting/dateConverter"
import { GridFilterOption } from "common-types/grids/gridFilterOption";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { sortByDisplayName } from "common-types/serviceRequests/utils/basketItemDtoUtils";

export const getOrderHistoryColumns = (entityId: number, docTypes: GridFilterOption[], statusOptions: GridFilterOption[], userOptions: GridFilterOption[]) => {
  return [
    {
      title: "Order ID",
      field: "id",
      headerFilter: 'text',
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          cell.getElement().querySelector("a").onclick = LinkClick;
        });
        return renderToStaticMarkup(
          <GridLink
            to={`/entities/${entityId}/servicerequests/order/${cell.getValue()}`}
            aria-label={cell.getValue()}
            title={cell.getValue()}
            dataSelector={`go-to-order-${cell.getValue()}`}>
            {cell.getValue()}
          </GridLink>
        );
      },
      width: 11
    },
    {
      title: "Order Date",
      field: "orderDisplayDate",
      headerFilter: 'date',
      headerFilterParams: {
        maxFilterDate: getFormattedCurrentDateTime('yyyy-MM-dd')
      },
      formatter: DateCell,
      formatterParams: {
        dataFormat: "iso",
        displayFormat: "dd-MM-yyyy",
        invalidPlaceholder: "(invalid date)"
      },
      advFilters: true,
      width: 11,
      accessorDownload: function (value: string) {
        return getFormattedDateFromString(value, 'yyyy-MM-dd')
      }
    }, {
      title: "Document Type",
      field: "orderItemNames",
      headerFilter: 'select',
      headerFilterFunc: 'arrayIncludes',
      headerFilterParams: {
        options: docTypes
      },
      formatter: (cell: any, _formatterParams: any) => {
        let orderItems = cell.getRow().getData().orderItems;

        if (!orderItems || orderItems.length === 0)
          return 'None.';

        let parsedList = distinct(sortByDisplayName(orderItems).map((a: BasketItemDto) => utils.docTypeDisplayName(a))).map((item: any) => {
          return `<li>${item}</li>`
        });

        return `<ul part="listCell">${parsedList.join('')}</ul>`;
      },
      accessorDownload: function(value: Array<string>, data: any, type: any, params: any, columns: any) {
        let formattedVal: string = '';
        value.forEach((line, index) => {
          formattedVal = formattedVal + `${line}${index !== value.length - 1 ? '\r\n' : ''}`
        });
        return formattedVal;
      },
      sorter: "array",
      sorterParams: {
        type: "length",
        alignEmptyValues: "bottom"
      }
    }, {
      title: "Status",
      field: "serviceRequestOrderStatusId",
      headerFilter: 'select',
      headerFilterParams: {
        options: statusOptions
      },
      formatter: (cell: any, _formatterParams: any) => utils.getStatusLabel(cell.getValue()),
      accessorDownload: function (value: number, data: any, type: any, params: any, columns: any) {
        return utils.getStatusLabel(value);
      }
    },
    {
      title: "Ordered By",
      field: "orderedByDisplay",
      headerFilter: 'select',
      headerFilterParams: {
        options: userOptions
      },
    }
  ];
};
