import { HomePageType } from "common-types/homePageTypeEnum";
import { AllEntitiesActions } from "modules/entities/allEntities/allEntities-actions";
import { EntitiesActions } from "modules/entities/entities-actions";
import { SearchResultActions } from "modules/entities/searchResults/searchResults-actions";
import { ClearEntitiesSearchTermAction, SetCurrentPageAction, SetHomePageType, SetPageSizeAction } from "modules/entities/types/entities-actions-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import Entities from "./Entities";

const mapStateToProps = (state: AppState) => {
  return {
    homePageType: state.entities.homePageType
  };
};

const mapDispatchToProps = {
  setCurrentPage: (pageNumber: number): SetCurrentPageAction =>
    AllEntitiesActions.setCurrentPage(pageNumber),
  setPageSize: (pageSize: number): SetPageSizeAction =>
    AllEntitiesActions.setPageSize(pageSize),  
  clearEntitiesSearchTerm: (): ClearEntitiesSearchTermAction => 
    SearchResultActions.clearEntitiesSearchTerm(),
  setHomePageType: (type: HomePageType): SetHomePageType => 
      EntitiesActions.setHomePageType(type),
}

export default connect(mapStateToProps, mapDispatchToProps)(Entities);
