import { connect } from 'react-redux'
import { getAuthenticatedUser, getAuthenticatedUserFetchStatus, getIsAuthenticated, getIsAuthenticatedFetchStatus } from "modules/auth/auth-selectors";
import { AppState } from "store/AppState";
import { RequireAuth } from './RequireAuth';
import { AUTH_ENDPOINT } from 'constants/urls';
import { navigateToPage } from 'helpers/history';
import { fetchIsAuthenticated } from "api/auth-api";

const callFetchIsAuthenticated = async (): Promise<boolean> => {
  return fetchIsAuthenticated().then((response: boolean) => { 
    return response; 
  });
};

const navigateToLogin = async () => {
  let returnUrl = window.location.href;
  let loginUrl = AUTH_ENDPOINT + "/login?returnUrl=" + encodeURI(returnUrl);

  window.location.assign(loginUrl);

  return null;
};

function mapStateToProps(state: AppState) {
  return {
    isAuthenticated: getIsAuthenticated(state),
    user: getAuthenticatedUser(state),
    userFetchStatus: getAuthenticatedUserFetchStatus(state),
    isAuthenticatedFetchStatus: getIsAuthenticatedFetchStatus(state),
    fetchIsAuthenticated: callFetchIsAuthenticated,
    navigateToLogin: navigateToLogin,
    navigateToPage: navigateToPage
  }
}

export default connect(mapStateToProps)(RequireAuth);