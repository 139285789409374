export class EntityDetailDto {
    entityId: number;
    companyMinutesAddress: string;
    companyRecordsAddress: string;
    registeredOfficeProviderAddress: string;
    registeredOfficeProviderName: string;
    bcActTransProvApplicable: boolean;
    authorisedShareCapitalInUSD?: number;
    numberOfAuthorisedShares: number;
    reportingEntityType: string;
    registrationDate?: Date;
    registrationNumber: string;
    incorporationDate?: Date;
    incorporationNumber: string;
    sezcRegistrationDate?: Date;
    natureOfBusiness: string;
    fiscalYearEnd: string;
    transferredInDate?: Date;
    continuationInDate?: Date;
    hasParValueShareClass?: boolean;
    hasNoParValueShareClass?: boolean;
    hasUnlimitedWithParValueShareClass?: boolean;
    hasUnlimitedWithNoParValueShareClass?: boolean;
}
