import { EntityPageRequest } from "api/interfaces/entityPageRequest";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { Action } from "typesafe-actions";

export const PagedListActionTypes = (pagedListKey: string) => {
  return {
    FETCH: 'FETCH_PL_' + pagedListKey,
    FETCH_SUCCESS: 'FETCH_SUCCESS_PL_' + pagedListKey,
    FETCH_FAILURE: 'FETCH_FAILURE_PL_' + pagedListKey,
    CANCEL_FETCH: 'CANCEL_FETCH_PL_' + pagedListKey
  }
};

export const PagedListActions = <Type>(pagedListKey: string) => { 
  const actionTypes = PagedListActionTypes(pagedListKey);
  
  return {
    fetch: (request: EntityPageRequest): PagedListFetchAction => ({
      type: actionTypes.FETCH,
      request
    }),

    fetchSuccess: (items: PagedListDto<Type>): PagedListFetchSuccessAction<Type> => ({
      type: actionTypes.FETCH_SUCCESS,
      items
    }),

    fetchFailure: (error: any) => ({
      type: actionTypes.FETCH_FAILURE,
      error
    }),

    cancel: () => ({
      type: actionTypes.CANCEL_FETCH
    })
  };
}

export const PagedListDispatchPropsActions = <Type>(pagedListKey: string): { fetch: (request: EntityPageRequest) => PagedListFetchAction, cancel: () => Action } => {
  const actionTypes = PagedListActionTypes(pagedListKey);

  return {
    fetch: (request: EntityPageRequest): PagedListFetchAction => ({
      type: actionTypes.FETCH,
      request
    }),

    cancel: () => ({
      type: actionTypes.CANCEL_FETCH
    })
  };
}

export interface PagedListFetchSuccessAction<Type> extends Action {
  items: PagedListDto<Type>;
}

export interface PagedListFetchAction extends Action {
  request: EntityPageRequest
}
