import produce from "immer";
import { EntityTerminationActionTypes } from "./entityTermination-actions";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { EntityTerminationState } from "./types/entityTermination-state";
import {
  FetchEntityTerminationAction,
  FetchEntityTerminationSuccessAction,
} from "./types/entityTermination-actions-types";

const initialState: EntityTerminationState = {
  entityTerminationDto: undefined,
  fetchEntityTerminationStatus: undefined,
  entityId: 0,
};

export default (
  state = initialState,
  action: FetchEntityTerminationAction & FetchEntityTerminationSuccessAction | undefined = undefined
): EntityTerminationState =>
  produce(state, (draft) => {
    switch (action?.type) {
      case EntityTerminationActionTypes.FETCH_ENTITYTERMINATION: {
        const { entityId } = action;
        draft.entityId = entityId;
        draft.fetchEntityTerminationStatus = FetchStatus.InProgress;
        return;
      }
      case EntityTerminationActionTypes.FETCH_ENTITYTERMINATION_SUCCESS: {
        const { entityTerminationDto } = action;
        draft.entityTerminationDto = entityTerminationDto;
        draft.fetchEntityTerminationStatus = entityTerminationDto
          ? FetchStatus.Complete
          : FetchStatus.NoContent;
        return;
      }
      case EntityTerminationActionTypes.FETCH_ENTITYTERMINATION_FAILURE: {
        draft.entityTerminationDto = undefined;
        draft.fetchEntityTerminationStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
