import { DateCell, StatusCell } from 'helpers/gridCells';

export const segregatedPortfoliosGridColumns: Array<any> = [
  {
    title: "Portfolio Name",
    field: "portfolioName"
  },
  {
    title: "Certificate ID",
    field: "certIdNumber"
  },
  {
    title: "Addition Date",
    field: "additionDate",
    formatter: DateCell
  },
  {
    title: "Termination Date",
    field: "terminationDate",
    formatter: DateCell
  },
  {
    title: "Status",
    field: "isActive",
    formatter: StatusCell
  }
];
