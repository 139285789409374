import { Component } from "react";
import { OfficerDto } from "common-types/officerDto";
import { SimplePagedSectionProps } from "components/common/types";
import ServerTableContainer from "components/common/ServerTableContainer";

export type OfficerProps = SimplePagedSectionProps<OfficerDto>;

class Officers extends Component<OfficerProps> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default Officers;
