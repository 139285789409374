import { RegisterManifestDto } from 'common-types';
import { SharesCell, ShareCapitalCell, ParValueCell, IssuedSharesCell, RemainingSharesCell } from 'helpers/gridCells';

export const authorisedShareCapitalGridColumns: Array<any> = [
  {
    title: "Class of Shares",
    field: "classOfShare"
  },
  {
    title: "Voting Rights",
    field: "votingRights"
  },
  {
    title: "Type of Share",
    field: "shareType"
  },
  {
    title: "Total Number of Shares",
    field: "totalUnits",
    formatter: SharesCell,
    headerHozAlign: 'right',
    hozAlign :'right',
    width:8.75
  },
  {
    title: "Issued Shares",
    field: "allocatedUnits",
    formatter: IssuedSharesCell,
    headerHozAlign: 'right',
    hozAlign: 'right'
  },
  {
    title: "Remaining Shares",
    field: "remainingUnits",
    formatter: RemainingSharesCell,
    headerHozAlign: 'right',
    hozAlign: 'right',
    width:8.75
  },
  {
    title: "Currency",
    field: "currencyCode"
  },
  {
    title: "Par Value",
    field: "parValue",
    formatter: ParValueCell,
    headerHozAlign: 'right',
    hozAlign: 'right'
  },
  {
    title: "Share Capital",
    field: "shareCapital",
    formatter: ShareCapitalCell,
    headerHozAlign: 'right',
    hozAlign: 'right',
    width:8.75
  }
];

export const getAuthorisedShareCapitalGridColumns = (registers: RegisterManifestDto[] | undefined) => {
  if (!registers)
    return authorisedShareCapitalGridColumns;

  const registerOfMembers = registers.find(r => r.internalCode === "Shareholders");

  if (registerOfMembers?.active) 
    return authorisedShareCapitalGridColumns;

  return authorisedShareCapitalGridColumns
    .filter(gc => gc.field !== "allocatedUnits" && gc.field !== "remainingUnits");
};