import React, { Component } from 'react';
import { KirbyMenuItem, KirbySpinner, KirbyUserMenu } from "maples-kirby-react";
import { UserAuthenticatedDto } from "common-types/user/userAuthenticatedDto";
import { AUTH_ENDPOINT } from "constants/urls";
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";
import { Actions, hasAction } from "helpers/userActions";

export interface UserMenuProps  {
  user: UserAuthenticatedDto | undefined | null;
  isFetchInProgress: boolean;
  isFetchError: boolean;
  isAuthenticated: boolean;
  actions:any
}

export class UserMenu extends Component<UserMenuProps> {

  render() {
    const { isFetchInProgress, user, isAuthenticated } = this.props;

    let menu;

    if (user && isAuthenticated) {
      menu = <AuthenticatedUserMenu displayName={this.getDisplayName()} isExternalUser={!user.isInternal} actions={this.props.actions} />
    } else if (!isFetchInProgress) {
      menu = <GuestUserMenu/>;
    }
    else {
      menu = <KirbySpinner size="small" />
    }

    return menu;
  }

  getDisplayName() {
    const { user, isAuthenticated } = this.props;

    return user && isAuthenticated ? user.displayName : "Guest";
  }
}

const AuthenticatedUserMenu = (props: any) => {

  const redirectToLogout = React.useCallback(() => window.location.href = AUTH_ENDPOINT + "/logout", []);

  const menuClick = React.useCallback(() => props.actions(true), [props]);

  const [unpFeatureEnabled, unpFeatureEnabledLoaded] = useFeatureFlag("UserNotificationPreferences");

  return (
    <>
      {unpFeatureEnabledLoaded &&
        <KirbyUserMenu data-testid="usermenu" userName={props.displayName}>
          {unpFeatureEnabled && props.isExternalUser && hasAction(Actions.ViewEntity) &&
            <KirbyMenuItem id="settings-menu-item" handleClick={menuClick}>
              <span>Notification Preferences</span>
            </KirbyMenuItem>}
          {props.isExternalUser && <KirbyMenuItem id="user-logout" handleClick={redirectToLogout}>
            <span>Sign Out</span>
          </KirbyMenuItem>}
        </KirbyUserMenu>
      }
    </>
  );
}

const GuestUserMenu = () => {

  const redirectToLogin = React.useCallback(() => window.location.href = AUTH_ENDPOINT + "/login", []);

  return (
    <KirbyUserMenu userName="Guest">
      <KirbyMenuItem id="user-login" handleClick={redirectToLogin}>
        <span>Sign In</span>
      </KirbyMenuItem>
    </KirbyUserMenu>
  );
}

export default UserMenu;
