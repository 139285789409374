import { EntityDetailDto } from "common-types/entityDetailDto";
import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { ENTITIES_DETAILS_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { urlEncoded } from "helpers/url-builder";
import { getAbortSignalTryCancelPending } from "./utils/requestAbortion";

export const fetchEntity = async (entityId: number): Promise<EntityDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}`);

export const fetchAllEntities = async (
  pageNumber: number,
  pageSize: number
): Promise<PagedListDto<EntityDto>> => {
  return ApiCaller.get(
    urlEncoded(ENTITIES_ENDPOINT, {
      pageNumber: pageNumber,
      pageSize: pageSize,
    }),
    { signal: getAbortSignalTryCancelPending("fetchAllEntities") }
  );
};

export const fetchSearchResults = async (
  pageNumber: number,
  pageSize: number,
  searchTerm: string = ""
): Promise<PagedListDto<EntityDto>> => {
  return ApiCaller.get(
    urlEncoded(ENTITIES_ENDPOINT, {
      pageNumber: pageNumber,
      pageSize: pageSize,
      searchTerm: searchTerm,
    }),
    { signal: getAbortSignalTryCancelPending("fetchSearchResults") }
  );
};

export const fetchEntityDetails = async (
  entityId: number
): Promise<EntityDetailDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${ENTITIES_DETAILS_ENDPOINT}`);
