import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { useCallback, useState } from "react";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { useServiceRequestContext } from "../ServiceRequestRoot";
import ServiceRequestItemStandard from "./ServiceRequestItemStandard";
import ServiceRequestItemOther from "./ServiceRequestItemOther";
import { OrderDto } from "common-types/serviceRequests/orderDto";

export interface RequestItemProps {
    requestItem: ServiceRequestItemDto;
    order: OrderDto;
}

export default function ServiceRequestItem(props: Readonly<RequestItemProps>){
    const { requestItem, order } = props;
    const { addToBasket } = useServiceRequestContext();
  
    const [quantity, setQuantity] = useState<number | undefined>();
    const [otherDescription, setOtherDescription] = useState('');
    const [express, setExpress] = useState(false);
    const [certificateType, setCertificateType] = useState<{id: number, name: string}>();
    const [selectedCertType, setSelectedCertType] = useState<string | undefined>('');
    const [selectedCertTypeError, setSelectedCertTypeError] = useState<string | undefined>();
    const [otherError, setOtherError] = useState<string>('');
    const [quantityError, setQuantityError] = useState<string | undefined>();

    const hasMultipleCertTypesAndSupportExpress = requestItem.certificationTypes.length > 1 && requestItem?.certificationTypes.filter(i => i.supportExpress).length > 0;
    const [isDisabledSupportExpress, setIsDisabledSupportExpress] = useState(hasMultipleCertTypesAndSupportExpress);

    const maxQuantity: number = 10;

    const addHandleClick = useCallback(() => {
        let valErrors = false;
        if (quantity === undefined || isNaN(quantity) || quantity < 1 || quantity > maxQuantity){
            setQuantityError("Required")
            valErrors = true;
        }
        if (requestItem.certificationTypes.length > 1 && (!selectedCertType || selectedCertType === '')) {
            setSelectedCertTypeError("Required")
            valErrors = true;
        }
        if (requestItem.nameOverride && (!otherDescription || otherDescription.length < 1 || otherDescription.trim() === '')) {
            setOtherError("Please provide a description.");
            valErrors = true;
        }
        if (valErrors) return;

        let submitCertType = certificateType;
        if (!submitCertType && requestItem.certificationTypes.length === 1) {
            submitCertType = {
                id: requestItem.certificationTypes[0].id,
                name: requestItem.certificationTypes[0].name
            }
        }

        let basketItem: BasketItemDto = {
            id: 0,
            name: otherDescription !== '' ? otherDescription : requestItem.name,
            isExpress: express,
            quantity: Number(quantity),
            serviceRequestCertificationId: submitCertType?.id ? Number(submitCertType?.id) : 7,
            serviceRequestCertificationType: submitCertType?.name ?? requestItem.certificationTypes[0].name,
            serviceRequestItemId: requestItem.id,
            serviceRequestOrderId: order.id,
            otherDescription: otherDescription,
            inDb: false,
            nameOverride: false
        };

        addToBasket(basketItem);
        setQuantity(undefined);
        setOtherDescription('');
        setCertificateType(undefined);   
        setSelectedCertType('');
        setExpress(false);
        if (hasMultipleCertTypesAndSupportExpress) {
            setIsDisabledSupportExpress(true);
        }
    }, [order, quantity, express, otherDescription, requestItem, selectedCertType]);

    const setNewQuantity = (quantity:any ) => {
        quantityError === "Required" && setQuantityError(undefined);
        setQuantity(quantity)
    }

    return (
        <>
        {
            requestItem.certificationTypes.length === 1 && requestItem.certificationTypes[0].hidden ?
                <ServiceRequestItemOther
                    addHandleClick={addHandleClick} 
                    otherDescription={otherDescription}
                    otherError={otherError}
                    quantity={quantity}
                    quantityError={quantityError}
                    requestItem={requestItem}
                    setNewQuantity={setNewQuantity}
                    setOtherDescription={setOtherDescription}
                    setOtherError={setOtherError}
                    setQuantityError={setQuantityError}
                />
                :
                <ServiceRequestItemStandard 
                    addHandleClick={addHandleClick}
                    express={express}
                    quantity={quantity}
                    quantityError={quantityError}
                    requestItem={requestItem}
                    selectedCertType={selectedCertType}
                    setCertificateType={setCertificateType}
                    setExpress={setExpress}
                    setNewQuantity={setNewQuantity}
                    setQuantityError={setQuantityError}
                    setSelectedCertType={setSelectedCertType}
                    setSelectedCertTypeError={setSelectedCertTypeError}
                    selectedCertTypeError={selectedCertTypeError}
                    hasMultipleCertTypesAndSupportExpress={hasMultipleCertTypesAndSupportExpress}
                    isDisabledSupportExpress={isDisabledSupportExpress}
                    setIsDisabledSupportExpress={setIsDisabledSupportExpress}

                />          
        }
        </>
    );
}
