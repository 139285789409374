import React, { useCallback, useEffect, useState } from "react";
import { KirbyButton, KirbySection } from "maples-kirby-react";
import styles from "./termsofuse.module.scss";
import "../TermsOfUse/terms.css";
import { fetchTermsOfUse } from "api/termsofuse-api";
import DOMPurify from "dompurify";
import { TermsOfUseDto } from "common-types/termsOfUseDto";
import { acceptTerms } from "api/user-api";
import { Actions, hasAction } from "helpers/userActions";
import { AuthActionTypes } from "modules/auth/auth-actions";
import store from "store/configureStore";

export default function TermsOfUse() {
    const [terms, setTerms] = useState<string | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const [showAccept, setShowAccept] = useState<boolean>(false);

    const getTerms = useCallback(async (): Promise<TermsOfUseDto> => {
        return fetchTermsOfUse()
            .then(response => { return response } );
    }, []);

    useEffect(() => {
        if (!terms) {
            getTerms()
                .then((x) => {
                    setTerms(DOMPurify.sanitize(x.htmlFragment));
                    setShowAccept(!hasAction(Actions.BypassTermsAndConditions));
                })
                .catch(err => {
                    setIsError(true);
                });
        }
    },[terms, getTerms, setShowAccept])     
    
    
    let content;

    if (!isError) {
        content = <Terms terms={terms} showAccept={showAccept} />;
    } else {
        content = <TermsError />;
    }

    return (
        <div className={styles.termsOfUseContainer}>
            {showAccept && 
                <div className={styles.tcOptionalTitle}>
                    Use of Phoenix is subject to the Terms of Use set out below. Please review and then click on 'Accept Terms' at the end to proceed.
                </div>}
            <KirbySection sectionTitle="Terms of Use" >
                {content}
            </KirbySection>
        </div>
    );
}

const Terms = (props:any) => {
    const acceptTermsButtonOnClick = useCallback(() => {
        acceptTerms()
            .then(() => {
                store.dispatch({ type: AuthActionTypes.FETCH_AUTHENTICATED_USER });
            })
            .catch(error => {
                console.log("Error accepting terms of use", error);
            });
    }, []); 

    return (<>
        <div dangerouslySetInnerHTML={{ __html: props.terms ?? "" }} />
        <div className={styles.termsOfUseAccept}>
            {
                props.showAccept &&
                <KirbyButton variant="primary" onClick={acceptTermsButtonOnClick} >
                    Accept Terms
                </KirbyButton>
            }
        </div>
    </>);
}

const TermsError = () => {
    return (
        <div className={styles.termsOfUseContainer}>
            An error occurred while fetching the terms.
        </div>
    );
}