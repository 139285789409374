import { ReleaseDto } from "api/releases-api";
import styles from "./releases.module.scss";

export const ReleaseInfo = (props: { release: ReleaseDto }) => {
  return (
    <div>
      <h4 className={styles.releaseNumberHeader}>{props.release.versionNumber} - {props.release.title}</h4>
      <div className={styles.displayLinebreak} dangerouslySetInnerHTML={{ __html: props.release.description ?? "" }} />
    </div>
  );
}
