import { HomePageType } from "common-types/homePageTypeEnum";
import { EntitiesActions } from "modules/entities/entities-actions";
import { RecentlyViewedEntitiesActions } from "modules/entities/recentlyViewedEntities/recentlyViewedEntities-actions";
import { getRecentlyViewedEntities, getRecentlyViewedEntitiesFetchStatus } from "modules/entities/recentlyViewedEntities/recentlyViewedEntities-selectors";
import { FetchRecentlyViewedEntitiesAction, SetHomePageType } from "modules/entities/types/entities-actions-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import RecentlyViewedEntities from "./RecentlyViewedEntities";

const mapStateToProps = (state: AppState) => {
  return {
    recentlyViewedEntities: getRecentlyViewedEntities(state),
    fetchStatus: getRecentlyViewedEntitiesFetchStatus(state),
  };
};

const mapDispatchToProps = {
  fetchRecentlyViewedEntities: (): FetchRecentlyViewedEntitiesAction =>
    RecentlyViewedEntitiesActions.fetchRecentlyViewedEntities(),
  setHomePageType: (type: HomePageType): SetHomePageType => 
    EntitiesActions.setHomePageType(type),
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyViewedEntities);
