import { KirbyDialog } from "maples-kirby-react";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { useState } from "react";

export interface DeleteOrderDialogProps {
  basketItem: BasketItemDto | undefined;
  confirm: Function;
  close: Function;
  show: boolean;
  message: string;
}

const DeleteOrderDialog = (props: DeleteOrderDialogProps) => {
  const { basketItem, confirm, close, show } = props;
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  return (
    <div data-testid={`basket-item-confirm-removal-dialog`} >
      <KirbyDialog
        open={show}
        nocancel={true}
        disablebackdropclose={true}
        actions={[
            {
                action: 'Cancel',
                title: 'Cancel',
                func: () => {
                    close();
                }
            },
            {
                action: 'Confirm',
                primary: true,
                func: async () => {
                    setConfirmDisabled(true);
                    await confirm(basketItem);
                },
                testIdPrefix: 'confirm-removal',
                title: 'Confirm',
                disabled: confirmDisabled
            }
        ]}
      >
        <p>{props.message}</p>
      </KirbyDialog>
    </div> 
  );
};

export default DeleteOrderDialog;