import { ENTITIES_ENDPOINT, SHAREHOLDERS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { ShareholderDto } from "common-types/shareholderDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchShareholders";

export const fetchShareholders = async(request: EntityPageRequest): Promise<PagedListDto<ShareholderDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${SHAREHOLDERS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchShareholders = (): void => tryCancelPending(signalKey);
