import { ServiceRequestItemDto } from "common-types/serviceItemDto"
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { OrderDto, buildEmptyBasket } from "common-types/serviceRequests/orderDto";
import { OrderAction } from "./ServiceRequestOrderReducer";


export type ServiceRequestContextType = {
  serviceRequestItems: ServiceRequestItemDto[] | undefined;
  order: OrderDto;
  addToBasket: (item: BasketItemDto) => void;
  updateQuantity: (item: BasketItemDto, quantity: number, persist: boolean) => void;
  setOrder: React.Dispatch<OrderAction>;
  setUpdatedBasketItem: (item: BasketItemDto | undefined) => void;
  updatedBasketItem: BasketItemDto | undefined;
  deleteOrder: () => void;
}

const DefaultServiceRequestContext: ServiceRequestContextType = {
  serviceRequestItems: undefined,
  order: buildEmptyBasket(),
  addToBasket: (item: BasketItemDto): void => { },
  updateQuantity: (item: BasketItemDto, quantity: number, persist: boolean) => { },
  setOrder: (orderAction: OrderAction) => {},
  setUpdatedBasketItem: (item: BasketItemDto | undefined) => { },
  updatedBasketItem: undefined,
  deleteOrder: () => { },
};

export { DefaultServiceRequestContext };
