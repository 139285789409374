import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { invoiceActions, invoiceActionTypes } from "./invoice-actions";
import { fetchInvoices, filterSortInvoices } from "./invoice-api";
import { FilterAndSortAction } from "./types/invoice-actions-types";

export function* watchFetchInvoices(): SagaIterator {

    yield takeEvery(invoiceActionTypes.FETCH_INVOICES, function*(): any {
        try {
            const invoices = yield call(fetchInvoices);
    
            yield put(invoiceActions.fetchInvoicesSuccess(invoices));
        } catch (e: any) {
            if (e && e.message === 'canceled') {
                yield put(invoiceActions.fetchInvoicesCancelled());
                return;
            }
            yield put(invoiceActions.fetchInvoicesFailure(e));
        }
    });
}

export function* watchFilterAndSortInvoices(): SagaIterator {
    yield takeEvery(invoiceActionTypes.FILTER_AND_SORT, function*(action: FilterAndSortAction): any {
        try {
            const { invoiceRequest } = action;
            const invoices = yield call(filterSortInvoices, invoiceRequest);

            yield put(invoiceActions.fetchInvoicesSuccess(invoices));
        } catch (e: any) {
            if (e && e.message === 'canceled') {
                yield put(invoiceActions.fetchInvoicesCancelled());
                return;
            }
            yield put(invoiceActions.fetchInvoicesFailure(e));
        }
    });
}

export default function* invoiceSaga(): SagaIterator {
    yield all([
        fork(watchFetchInvoices),
        fork(watchFilterAndSortInvoices)
    ]);
}