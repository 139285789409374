import produce from "immer";
import { ShippingDetailsActionTypes } from "./shipping-details-actions";
import { FetchStatus } from "../../common-types/fetchStatusEnum";
import { ShippingDetailsState } from "./types/shipping-details-state";
import { FetchShippingDetailsAction, FetchShippingDetailsSuccessAction } from "./types/shipping-details-actions-types";

const initialState: ShippingDetailsState = {
  shippingDetailsDto: undefined,
  fetchShippingDetailsStatus: undefined,
  entityId: 0,
};

export default (
  state = initialState,
  action: FetchShippingDetailsAction & FetchShippingDetailsSuccessAction): ShippingDetailsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS: {
        
        const { entityId } = action;
        draft.entityId = entityId;
        draft.fetchShippingDetailsStatus = FetchStatus.InProgress;
        return;
      }
      case ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS_SUCCESS: {
        
        const { shippingDetailsDto } = action;
        draft.shippingDetailsDto = shippingDetailsDto;
        draft.fetchShippingDetailsStatus = shippingDetailsDto ? FetchStatus.Complete : FetchStatus.NoContent;
        return; 
      }
      case ShippingDetailsActionTypes.FETCH_SHIPPING_DETAILS_FAILURE: {
        
        draft.shippingDetailsDto = undefined;
        draft.fetchShippingDetailsStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
