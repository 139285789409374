import { useCallback, useEffect, useState } from "react";
import { KirbySection, KirbyTableServer } from "maples-kirby-react";
import { InvoiceComponentProps } from "modules/invoice/types/invoice-component-types";
import { defaultFilters } from "modules/invoice/invoice-reducer";
import styles from "./invoices.module.scss";
import { invoicesGridColumns } from "./invoicesGridColumns";
import { SortOrderEnum } from "api/interfaces/sortOrderEnum";
import { Filter } from "api/interfaces/filter";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { kirbyMsExcel } from "maples-kirby-icons";
import { exportInvoiceFile } from "modules/invoice/invoice-api";
import { AxiosResponse } from "axios";
import { browserDownloadResult } from "modules/download/fileDownload";
import { getFileName } from "modules/download/fileResult";
import { addToast, updateToast } from "components/common/Toaster";
import { numberWithCommas } from "helpers/formatting/number";
import { genericErrorMessage } from "helpers/apiCaller/constants";
import { cloneDeep } from "lodash";
import { useNavigate, useLocation } from "react-router";
import { buildApiFilters } from "./invoiceFilters";
import { Actions, hasAction } from '../../helpers/userActions';
import AccessDenied from '../Pages/Errors/accessDenied';
import { auditPageVisit } from "api/audit-api";
const hash = require('object-hash');

export interface FilterPanelResponse {
  clientNumber: string | undefined;
  entityName: string | undefined;
  invoiceDate: DateRange;
  status: string | undefined;
}

export interface DateRange {
  start: string | undefined;
  end: string | undefined;
}

const Invoices = (props: InvoiceComponentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [columns, setColumns] = useState<Array<any>>([]);
  const { invoices, fetchStatus, filters, currentPage, pageSize, sorting, activeFilterState, 
    filterAndSort, setCurrentPage, setPageSize, setSorting, setFilters, reset } = props;
  const [filtersReady, setFiltersReady] = useState(false);
  const [routeLoading, setRouteLoading] = useState(false);
  const [resetTableColumns, setResetTableColumns] = useState<Array<any>>([]);
  const [customActions, setCustomActions] = useState<any>()

  useEffect(() => {
    if (location.state?.reset) {
      navigate(".", { replace: true });
      setCurrentPage(1);
      setPageSize(invoices.pageSize);
      reset();
      setRouteLoading(true);
      setTimeout(() => {
        setRouteLoading(false);
        initTable();
      }, 100)
    }
  }, [location]);

  useEffect(() => {
    document.title = "Invoices";
    initTable();
    auditPageVisit("InvoicesPageView", "Navigate to Invoices");
  }, []);

  const initTable = () => {
    let tempCols = cloneDeep(invoicesGridColumns);
    if (tempCols.length > 0) {
      props.filters.forEach((filter) => {
        if (filter.fieldName === "InvoiceDate") {
          let invoiceDateCol = tempCols.findIndex(
            (col: any) => col.field === "invoiceDate"
          );
          if (invoiceDateCol >= 0) {
            if (filter.filterType === "dateMatch") {
              tempCols[invoiceDateCol].initialFilterValue = {
                type: "dateMatch",
                value: filter.value1
              }
            } else if (filter.filterType === "dateAfter") {
              tempCols[invoiceDateCol].initialFilterValue = {
                type: "dateAfter",
                value: filter.value1
              }
            } else if (filter.filterType === "dateRangeMatch") {
              tempCols[invoiceDateCol].initialFilterValue = {
                type: "dateRangeMatch",
                value: { start: filter.value1, end: filter.value2 }
              }
            }
          }
        } else if (filter.fieldName === 'statuscode' && filter.value1 === 'O') {
          let filterCol = tempCols.findIndex(
            (col: any) => col.field === 'status'
          );
          if (filterCol >= 0)
            tempCols[filterCol].initialFilterValue = "Outstanding";
        } else {
          let filterCol = tempCols.findIndex(
            (col: any) => col.field === filter.fieldName.charAt(0).toLowerCase() + filter.fieldName.slice(1)
          );
          if (filterCol >= 0) {
            tempCols[filterCol].initialFilterValue = filter.value1;
          }
        }
      });
    }
    setColumns(tempCols);
    setFiltersReady(true);

    const filterAndSortRequest = {
      filters: filters,
      sorting: sorting,
      paging: {
        pageNumber: currentPage,
        pageSize
      },
    };

    if (hash(filterAndSortRequest) === activeFilterState) return;
    filterAndSort(filterAndSortRequest);
  }

  const handleDataChange = useCallback(
    (tableState: any) => {

      if (tableState === undefined)
        return;

      let sorting = {
        fieldName: tableState.newSort[0]?.field || "InvoiceDate",
        sortOrder:
          tableState.newSort[0]?.dir === "asc"
            ? SortOrderEnum.ASC
            : SortOrderEnum.DESC,
      };
      setSorting(sorting);
      let paging = {
        pageNumber: tableState?.newPageIndex,
        pageSize: tableState?.newPageSize,
      };

      setCurrentPage(paging.pageNumber);
      setPageSize(paging.pageSize);

      let filterResponses: Filter[] = buildApiFilters(tableState.filters);
      setFilters(filterResponses);

      const filterAndSortRequest = {
        filters: filterResponses,
        sorting: sorting,
        paging: {
          pageNumber: paging.pageNumber,
          pageSize: paging.pageSize
        },
      };

      filterAndSort(filterAndSortRequest);
    },
    [setSorting, setCurrentPage, setPageSize, setFilters]
  );

  const invoiceExport = useCallback(async () => {
    if (downloadInProgress)
      return;

    let toastId = await addToast("Exporting Invoices...", "", "spinner");
    setDownloadInProgress(true);

    const request = {
      filters: props.filters,
      sorting: sorting,
      paging: {
        pageNumber: currentPage,
        pageSize
      },
    };
    const handler = (axiosResponse: AxiosResponse<any, any>) => {
      browserDownloadResult(
        new Blob([axiosResponse.data]),
        getFileName(axiosResponse.headers)
      );
    };

    let result = await exportInvoiceFile(request, handler);

    if (result.success) {
      updateToast(toastId, "Export Complete!", "", "success");
    } else {
      const errorMessage = result.errors ? result.errors[0] : genericErrorMessage;
      updateToast(toastId, "Export Failed.", errorMessage, "error");
    }

    setDownloadInProgress(false);
  }, [downloadInProgress, setDownloadInProgress, currentPage, pageSize, sorting, props]);

  const exportLimit = 1000000;
  const exportLimitExceeded = invoices.totalCount > exportLimit;
  const defaultExportTitle: string = `Export to Excel`;
  const exportTitle = exportLimitExceeded
    ? `${defaultExportTitle} Max ${numberWithCommas(exportLimit)} invoices`
    : defaultExportTitle;
  const exportLabel = exportLimitExceeded
    ? `Export Limit Exceeded (${numberWithCommas(exportLimit)} invoices)`
    : defaultExportTitle;

  useEffect(() => {
    let tempCols = cloneDeep(invoicesGridColumns);
    if (tempCols.length > 0) {
      defaultFilters.forEach((filter) => {
        if (filter.fieldName === "InvoiceDate") {
          let invoiceDateCol = tempCols.findIndex(
            (col: any) => col.field === "invoiceDate"
          );
          if (invoiceDateCol >= 0) {
            tempCols[invoiceDateCol].initialFilterValue = {
              type: "dateRangeMatch",
              value: {
                start: filter.value1,
                end: filter.value2,
              }
            };

          }
        }
      });
    }
    setResetTableColumns(tempCols);
  }, [])

  useEffect(() => {
    setCustomActions([
      {
        disabled: invoices.totalCount === 0 || exportLimitExceeded || downloadInProgress,
        func: invoiceExport,
        icon: {
          icon: kirbyMsExcel
        },
        label: exportLabel,
        placement: "toolbar",
        title: exportTitle
      }
    ])
  }, [invoices, downloadInProgress])

  return (
    <>
      {hasAction(Actions.ViewEntity) ? (
        <div className={"portalForm"}>
          {invoices && filtersReady && !routeLoading === true ? (
            <div className={styles.invoiceContainer}>
              <KirbySection sectionTitle="Invoices">
                <div className={styles.content}>
                  <KirbyTableServer
                    columns={columns}
                    data={cloneDeep(invoices?.page) ?? []}
                    handleDataChange={handleDataChange}
                    defaultPageSize={invoices.pageSize}
                    pageIndex={invoices.currentPage}
                    rowCount={invoices.totalCount}
                    pageSizeOptions={[20, 50, 100]}
                    loading={{
                      active: fetchStatus === FetchStatus.InProgress,
                      text: "We are working on returning your results. Please note that broader queries will take longer to run",
                    }}
                    emptyMessage="No invoices match your selection criteria"
                    tablePart="invoiceTable"
                    widthBreakpoints={{
                      responsiveWidth: 60,
                    }}
                    resetTableColumns={resetTableColumns}
                    batchFilter
                    headerSortable={false}
                    customActions={customActions}
                    showManageColumns
                    tableStore={{
                      name: "PhoenixInvoices",
                      version: 1
                    }}
                  />
                </div>
              </KirbySection>
            </div>
          ) : (
            <span>Loading...</span>
          )}
        </div>) : <AccessDenied></AccessDenied>
      }
    </>);
};

export default Invoices;
