import { createSelector } from "reselect";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { Selector } from "react-redux";
import { AppState } from "store/AppState";
import { AuthState } from "./auth-state";

const authStateSelector = (state: AppState): AuthState => state.auth;

export const getAuthenticatedUser = createSelector(
  authStateSelector,
  authState => {
    return authState.user;
  }
);

export const getAllowedActions = createSelector(
  authStateSelector,
  authState => {
    if (!authState?.user?.allowedActions)
      return [];

    return authState.user.allowedActions;
  }
);

export const getAuthenticatedUserFetchError: Selector<AppState, boolean> = createSelector(
  authStateSelector,
  authState => { return authState.authenticatedUserFetchStatus === FetchStatus.NotFound || authState.authenticatedUserFetchStatus === FetchStatus.Error }
);

export const getAuthenticatedUserFetchInProgress: Selector<AppState, boolean> = createSelector(
  authStateSelector,
  authState => { return authState.authenticatedUserFetchStatus === FetchStatus.InProgress }
);

export const getAuthenticatedUserFetchStatus: Selector<AppState, FetchStatus> = createSelector(
  authStateSelector,
  authState => { return authState.authenticatedUserFetchStatus }
);

export const getIsAuthenticatedFetchError: Selector<AppState, boolean> = createSelector(
  authStateSelector,
  authState => { return authState.isAuthenticatedFetchStatus === FetchStatus.NotFound || authState.isAuthenticatedFetchStatus === FetchStatus.Error }
);

export const getIsAuthenticatedFetchInProgress: Selector<AppState, boolean> = createSelector(
  authStateSelector,
  authState => { return authState.isAuthenticatedFetchStatus === FetchStatus.InProgress }
);

export const getIsAuthenticatedFetchStatus: Selector<AppState, FetchStatus> = createSelector(
  authStateSelector,
  authState => { return authState.isAuthenticatedFetchStatus }
);

export const getIsAuthenticated = createSelector(
  authStateSelector,
  authState => authState.isAuthenticated
);
