import { useCallback, useEffect, useState } from "react";
import { DocumentDto } from "common-types/documentDto";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import styles from "./FolderContent.module.scss";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import * as DocManagementApiModule from "api/doc-management-api";
import { useParams } from "react-router";
import { KirbySpinner, KirbyUpload, KirbyButton} from "maples-kirby-react";
import DocManagementActionPanel from "./Panel/DocManagementActionPanel";
import { ContentItem } from "./ContentItem";
import DeleteDocumentsDialog from "./Delete/DeleteDocumentsDialog";
import { useGlobalContext } from "./DocManagement";
import { Copy } from "constants/copy/document-management/copy";
import { deleteDocuments,  uploadDocuments} from "./Actions/doc-management-actions";
import RenameFolderDialog from "./Rename/RenameFolderDialog";
import DocActionApi from "helpers/userActions/documentActions";
import { downloadCompleteMessage, downloadMessage, entityDocsFileName} from "./Common/download";
import { addToast } from "components/common/Toaster";
import { ToTreeItem } from "common-types/converters/iTreeItemConverters";
import RequireAction from "components/common/RequireActionContainer";
import Breadcrumb, { getPath } from "./Common/Breadcrumb";
import ToggleViewButton from "./ToggleViewButton";
import localForage from "localforage";
import { copyDocuments } from "./Actions/doc-management-copy-actions";
import { kirbyStructureChart } from "maples-kirby-icons";
import { onCopyCut, onPaste, isPasteValid } from "./Copy/CopyFunctions";
import OverwriteFileDialog from "./Actions/Overwrite/OverwriteFileDialog";

export interface FolderContentProps {
  selectedFolder: Partial<DocumentFolderDto> | undefined;
  docClipboard: Array<TreeItem>;
  setDocClipboard: Function;
  clipboardCut: boolean;
  setClipboardCut: Function;
  setMobileOpen?: Function;
}
export default function FolderContent(props: FolderContentProps) {
  const {
    selectedFolder,
    docClipboard,
    setDocClipboard,
    clipboardCut,
    setClipboardCut,
    setMobileOpen,
  } = props;

  const { id } = useParams();
  const [documents, setDocuments] = useState<Array<TreeItem>>([]);
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.InProgress);
  const entityId = id ? parseInt(id) : 0;
  const [deleteDocumentsDialogOpen, setDeleteDocumentsDialogOpen] = useState<boolean>(false);
  const [renameDocumentDialogOpen, setRenameDocumentDialogOpen] = useState<boolean>(false);
  const [gridView, setGridView] = useState<boolean>(false);
  const [renameSelectedDocument, setRenameSelectedDocument] = useState<DocumentDto | undefined>(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const [confirmPasteOverwriteDialogOpen, setConfirmPasteOverwriteDialogOpen] = useState<boolean>(false);
  const [pasteInProgress, setPasteInProgress] = useState<boolean>(false);
  const [layout, setLayout] = useState<string>("full");
  const [overwriteFileDialogOpen, setOverwriteFileDialogOpen] = useState<boolean>(false);
  const [handleConfirmOverwrite, setHandleConfirmOverwrite] = useState<((confirmed: boolean) => (confirmed: boolean) => void)>();
  const [docsToOverwrite, setDocsToOverwrite] = useState<Array<TreeItem>>([]);
  let ctrlKeyDown = false;

  const {
    setFolderDeletedKey,
    folderAdded,
    downloadDocuments,
    setFolderRenamed,
    entityFolders,
  } = useGlobalContext();

  const store = localForage.createInstance({
    name: "docStoreStates",
  });

  const updateLocalStore = useCallback(
    (isGrid: boolean) => {
      const docStore = { viewMode: isGrid ? "Grid" : "List" };
      store.setItem("view", docStore).catch(function (err: any) {
        throw new Error("Could not get local storage for entityTypes" + err);
      });
    },
    [store]
  );

  const getFiles = useCallback(
    async (
      folder: Partial<DocumentFolderDto> | undefined
    ): Promise<Array<TreeItem>> => {
      let folderId = folder?.id;

      const children = await DocManagementApiModule.fetchDocs(
        entityId,
        folderId
      ).then((response) => {
        return !response ? [] : response;
      });

      let files = children.map((doc: DocumentDto) => {
        let treeItem: TreeItem = {
          key: doc.id,
          label: doc.isFolder ? doc.displayName : doc.displayName + "." + doc.fileType,
          hasChildren: false,
          type: doc.isFolder ? "folder" : "document",
          value: doc,
        };
        return treeItem;
      });

      return files || [];
    },
    [entityId]
  );

  const getData = useCallback(async () => {
    const result = await getFiles(selectedFolder);
    setDocuments(result);
    setFetchStatus(FetchStatus.Complete);
  }, [selectedFolder, getFiles]);

  const handleFileOverwriteConfirmation = useCallback(async (files: File[], parentFolderId: string): Promise<boolean> => {
    const folderDto = new DocumentFolderDto();
    folderDto.id = parentFolderId;
    const currentFiles = await getFiles(folderDto);

    const replaceDocs = currentFiles.filter(existingFile =>
      files.some(file => file.name.toLowerCase() === existingFile.label.toLowerCase()));

    if (replaceDocs.length > 0) {

      return new Promise<boolean>((resolve) => {
        setDocsToOverwrite(replaceDocs);
        setOverwriteFileDialogOpen(true);

        const handleconfirmation = (confirmed: boolean): void => {
          setOverwriteFileDialogOpen(false);
          resolve(confirmed);
        };

        setHandleConfirmOverwrite(() => handleconfirmation);

      });
    } else {
      return true;
    }

  }, [handleConfirmOverwrite, docsToOverwrite, overwriteFileDialogOpen, getFiles, pasteInProgress]);

  const handlePasteOverwrites = useCallback(async (parentFolderId: string, files: TreeItem[]) => {

    setPasteInProgress(true);

    const folderDto = new DocumentFolderDto();
    folderDto.id = parentFolderId;
    const currentFiles = await getFiles(folderDto);

    const replaceDocs = currentFiles.filter(existingFile =>
      files.some(file => {
        const existingFileLabel = existingFile.label.toLowerCase();
        const newFileLabel = file.label.toLowerCase();

        return existingFileLabel == newFileLabel;
      }));

    const overwriteDocsCount = replaceDocs.length;

    setDocsToOverwrite(replaceDocs);

    if (overwriteDocsCount < 1) {

        await onPasteItems();        
        return;      
    }

    setConfirmPasteOverwriteDialogOpen(true);

  }, [confirmPasteOverwriteDialogOpen, docsToOverwrite, docClipboard, selectedFolder, pasteInProgress]);

  useEffect(() => {
    if (!selectedFolder) return;

    setFetchStatus(FetchStatus.InProgress);
    getData();
  }, [entityId, selectedFolder, folderAdded, getData]);

  const toggleAllSelected = useCallback(
    (selected: boolean) => {
      const newState = documents.map((obj) => {
        return { ...obj, selected: selected };
      });

      setDocuments(newState);
    },
    [documents, setDocuments]
  );

  const copySelectedDocument = useCallback(
    async (document: DocumentDto): Promise<any> => {
      onCopyCut(
        [document],
        [selectedFolder?.displayName || "unknown"],
        setDocClipboard,
        setClipboardCut,
        false
      );
    },
    [selectedFolder, setDocClipboard, setClipboardCut]
  );

  const toggleSelected = useCallback(
    (doc: TreeItem) => {
      const newState = documents.map((obj) => {
        if (obj.key === doc.key) {
          return { ...obj, selected: !doc.selected };
        }
        return obj;
      });

      setDocuments(JSON.parse(JSON.stringify(newState)));
    },
    [setDocuments, documents]
  );

  const onDeleteClicked = useCallback(async (): Promise<any> => {
    setSelectedDocuments(
      documents
        .filter((a) => a.selected)
        .map((doc) => {
          return { label: doc.label, key: doc.key };
        })
    );
    setDeleteDocumentsDialogOpen(true);
  }, [setSelectedDocuments, setDeleteDocumentsDialogOpen, documents]);

  const deleteDocument = useCallback(
    (item: any): void => {
      setSelectedDocuments(documents.filter((a) => a.key === item.key));
      setDeleteDocumentsDialogOpen(true);
    },
    [setSelectedDocuments, setDeleteDocumentsDialogOpen, documents]
  );

  const onDownloadClicked = useCallback(() => {
    let downloadTargets = documents.filter((a) => a.selected);
    let downloadOptions = {
      forceZip: downloadTargets[0].value.isFolder,
      fileName: entityDocsFileName(),
      toastMessage: downloadMessage(downloadTargets),
      toastCompleteMessage: downloadCompleteMessage(downloadTargets),
    };
    downloadDocuments(
      downloadTargets.map((a) => a.value),
      downloadOptions
    );
  }, [documents, downloadDocuments]);

  const removeDocumentFromView = useCallback(
    (key: string) => {
      const docFound: TreeItem | undefined = documents.find(
        (k) => k.key === key
      );
      if (docFound !== undefined) {
        const index = documents.indexOf(docFound, 0);
        documents.splice(index, 1);
        const newData: TreeItem[] = [];
        documents.forEach((doc) => {
          newData.push(Object.assign({}, doc));
        });
        setDocuments(newData);
        if (docFound.type === "folder") {
          setFolderDeletedKey(key);
        }
      }
    },
    [documents, setDocuments, setFolderDeletedKey]
  );

  const onDocumentDeleted = useCallback(
    (key: string) => {
      removeDocumentFromView(key);
    },
    [removeDocumentFromView]
  );

  const confirmDeleteFunction = useCallback(() => {
    setDeleteDocumentsDialogOpen(false);
    const copyDocs: any[] = [];
    selectedDocuments.forEach((doc) => {
      copyDocs.push(Object.assign({}, doc));
    });

    deleteDocuments(entityId, copyDocs, onDocumentDeleted);
  }, [
    setDeleteDocumentsDialogOpen,
    selectedDocuments,
    entityId,
    onDocumentDeleted,
  ]);

  const onDocumentNotFound = useCallback(
    (key: string) => {
      addToast(
        Copy.RenameDocumentToastTitle,
        Copy.ItemNotFoundMessage,
        "error"
      );
      removeDocumentFromView(key);
    },
    [removeDocumentFromView]
  );

  const renameDocument = useCallback(
    (item: DocumentDto | undefined) => {
      setRenameSelectedDocument(item);
      setRenameDocumentDialogOpen(true);
    },
    [setRenameSelectedDocument, setRenameDocumentDialogOpen]
  );

  function sortTreeItems(unsorted: TreeItem[]) {
    return [...unsorted]
      .sort((a: TreeItem, b: TreeItem) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      })
      .map((x: TreeItem) => x);
  }

  const renameDialogHandleClose = useCallback(() => {
    setRenameDocumentDialogOpen(false);
  }, [setRenameDocumentDialogOpen]);

  const deleteDialogHandleClose = useCallback(() => {
    setDeleteDocumentsDialogOpen(false);
  }, [setDeleteDocumentsDialogOpen]);

  const onDocumentRenamed = useCallback(
    (key: string, document: DocumentDto) => {
      const docFound: TreeItem | undefined = documents.find(
        (k) => k.key === key
      );
      if (docFound) {
        let newData: TreeItem[] = [];
        documents.forEach((doc) => {
          if (doc.key === key) {
            doc = ToTreeItem(document);
          }
          newData.push(Object.assign({}, doc));
        });

        newData = sortTreeItems(newData);
        setDocuments(newData);
      }
      setFolderRenamed({ key, document });
      addToast(
        "Rename folder/document",
        "Selected item was renamed",
        "success"
      );
    },
    [documents, setDocuments, setFolderRenamed]
  );

  const confirmPasteOverwriteDialogHandleClose = useCallback(() => {
    setConfirmPasteOverwriteDialogOpen(false);
  }, []);

  const confirmPasteOverwriteDialogHandleCancel = useCallback(() => {
    setPasteInProgress(false);
    setConfirmPasteOverwriteDialogOpen(false);
  }, []);

  const content = (files: Array<TreeItem>) => {
    if (!selectedFolder || selectedFolder.id === "root")
      return <span>Please select a folder</span>;

    if (fetchStatus === FetchStatus.InProgress) {
      return (
        <div className={styles.spinner}>
          <KirbySpinner size="medium" data-testid="folder-content-spinner" />
        </div>
      );
    }

    if (!files?.length)
      return (
        <>
          <span>There are no documents to display</span>
        </>
      );

    return contentFiles(files);
  };

  const contentFiles = (files: Array<TreeItem>) => {
    let folderItems = files.filter((f) => f.type === "folder");
    let fileItems = files.filter((f) => f.type === "document");

    const contentItem = (treeItem: TreeItem) => {
      return (
        <ContentItem
          key={treeItem.key}
          file={treeItem}
          toggleSelected={toggleSelected}
          deleteDocument={deleteDocument}
          renameDocument={renameDocument}
          copyDocument={copySelectedDocument}
          gridView={gridView}
        />
      );
    };

    return (
      <>
        {folderItems.length > 0 && (
          <div
            className={styles.contentTitle}
            data-testid="folder-content-folder">
            <h5>Folders</h5>
          </div>
        )}
        <div className={styles.folderList}>
          {folderItems.map((folder: TreeItem) => {
            return contentItem(folder);
          })}
        </div>
        {fileItems.length > 0 && (
          <div
            className={styles.contentTitle}
            data-testid="folder-content-files">
            <h5>Documents</h5>
          </div>
        )}
        <div className={styles.itemList}>
          {fileItems.map((file: TreeItem) => {
            return contentItem(file);
          })}
        </div>
      </>
    );
  };

  const toggleViewMode = useCallback(
    (isGrid: boolean) => {
      updateLocalStore(isGrid);
      setGridView(isGrid);
    },
    [updateLocalStore, setGridView]
  );

  const getLocalStore = useCallback(async () => {
    try {
      const viewMode: any = await store.getItem("view");
      setGridView(viewMode?.viewMode === "Grid");
    } catch (err) {
      throw new Error("Could not get local storage for entityTypes" + err);
    }
  }, [store]);

  useEffect(() => {
    getLocalStore();
  }, [getLocalStore]);

  useEffect(() => {
    window.document.addEventListener("keydown", keyDownHandler);
    window.document.addEventListener("keyup", keyUpHandler);
    return () => {
      window.document.removeEventListener("keydown", keyDownHandler);
      window.document.removeEventListener("keyup", keyUpHandler);
    };
  });

  const keyDownHandler = async (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    // We only get one key at a time, so track if the ctrl key is down
    if (e.key === "Control") {
      ctrlKeyDown = true;
    }

    // If there's a selection and Ctrl + c is pressed
    if (
      documents.filter((doc) => doc.selected).length > 0 &&
      ctrlKeyDown &&
      (e.key === "c" || e.key === "x")
    ) {
      onCopyCut(
        documents.filter((doc) => doc.selected),
        [selectedFolder?.displayName ?? "unknown"],
        setDocClipboard,
        setClipboardCut,
        e.key === "x"
      );
    }

    // If Ctrl + v is pressed
    if (ctrlKeyDown && e.key === "v") {

      if(document.activeElement?.nodeName === "KIRBY-SEARCH-BOX" || pasteInProgress) {
        // User has selected the search box. Do not attempt to paste any copied documents.
        return;
      }

      onPaste(
        selectedFolder,
        entityFolders,
        getPath,
        docClipboard,
        handlePasteOverwrites
      );
    
    }

    // If Del is pressed
    if (
      documents.filter((doc) => doc.selected).length > 0 &&
      documents
        .filter((a) => a.selected)
        .every((x) =>
          DocActionApi.hasDeleteAction(x.value.source, x.value.documentType)
        ) &&
      e.key === "Delete"
    ) {
      onDeleteClicked();
    }
  };

  const keyUpHandler = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === "Control") ctrlKeyDown = false;
  };

  const onPasteItems = async () => {
    
      setConfirmPasteOverwriteDialogOpen(false);
      let copyResults = await copyDocuments(
        entityId,
        docClipboard.map((doc: TreeItem) => {
          return doc.value;
        }),
        selectedFolder as DocumentFolderDto,
        clipboardCut
      );
      let successfulResults: Array<TreeItem> = [];
      if (Array.isArray(copyResults)) {
        copyResults.forEach((result, index) => {
          if (result.isSuccess) {
            successfulResults.push(docClipboard[index]);
          }
        });
      } else {
        successfulResults.push(docClipboard[0]);
      }
      setFetchStatus(FetchStatus.InProgress);
      getData();
      if (
        !copyResults ||
        (Array.isArray(copyResults) && successfulResults.length <= 0) ||
        (!Array.isArray(copyResults) && copyResults.success !== true)
      )
        return;
      if (clipboardCut) {
        deleteDocuments(entityId, successfulResults, () => { }, true);
        setClipboardCut(false);
        setDocClipboard([]);
      }
      setPasteInProgress(false);
  };

  const copyClick = useCallback(() => {
    onCopyCut(
      documents.filter((doc) => doc.selected),
      [selectedFolder?.displayName ?? "unknown"],
      setDocClipboard,
      setClipboardCut,
      false
    );
  }, [
    documents,
    selectedFolder,
    setDocClipboard,
    setClipboardCut,
  ]);

  const cutClick = useCallback(() => {
    onCopyCut(
      documents.filter((doc) => doc.selected),
      [selectedFolder?.displayName ?? "unknown"],
      setDocClipboard,
      setClipboardCut,
      true
    );
  }, [
    documents,
    selectedFolder,
    setDocClipboard,
    setClipboardCut,
  ]);

  const pasteClick = useCallback(() => {

    if(pasteInProgress) { 
      return;
    }

    onPaste(
      selectedFolder,
      entityFolders,
      getPath,
      docClipboard,
      handlePasteOverwrites
    );

  }, [selectedFolder, entityFolders, docClipboard, pasteInProgress]);

  const uploadHandleFileChange = useCallback(
    async (files: File[]) => {
      uploadDocuments(
        entityId,
        selectedFolder?.id as string,
        files,
        async () => await getData(),
        handleFileOverwriteConfirmation
      );
    },
    [entityId, selectedFolder, getData]
  );

  const handleConfirmOverwriteClick = useCallback((confirmed: boolean) => {
    if (handleConfirmOverwrite) {
      handleConfirmOverwrite(confirmed);
    }
  }, [handleConfirmOverwrite]);

  const handleCancelOverwriteClick = useCallback(() => {
    setOverwriteFileDialogOpen(false);  
  }, []);

  useEffect(() => {
    function updateLayout() {
      setLayout(window.innerWidth > 599 ? "full" : "mobile");
    }
    window.addEventListener("resize", updateLayout);
    updateLayout();
    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  return (
    <>
      <div
        className={`${styles.folderContent} ${gridView ? styles.gridview : styles.listview
          }`}
        data-testid="folder-content">
        <div className={`${styles.sectionTitle} sectiontitle`}>
          <h4>
            {selectedFolder ? selectedFolder?.displayName : "Folder Content"}
          </h4>
          {layout === "full" && (
            <ToggleViewButton
              gridView={gridView}
              setGridView={toggleViewMode}
            />
          )}
        </div>
        <Breadcrumb selectedFolder={selectedFolder} />
        <div className={`${styles.toolbar} toolbar`}>
          {layout === "mobile" && (
            <KirbyButton
              icon={kirbyStructureChart}
              onClick={() => !!setMobileOpen && setMobileOpen(true)}>
              All Folders
            </KirbyButton>
          )}
          <DocManagementActionPanel
            showButtons={documents.length > 0}
            selectAll={toggleAllSelected}
            deleteDisabled={
              documents.filter((doc) => doc.selected).length === 0 ||
              !documents
                .filter((a) => a.selected)
                .every((x) =>
                  DocActionApi.hasDeleteAction(
                    x.value.source,
                    x.value.documentType
                  )
                )
            }
            downloadDisabled={
              documents.filter((doc) => doc.selected).length === 0
            }
            copyDisabled={documents.filter((doc) => doc.selected).length === 0}
            pasteDisabled={
              isPasteValid(
                selectedFolder && entityFolders
                  ? getPath(selectedFolder, entityFolders)?.map(
                    (parent) => parent.id
                  )
                  : [],
                docClipboard,
                selectedFolder
              ) !== true
            }
            selectAllDisabled={documents.every((x) => x.selected)}
            clearAllDisabled={
              documents.filter((doc) => doc.selected).length === 0
            }
            deleteClick={onDeleteClicked}
            downloadClick={onDownloadClicked}
            copyClick={copyClick}
            cutClick={cutClick}
            pasteClick={pasteClick}
            menuMode={layout === "mobile"}
          />
          {layout === "mobile" && (
            <ToggleViewButton
              gridView={gridView}
              setGridView={toggleViewMode}
            />
          )}
        </div>
        <div data-testid="document-upload">
          <RequireAction
            actionName={`Document.${selectedFolder?.source}.${selectedFolder?.documentType}.Create`}>
            <KirbyUpload
              dropZoneText={{
                title: "Document Upload",
                subTitle: "Drag document(s) here to upload",
              }}
              handleChange={uploadHandleFileChange}
              accept=".xlsx,.xls,.pdf,.jpg,.jpeg,.pdf,.doc,.docx,.csv,.txt,.png,.ppt,.pptx"
            />
          </RequireAction>
        </div>
        {content(documents)}
      </div>
      <DeleteDocumentsDialog
        open={deleteDocumentsDialogOpen}
        confirmFunction={confirmDeleteFunction}
        handleClose={deleteDialogHandleClose}
        message={Copy.DeleteConfirmMessage}
      />
      <OverwriteFileDialog
        open={overwriteFileDialogOpen}
        confirm={handleConfirmOverwriteClick}
        handleClose={handleCancelOverwriteClick}
        handleCancel={handleCancelOverwriteClick}
        files={docsToOverwrite}
      />
      <OverwriteFileDialog
        open={confirmPasteOverwriteDialogOpen}
        confirm={onPasteItems}
        handleClose={confirmPasteOverwriteDialogHandleClose}
        handleCancel={confirmPasteOverwriteDialogHandleCancel}
        files={docsToOverwrite}
      />
      <RenameFolderDialog
        open={renameDocumentDialogOpen}
        document={renameSelectedDocument}
        documentUpdated={onDocumentRenamed}
        handleNotFound={onDocumentNotFound}
        handleClose={renameDialogHandleClose}
      />
    </>
  );
}
