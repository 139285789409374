import { RELEASES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";

export const fetchReleases = async (): Promise<Array<ReleaseDto>> =>
  ApiCaller.get(`${RELEASES_ENDPOINT}`);

export class ReleaseDto {
  versionNumber: string;
  releaseDate: Date;
  title: string;
  description: string;
  selected?: boolean;
}
