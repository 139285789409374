import { RegisterPropertyDto } from "common-types/registerPropertiesDto";
import { ENTITIES_ENDPOINT, REGISTERPROPERTIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchRegisterProperties";

export const fetchRegisterProperties = async (
  entityId: number
): Promise<RegisterPropertyDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${REGISTERPROPERTIES_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchRegisterProperties = (): void => tryCancelPending(signalKey);
