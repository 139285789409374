import { DateCell } from "helpers/gridCells";

export const amlOfficerGridColumns: Array<any> = [
  {
    title: "Contact",
    field: "amlOfficerName",
  },
  {
    title: "Officer Designation",
    field: "amlOfficerType",
  },
  {
    title: "Date CIMA Notified",
    field: "dateCimaNotified",
    formatter: DateCell,
  },
];
