import { getEntity, getEntityDetail } from "modules/entity/entity-selectors";
import { EntityDetailComponentProps, EntityDetailsOwnProps } from "modules/entity/types/component-types";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import EntityDetail from "./EntityDetail";

const mapStateToProps = (
  state: AppState,
  ownProps: EntityDetailsOwnProps
): EntityDetailComponentProps => ({
  entityDetail: getEntityDetail(state),
  entity: getEntity(state),
  closeDetails: ownProps.closeDetails
});

export default connect(mapStateToProps)(EntityDetail);
