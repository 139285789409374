import { EntityPageRequest } from "api/interfaces/entityPageRequest";
import { Action } from "typesafe-actions";

export default interface ServerTableContainerProps<Type> {
  list: Type[];
  id: number;
  currentPageInfo: PageInfo;
  header?: string;
  title?: string;
  emptyMessage?: string;
  columns: any;
  loading?: boolean;
  collapsed: boolean;
  handleOpenToggle: Function;
  updateLoadingStatus: Function;
  handleTableChange: Function;
  pageSize:number;
  responsiveWidth: number;
  rowFormatter: Function | undefined;
}

export interface ServerTableContainerDispatchProps {
  fetch: (request: EntityPageRequest) => Action;
  cancel?: () => Action;
}

export interface PageInfo {
  totalPages: number;
  pageSize: number;
  pageNumber: number;
  totalCount: number;
}

export const DefaultPageInfo: PageInfo = {
  totalPages: 0,
  pageSize: 10,
  pageNumber: 1,
  totalCount:0
}

export interface ServerTableContainerOwnProps {
  collapsed: boolean;
  handleOpenToggle: Function;
  updateLoadingStatus: Function;
  pageSize: number;
  handleTableChange: Function;
}

export interface TableDataChangeResponse{
  filters:any,
  newPageIndex: number;
  newPageSize:number;
  newSort:any;
}
