import { ENTITIES_ENDPOINT, VESSELS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { VesselDto } from "common-types/vesselDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchVessels";

export const fetchVessels = async(request: EntityPageRequest): Promise<PagedListDto<VesselDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${VESSELS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchVessels = (): void => tryCancelPending(signalKey);


