import { connect } from "react-redux";
import { AppState } from "store/AppState";
import Partnerships from "./Partnerships";
import { getEntity } from "modules/entity/entity-selectors";
import { PartnershipDto } from "common-types";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import PagedListSelectors from "modules/common/paged-list/paged-list-selectors";
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import PagedListKeys from "constants/pagedListKeys";
import headerText from "constants/copy/registers/headerText";
import { gridColumns } from "./PartnershipsGridColumns";
import {
  ServerTableContainerProps,
  ServerTableContainerOwnProps,
} from "components/common/types";

const stateSelector = (
  state: AppState
): GenericPagedListState<PartnershipDto> => state.partnerships;

const getPartnerships = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo =
  PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);
const columns: Array<any> = gridColumns;

const mapStateToProps = (
  state: AppState,
  ownProps: ServerTableContainerOwnProps
): ServerTableContainerProps<PartnershipDto> & ServerTableContainerOwnProps => {
  return {
    list: getPartnerships(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state),
    header: headerText.Partnerships,
    columns: columns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    responsiveWidth: 21.875,
    rowFormatter: undefined
  };
};

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.PARTNERSHIPS);

export default connect(mapStateToProps, mapDispatchToProps)(Partnerships);
