import { ENTITIES_ENDPOINT, CIMAREGISTRATIONS_ENDPOINT } from "constants/urls";
import { CimaRegistrationDto } from "../../common-types";
import { ApiCaller } from "../../helpers";
import { getAbortSignalTryCancelPending, tryCancelPending } from "api/utils/requestAbortion";

const signalKey = "fetchCimaRegistrations";

export const fetchCimaRegistrations = async(entityId: number): Promise<CimaRegistrationDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${CIMAREGISTRATIONS_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchCimaRegistrations = (): void => tryCancelPending(signalKey);
