import { FetchStatus } from "common-types/fetchStatusEnum";
import produce from "immer";
import {
  FetchSearchResultsSuccessAction,
  ResetSearchFetchStatusAction,
  SetCurrentPageAction,
  SetPageSizeAction,
  SetSearchTermAction,
} from "../types/entities-actions-types";
import { SearchResultsState } from "../types/entities-state";
import { SearchResultActionTypes } from "./searchResults-actions";

const initialState: SearchResultsState = {
  searchResults: undefined,
  currentPage: 1,
  pageSize: 20,
  searchTerm: "",
  redirectedForSingleResult: false,
  fetchStatus: undefined,
  activeSearchKey: "",
};

export default (
  state = initialState,
  action: FetchSearchResultsSuccessAction &
    SetCurrentPageAction &
    SetPageSizeAction &
    SetSearchTermAction &
    ResetSearchFetchStatusAction
): SearchResultsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SearchResultActionTypes.FETCH_SEARCHRESULTS_SUCCESS: {
        const { searchResults } = action;
        draft.redirectedForSingleResult = false;
        draft.searchResults = searchResults;
        draft.fetchStatus = FetchStatus.Complete;
        draft.activeSearchKey = `${draft.currentPage}|${
          state.searchTerm ?? ""
        }|${draft.pageSize}`;
        return;
      }
      case SearchResultActionTypes.FETCH_SEARCHRESULTS_FAILURE: {
        draft.searchResults = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      case SearchResultActionTypes.SET_PAGE_NUMBER: {
        const { pageNumber } = action;
        draft.currentPage = pageNumber;
        return;
      }
      case SearchResultActionTypes.SET_PAGE_SIZE: {
        const { pageSize } = action;
        draft.pageSize = pageSize;
        return;
      }
      case SearchResultActionTypes.SET_SEARCH_TERM: {
        const { searchTerm } = action;
        draft.searchTerm = searchTerm;
        draft.currentPage = 1;
        return;
      }
      case SearchResultActionTypes.SET_REDIRECTED: {
        draft.redirectedForSingleResult = true;
        return;
      }
      case SearchResultActionTypes.FETCH_SEARCHRESULTS: {
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case SearchResultActionTypes.CLEAR_SEARCH_TERM: {
        draft.searchResults = undefined;
        draft.fetchStatus = undefined;
        draft.redirectedForSingleResult = false;
        draft.activeSearchKey = "";
        draft.searchTerm = "";
        return;
      }
      case SearchResultActionTypes.RESET_FETCHSTATUS: {
        draft.fetchStatus = undefined;
        draft.activeSearchKey = "";
        return;
      }
      default:
        return state;
    }
  });
