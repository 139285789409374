import { BoolCell, DateCell } from 'helpers/gridCells';

export const vesselsGridColumns: Array<any> = [
  {
    title: "Name of Vessel",
    field: "nameOfVessel"
  },
  {
    title: "Official Number",
    field: "officialNumber"
  },
  {
    title: "Type of Vessel",
    field: "typeOfVessel"
  },
  {
    title: "Radio Licence Date",
    field: "radioLicenceDate",
    formatter: DateCell
  },
  {
    title: "Radio Licence Expiry Date",
    field: "radioLicenceExpiryDate",
    formatter: DateCell
  },
  {
    title: "Call Sign",
    field: "callSign",
  },
  {
    title: "Call Sign Expiry Date",
    field: "callSignExpiryDate",
    formatter: DateCell
  },
  {
    title: "Tonnage of Vessel",
    field: "tonnageOfVessel"
  },
  {
    title: "Act as RO?",
    field: "actAsRo",
    formatter: BoolCell
  },
  {
    title: "Tonnage tax paid by client?",
    field: "tonnageTaxPaidByClient",
    formatter: BoolCell
  },
  {
    title: "Date of Closing",
    field: "dateOfClosing",
    formatter: DateCell
  }
];
