export class DocumentDto {
    id: string;
    source: string;
    parentIdentifier: string | undefined;
    name: string;
    displayName: string;
    description: string;
    documentType: string;
    fileType: string;
    isFolder: boolean;
    hasChildFolders: boolean;
    features: string[];
    created: string;
    modified: string;
    docs: DocumentDto[];
    parentId: any;
}

export const DocumentFeatures = {
  CanCreateSubFolder: "CanCreateSubFolder",
  CanDeleteFolderOrDocument: "CanDeleteFolderOrDocument"
}

export interface DocumentCopyResultDto {
  document?: DocumentDto;
  isSuccess: boolean;
  message?: string;
  statusCode: number;
}
