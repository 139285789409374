import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from "redux-saga";
import { UserActions, UserActionTypes } from './user-actions';
import { fetchUserSettings, saveUserSettings } from 'api/user-api';
import { UserSettingsDto } from 'common-types/user/userSettingsDto';
import { SaveSettingsAction } from './types/user-actions';
import { FormState } from 'common-types/forms/FormState';
import { ApiResponse } from 'helpers/apiCaller/ApiResponse';

export function* watchFetchSettings(): SagaIterator {
  yield takeEvery(UserActionTypes.FETCH_SETTINGS, function* () {
    try {
      let settings: UserSettingsDto = yield call(fetchUserSettings);
      yield put(UserActions.fetchSettingsSuccess(settings));
    }
    catch (e) {
      yield put(UserActions.fetchSettingsFailure(e));
    }
  })
}

export function* watchSaveSettings(): SagaIterator {
  yield takeEvery(UserActionTypes.SAVE_SETTINGS, function* (action: SaveSettingsAction) {
    try {
      yield put(UserActions.setFormState(FormState.Submitting));
      let apiResponse: ApiResponse<UserSettingsDto> =  yield call(saveUserSettings, action.settings);
      if (apiResponse.success) {
        yield put(UserActions.fetchSettingsSuccess(apiResponse.value!));
        yield put(UserActions.saveSettingsSuccess());
      } else {
        yield put(UserActions.saveSettingsFail( { error: apiResponse.errors }));
      }
    }
    catch (e) {
      yield put(UserActions.saveSettingsFail(e));
    }
  })
}

export default function* UserSaga(): SagaIterator {
  yield all([
    fork(watchFetchSettings),
    fork(watchSaveSettings)
  ]);
}
