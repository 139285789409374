import { FetchStatus } from "common-types/fetchStatusEnum";
import { withParams } from "components/common/hoc";
import {
  EntityComponentDispatchProps,
  EntityComponentProps,
} from "modules/entity/types/component-types";
import { Component } from "react";
import MediaQuery from "react-responsive";
import { Outlet } from "react-router-dom";
import ContentDeniedContainer from "../Errors/ContentDeniedContainer";
import { Page204 } from "../Errors/Page204";
import EntityHeader from "./EntityHeader/EntityHeader";
import EntityMobileHeader from "./EntityHeader/EntityMobileHeader";

export type EntityProps = EntityComponentProps & EntityComponentDispatchProps;

export type EntityWithUseParamsProps = {
  id: number;
};

export class Entity extends Component<EntityProps> {
  componentDidMount() {
    this.props.resetSearchFetchStatus();
    this.refreshEntity();
  }

  componentWillUnmount() {
    this.props.clearCurrentEntity();
  }

  componentDidUpdate(
    prevProps: Readonly<EntityProps & EntityWithUseParamsProps>
  ): void {
    if (prevProps.id !== (this.props as any as EntityWithUseParamsProps).id)
      this.refreshEntity();
  }

  refreshEntity() {
    const { fetchEntity, id, } = this.props as EntityProps & EntityWithUseParamsProps;
    fetchEntity(id || "");
    this.props.resetSearchFetchStatus();
  }

  render() {
    const { entity, fetchStatus } = this.props;

    switch (fetchStatus) {
      case FetchStatus.Complete:
      case FetchStatus.InProgress:
        return (
          <>
            <MediaQuery minWidth={600}>
              <EntityHeader entity={entity} />
            </MediaQuery>
            <MediaQuery maxWidth={599}>
              <EntityMobileHeader entity={entity} />
            </MediaQuery>
            <Outlet />
          </>
        );
      case FetchStatus.NoContent:
        return <ContentDeniedContainer />;
      default:
        return <Page204 />;
    }
  }
}

export default withParams(Entity);
