import React from 'react'
import { KirbyLoaderPage } from 'maples-kirby-react';
import { RequireTermsProps } from "modules/auth/auth-component-types";
import { Actions, hasAction } from 'helpers/userActions';
import TermsOfUse from 'components/Pages/TermsOfUse/TermsOfUse';
import { FetchStatus } from 'common-types/fetchStatusEnum';

export function RequireTerms(props: RequireTermsProps) {
  const { userFetchStatus, user, children } = props;

  if (userFetchStatus !== FetchStatus.Complete) {
    return <KirbyLoaderPage></KirbyLoaderPage>;
  }

  if (!user || hasAction(Actions.BypassTermsAndConditions)) {
    return <>{children}</>;
  }
  
  return (<TermsOfUse />);
}
