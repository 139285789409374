import { ENTITIES_ENDPOINT, CURRENTDIRECTORSOFFICERS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { DirectorOfficerDto } from "../common-types/directorOfficerDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey: string = "CurrentDirectorsAndOfficers";

export const fetchCurrentDirectorsAndOfficers = async (request: EntityPageRequest): Promise<PagedListDto<DirectorOfficerDto>> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${CURRENTDIRECTORSOFFICERS_ENDPOINT}${EntityPageRequestToQueryString(request)}`,
    { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelCurrentDirectorsAndOfficers = (): void => {
  tryCancelPending(signalKey);
}