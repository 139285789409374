import { orderActions } from "modules/order/order-actions";
import { getOrders, getOrdersFetchStatus } from "modules/order/order-selectors";
import { AppState } from "store/AppState";
import { connect } from "react-redux";
import OrderHistory from "./OrderHistory";
import { FetchOrderHistoryAction } from "modules/order/types/order-actions-types";

const mapStateToProps = (state: AppState) => ({
    orders: getOrders(state),
    fetchStatus: getOrdersFetchStatus(state),
});

const mapDispatchToProps = {
    fetchOrderHistory: (entityId: number): FetchOrderHistoryAction => orderActions.fetchOrderHistory(entityId),
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);