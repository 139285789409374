import { useEffect, useState } from "react";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { useGlobalContext } from "../DocManagement";
import { KirbyBreadcrumbs } from "maples-kirby-react";

export interface BreadcrumbProps {
  selectedFolder: Partial<DocumentFolderDto> | undefined;
}

export const getPath = (
  folder: Partial<DocumentFolderDto>,
  entityFolders: Array<DocumentFolderDto>,
  setTargetPath?: Function
) => {
  let path: DocumentFolderDto[] = [];

  const findNode = (id: string) => {
    let foundNode = entityFolders?.find((x) => x.id === id);
    if (foundNode) {
      path.push(foundNode);
      findNode(foundNode.parentId);
    }
    return path;
  };
  if (folder && setTargetPath) {
    setTargetPath(findNode(folder.id?.toString() ?? "").reverse());
  } else {
    return findNode(folder.id?.toString() ?? "");
  }
};

export default function Breadcrumb(props: BreadcrumbProps) {
  const { entityFolders, setSelectedFolder } = useGlobalContext();
  const [targetPath, setTargetPath] = useState<DocumentFolderDto[]>([]);

  useEffect(() => {
    if (props.selectedFolder && entityFolders) {
      getPath(props.selectedFolder, entityFolders, setTargetPath);
    }
  }, [props.selectedFolder, entityFolders]);

  const onHandleClick = (element: DocumentFolderDto) => {
    if (props.selectedFolder?.id !== element.id) {
      setSelectedFolder(element);
    }
  };

  return targetPath.length > 0 ? (
    <KirbyBreadcrumbs data-testid="folder-content-breadcrumb">
      {targetPath.map((element, index) => {
        return (
          <span
            key={element.id}
            data-testid={`folder-content-breadcrumb-item-${element.id}`}
            className={`${index === targetPath.length - 1 && "active"}`}
            onClick={() => onHandleClick(element)}
            title={element.displayName}>
            {element.displayName}
          </span>
        );
      })}
    </KirbyBreadcrumbs>
  ) : null;
}
