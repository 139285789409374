import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { entityFilingActions, entityFilingsActionTypes } from "./entityFilings-actions";
import { downloadEconomicSubstanceFilings, getEconomicSubstanceFilings } from "./entityFilings-api";
import { DownloadEntityFilingsAction, FetchEntityFilingsAction } from "./types/entityFilings-actions-types";

export function* watchEntityFilingsActionTypes(): SagaIterator {
    yield takeEvery(entityFilingsActionTypes.FETCH_FILINGS, function*(action: FetchEntityFilingsAction): any {
        try {
            const { request } = action;
            const page = yield call(getEconomicSubstanceFilings, request);

            yield put(entityFilingActions.fetchFilingsSuccess(page));
        } catch (e: any) {
            if (e && e.message === 'canceled') {
                yield put(entityFilingActions.fetchFilingsCancelled());
                return;
            }
            yield put(entityFilingActions.fetchFilingsFailure(e));
        }
    });

    yield takeEvery(entityFilingsActionTypes.DOWNLOAD_FILINGS, function*(action: DownloadEntityFilingsAction): any {
        try {
            const { request, callBack } = action;
          yield call(downloadEconomicSubstanceFilings, request, callBack);
            yield put(entityFilingActions.downloadFilingsSuccess());
        } catch (e: any) {
            yield put(entityFilingActions.downloadFilingsFailure(e));
        }
    });
}

export default function* entityFilingsSaga(): SagaIterator {
    yield all([
        fork(watchEntityFilingsActionTypes)
    ]);
}
