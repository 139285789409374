import { createSelector } from "reselect";
import { AppState } from "../../store/AppState";
import { EntityCimaRegistrationsState } from "./types/cima-registrations-state";

const cimaRegistrationsStateSelector = (state: AppState): EntityCimaRegistrationsState => state.cimaRegistrations;

export const getCimaRegistrations = createSelector(
  cimaRegistrationsStateSelector,
  (cimaRegistrationsState) => {
    if (!cimaRegistrationsState || !cimaRegistrationsState.cimaRegistrations) return undefined;
    return cimaRegistrationsState.cimaRegistrations;
  }
);

export const getCimaRegistrationsFetchStatus = createSelector(
  cimaRegistrationsStateSelector,
  (properties) => properties?.fetchCimaRegistrationsStatus
);
