
const controllers: Map<string, AbortController> = new Map<string, AbortController>();

export const getController = (key: string): AbortController | undefined => controllers.get(key);

export const addController = (key: string): AbortController => {
  let controller = new AbortController();
  controllers.set(key, controller);
  return controller;
}

export const getAbortSignalTryCancelPending = (key: string): AbortSignal => {
  let controller = getController(key);

  if (controller && !controller.signal.aborted)
    controller.abort();

  return addController(key).signal;
}

export const tryCancelPending = (key: string): void => {
  let controller = getController(key);

  if (controller && !controller.signal.aborted)
    controller.abort();
}