import { connect } from 'react-redux'
import { getAuthenticatedUser, getAuthenticatedUserFetchStatus } from "modules/auth/auth-selectors";
import { AppState } from "store/AppState";
import { RequireTerms } from './RequireTerms';

function mapStateToProps(state: AppState) {
  return {
    userFetchStatus: getAuthenticatedUserFetchStatus(state),
    user: getAuthenticatedUser(state)
  }
}

export default connect(mapStateToProps)(RequireTerms);