import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  EntityTerminationActions,
  EntityTerminationActionTypes,
} from "./entityTermination-actions";
import { FetchEntityTerminationAction } from "./types/entityTermination-actions-types";
import { cancelFetchEntityTermination, fetchEntityTermination } from "./entityTermination.api";
import { EntityTerminationDto } from "common-types/entityTerminationDto";

export function* watchFetchEntityTermination(): SagaIterator {
  yield takeEvery(
    EntityTerminationActionTypes.FETCH_ENTITYTERMINATION,
    function* (action: FetchEntityTerminationAction): any {
      try {
        const { entityId } = action;
        let entityTerminationDto: EntityTerminationDto = yield call(
          fetchEntityTermination,
          entityId
        );
        yield put(
          EntityTerminationActions.fetchEntityTerminationSuccess(entityTerminationDto)
        );
      } catch (e) {
        yield put(EntityTerminationActions.fetchEntityTerminationFailure(e));
      }
    }
  );

  yield takeEvery(
    EntityTerminationActionTypes.CANCEL_FETCH, function* (): any {
        yield call(cancelFetchEntityTermination);
    }
  );
}

export default function* EntityTerminationSaga(): SagaIterator {
  yield all([fork(watchFetchEntityTermination)]);
}
