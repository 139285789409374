import produce from "immer";
import { FetchStatus } from "../../common-types/fetchStatusEnum";
import { FscLicenceActionTypes } from "./fscLicence-actions";
import { FetchFscLicenceSuccessAction } from "./types/fscLicense-actions-types";
import { FscLicenceState } from "./types/fscLicense-state";

const initialState: FscLicenceState = {
  entityId: 0,
  licence: undefined,
  fetchStatus: FetchStatus.InProgress
};

export default (
  state = initialState,
  action: FetchFscLicenceSuccessAction = { licence: undefined, type: "" }): FscLicenceState =>
  produce(state, (draft) => {
    switch (action.type) {
      case FscLicenceActionTypes.FETCH_FSC_LICENCE: {
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case FscLicenceActionTypes.FETCH_FSC_LICENCE_SUCCESS: {
        const { licence } = action;
        draft.licence = licence;
        draft.fetchStatus = licence ? FetchStatus.Complete : FetchStatus.NoContent;
        return;
      }
      case FscLicenceActionTypes.FETCH_FSC_LICENCE_FAILURE: {
        draft.licence = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
