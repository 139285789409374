import { EntityDto } from "common-types/entityDto";
import { RECENTLYVIEWEDENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { urlEncoded } from "helpers/url-builder";
import { getAbortSignalTryCancelPending } from "./utils/requestAbortion";

export const fetchRecentlyViewedEntities = async (): Promise<EntityDto[]> => {
  return ApiCaller.get(urlEncoded(RECENTLYVIEWEDENTITIES_ENDPOINT), {
    signal: getAbortSignalTryCancelPending("fetchRecentlyViewedEntities"),
  });
};
