import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { CoreEntityDetailsState } from "./types/core-entity-details-state";

const CoreEntityDetailsStateSelector = (
    state: AppState
): CoreEntityDetailsState => state.coreEntityDetails;

export const getCoreEntityDetails = createSelector(
    CoreEntityDetailsStateSelector,
    (coreEntityDetailsState) => {
        if(!coreEntityDetailsState || !coreEntityDetailsState.coreEntityDetails)
            return undefined;
        return coreEntityDetailsState.coreEntityDetails;
    }
);

export const getCoreEntityDetailsFetchStatus = createSelector(
    CoreEntityDetailsStateSelector,
    (properties) => properties?.fetchCoreEntityDetailsStatus
);