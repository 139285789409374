import { DocumentDto } from "common-types/documentDto";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import { showErrorToast, showSuccessToast } from "components/common/Toaster";
import DocActionApi from "helpers/userActions/documentActions";
import { ToTreeItem } from "common-types/converters/iTreeItemConverters";

export const onCopyCut = (
  selectedDocuments: Array<TreeItem> | Array<DocumentDto>,
  selectedFolder: Array<string>,
  setDocClipboard: Function,
  setClipboardCut: Function,
  cut?: boolean
) => {
  let selectedDocs = selectedDocuments.map((doc: TreeItem | DocumentDto) => {
    if (doc.hasOwnProperty('value')) {
      return { ...doc, selected: false };
    } else {
      return { ...ToTreeItem(doc as DocumentDto), selected: false };
    }
  })
  setDocClipboard(selectedDocs);

  if (!cut) {
    showSuccessToast(`${selectedDocs.length} items copied to clipboard`);
    setClipboardCut(false);
  } else {
    setClipboardCut(true);
    showSuccessToast(`${selectedDocs.length} items cut to clipboard`);
  }
}

export const onPaste = (
  selectedFolder: Partial<DocumentFolderDto> | undefined,
  entityFolders: DocumentFolderDto[] | undefined,
  getPath: Function,
  docClipboard: Array<TreeItem>,
  onPasteOverwrites: (parentFolderId: string, files: TreeItem[]) => {}
) => {
  if (docClipboard.length === 0 || !selectedFolder || !entityFolders) return false;
  let parentIds: Array<string> | undefined = selectedFolder && entityFolders ? getPath(selectedFolder, entityFolders)?.map((parent: DocumentFolderDto) => parent.id) : [];
  let validPaste: boolean | string = isPasteValid(parentIds, docClipboard, selectedFolder);
  if (validPaste === false) return;
  if (validPaste === true) {
    onPasteOverwrites(selectedFolder?.id as string, docClipboard);
  }
  if (typeof validPaste !== "boolean") showErrorToast(validPaste);
}

export const isPasteValid = (
  parentIds: Array<string> | undefined,
  docClipboard: Array<TreeItem>,
  selectedFolder?: Partial<DocumentFolderDto>
) => {
  if (docClipboard.length === 0 || !selectedFolder) return false;
  if (docClipboard.filter(doc => doc.value.parentId === selectedFolder.id).length > 0) {
    return `Can't paste to same location`;
  } else if (!DocActionApi.hasCreateAction(selectedFolder.source, selectedFolder.documentType)) {
    return `Can't paste to this folder`;
  } else if (parentIds && parentIds.includes(docClipboard[0].value.id)) {
    return `Can't paste item into itself`;
  } else {
    return true;
  }
}
