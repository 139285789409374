import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";

export const SearchResultActionTypes = {
  FETCH_SEARCHRESULTS: "FETCH_SEARCHRESULTS",
  FETCH_SEARCHRESULTS_SUCCESS: "FETCH_SEARCHRESULTS_SUCCESS",
  FETCH_SEARCHRESULTS_FAILURE: "FETCH_SEARCHRESULTS_FAILURE",
  SET_SEARCH_TERM: "SET_ENTITIES_SEARCH_TERM",
  SET_REDIRECTED: "SET_REDIRECTED",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  IN_PROGRESS: "IN_PROGRESS",
  CLEAR_SEARCH_TERM: "CLEAR_SEARCH_TERM",
  RESET_FETCHSTATUS: "RESET_FETCHSTATUS",
};

export const SearchResultActions = {
  fetchSearchResults: (
    pageNumber: number,
    pageSize: number,
    searchTerm: string = ""
  ) => ({
    type: SearchResultActionTypes.FETCH_SEARCHRESULTS,
    pageNumber,
    searchTerm,
    pageSize,
  }),

  fetchSearchResultsSuccess: (searchResults: PagedListDto<EntityDto>) => ({
    type: SearchResultActionTypes.FETCH_SEARCHRESULTS_SUCCESS,
    searchResults,
  }),

  fetchSearchResultsFailure: (error: any) => ({
    type: SearchResultActionTypes.FETCH_SEARCHRESULTS_FAILURE,
    error,
  }),

  setCurrentPage: (pageNumber: number) => ({
    type: SearchResultActionTypes.SET_PAGE_NUMBER,
    pageNumber,
  }),

  setPageSize: (pageSize: number) => ({
    type: SearchResultActionTypes.SET_PAGE_SIZE,
    pageSize,
  }),

  setEntitiesSearchTerm: (searchTerm: string) => ({
    type: SearchResultActionTypes.SET_SEARCH_TERM,
    searchTerm,
  }),

  setRedirected: () => ({
    type: SearchResultActionTypes.SET_REDIRECTED,
  }),

  clearEntitiesSearchTerm: () => ({
    type: SearchResultActionTypes.CLEAR_SEARCH_TERM,
  }),

  resetFetchStatus: () => ({
    type: SearchResultActionTypes.RESET_FETCHSTATUS,
  }),
};
