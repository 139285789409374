import { UnitPartnershipInterestDto } from 'common-types';
import { ENTITIES_ENDPOINT, UNITPARTNERSHIPINTERESTS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from './interfaces/entityPageRequest';
import { getAbortSignalTryCancelPending, tryCancelPending } from './utils/requestAbortion';

const signalKey = "fetchUnitPartnershipInterests";

export const fetchUnitPartnershipInterests = async (request: EntityPageRequest): Promise<PagedListDto<UnitPartnershipInterestDto>> =>
    ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${UNITPARTNERSHIPINTERESTS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchUnitPartnershipInterests = (): void => tryCancelPending(signalKey);
