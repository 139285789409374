import { TRADEMARKCLASSES_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { TrademarkClassDto } from "../common-types/trademarkClassDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchTrademarkClasses";

export const fetchTrademarkClasses = async(request: EntityPageRequest): Promise<PagedListDto<TrademarkClassDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${TRADEMARKCLASSES_ENDPOINT}${EntityPageRequestToQueryString(request)}`,
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchTrademarkClasses = (): void => tryCancelPending(signalKey);
