import { AppState } from "../../../../store/AppState";
import Registers, { RegistersProps } from "./Registers";
import { connect } from "react-redux";
import {
  getRegisters,
  getRegistersFetchStatus,
} from "modules/registers/registers-selectors";
import { FetchRegistersAction } from "modules/registers/types/registers-actions-types";
import { RegistersActions } from "modules/registers/registers-actions";
import {  RegistersOwnProps } from "modules/registers/types/registers-component-types";
import { getEntity } from "modules/entity/entity-selectors";
import { getRegisterProperties } from "modules/registerProperties/registerProperties-selectors";
import { FetchRegisterPropertiesAction } from "modules/registerProperties/types/registerProperties-actions-types";
import { RegisterPropertiesActions } from "modules/registerProperties/registerProperties-actions";
import headerText from "../../../../constants/copy/registers/headerText";
import { Action } from "typesafe-actions";

const mapStateToProps = (state: AppState, ownProps: RegistersOwnProps): Partial<RegistersProps> & RegistersOwnProps => ({
  registers: getRegisters(state),
  fetchStatus: getRegistersFetchStatus(state),
  entity: getEntity(state),
  registerProperties: getRegisterProperties(state),
  collapsed: ownProps.collapsed,
  handleOpenToggle: ownProps.handleOpenToggle,
  header: headerText.Registers
});

const mapDispatchToProps = {
  fetchRegisters: (entityId: number): FetchRegistersAction =>
    RegistersActions.fetchRegisters(entityId),

  fetchRegisterProperties: (entityId: number): FetchRegisterPropertiesAction =>
    RegisterPropertiesActions.fetchRegisterProperties(entityId),
  
  cancel: (): Action => RegistersActions.cancelRegisters()
};

export default connect(mapStateToProps, mapDispatchToProps)(Registers);
