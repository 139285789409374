import { connect } from "react-redux";
import { AppState } from "store/AppState";
import UnitPartnershipInterests from "./UnitPartnershipInterests";
import { getEntity } from "modules/entity/entity-selectors";
import { UnitPartnershipInterestDto } from "common-types";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import PagedListKeys from "constants/pagedListKeys";
import headerText from "constants/copy/registers/headerText";
import { gridColumns } from "./UnitPartnershipInterestsGridColumns";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";

const stateSelector = (state: AppState): GenericPagedListState<UnitPartnershipInterestDto> => state.unitpartnershipInterests;

const getUnitPartnershipInterests = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<UnitPartnershipInterestDto> & ServerTableContainerOwnProps => {
  return {
    list: getUnitPartnershipInterests(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state),
    header: headerText.UnitPartnershipInterests,
    columns: gridColumns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 63.125,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
}
const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.UNIT_PARTNERSHIP_INTERESTS);

export default connect(mapStateToProps, mapDispatchToProps)(UnitPartnershipInterests);