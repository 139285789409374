import { useCallback, useState } from "react";
import { kirbyGrid, kirbyMenu } from "maples-kirby-icons";
import {
  KirbyButton,
  KirbyMenu,
  KirbyIcon,
  KirbyMenuItem,
} from "maples-kirby-react";

export default function ToggleViewButton({ gridView, setGridView }: any) {
  const [viewMenuOpen, setViewMenuOpen] = useState(false);

  const viewButtonClick = useCallback(() => {
    setViewMenuOpen(true);
  }, [setViewMenuOpen]);

  const menuHandleClose = useCallback(() => {
    setViewMenuOpen(false);
  }, [setViewMenuOpen]);

  const enableGridView = useCallback(() => {
    setGridView(true);
  }, [setGridView]);

  const enableListView = useCallback(() => {
    setGridView(false);
  }, [setGridView]);

  return (
    <>
      <KirbyButton
        id="viewBut"
        variant="tertiary"
        handleClick={viewButtonClick}>
        <KirbyIcon icon={gridView ? kirbyGrid : kirbyMenu} /> View
      </KirbyButton>
      <KirbyMenu
        className={`${viewMenuOpen ? "open" : ""}`}
        target="#viewBut"
        position="bottom-end"
        open={viewMenuOpen}
        handleClose={menuHandleClose}>
        <KirbyMenuItem handleClick={enableGridView}>
          <KirbyIcon icon={kirbyGrid} />
          <span>Grid</span>
        </KirbyMenuItem>
        <KirbyMenuItem handleClick={enableListView}>
          <KirbyIcon icon={kirbyMenu} />
          <span>List</span>
        </KirbyMenuItem>
      </KirbyMenu>
    </>
  );
}
