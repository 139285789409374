import { useCallback, useMemo, useState } from "react";
import { KirbyDialog } from "maples-kirby-react";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { basketUtils } from "common-types/serviceRequests/utils/basketUtils";
import { OrderDto } from "common-types/serviceRequests/orderDto";

export interface BasketItemUpdatedDialogProps {
  basketItem: BasketItemDto | undefined;
  setBasketItem: (item: BasketItemDto | undefined) => void;
  confirm: (item: BasketItemDto) => Promise<void>;
  order: OrderDto;
}

const BasketItemUpdatedDialog = (props: BasketItemUpdatedDialogProps) => {
  const { basketItem, setBasketItem, confirm, order } = props;
  const [disableConfirm, setDisableConfirm] = useState(false);

  const closeDialog = useCallback(() => {
    setBasketItem(undefined);
  }, [setBasketItem, basketItem, confirm]);

  const confirmAndClose = useCallback(async () => {
    setDisableConfirm(true);
    await confirm(basketItem!);
    closeDialog();
    setDisableConfirm(false);
  }, [confirm, basketItem]);

  const actions = useMemo(() => {
    if (!basketItem)
      return [];

    const existingBasketItem = basketUtils.findMatch(order.orderItems, basketItem);
    if (existingBasketItem !== undefined && existingBasketItem.quantity >= 10)
      return [];

    return [
      {
        action: 'Confirm',
        func: confirmAndClose,
        testIdPrefix: 'confirm-update-basket',
        title: 'Confirm',
        primary: true,
        disabled: disableConfirm
      }
    ];
  }, [order, basketItem, confirmAndClose]);

  const message = useMemo(() => {
    if (!basketItem)
      return '';
    
    if (actions.length === 0)
      return `You have already added the maximum number (10) of this item in your order.`;

    if (basketItem.quantity !== (basketItem.requestedQuantity ?? 0))
      return 'Your order will be increased to the maximum number (10) for this item, would you like to proceed?';
    
    return 'You have already added this item, adding it again will update the quantity of the existing item, would you like to proceed?';
  }, [basketItem]);

  return (
    <div data-testid={`basket-item-updated-dialog`} >
      <KirbyDialog
        data-testid={'basket-item-kirby-dialog'}
        open={basketItem !== undefined}
        handleClose={closeDialog}
        disablebackdropclose={true}
        actions={actions} >
          <p data-testid={'message-p'}>{message}</p>
      </KirbyDialog>
    </div> 
  );
};

export default BasketItemUpdatedDialog;
