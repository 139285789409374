import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./Settings.module.scss";
import { KirbyDialog } from "maples-kirby-react";
import NotificationSettingsRadio from "./notifications/NotificationSettingsRadio";
import { UserSettingsDto } from "common-types/user/userSettingsDto";
import { Formik, FormikProps } from "formik";
import { getNotificationTypeHeader } from "constants/copy/usersettings/headers";
import { FormState } from "common-types/forms/FormState";
import { SaveSettingsState } from "common-types/user/SaveSettingsState";
import { showSuccessToast, showErrorToast } from "components/common/Toaster";
import { FormikNotificationSettings } from "modules/user/types/component-types";
import {
  formikNotificationSettingsToUserSettingsDto,
  notificationTypeDic,
  userSettingsDtoToFormikNotificationSettings,
} from "modules/user/component-types-helpers";

export interface UserSettingsProps {
  settings: UserSettingsDto | undefined;
  formState: FormState | undefined;
  saveSettingsStatus: SaveSettingsState | undefined;
  fetchSettings: () => void;
  saveSettings: (settings: UserSettingsDto) => void;
  open: boolean;
  setOpen: Function;
  acceptMode: boolean | undefined;
}

const Settings = (props: UserSettingsProps) => {
  const {
    fetchSettings,
    saveSettings,
    settings,
    open,
    setOpen,
    saveSettingsStatus,
    acceptMode,
  } = props;
  const formRef = useRef<FormikProps<FormikNotificationSettings>>(null);
  const [acceptModeComplete, setAcceptModeComplete] = useState(false);
  const notificationsToBeAccepted = acceptMode && !acceptModeComplete;

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    if (saveSettingsStatus === SaveSettingsState.Success) {
      setAcceptModeComplete(true);
      showSuccessToast("Saved notification preferences");
    } else if (saveSettingsStatus === SaveSettingsState.Failed) {
      showErrorToast("Error saving notification preferences");
    }
  }, [saveSettingsStatus]);

  function saveMe() {
    formRef?.current?.handleSubmit();
  }

  const dialogHandleClose = useCallback(() => {
    setOpen(false);
    formRef?.current?.resetForm();
  }, [setOpen]);

  const submitForm = useCallback(
    (values: FormikNotificationSettings) => {
      const result = formikNotificationSettingsToUserSettingsDto(values);
      saveSettings(result);
      setOpen(false);
    },
    [saveSettings, setOpen]
  );

  const formikInitialValue = useMemo(
    (): FormikNotificationSettings =>
      userSettingsDtoToFormikNotificationSettings(settings),
    [settings]
  );

  const getHeader = (notificationType: string): string =>
    notificationTypeDic.has(notificationType)
      ? getNotificationTypeHeader(notificationTypeDic.get(notificationType)!) ??
        ""
      : "";

  const actions = {
    Accept: "Accept",
    Update: "Update",
  };

  const isNotificationTypeEnabled = (notificationType: string) => {
    const setting = 
      settings?.notificationSettings.find(s => s.type === notificationType);

    if (setting)
      return setting.isNotificationTypeEnabled;

    return false;
  };

  return (
    <KirbyDialog
      open={open}
      handleClose={dialogHandleClose}
      disablebackdropclose={notificationsToBeAccepted}
      nocancel={notificationsToBeAccepted}
      header="Notification Preferences"
      className={styles.settingsDialog}
      data-testid={'settings-kirby-dialog'}
      actions={[
        {
          action: notificationsToBeAccepted ? actions.Accept : actions.Update,
          func: saveMe,
          primary: true,
          testIdPrefix: "confirmButton",
        },
      ]}>
      {!notificationsToBeAccepted && (
        <p data-testid="defaultDialogText">
          Please select the frequency to receive the email notifications for the
          options below.
        </p>
      )}

      {notificationsToBeAccepted && (
        <p data-testid="acceptModeDialogText">
          Please select the frequency to receive the email notifications for the
          options below.
          <br />
          Note that you can change these later through the user menu.
        </p>
      )}

      {props.settings ? (
        <Formik
          innerRef={formRef}
          initialValues={{
            ...formikInitialValue,
          }}
          enableReinitialize={true}
          onSubmit={submitForm}>
          {({ values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              {Object.keys(values)
                .filter(notificationType => isNotificationTypeEnabled(notificationType))
                .map((notificationType: string) => (
                  <NotificationSettingsRadio
                    key={`notification-setting-${notificationType}`}
                    header={getHeader(notificationType)}
                    propertyName={notificationType}
                    setFieldValue={setFieldValue}
                  />
                )
              )}
            </form>
          )}
        </Formik>
      ) : (
        <span>Loading...</span>
      )}
    </KirbyDialog>
  );
};

export default Settings;
