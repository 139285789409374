import { all, call, put, takeEvery, fork } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import { CoreEntityDetailsDto } from "common-types/coreEntityDetailsDto";
import { CoreEntityDetailsActions, CoreEntityDetailsActionTypes } from "./core-entity-details-actions";
import { cancelFetchCoreEntityDetails, fetchCoreEntityDetails } from "./core-entity-details.api";
import { FetchCoreEntityDetailsAction } from "./types/core-entity-details-actions-types";

export function* watchFetchCoreEntityDetails(): SagaIterator {
    yield takeEvery(
        CoreEntityDetailsActionTypes.FETCH_CORE_ENTITY_DETAILS,
        function* (action: FetchCoreEntityDetailsAction): any {
            try{
                const {entityId} = action;
                let coreEntityDetails: CoreEntityDetailsDto = yield call(
                    fetchCoreEntityDetails,
                    entityId
                );
                yield put(
                    CoreEntityDetailsActions.fetchCoreEntityDetailsSuccess(
                        coreEntityDetails
                    )
                );
            } catch (e) {
                yield put(CoreEntityDetailsActions.fetchCoreEntityDetailsFailure(e));
            }
        }
    );

    yield takeEvery(
        CoreEntityDetailsActionTypes.CANCEL_FETCH,
        function* (): any {
            yield call(cancelFetchCoreEntityDetails);
        }
    );
}

export default function* CoreEntityDetailsSaga(): SagaIterator {
    yield all([fork(watchFetchCoreEntityDetails)]);
}