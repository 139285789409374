import { Component } from "react";
import { TrademarkClassDto } from "common-types/trademarkClassDto";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";

class TrademarkClasses extends Component<
  SimplePagedSectionProps<TrademarkClassDto>
> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default TrademarkClasses;
