import { useEffect, useState } from "react";
import { KirbyButton, KirbySection, KirbySectionToolbar, KirbyTextfield, KirbyTableClient, KirbyIcon } from "maples-kirby-react";
import { maplesStandardXLSX } from "maples-kirby";
import { kirbyBooleanTick, kirbyCancel, kirbyTick } from "maples-kirby-icons";
import { IconCell } from "helpers/gridCells";
import { renderToStaticMarkup } from "react-dom/server";
import { DateTime } from 'luxon';
import styles from "./OrderHistoryDetails.module.scss";
import { OrderHistoryDetailsComponentProps } from "modules/order/types/order-component-types";
import { useNavigate, useParams } from "react-router";
import utils from "../OrderHistory/utils";
import OrderNotes from "../Notes/OrderNotes";
import OrderDetailsDialog from "../Dialogs/OrderDetailsDialog";
import { servicerequestOrderStatusenum } from "common-types/enums/serviceRequestOrderStatusEnum";
import { RequireAction } from "components/common/RequireAction";
import { ServiceRequestCertificationTypeEnum } from "common-types/enums/serviceRequestCertificationTypeEnum";
import * as XLSX from 'sheetjs-style';
import { verifyIfOrderStatusChanged } from "../Util/ServiceRequestUtil";
import ServiceRequestErrorDialog from "../Dialogs/ServiceRequestErrorDialog";
import { buildEmptyBasket } from "../../../../common-types/serviceRequests/orderDto";
import { uploadFileToBlob } from "api/audit-api";
import { addToast } from "components/common/Toaster";
import { sortByDisplayName } from "common-types/serviceRequests/utils/basketItemDtoUtils";
import { ActionTypeEnum } from "../../../../common-types/actionTypeEnum";

window.XLSX = XLSX;
declare global {
  interface Window {
    XLSX: any;
  }
}

export default function OrderHistoryDetailsContent(props: Readonly<OrderHistoryDetailsComponentProps>) {

  const { order, fetchOrder, cancelOrder, completeOrder } = props;
  const { id, orderDetailsId } = useParams();
  const entityId = id ? parseInt(id) : 0;
  const navigate = useNavigate();
  const orderId = orderDetailsId ? parseInt(orderDetailsId) : 0;

  const enum orderStatusClickEnum {
    CANCEL,
    COMPLETE
  };

  const [showCancelCompleteButton, setShowCancelCompleteButton] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [orderStatusClick, setOrderStatusClick] = useState<orderStatusClickEnum>(orderStatusClickEnum.CANCEL);
  const [showServiceRequestError, setShowServiceRequestError] = useState<boolean>(false);
  const [isOrderDetailsDialogVisable, setIsOrderDetailsDialogVisable] = useState<boolean>(false);

  useEffect(() => {
    fetchOrder(entityId, orderId);
  }, [fetchOrder, entityId, orderId]);

  const basketCols = [
    {
      title: "Document Type",
      field: "name",
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        return (cell.getRow().getData().serviceRequestCertificationId === ServiceRequestCertificationTypeEnum.Other ? "Other - " : "") + cell.getValue();
      },
      accessorDownload: function (value: string, data: any) {
        return (data.serviceRequestCertificationId === ServiceRequestCertificationTypeEnum.Other ? "Other - " : "") + value;
      }
    }, {
      title: "Certification Type",
      field: "serviceRequestCertificationType"
    }, {
      title: "Express",
      field: "isExpress",
      width: 7,
      resizable: false,
      hozAlign: 'center',
      cssPart: 'expresscell',
      formatter: (cell: any, _formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          if (cell.getValue()) cell.getElement().querySelector('kirby-icon').icon = kirbyBooleanTick;
        })
        return cell.getValue() ? renderToStaticMarkup(IconCell('Express Selected', undefined, undefined, 'var(--maples-text-main)')) : '';
      },
      accessorDownload: function (value: boolean) {
        return value ? 'Yes' : 'No'
      }
    }, {
      title: "Quantity",
      field: "quantity",
      width: 8,
      hozAlign: 'right',
      resizable: false,
    }
  ]

  const orderActionOnClick = (status: orderStatusClickEnum) => {
    if (confirmButtonDisabled()) {
      return;
    }

    setShowCancelCompleteButton(true);
    setOrderStatusClick(status);
    const statusMessage = status === orderStatusClickEnum.CANCEL ? 'cancelled' : 'completed';
    setDialogMessage(`This order will be ${statusMessage}. Do you want to continue?`);
    setIsOrderDetailsDialogVisable(true);
  }

  const confirmReturnToOrderHistory = () => {
    setShowServiceRequestError(false);
    setIsOrderDetailsDialogVisable(false);
    setTimeout(() => navigate('/entities/' + entityId + "/servicerequests/orderhistory"), 500);
  };

  const confirmButtonDisabled = (): boolean =>
    order?.serviceRequestOrderStatusId === servicerequestOrderStatusenum.Complete ||
    order?.serviceRequestOrderStatusId === servicerequestOrderStatusenum.Cancel ||
    showCancelCompleteButton;

  const confirmDialogAction = async () => {
    const result = await verifyIfOrderStatusChanged(order ?? buildEmptyBasket(), entityId);
    setShowServiceRequestError(result);
    if (!result) {
      if (orderStatusClick === orderStatusClickEnum.CANCEL) {
        cancelOrder(entityId, orderId);
      } else if (orderStatusClick === orderStatusClickEnum.COMPLETE) {
        completeOrder(entityId, orderId);
      }
    }
    setIsOrderDetailsDialogVisable(false);
  }

  const confirmCloseDialogAction = () => {
    setShowCancelCompleteButton(false);
    setIsOrderDetailsDialogVisable(false);
  }

  return (
    <>
      {order && (
        <>
          <KirbySection sectionTitle="Order History Details">
            <RequireAction actionName="ServiceRequest.Order.StatusChange.Cancel">
              <KirbySectionToolbar>
                <KirbyButton variant="secondary" icon={kirbyCancel}
                  handleClick={() => orderActionOnClick(orderStatusClickEnum.CANCEL)}
                  disabled={confirmButtonDisabled()}>Cancel Order</KirbyButton>
                <KirbyButton variant="primary"
                  handleClick={() => orderActionOnClick(orderStatusClickEnum.COMPLETE)}
                  disabled={confirmButtonDisabled()}><KirbyIcon icon={kirbyTick} />Complete</KirbyButton>
              </KirbySectionToolbar>
            </RequireAction>
            <div className={styles.sectionContent}>
              <div className={styles.topSection}>
                <div className={styles.details}>
                  <KirbyTextfield label="Status" value={utils.getStatusLabel(order.serviceRequestOrderStatusId)} readonly></KirbyTextfield>
                  <KirbyTextfield label="Order ID" value={order.id.toString()} readonly></KirbyTextfield>
                  <KirbyTextfield label="Order Date" value={`${DateTime.fromISO(order.submittedDate?.toString() ?? '').toFormat('dd-MMM-yyyy, H:mm:ss')} (UTC)`} readonly></KirbyTextfield>
                  <KirbyTextfield label="Ordered By" value={order.submittedByName} readonly></KirbyTextfield>
                  { order.completedByName &&
                    <KirbyTextfield label="Completed By" value={order.completedByName} readonly></KirbyTextfield>
                  }
                  { order.cancelledByName &&
                    <KirbyTextfield label="Cancelled By" value={order.cancelledByName} readonly></KirbyTextfield>
                  }
                </div>
                <div className={styles.notesContent}>
                  <OrderNotes order={order} disabled={false}/>
                </div>
              </div>
              {(order.orderItems && order.orderItems.length > 0) &&
                <KirbyTableClient
                  data={sortByDisplayName(order.orderItems)}
                  columns={basketCols}
                  hidePagination
                  widthBreakpoints={{
                    responsiveWidth: 40,
                    stickyColumnsActive: 20
                  }}
                  allowXlsxExport={{
                    fileName: `Phoenix Order ${order.id}.xlsx`,
                    options: {
                      documentProcessing: (workbook: any) => {
                        const excelBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                        const excelFile = new File([excelBlob], `Phoenix Order ${order.id}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        uploadFileToBlob(ActionTypeEnum.ExportServiceRequestOrderHistoryDetails, "Exporting Service Request Order History Details Excel", excelFile);
                        addToast("Service Requests Exported", "", "success");
                        return maplesStandardXLSX(workbook);
                      }
                    }
                  }}
                />
              }
            </div>
          </KirbySection>
          <OrderDetailsDialog
            order={order}
            confirm={confirmDialogAction}
            close={confirmCloseDialogAction}
            show={isOrderDetailsDialogVisable}
            message={dialogMessage}
          />
          <ServiceRequestErrorDialog
            confirm={() => confirmReturnToOrderHistory()}
            close={() => setShowServiceRequestError(false)}
            show={showServiceRequestError}
          />
        </>
      )}
    </>
  );
}

