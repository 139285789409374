
export const distinct = <T>(array: T[]): T[] => {
    let result: T[] = [];

    array.forEach(element => {
        if (!result.find(a => a === element))
            result.push(element);
    });

    return result;
};