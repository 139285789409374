import { OrderDto } from "../../../../common-types/serviceRequests/orderDto";
import * as ServiceRequestApiModule from "api/service-requests-api";

export async function verifyIfOrderStatusChanged(order: OrderDto, entityId: number): Promise<boolean> {
  if (order?.id == 0)
    return false;

  try {
    const response = await ServiceRequestApiModule.fetchOrder(entityId, order.id, false);
    return order.serviceRequestOrderStatusId != response.serviceRequestOrderStatusId;
  } catch (error: any) {
    return error.code != "ERR_CANCELED";
  }
};