import { connect } from "react-redux";
import { CoreEntityDetailsActions } from "modules/coreEntityDetails/core-entity-details-actions";
import { getCoreEntityDetails, getCoreEntityDetailsFetchStatus } from "modules/coreEntityDetails/core-entity-details-selectors";
import { FetchCoreEntityDetailsAction } from "modules/coreEntityDetails/types/core-entity-details-actions-types";
import { getEntity } from "modules/entity/entity-selectors";
import { AppState } from "store/AppState";
import CoreEntityDetails, { CoreEntityDetailsProps } from "./CoreEntityDetails";
import { Action } from "typesafe-actions";

const mapStateToCoreEntityDetailsProps = (state: AppState): Partial<CoreEntityDetailsProps> => ({
    coreEntityDetails: getCoreEntityDetails(state),
    fetchCoreEntityDetailsStatus: getCoreEntityDetailsFetchStatus(state),
    entity: getEntity(state),
});

const mapDispatchToCoreEntityDetailsProps = {
    fetchCoreEntityDetails: (entityId: number): FetchCoreEntityDetailsAction => CoreEntityDetailsActions.fetchCoreEntityDetails(entityId),
    cancel: (): Action => CoreEntityDetailsActions.cancel()
};

export default connect(mapStateToCoreEntityDetailsProps, mapDispatchToCoreEntityDetailsProps)(CoreEntityDetails)