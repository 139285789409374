import localForage from "localforage";

const store = localForage.createInstance({
    name: "overviewStates",
});

export async function getLocalStorage(){
    interface OverviewLocalStorage {
      [key: string]: any[];
    }
    try {
      const vals: OverviewLocalStorage | null = await store.getItem(
        "entityTypes"
      );
      return vals ? vals : []
    }catch (err) {
      throw new Error("Could not get local storage for entityTypes" + err);
    }
  }

  export function updateLocalStorage(entityType : string, accordion:string, openState:boolean){
    let updatedEntityTypes:any = {};
    getLocalStorage().then((data:any) => {
      updatedEntityTypes = {...data};
      const updatedEntityType:any = {...data[entityType]}
      const accordionDetails = {
        [accordion] : openState
      }
      if(updatedEntityType.details){
        let foundAccordion = updatedEntityType.details.find((o:any) => o[accordion] !== undefined);
        const index = updatedEntityType.details.indexOf(foundAccordion);
        if(index !== -1){
            updatedEntityType.details[index] = accordionDetails;
        }else{
            updatedEntityType.details.push(accordionDetails)
        }
      }else{
        updatedEntityType.details = [accordionDetails];
      }

      updatedEntityTypes[entityType] = updatedEntityType;
      store.setItem("entityTypes", updatedEntityTypes).catch(function (err: any) {
        throw new Error("Could not update local storage for entityTypes" + err);
      });
    })
  }