
export interface EconomicSubstanceFilingDto {
  entityId: number;
  clientNumber: string;
  entityName: string;
  dueDate: string;
  status: string;
  externalLink: string;
  filingType: string;
  showInPortal: boolean;
  closed: Date | null;
}

export const ESFilingStatuses: string[] = ['Pending', 'Overdue', 'Under Review' ,'Complete'];
