import { BasketItemDto } from "../basketItemDto";
import { OrderDto } from "../orderDto";

export const ServiceRequestIdOther: number = 13;

const otherDescriptionsMatch = (item1: BasketItemDto, item2: BasketItemDto): boolean =>
  item1.otherDescription?.trim().toLowerCase() === item2.otherDescription?.trim().toLowerCase();

const areBothRequestTypeOther = (item1: BasketItemDto, item2: BasketItemDto): boolean =>
  item1.serviceRequestItemId === ServiceRequestIdOther && item2.serviceRequestItemId === ServiceRequestIdOther;

const isOfSameRequestTypeAndCertificateType = (item1: BasketItemDto, item2: BasketItemDto): boolean =>
  (item1.serviceRequestItemId === item2.serviceRequestItemId && item1.serviceRequestCertificationId === item2.serviceRequestCertificationId && item1.isExpress == item2.isExpress);

const isMatch = (item1: BasketItemDto, item2: BasketItemDto): boolean => {
  if (areBothRequestTypeOther(item1, item2) && !otherDescriptionsMatch(item1, item2))
    return false;

  return isOfSameRequestTypeAndCertificateType(item1, item2);
}

const findMatch = (basket: BasketItemDto[], basketItem: BasketItemDto): BasketItemDto | undefined =>
  basket.find(a => isMatch(a, basketItem));

const findIndex = (basket: BasketItemDto[], basketItem: BasketItemDto): number =>
  basket.findIndex(a => isMatch(a, basketItem));

const findById = (basket: BasketItemDto[], itemId: number): BasketItemDto | undefined =>
  basket.find(a => a.id === itemId);

const updateBasket = (order: OrderDto, newItem: BasketItemDto): OrderDto => {

  if (order.id === 0) {
    order.id = newItem.serviceRequestOrderId;
    order.serviceRequestOrderStatusId = 1;
  }

  const basket = order.orderItems;
  if (!basket.find(a => isMatch(a, newItem))) {
    return {
      ...order,
      orderItems: [...basket, newItem]
    };
  }

  return {
    ...order,
    orderItems: basket.map(item => {
      if (!isMatch(item, newItem))
        return item;

      return {
        ...item,
        quantity: newItem.quantity,
        previousQuantity: item.quantity
      };
    })
  };
};

const removeFromBasketById = (order: OrderDto, itemId: number): OrderDto => {

  const itemIndex = order.orderItems.findIndex((a: BasketItemDto) => a.id === itemId);

  if (itemIndex < 0)
    return order;

  order.orderItems.splice(itemIndex, 1);

  return {
    ...order,
    orderItems: order.orderItems
  };
}

const basketUtils = {
  isMatch,
  updateBasket,
  findMatch,
  removeFromBasketById,
  findById,
  findIndex
}

export { basketUtils };
