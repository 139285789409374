import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import styles from "./ServiceRequestItems.module.scss";
import ServiceRequestItem from "./ServiceRequestItem";
import { ServiceRequestsCopy } from "constants/copy/service-requests/service-request-copy";
import ServiceRequestsMessage from "./ServiceRequestsMessage";
import { OrderDto } from "common-types/serviceRequests/orderDto";

export interface RequestItemsProps {
    requestItems: ServiceRequestItemDto[] | undefined;
    order: OrderDto;
}

export default function ServiceRequestItems(props: RequestItemsProps) {
    const { requestItems, order } = props;

    return (
        <>
            {
                requestItems && requestItems.length > 0 ? 
                <>
                    <ServiceRequestsMessage requestItems={requestItems} order={order} />
                    <div className={styles.requestItems} data-testid="service-requests-component">
                        {
                            requestItems.map((item: ServiceRequestItemDto, i) => {
                                return <ServiceRequestItem key={item.id} requestItem={item} order={order} />;
                            })
                        }
                    </div>
                </> :
                <div className={styles.emptyMessage} data-testid="service-requests-component-empty">{ServiceRequestsCopy.OrderItems.NoItemsAvailable}</div>
            }
        </>       
    );
}



