import { Route, Routes } from "react-router-dom";
import Home from "components/Home";
import Entity from "components/Pages/Entity/EntityContainer";
import DocManagement from "components/Pages/DocManagement/DocManagement";
import ServiceRequestManagement from "components/Pages/ServiceRequests/ServiceRequestManagement";
import ServiceRequestOrderReview from "components/Pages/ServiceRequests/OrderReview/OrderReview";
import ServiceRequestOrderHistory from "components/Pages/ServiceRequests/OrderHistory/OrderHistoryContainer";
import ServiceRequestOrderHistoryDetails from "components/Pages/ServiceRequests/OrderHistoryDetails/OrderHistoryDetailsContainer";
import Layout from "components/Layout";
import EntityOverview from "components/Pages/Entity/EntityOverviewContainer";
import { Catch } from "components/common/Catch";
import { Page204, Page401 } from "components/Pages/Errors";
import Invoices from "components/invoices/InvoicesContainer";
import ServiceRequests from "components/serviceRequests/serviceRequestsContainer";
import Reports from "components/reports/reports";
import TermsOfUse from "components/Pages/TermsOfUse/TermsOfUse";
import Page403 from "components/Pages/Errors/Page403Container";
import ContentDenied from "components/Pages/Errors/ContentDeniedContainer";
import ServiceRequestRoot from "components/Pages/ServiceRequests/ServiceRequestRoot";
import EntityFilings from "components/EntityFilings/EntityFilingsContainer";
import Releases from "../../Pages/ReleaseNotes/Releases";

export default function AppRoutes() {

  return (
    <Routes>
      <Route path="403" element={<Page403 />} />
      <Route path="401" element={<Page401 />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="entities">
          <Route index element={<Home />} />
          <Route
            path=":id"
            element={
              <Catch message="We could not fetch the entity, please try again shortly.">
                <Entity />
              </Catch>
            }>
            <Route
              index
              element={
                <Catch message="We could not fetch this entity's information, please try again shortly.">
                  <EntityOverview />
                </Catch>
              }
            />
            <Route
              path="download/:docId"
              element={
                <Catch message="We could not fetch this entity's information, please try again shortly.">
                  <EntityOverview />
                </Catch>
              }
            />
            <Route
              path="invoice/:invoiceId"
              element={
                <Catch message="We could not fetch this entity's information, please try again shortly.">
                  <EntityOverview />
                </Catch>
              }
            />
            <Route
              path="docs"
              element={
                <Catch message="We could not fetch this entity's documents, please try again shortly.">
                  <DocManagement />
                </Catch>
              }
            />
            <Route path="servicerequests">
              <Route 
                index
                element={
                  <Catch message="We could not fetch this entity's service requests, please try again shortly.">
                    <ServiceRequestRoot>
                      <ServiceRequestManagement />
                    </ServiceRequestRoot>
                  </Catch>
                }
              />
              <Route 
                path="orderreview"
                element={
                  <Catch message="We could not fetch this entity's order, please try again shortly.">
                    <ServiceRequestRoot>
                      <ServiceRequestOrderReview />
                    </ServiceRequestRoot>
                  </Catch>
                }
              />
              <Route
                path="orderhistory"
                element={
                  <Catch message="We could not fetch this entity's service requests, please try again shortly.">
                    <ServiceRequestOrderHistory />
                  </Catch>
                }
              />
              <Route
                path="order/:orderDetailsId"
                element={
                  <Catch message="We could not fetch this entity's service requests, please try again shortly.">
                    <ServiceRequestOrderHistoryDetails />
                  </Catch>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path="invoices" element={<Invoices />} />

        <Route path="entityfilings" element={<EntityFilings />} />

        <Route path="reports" element={<Reports />} />
        <Route path="servicerequests" element={<ServiceRequests />} />
        <Route path="termsofuse" element={<TermsOfUse />} />
        <Route path="notfound" element={<Page204 />} />
        <Route path="unauthorised" element={<ContentDenied />} />
        <Route path="releases" element={<Releases />} />
      </Route>
       {(<Route
        path="*"
        element={
          <header>
            <h2>Page Not Found</h2>
          </header>
        }
      />)}
    </Routes>
  );
}
