import AmlOfficers from "./AmlOfficers/AmlOfficersContainer";
import AuthorisedShareCapital from "./AuthorisedShareCapital/AuthorisedShareCapitalContainer";
import AuthorisedShareCapitalBvi from "./AuthorisedShareCapitalBvi/AuthorisedShareCapitalBviContainer";
import CurrentManagers from "./CurrentManagers/CurrentManagersContainer";
import Partnerships from "./Partnerships/PartnershipsContainer";
import UnitPartnershipInterests from "./UnitPartnershipInterests/UnitPartnershipInterestsContainer";
import LLCMembers from "./LLCMembers/LLCMembersContainer";
import NameHistory from "./NameHistory/NameHistoryContainer";
import Officers from "./Officers/OfficersContainer";
import Shareholders from "./Shareholders/ShareholdersContainer";
import SegregatedPortfolios from "./SegregatedPortfolios/SegregatedPortfoliosContainer";
import Vessels from "./Vessels/VesselsContainer";
import headerText from "constants/copy/registers/headerText";
import CurrentPartners from "./CurrentPartners/CurrentPartnersContainer";
import CurrentDirectorsAndOfficers from "./CurrentDirectorsAndOfficers/CurrentDirectorsAndOfficersContainer";
import CurrentDirectorsAndOfficersBvi from "./CurrentDirectorsAndOfficersBvi/CurrentDirectorsAndOfficersBviContainer";
import TrademarkClasses from "./TrademarkClasses/TrademarkClassesContainer";
import SecurityInterestsOverLlcInterests from "./SecurityInterestsOverLlcInterests/SecurityInterestsOverLlcInterestsContainer";
import { EntityDataTypeKeys } from "constants/entity/entityDataTypes";
import Registers from "./Registers/RegistersContainer";
import MaplesTeam from "./MaplesTeam/MaplesTeamContainer";

export interface AccordionMember {
  name: string;
  text: string;
  visible: boolean;
  collapsed?: boolean;
  pageSize?: number;
  component: any;
}

const emptyProps = {
  collapsed: false,
  handleOpenToggle: () => {
    // This is intentional
  },
  updateLoadingStatus: () => {
    // This is intentional
  },
  handleTableChange: () => {
    // This is intentional
  },
  pageSize: 5,
};

const registerEmptyProps = {
  ...emptyProps,
  header: "",
};

const EntityOverviewAccordionComponents: AccordionMember[] = [
  {
    name: EntityDataTypeKeys.Registers,
    text: headerText.Registers,
    visible: true,
    collapsed: false,
    component: <Registers {...registerEmptyProps} />,
  },
  {
    name: EntityDataTypeKeys.Directors,
    text: headerText.CurrentDirectorsAndOfficers,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <CurrentDirectorsAndOfficers {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.AuthorisedCapital,
    text: headerText.AuthorisedShareCapital,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <AuthorisedShareCapital {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.AuthorisedCapitalBvi,
    text: headerText.AuthorisedShareCapitalBvi,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <AuthorisedShareCapitalBvi {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.DirectorsBvi,
    text: headerText.CurrentDirectorsAndOfficersBvi,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <CurrentDirectorsAndOfficersBvi {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.AMLOfficers,
    text: headerText.AmlOfficers,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <AmlOfficers {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.Partnerships,
    text: headerText.Partnerships,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <Partnerships {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.Vessel,
    text: headerText.Vessels,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <Vessels {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.Offices,
    text: headerText.Officers,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <Officers {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.Shareholders,
    text: headerText.Shareholders,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <Shareholders {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.SegregatedPortfolios,
    text: headerText.SegregatedPortfolios,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <SegregatedPortfolios {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.EntityNameHistory,
    text: headerText.NameHistory,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <NameHistory {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.CurrentManagers,
    text: headerText.CurrentManagers,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <CurrentManagers {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.Members,
    text: headerText.CurrentLlcMembers,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <LLCMembers {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.CurrentPartners,
    text: headerText.CurrentPartners,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <CurrentPartners {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.UnitPartnershipInterests,
    text: headerText.UnitPartnershipInterests,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <UnitPartnershipInterests {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.TrademarkClasses,
    text: headerText.TrademarkClasses,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <TrademarkClasses {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.SecurityInterestsLLC,
    text: headerText.SecurityInterestsOverLlcInterests,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <SecurityInterestsOverLlcInterests {...emptyProps} />,
  },
  {
    name: EntityDataTypeKeys.MaplesTeam,
    text: headerText.MaplesTeam,
    visible: true,
    collapsed: false,
    pageSize: 5,
    component: <MaplesTeam {...emptyProps} />,
  },
];

export default EntityOverviewAccordionComponents;
