import { EntityDto } from "common-types";
import { EntityDetailDto } from "common-types/entityDetailDto";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { EntityDetailState, ActiveEntityState } from "./types/entity-state";

const activeEntityStateSelector = (state: AppState): ActiveEntityState => state.activeEntity;
const entityStateSelector = (state: AppState): EntityDetailState => state.entityDetail;

export const getEntity = createSelector(
  activeEntityStateSelector,
  (entitiesState: ActiveEntityState): EntityDto => {
    if (!entitiesState || !entitiesState.activeEntity) 
      return new EntityDto();

    return entitiesState.activeEntity;
  }
);

export const getEntityFetchStatus = createSelector(
  activeEntityStateSelector,
  (entity) => entity.fetchStatus
);

export const getEntityDetail = createSelector(
  entityStateSelector,
  (entityDetailState: EntityDetailState): EntityDetailDto => {
    if(!entityDetailState || !entityDetailState.entityDetail) {
      return new EntityDetailDto();
    }

    return entityDetailState.entityDetail;
  }
);
