import { FetchStatus } from "common-types/fetchStatusEnum";
import { useEffect } from "react";
import AccessDenied from "./accessDenied";

export interface ContentDeniedProps {
  fetchAuthenticatedUser: Function;
  authenticatedUserFetchStatus: FetchStatus;
}

export function ContentDenied(props: ContentDeniedProps) {
  const { fetchAuthenticatedUser, authenticatedUserFetchStatus } = props;

  useEffect(() => {
    if (authenticatedUserFetchStatus === FetchStatus.Default) {
      fetchAuthenticatedUser();
    }
  }, [fetchAuthenticatedUser, authenticatedUserFetchStatus]);

  return (
    <AccessDenied></AccessDenied>
  );
}
