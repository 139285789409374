import { Filter } from "api/interfaces/filter";
import { InvoiceRequest } from "api/interfaces/invoiceRequest";
import { SortInfo } from "api/interfaces/sortInfo";
import { PagedListDto } from "common-types";
import { InvoiceDto } from "common-types/invoiceDto";

export const invoiceActionTypes = {
    FETCH_INVOICES: 'FETCH_INVOICES',
    FETCH_INVOICES_SUCCESS: 'FETCH_INVOICES_SUCCESS',
    FETCH_INVOICES_FAILURE: 'FETCH_INVOICES_FAILURE',
    FETCH_INVOICES_CANCELLED: 'FETCH_INVOICES_CANCELLED',
    FILTER_AND_SORT: 'FILTER_AND_SORT',
    FILTER_AND_SORT_SUCCESS: 'FILTER_AND_SORT_SUCCESS',
    FILTER_AND_SORT_FAILURE: 'FILTER_AND_SORT_FAILURE',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    SET_FILTERS: 'SET_FILTERS',
    RESET_FILTERS: 'RESET_FILTERS',
    SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
    SET_PAGE_SIZE: "SET_PAGE_SIZE",
    SET_SORTING: "SET_SORTING",
}

export const invoiceActions = {
    fetchInvoices: () => ({
        type: invoiceActionTypes.FETCH_INVOICES
    }),

    fetchInvoicesSuccess: (invoices: PagedListDto<InvoiceDto>) => ({
        type: invoiceActionTypes.FETCH_INVOICES_SUCCESS,
        invoices
    }),

    fetchInvoicesFailure: (error: any) => ({
        type: invoiceActionTypes.FETCH_INVOICES_FAILURE,
        error
    }),

    fetchInvoicesCancelled: () => ({
        type: invoiceActionTypes.FETCH_INVOICES_CANCELLED
    }),

    filterAndSort: (invoiceRequest: InvoiceRequest) => ({
        type: invoiceActionTypes.FILTER_AND_SORT,
        invoiceRequest
    }),

    filterAndSortSuccess: (invoices: PagedListDto<InvoiceDto>) => ({
        type: invoiceActionTypes.FILTER_AND_SORT_SUCCESS,
        invoices
    }),

    filterAndSortFailure: (error: any) => ({
        type: invoiceActionTypes.FILTER_AND_SORT_FAILURE,
        error
    }),

    clearFilters: () => ({
        type: invoiceActionTypes.FILTER_AND_SORT_FAILURE
    }),

    setFilters: (filters: Filter[]) => ({
        type: invoiceActionTypes.SET_FILTERS,
        filters
    }),

    resetFilters: () => ({
        type: invoiceActionTypes.RESET_FILTERS
    }),

    setCurrentPage: (pageNumber: number) => ({
        type: invoiceActionTypes.SET_PAGE_NUMBER,
        pageNumber,
    }),
  
    setPageSize: (pageSize: number) => ({
        type: invoiceActionTypes.SET_PAGE_SIZE,
        pageSize,
    }),

    setSorting: (sorting: SortInfo) => ({
        type: invoiceActionTypes.SET_SORTING,
        sorting
    })
}