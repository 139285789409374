import { ComponentType } from "react";
import { useParams } from "react-router-dom";

export function withParams<T>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithParams = (props: T) => {
    const params = useParams();
    return <WrappedComponent {...params} {...props} />;
  };

  ComponentWithParams.displayName = `withParams(${displayName})`;

  return ComponentWithParams;
}
