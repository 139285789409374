import { RefObject, useCallback } from "react";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { KirbySpinner, KirbyTree } from "maples-kirby-react";
import { TreeItem } from "maples-kirby/dist/types/types/tree";
import styles from "./DocManagement.module.scss";

const ContentFolderTree = (props: {
  entityId: number;
  folders: Array<TreeItem>;
  selectFolder: Function;
  treeElement: RefObject<HTMLKirbyTreeElement>;
  selected: Partial<DocumentFolderDto> | undefined;
  fetchStatus: FetchStatus | undefined;
}) => {
  const handleExpansion = useCallback(
    (_tree: Array<TreeItem>, expandedNode: TreeItem): TreeItem[] => {
      if (
        expandedNode &&
        expandedNode.children &&
        expandedNode.children.length > 0
      )
        return expandedNode.children;

      return [];
    },
    []
  );

  const handleSelection = useCallback(
    (item: TreeItem) => {
      props.selectFolder(item?.value);
    },
    [props]
  );

  if (props.fetchStatus === FetchStatus.InProgress) {
    return (
      <div className={styles.spinner}>
        <KirbySpinner size="small" data-testid="folder-tree-spinner" />
      </div>
    );
  }

  if (!props.folders?.length) {
    return <span>There are no folders to display</span>;
  }

  return (
    <KirbyTree
      tree={props.folders}
      handleSelection={handleSelection}
      selectedNode={props.selected?.id?.toString()}
      handleExpansion={handleExpansion}
      emptyMessage="There are no folders to display"
      ref={props.treeElement}
    />
  );
};

export default ContentFolderTree;
