import { Component } from "react";
import { VesselDto } from "common-types/vesselDto";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";

class Vessels extends Component<SimplePagedSectionProps<VesselDto>> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default Vessels;
