import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { KirbyNumericfield } from "maples-kirby-react"

export type QuantityFieldProps = {
    requestItem: ServiceRequestItemDto;
    quantity: number | undefined;
    setQuantity: (quantity: number | undefined) => void;
    maxQuantity: number;
    error: string | undefined;
}

export const QuantityField = (props: QuantityFieldProps) => {

    return (
        <KirbyNumericfield 
            data-testid={`service-requests-item-quantity-${props.requestItem.id}`}
            label="Quantity"
            required
            max={props.maxQuantity}
            min={1}
            maxError={`Quantity must be between 1 and ${props.maxQuantity}.`}
            minError={`Quantity must be between 1 and ${props.maxQuantity}.`}
            error={props.error}
            posOnly
            wholeIntOnly
            value={props.quantity}
            validateOnChange
            handleChange={(field: any) => {
                if (field.target.value.toString().trim() == '') {
                    props.setQuantity(undefined);
                    return;
                }

                if(field.target.value <= props.maxQuantity && field.target.value >= 1){
                    props.setQuantity(field.target.value);
                } 
                
            }} 
        />
    )
}
