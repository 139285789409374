import { useCallback, useEffect, useState } from "react";
import { KirbySection, KirbyTableClient, KirbyButton } from "maples-kirby-react";
import styles from "./releases.module.scss";
import "../TermsOfUse/terms.css";
import { fetchReleases, ReleaseDto } from "api/releases-api";
import { ReleaseInfo } from "./ReleaseInfo";
import useFeatureFlag from "../../../helpers/featureManagement/useFeatureFlag";
import AccessDenied from "../Errors/accessDenied";
export default function Releases() {
  const [releases, setReleases] = useState<ReleaseDto[]>();
  const [showVersions, setShowVersions] = useState<boolean>(false);
  const [releaseNotesEnabled, releaseNotesFlagLoaded] = useFeatureFlag('ReleaseNotes');

  const refreshReleases = useCallback(() => {
   fetchReleases().then(response => {
     setReleases(response);
   });
  }, []);

  useEffect(() => {
   refreshReleases();
  }, []);

  const selectVersion = useCallback((newVersion?: ReleaseDto, versions?: ReleaseDto[]) => {
    let versionCopy = [...versions ?? []];
    versionCopy.forEach((version) => {
      if (newVersion && version.versionNumber === newVersion.versionNumber) {
        version.selected = true;
      } else {
        version.selected = false;
      }
    });
    setReleases([...versionCopy]);
  }, [releases]);

  const columns = useCallback(() => {
    return [{
      title: 'Versions',
      field: 'versionNumber',
      headerFilter: 'text',
      resizable: false,
      cellClick: (e: any, cell: any) => {
        selectVersion(cell.getData(), cell.getTable().getData());
      },
      formatter: (cell: any, formatterParams: any, onRendered: Function) => {
        onRendered(() => {
          if (cell.getData().selected === true) {
            cell.getElement().style.background = 'var(--mdc-theme-primary)';
            cell.getElement().style.color = '#FFF';
          }
        });
        return `${cell.getValue()}`
      }
    }];
  }, [releases, selectVersion]);

  return (
    <>
      {releaseNotesEnabled ? (
        <div className={`${styles.releasesContainer} releasesContainer`} >
          <KirbySection sectionTitle="Releases" >
            <div className={styles.releaseContainer}>
              <KirbyButton handleClick={() => { setShowVersions(!showVersions) }} variant="secondary" className={styles.showVersionsButton}>
                {`${showVersions ? 'Hide Versions' : 'Show Versions'}`}
              </KirbyButton>
              <div className={`${styles.side} ${showVersions ? styles.showVersions : styles.hideVersions}`}>
                <KirbyTableClient
                  columns={columns()}
                  data={releases ?? []}
                  hidePagination
                  hideReset
                  fixedHeight="100%"
                  loading={{ active: false, text: 'Loading versions...' }}
                />
              </div>
              <div className={styles.main}>
                {releases?.map((release, index) => {
                  if (!release.selected)
                    return (null);
                  return (<div key={index}><ReleaseInfo release={release} /></div>);
                })}
              </div>
            </div>
          </KirbySection>
        </div>
      ) : releaseNotesFlagLoaded && (<AccessDenied></AccessDenied>)
      }
    </>
  )
}
