const headerText = {
  AmlOfficers: "Current AML Officers",
  AuthorisedShareCapital: "Authorised Share Capital",
  AuthorisedShareCapitalBvi: "Authorised Share Capital",
  CimaDetails: "CIMA Details",
  CoreEntityDetails: "Entity Details",
  CurrentDirectorsAndOfficers: "Current Directors and Officers",
  CurrentDirectorsAndOfficersBvi: "Current Directors and Officers",
  CurrentLlcMembers: "Current LLC Members",
  CurrentManagers: "Current Managers",
  CurrentPartners: "Current Partners",
  UnitPartnershipInterests: "Current Partnership Interests",
  EntityIdentifiers: "Entity Identifiers",
  EntityTermination: "Entity Termination",
  Partnerships: "Partnerships",
  FscLicence: {
    Header: "FSC Details",
    Tooltip: "Financial Services Commission Details",
  },
  NameHistory: "Name History",
  Officers: "Current Officers",
  Shareholders: "Current Shareholders",
  Registers: "Registers",
  SecurityInterestsOverLlcInterests: "Security Interests Over LLC Interests",
  SegregatedPortfolios: "Segregated Portfolios",
  TrademarkClasses: "Trademark Classes",
  Vessels: "Vessels",
  MaplesTeam: "Maples Team",
  ShippingDetails: "Shipping Details"
};

export default headerText;
