import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { MaplesTeamActions, MaplesTeamActionTypes } from "./maples-team-actions";
import { cancelFetchMaplesTeam, fetchMaplesTeam } from "../../api/maples-team-api";
import { FetchMaplesTeamAction } from "./types/maples-team-actions-types"

export function* watchFetchMaplesTeam(): SagaIterator {
    yield takeEvery(MaplesTeamActionTypes.FETCH_MAPLES_TEAM, function*(action: FetchMaplesTeamAction): any {
        try {
            const { entityId } = action;

            const maplesTeam = yield call(fetchMaplesTeam, entityId);

            yield put(MaplesTeamActions.fetchMaplesTeamSuccess(maplesTeam));
        } catch (e) {
            yield put(MaplesTeamActions.fetchMaplesTeamFailure(e));
        }
    });

    yield takeEvery(MaplesTeamActionTypes.CANCEL_FETCH_MAPLES_TEAM, function*(): any {
            yield call(cancelFetchMaplesTeam);
    });
}

export default function* maplesTeamSaga(): SagaIterator {
    yield all([
      fork(watchFetchMaplesTeam)
    ]);
  }