import { AxiosResponse } from "axios";
import { addToast, updateToast } from "components/common/Toaster";
import { Copy } from "constants/copy/document-management/copy";
import { browserDownloadResult } from "modules/download/fileDownload";
import { getFileName } from "modules/download/fileResult";
import { downloadInvoicePdf } from "modules/invoice/invoice-api";

export const handleInvoiceDownload = async (entityId: number, invoiceNumber: number) => {

    let toastId = await addToast("Downloading", `Downloading Invoice ${invoiceNumber}`, "spinner");

    const blobHandler = (axiosResponse: AxiosResponse<any, any>) => {
      browserDownloadResult(new Blob([axiosResponse.data]), getFileName(axiosResponse.headers));
    };

    const fail = (message: string) => {
        updateToast(toastId, "Invoice Download Failed", message, "error");
    }
    
    downloadInvoicePdf(entityId, invoiceNumber, blobHandler).then((a: any) => {
        if (a.statusCode < 500 && a.statusCode >= 400) {
            fail(a.errors[0] ?? Copy.GenericErrorMessage);
            return;
        }

        if(!a.success) {
            fail(a.errors ? a.errors[0] : Copy.GenericErrorMessage);
            return;
        }

        updateToast(toastId, "Invoice Download Complete", "Invoice downloaded successfully", "success");
    }).catch(() => {
        fail(Copy.GenericErrorMessage);
    });
  }