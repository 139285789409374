import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "modules/entity/entity-selectors";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import PagedListKeys from "constants/pagedListKeys";
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import headerText from "constants/copy/registers/headerText";
import { gridColumns } from "./GridColumns";
import { PartnerDto } from "common-types";
import CurrentPartners from "./CurrentPartners";

const stateSelector = (state: AppState): GenericPagedListState<PartnerDto> => state.currentPartners;

const getPage = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<PartnerDto> & ServerTableContainerOwnProps => {
  return {
    list: getPage(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state), 
    header: headerText.CurrentPartners,
    columns: gridColumns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    responsiveWidth: 31.25,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.CURRENT_PARTNERS);

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPartners);
