import { Component } from "react";
import { format } from "helpers";
import { FetchStatus } from "common-types/fetchStatusEnum";
import headerText from "constants/copy/registers/headerText";
import EntityProperty from "../EntityProperty";
import { FscLicenceComponentProps } from "modules/fscLicence/types/fscLicense-component-types";
import Collapsible from "components/controls/collapsible/Collapsible";
import {
  getLocalStorage,
  updateLocalStorage,
} from "../utils/EntityDetailsUtils";
import {
  AccordionObject,
  AccordionState,
} from "components/common/types/overviewLocalStorage";

class FscLicence extends Component<FscLicenceComponentProps> {
  entityType: string = this.props.entity?.jurisdictionEntityType
    ?.code as string;
  state: AccordionState = {
    open: false,
  };

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetch(this.props.entity.id);
    }
    getLocalStorage().then((store: any) => {
      const entityTypeDetails = store[this.entityType];
      if (entityTypeDetails?.details) {
        let accordionFound = entityTypeDetails.details.find(
          (o: AccordionObject) => o.FscLicence !== undefined
        );
        accordionFound &&
          this.setState({
            open: accordionFound.FscLicence,
          });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<FscLicenceComponentProps>): void {
    if (this.props.entity) {
      if (this.props.entity.id !== prevProps?.entity?.id) {
        this.props.fetch(this.props.entity.id);
      }
    }
  }

  componentWillUnmount(): void {
    this.props.cancel();
  }

  switchContentOnFetchStatus() {
    const { fetchStatus } = this.props;
    return fetchStatus === FetchStatus.Complete
      ? this.FscLicenceContent()
      : null;
  }

  hasValues() {
    const { license } = this.props;
    if (!license) return false;

    return license.licenceNumber || license.licenceDate;
  }

  toggleOpen() {
    this.setState((prevState: AccordionState) =>
      ({
        open: !prevState.open,
      }),
      () =>
        updateLocalStorage(
          this.props.entity?.jurisdictionEntityType?.code as string,
          "FscLicence",
          this.state.open
        )
    );
  }

  FscLicenceContent() {
    const { license } = this.props;
    const { licenceDate, licenceNumber } = license || {};

    if (!this.hasValues()) return null;

    return (
      <Collapsible
        open={this.state.open}
        handleOpenToggle={() => this.toggleOpen()}
        title={headerText.FscLicence.Tooltip}
        headerText={headerText.FscLicence.Header}>
        <div className={"fields"}>
          <EntityProperty title="Licence Number" value={licenceNumber} />
          <EntityProperty
            title="Licence Date"
            value={format.date(new Date(licenceDate ?? ""))}
          />
        </div>
      </Collapsible>
    );
  }

  render() {
    return this.switchContentOnFetchStatus();
  }
}

export default FscLicence;
