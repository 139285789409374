import { MaplesTeamDto } from "common-types/maplesTeamDto"

export const MaplesTeamActionTypes = {
    FETCH_MAPLES_TEAM: "FETCH_ENTITY_MAPLES_TEAM",
    FETCH_MAPLES_TEAM_SUCCESS: "FETCH_ENTITY_MAPLES_TEAM_SUCCESS",
    FETCH_MAPLES_TEAM_FAILURE: "FETCH_ENTITY_MAPLES_TEAM_FAILURE",
    CANCEL_FETCH_MAPLES_TEAM: "CANCEL_FETCH_MAPLES_TEAM"
}

export const MaplesTeamActions = {
    fetchMaplesTeam: (entityId: number) => ({
      type: MaplesTeamActionTypes.FETCH_MAPLES_TEAM,
      entityId,
    }),
  
    fetchMaplesTeamSuccess: (maplesTeam: Array<MaplesTeamDto>) => ({
      type: MaplesTeamActionTypes.FETCH_MAPLES_TEAM_SUCCESS,
      maplesTeam,
    }),
  
    fetchMaplesTeamFailure: (error: any) => ({
      type: MaplesTeamActionTypes.FETCH_MAPLES_TEAM_FAILURE,
      error,
    }),

    cancel: () => ({
      type: MaplesTeamActionTypes.CANCEL_FETCH_MAPLES_TEAM
    })
}