import { DateCell } from "helpers/gridCells";

const gridColumns: Array<any> = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Service or Registered Address',
    field: 'serviceOrRegisteredAddress'
  },
  {
    title: 'Residential or Principal Address',
    field: 'residentialOrPrincipalAddress'
  },
  {
    title: 'Former Name',
    field: 'formerName'
  },
  {
    title: 'Contact Type',
    field: 'contactType'
  },
  // {
  //   title: 'Date of Birth or Incorporation',
  //   field: 'dateOfBirthOrIncorporation',
  //   formatter: DateCell
  // },
  // {
  //   title: 'Place of Birth or Incorporation',
  //   field: 'placeOfBirthOrIncorporation'
  // },
  // {
  //   title: 'Nationality',
  //   field: 'Nationality'
  // },
  {
    title: 'Incorporation Number',
    field: 'IncorporationNumber'
  },
  {
    title: 'Office Held',
    field: 'officeHeld'
  },
  {
    title: 'Date Appointed',
    field: 'dateAppointed',
    formatter: DateCell
  }
];

export { gridColumns };
