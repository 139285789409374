import { useState, useRef, useEffect, useCallback } from "react";
import { KirbyTextfield, KirbyDialog } from "maples-kirby-react";
import styles from "./NewFolderDialog.module.scss";
import { getMercuryDocumentName } from "../Common/documentUtilities";

export interface NewFolderDialogProps {
  open: boolean;
  confirmFunction: (
    folderName: string,
    cb: (result: {
      success: boolean;
      error: string;
      statusCode: number;
    }) => void
  ) => void;
  handleClose: Function;
  parentName: string | undefined;
}

const NewFolderDialog = (props: NewFolderDialogProps) => {
  const [newFolderName, setNewFolderName] = useState("");
  const [error, setError] = useState<string>("");
  const KirbyTextfieldRef = useRef<any>(null);
  const { open, handleClose } = props;

  const closeDialog = useCallback(() => {
    setNewFolderName("");
    setError("");
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (open) {
      const keyDownHandler = (event: {
        key: string;
        preventDefault: () => void;
      }) => {
        if (event.key === "Escape") {
          event.preventDefault();
          closeDialog();
        }
      };
      window.document.addEventListener("keydown", keyDownHandler);

      return () => {
        window.document.removeEventListener("keydown", keyDownHandler);
      };
    }
  }, [open, closeDialog]);

  const confirmFunction = () => {
    let sanitizedName = getMercuryDocumentName(newFolderName);
    setNewFolderName(sanitizedName);

    props.confirmFunction(sanitizedName, (result) => {
      if (result.success || result.statusCode === 403) {
        closeDialog();
        return;
      }

      setError(result.error);
    });
  };

  const handleTextChange = useCallback(
    (e: any) => {
      setNewFolderName(e.target.value);
    },
    [setNewFolderName]
  );

  return (
    <KirbyDialog
      open={props.open}
      handleClose={closeDialog}
      header="Add New Folder"
      actions={[
        { action: "Add Folder", func: confirmFunction, primary: true },
      ]}>
      <div className={styles.infoArea}>
        <label>Parent Folder</label>
        <p test-id="parent-folder-name">{props.parentName}</p>
      </div>

      <KirbyTextfield
        ref={KirbyTextfieldRef}
        label="Name"
        value={newFolderName}
        handleChange={handleTextChange}
        test-id={"folder-name"}
      />
      <p className={styles.notification}>{error}</p>
    </KirbyDialog>
  );
};

export default NewFolderDialog;
