import { KirbyDialog } from "maples-kirby-react";

interface ServiceRequestErrorDialogProps {
  confirm: Function;
  close: Function;
  show: boolean;
}

const ServiceRequestErrorDialog = (props: ServiceRequestErrorDialogProps) => {
  const { confirm, close, show } = props;

  return (
    <div data-testid={`service-request-error-dialog`}>
      <KirbyDialog
        open={show}
        handleClose={() => close()}
        disablebackdropclose={true}
        actions={[
          {
            action: 'Return To Order History',
            func: () => {
              confirm();
            },
            testIdPrefix: 'return-order-history-service-request-error',
            title: 'Return To Order History',
            primary: true
          }
        ]}
      >
        <p>Another user has updated the Service Request Item you are viewing.</p>
      </KirbyDialog>
    </div>
  );
};

export default ServiceRequestErrorDialog;
