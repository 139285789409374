import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { kirbyAdd } from "maples-kirby-icons";
import { KirbyButton } from "maples-kirby-react";

export type AddButtonProps = {
    requestItem: ServiceRequestItemDto;
    enabled: boolean;
    handleClick: Function;
}

export const AddButton = (props: AddButtonProps) => {
    return (
        <KirbyButton 
            disabled={!props.enabled} 
            data-testid={`service-request-item-button-add-${props.requestItem.id}`}
            handleClick={props.handleClick}
            title={props.enabled ? "Add Item to Order" : "Please choose a quantity and certification type (if applicable) to add item to your order"}
            icon={kirbyAdd}
            iconPart="AddButtonIcon"
        >
        </KirbyButton>
    )
}
