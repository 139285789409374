import { MaplesTeamDto } from "common-types/maplesTeamDto";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { createSelector, Selector } from "reselect";
import { AppState } from "store/AppState"
import { MaplesTeamState } from "./types/maples-team-state"

const maplesTeamStateSelector = (state: AppState): MaplesTeamState => state.maplesTeam;

export const getMaplesTeam: Selector<AppState, Array<MaplesTeamDto>> = createSelector(
    maplesTeamStateSelector,
    (maplesTeamState: MaplesTeamState): Array<MaplesTeamDto> => {
        if (!maplesTeamState || !maplesTeamState.maplesTeam) 
            return new Array<MaplesTeamDto>();

        return maplesTeamState.maplesTeam;
    }
);

export const getMaplesTeamFetchStatus = createSelector(
    maplesTeamStateSelector,
    (maplesTeamState: MaplesTeamState): FetchStatus => maplesTeamState.fetchStatus || FetchStatus.NotFound
);