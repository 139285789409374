import { MaplesTeamState } from "./types/maples-team-state";
import { FetchMaplesTeamAction, FetchMaplesTeamSuccessAction } from './types/maples-team-actions-types';
import produce from "immer";
import { MaplesTeamActionTypes } from "./maples-team-actions";
import { FetchStatus } from "common-types/fetchStatusEnum";

const initialState: MaplesTeamState = {
    entityId: 0,
    fetchStatus: undefined,
    maplesTeam: undefined
};

type Action = (FetchMaplesTeamAction & FetchMaplesTeamSuccessAction) | undefined;

export default (
    state = initialState,
    action: Action = undefined
): MaplesTeamState =>
    produce(state, (draft) => {
        switch (action?.type) {
            case MaplesTeamActionTypes.FETCH_MAPLES_TEAM: {
                const { entityId } = action;
                draft.entityId = entityId;
                draft.fetchStatus = FetchStatus.InProgress;
                return;
            }
            case MaplesTeamActionTypes.FETCH_MAPLES_TEAM_SUCCESS: {
                const { maplesTeam } = action;
                draft.maplesTeam = maplesTeam;
                draft.fetchStatus = maplesTeam && maplesTeam.length > 0 ? FetchStatus.Complete : FetchStatus.NoContent;
                return;
            }
            case MaplesTeamActionTypes.FETCH_MAPLES_TEAM_FAILURE: {
                draft.maplesTeam = undefined;
                draft.fetchStatus = FetchStatus.NotFound;
                return;
            }
            default:
                return state;
        }
    });