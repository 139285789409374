import { DateCell } from "helpers/gridCells";

export const securityInterestsOverLlcInterestsGridColumns: Array<any> = [
  {
    title: "Name of Member",
    field: "nameofMember",
  },
  {
    title: "Details of Notice",
    field: "detailsofNotice",
  },
  {
    title: "Date of Receipt at RO",
    field: "dateofReceiptatRO",
    formatter: DateCell
  },
  {
    title: "Time of Receipt at RO",
    field: "timeofReceiptatRO",
  },
  {
    title: "Date of Release",
    field: "dateofRelease",
    formatter: DateCell
  },
];
