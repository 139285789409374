import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { navItems } from "./EntityNavItems";
import { KirbyMenu, KirbyMenuItem, KirbyButton } from "maples-kirby-react";
import { kirbyChevronRight } from "maples-kirby-icons";
import styles from "./EntityNav.module.scss";

export default function EntityNav() {
  const { id } = useParams();
  const location = useLocation();

  return (<ul className={`${styles.list} entitynav`}>
    <MenuItems entityId={id ?? ""} location={location.pathname} />
  </ul>);
}

function MenuItems(props: Readonly<{ entityId: string, location: string }>) {
  const { entityId, location } = props;
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState<{ [key: string]: boolean }>({});

  function navItemActive(currentLocation: string, navPath: string, id: string) {
    let isDefault = navPath === "" && currentLocation.endsWith(id ?? "0");
    let isMatchingNavPath = navPath !== "" && (currentLocation.endsWith(navPath) || currentLocation.includes(navPath + '/'));
  
    return isDefault || isMatchingNavPath;
  }

  return (<>
    {
      navItems().map((item) => {
        let activeStyle = navItemActive(location, item.path, entityId.toString())
          ? styles.selected
          : "";

        return (
          <li key={item.path} title={item.title} className={`${item.path}-link ${activeStyle}`}>
            <h5>
              {item.enabled ? (
                <Link to={item.path}>
                  {item.text}
                </Link>
              ) : (
                item.text
              )}
              {item.subPages && item.subPages.length > 0 &&
                <>
                  <KirbyButton
                    className={`${item.path}-submenuIcon ${subMenuOpen[item.path] ? styles.open : ""}`}
                    icon={kirbyChevronRight}
                    onClick={() => setSubMenuOpen({ ...subMenuOpen, [item.path]: !subMenuOpen[item.path] })}
                  >
                  </KirbyButton>
                  <KirbyMenu
                    position="bottom-end"
                    target={`.${item.path}-link`}
                    open={subMenuOpen[item.path] === true}
                    handleClose={() => setSubMenuOpen({ ...subMenuOpen, [item.path]: false })}
                  >
                    {item.subPages.map((subPage) => {
                      return (
                        <KirbyMenuItem
                          key={`${item.path}-${subPage.path}`}
                          handleClick={() => { navigate(subPage.path) }}
                        >
                          <span>{subPage.text}</span>
                        </KirbyMenuItem>
                      )
                    })}
                  </KirbyMenu>
                </>
              }
            </h5>
          </li>
        );
      })
    }
  </>)
}