
export class PagedListDto<Type> {
  page: Type[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export const EmptyPagedList = <Type>(): PagedListDto<Type> => {
  return {
    currentPage: 1,
    hasNext: false,
    hasPrevious: false,
    page: [],
    pageSize: 20,
    totalCount: 0,
    totalPages: 1
  }
}
