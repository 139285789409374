import { Component } from "react";
import { DirectorOfficerDto } from "../../../../common-types/directorOfficerDto";
import ServerTableContainer from "../../../common/ServerTableContainer";
import { SimplePagedSectionProps } from "../../../common/types";

class CurrentDirectorsAndOfficers extends Component<SimplePagedSectionProps<DirectorOfficerDto>> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default CurrentDirectorsAndOfficers;
