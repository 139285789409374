import { OutstandingInvoicesDto } from "common-types/outstandingInvoicesDto";
import { ENTITIES_ENDPOINT, OUTSTANDINGINVOICES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchOutstandingInvoices";

export const fetchOutstandingInvoices = async (entityId: number): Promise<OutstandingInvoicesDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}/${OUTSTANDINGINVOICES_ENDPOINT}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });
    
  export const cancelFetchOutstandingInvoices = (): void => tryCancelPending(signalKey);