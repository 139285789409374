import { connect } from 'react-redux'
import { getAuthenticatedUser } from "modules/auth/auth-selectors";
import { AppState } from "store/AppState";
import { RequireAction } from "./RequireAction";

function mapStateToProps(state: AppState, ownProps: { actionName: string, fallbackComponent?: JSX.Element }) {
  return {
    user: getAuthenticatedUser(state),
    actionName: ownProps.actionName,
    fallbackComponent: ownProps.fallbackComponent
  }
}

export default connect(mapStateToProps)(RequireAction);
