import { SortOrderEnum } from "api/interfaces/sortOrderEnum";
import { FetchStatus } from "common-types/fetchStatusEnum";
import produce from "immer";
import { EntityFilingsState } from "./types/entityFilings-state";
import { entityFilingsActionTypes } from "./entityFilings-actions";
import { FetchEntityFilingsAction, FetchEntityFilingsSuccessAction } from "./types/entityFilings-actions-types";

const initialState: EntityFilingsState = {
  currentPage: undefined,
  request: {
    filters: [],
    paging: {
      pageNumber: 1,
      pageSize: 10
    },
    sorting: {
      fieldName: 'dueDate',
      sortOrder: SortOrderEnum.DESC,
    }
  },
  fetchStatus: FetchStatus.Default,
  downloadStatus: FetchStatus.Default
}

type Action = (
  FetchEntityFilingsSuccessAction & FetchEntityFilingsAction
) | undefined;

export default (state = initialState, action: Action = undefined): EntityFilingsState =>
  produce(state, (draft) => {

    switch (action?.type) {
      case entityFilingsActionTypes.FETCH_FILINGS: {
        draft.fetchStatus = FetchStatus.InProgress;
        draft.request = action.request;
        return;
      }
      case entityFilingsActionTypes.FETCH_FILINGS_SUCCESS: {
        const { page } = action;

        draft.currentPage = page;
        draft.fetchStatus = page.page && page.page.length > 0 ? FetchStatus.Complete : FetchStatus.NoContent;
        return;
      }
      case entityFilingsActionTypes.FETCH_FILINGS_FAILURE: {
        draft.currentPage = undefined;
        draft.fetchStatus = FetchStatus.Error;
        return;
      }
      case entityFilingsActionTypes.DOWNLOAD_FILINGS: {
        draft.downloadStatus = FetchStatus.InProgress;
        return;
      }
      case entityFilingsActionTypes.DOWNLOAD_FILINGS_SUCCESS: {
        draft.downloadStatus = FetchStatus.Complete;
        return;
      }
      case entityFilingsActionTypes.DOWNLOAD_FILINGS_FAILURE: {
        draft.downloadStatus = FetchStatus.Error;
        return;
      }
      default:
        return state;
    }
  });
