import { connect } from "react-redux";
import { getAuthenticatedUser, getAuthenticatedUserFetchInProgress, getAuthenticatedUserFetchError, getIsAuthenticated } from "modules/auth/auth-selectors";
import { AppState } from "store/AppState";
import { UserMenu } from "./UserMenu";

const mapStateToProps = (state: AppState) => {
  return {
    user: getAuthenticatedUser(state),
    isFetchInProgress: getAuthenticatedUserFetchInProgress(state),
    isFetchError: getAuthenticatedUserFetchError(state),
    isAuthenticated: getIsAuthenticated(state)
  }
};

export default connect(mapStateToProps)(UserMenu);
