import { EntityIdentifierDto } from "../../common-types/entityIdentifierDto"

export const EntityIdentifiersActionTypes = {
  FETCH_ENTITY_IDENTIFIERS: "FETCH_ENTITY_IDENTIFIERS",
  FETCH_ENTITY_IDENTIFIERS_SUCCESS: "FETCH_ENTITY_IDENTIFIERS_SUCCESS",
  FETCH_ENTITY_IDENTIFIERS_FAILURE: "FAILURE",
  CANCEL_FETCH: "CANCEL_FETCH"
}

export const EntityIdentifiersActions = {
  fetchEntityIdentifiers: (entityId: number) => ({
    type: EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS,
    entityId
  }),

  fetchEntityIdentifiersSuccess: (entityIdentifiers: EntityIdentifierDto) => ({
    type: EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS_SUCCESS,
    entityIdentifiers
  }),

  fetchEntityIdentifiersFailure: (error: any) => ({
    type: EntityIdentifiersActionTypes.FETCH_ENTITY_IDENTIFIERS_FAILURE,
    error
  }),

  cancel: () => ({
    type: EntityIdentifiersActionTypes.CANCEL_FETCH
  })
};
