import { EntityDto } from "common-types";
import { EmptyPagedList } from "common-types/paging/pagedListDto";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { RecentlyViewedEntitiesState } from "../types/entities-state";

const recentlyViewedEntitiesStateSelector = (
  state: AppState
): RecentlyViewedEntitiesState => state.recentlyViewedEntities;

export const getRecentlyViewedEntities = createSelector(
  recentlyViewedEntitiesStateSelector,
  (recentlyViewedEntitiesState) => {
    if (
      (recentlyViewedEntitiesState?.recentlyViewedEntities?.page?.length ??
        0) <= 0
    )
      return EmptyPagedList<EntityDto>();

    return recentlyViewedEntitiesState.recentlyViewedEntities;
  }
);

export const getRecentlyViewedEntitiesFetchStatus = createSelector(
  recentlyViewedEntitiesStateSelector,
  (recentlyViewedEntitiesState) => recentlyViewedEntitiesState.fetchStatus
);
