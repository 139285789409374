import { EntityBooleanPropertyProps } from "modules/entity/types/component-types";
import { format } from "helpers";
import { Component } from "react";

class EntityBooleanProperty extends Component<EntityBooleanPropertyProps> {
  render() {
    const { title, value, displayIfEmpty } = this.props || {};
    return value === true || value === false || displayIfEmpty ? (
      <div>
        <label>{title}</label>
        <span>{format.yesNo(value)}</span>
      </div>
    ) : null;
  }
}

export default EntityBooleanProperty;
