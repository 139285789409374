import { orderActions } from "modules/order/order-actions";
import { getOrder, getOrderFetchStatus } from "modules/order/order-selectors";
import { CancelOrderAction, FetchOrderAction } from "modules/order/types/order-actions-types";
import { AppState } from "store/AppState";
import OrderHistoryDetails from "./OrderHistoryDetails";
import { connect } from "react-redux";

const mapStateToProps = (state: AppState) => ({
    order: getOrder(state),
    fetchStatus: getOrderFetchStatus(state),
});

const mapDispatchToProps = {
    fetchOrder: (entityId: number, orderId: number): FetchOrderAction => orderActions.fetchOrder(entityId, orderId),
    cancelOrder: (entityId: number, orderId: number): CancelOrderAction => orderActions.cancelOrder(entityId, orderId),
    completeOrder: (entityId: number, orderId: number): CancelOrderAction => orderActions.completeOrder(entityId, orderId),
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryDetails);