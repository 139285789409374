import { BasketItemDto } from "../basketItemDto";
import { ServiceRequestIdOther } from "./basketUtils";

export const sortOrderItems = (a: BasketItemDto, b: BasketItemDto) => {
    const aDisplayName = getDisplayName(a), bDisplayName = getDisplayName(b);
    if (aDisplayName < bDisplayName)
        return -1;
    if (aDisplayName > bDisplayName)
        return 1;
    return 0;
}

export const sortByDisplayName = (basketItems: BasketItemDto[]): BasketItemDto[] => {
    if (basketItems.length === 0)
        return [];

    return [...basketItems].sort(sortOrderItems);
}

export const getDisplayName = (basketItem: BasketItemDto): string => {
    if (basketItem.serviceRequestItemId == ServiceRequestIdOther)
        return `Other:  ` + basketItem.otherDescription;
    return basketItem.name;
};