import { DateCell } from "helpers/gridCells";

export const officersGridColumns: Array<any> = [
  {
    title: "Officer",
    field: "name"
  },
  {
    title: "Position Held",
    field: "office"
  },
  {
    title: "Date Appointed",
    field: "dateAppointed",
    formatter: DateCell
  }
];
