import produce from "immer";
import { FetchStatus } from "../../common-types/fetchStatusEnum";
import { CimaRegistrationsActionTypes } from "./cima-registrations-actions";
import { FetchCimaRegistrationsSuccessAction } from "./types/cima-registrations-actions-types";
import { EntityCimaRegistrationsState } from "./types/cima-registrations-state";

const initialState: EntityCimaRegistrationsState = {
  entityId: 0,
  cimaRegistrations: undefined,
  fetchCimaRegistrationsStatus: FetchStatus.InProgress
};

export default (
  state = initialState,
  action: FetchCimaRegistrationsSuccessAction = { cimaRegistrations: undefined, type: "" }): EntityCimaRegistrationsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS: {
        draft.fetchCimaRegistrationsStatus = FetchStatus.InProgress;
        return;
      }
      case CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS_SUCCESS: {
        const { cimaRegistrations } = action;
        draft.cimaRegistrations = cimaRegistrations;
        draft.fetchCimaRegistrationsStatus = cimaRegistrations ? FetchStatus.Complete : FetchStatus.NoContent;
        return;
      }
      case CimaRegistrationsActionTypes.FETCH_CIMA_REGISTRATIONS_FAILURE: {
        draft.cimaRegistrations = undefined;
        draft.fetchCimaRegistrationsStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
