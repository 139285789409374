import { useEffect } from "react";
import { debounce } from "lodash";

export default function FixPreviewHandler(setFixPreview: Function) {
  useEffect(() => {
    const handleFixed = debounce(() => {
      if (window.scrollY > 0) {
        setFixPreview(true)
      } else if (window.scrollY <= 0) {
        setFixPreview(false)
      }
    });

    window.addEventListener("scroll", handleFixed);
    window.addEventListener("resize", handleFixed);

    return () => {
      window.removeEventListener("scroll", handleFixed);
      window.removeEventListener("resize", handleFixed);
    };
  }, []);
}
