export const dtoHasNoContent = <T>(
  obj: T,
  propertiesNotToCheck: (keyof typeof obj | null | undefined)[] = []
) => {
  if (obj === undefined || obj === null) return true;

  const props = Object.keys(obj);
  if (props.length === 0) return true;

  return !props
    .filter(
      (prop) => propertiesNotToCheck.find((p) => p === prop) === undefined
    )
    .map((key) => (obj as any)[key])
    .some((value) => value !== undefined && value !== null);
};

export const dtoHasNoContentCustom = <T>(
  obj: T,
  propertiesNotToCheck: (keyof typeof obj | null | undefined)[] = [],
  propertyIsEmptyWhen: (value: any) => boolean = () => false
) => {
  if (obj === undefined || obj === null) return true;

  const props = Object.keys(obj);
  if (props.length === 0) return true;

  return !props
    .filter(
      (prop) => propertiesNotToCheck.find((p) => p === prop) === undefined
    )
    .map((key) => (obj as any)[key])
    .some(
      (value) =>
        value !== undefined && value !== null && !propertyIsEmptyWhen(value)
    );
};
