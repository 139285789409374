import { useEffect } from "react";
import { useCustomEventListener } from 'react-custom-events';
import { debounce } from "lodash";

export default function EntityViewListeners(headerHeight: number, setHeaderHeight: Function, maxHeaderHeight: number, setMaxHeaderHeight: Function, effectTrigger?: any) {

  useEffect(() => {
    window.addEventListener("scroll", handleResize);
    window.addEventListener("resize", handleResize);
    setTimeout(() => {
      let newHeaderHeight = document.querySelector("header")?.getBoundingClientRect().height;
      setHeaderHeight(newHeaderHeight ?? 0);
      setMaxHeaderHeight(newHeaderHeight ?? 0);
    }, 300);

    return () => {
      window.removeEventListener("scroll", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [effectTrigger]);

  const handleResize = debounce(() => {
    setTimeout(() => {
      let newHeaderHeight = document.querySelector("header")?.getBoundingClientRect().height;
      setHeaderHeight(newHeaderHeight ?? 0);
      if (newHeaderHeight && newHeaderHeight > maxHeaderHeight) {
        setMaxHeaderHeight(newHeaderHeight);
      }
    }, 300);
  });

  useCustomEventListener('header-update', handleResize);

}
