import { FormState } from "common-types/forms/FormState";
import { UserSettingsDto } from "common-types/user/userSettingsDto";
import { FetchSettingsAction, SaveSettingsAction, UpdateFormStateAction } from "modules/user/types/user-actions";
import { UserActions } from "modules/user/user-actions";
import { getFormState, getUserSettings, getSaveSettingsStatus } from "modules/user/user-selectors";
import { connect } from "react-redux";
import { AppState } from "store/AppState";
import Settings from "./Settings";

const mapStateToProps = (state: AppState)=> {
  return {
      settings: getUserSettings(state),
      formState: getFormState(state),
      saveSettingsStatus: getSaveSettingsStatus(state)
  };
}

const mapDispatchToProps = {
  fetchSettings: (): FetchSettingsAction => UserActions.fetchSettings(),
  saveSettings: (settings: UserSettingsDto): SaveSettingsAction => UserActions.saveSettings(settings),
  setFormState: (state: FormState): UpdateFormStateAction => UserActions.setFormState(state)
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
