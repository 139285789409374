import { KirbyDialog } from "maples-kirby-react";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { useState } from "react";

export interface ConfirmRemovalDialogProps {
  basketItem: BasketItemDto | undefined;
  confirm: Function;
  close: Function;
  show: boolean;
  message: string;
}

const ConfirmRemovalDialog = (props: ConfirmRemovalDialogProps) => {
  const { basketItem, confirm, close, show } = props;
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  return (
    <div data-testid={`basket-item-confirm-removal-dialog`} >
      <KirbyDialog
        open={show}
        handleClose={(reason: string) => {
          if (reason === 'cancel' || reason === 'backdrop') {
            close(basketItem);
          }
        }}
        disablebackdropclose={true}
        actions={[
          {
            action: 'Confirm',
            primary: true,
            func: async () => {
              setConfirmDisabled(true);
              await confirm(basketItem);
              setConfirmDisabled(false);
            },
            testIdPrefix: 'confirm-removal',
            title: 'Confirm',
            disabled: confirmDisabled
          }
        ]}
      >
        <p>{props.message}</p>
      </KirbyDialog>
    </div> 
  );
};

export default ConfirmRemovalDialog;
