import { ServiceRequestItemCertificationTypeDto } from "common-types/ServiceRequestItemCertificationTypeDto";
import { KirbySelectfield } from "maples-kirby-react";

type CertifateDorpDownProps = {
    id: number;
    setCertificateType: Function;
    setSelectedCertType: Function;
    certificationTypes: ServiceRequestItemCertificationTypeDto[];
    selectedCertType: any;
    setIsDisabledSupportExpress: Function;
    express: boolean;
    setExpress: Function;
    selectedCertTypeError: string | undefined;
    setSelectedCertTypeError: Function;
}

const CertificationTypesDropDown = (props: CertifateDorpDownProps) => {
    const { id, setCertificateType, setSelectedCertType, certificationTypes, selectedCertType, setIsDisabledSupportExpress, express, setExpress, selectedCertTypeError } = props;

    return (
        <KirbySelectfield id={`service-requests-item-cert-types-${id}`} data-testid={`service-requests-item-cert-types-${id}`} 
            label="Certification Type"
            name="SelectCertificateType" 
            options = {certificationTypes.map((option) => {
                return {value : option.id.toString(), label: option.name}
            })}
            value={selectedCertType}
            required
            error={selectedCertTypeError}
            allowempty={true}
            handleChange={(field: any) => {
                const certificationType = certificationTypes.find(a => a.id == field.target.value);
                setSelectedCertType(field.target.value);
                setCertificateType({
                    id: field.target.value,
                    name: certificationType?.name
                });
                if(express && !certificationType?.supportExpress) {
                    setExpress(false);
                }
                setIsDisabledSupportExpress(!certificationType?.supportExpress);
            }}
        />
    );
};

export default CertificationTypesDropDown;
