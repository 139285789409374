import { GridFilterOption } from "common-types/grids/gridFilterOption";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { getDisplayName } from "common-types/serviceRequests/utils/basketItemDtoUtils";

const docTypeDisplayName = (basketItem: BasketItemDto): string => {
    return getDisplayName(basketItem);
};

const getStatusLabel = (id: number): string => {
    switch (id) {
        case 1:
        return 'Added to Cart';
        case 2:
        return 'Submitted';
        case 3:
        return 'Completed';
        case 4:
        return 'Cancelled';
        default:
        return id.toString();
    }
}

const toOption = (value: string, label: string | null = null): GridFilterOption => {
    return { value, label: label ?? value};
}

export default { docTypeDisplayName, getStatusLabel, toOption };