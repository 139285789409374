import { hasAction } from '.';

const documentActionPrefix: string = 'Document';

const hasDeleteAction = (documentSource: string, documentType: string): boolean => {
  if (!documentSource || !documentType) 
    return false;

  return hasAction(`${documentActionPrefix}.${documentSource}.${documentType}.Delete`)
}

const hasCreateAction = (documentSource: string | undefined, documentType: string | undefined): boolean => {
  if (!documentSource || !documentType) 
    return false;

  return hasAction(`${documentActionPrefix}.${documentSource}.${documentType}.Create`)
}

const hasUpdateAction = (documentSource: string, documentType: string): boolean => {
  if (!documentSource || !documentType) 
    return false;

  return hasAction(`${documentActionPrefix}.${documentSource}.${documentType}.Update`)
}

export default { hasDeleteAction, hasCreateAction, hasUpdateAction }
