import { PagedListDto } from "common-types";
import produce from "immer";
import { PagedListActionTypes, PagedListFetchSuccessAction } from "./paged-list-actions";
import { GenericPagedListState } from "./paged-list-state";

export default <Type>(pagedListKey: string) => (
  state: GenericPagedListState<any> = { items: new PagedListDto<Type>(), fetchInProgress: false }, 
  action: PagedListFetchSuccessAction<any> = { items: new PagedListDto<Type>(), type: "" }
): GenericPagedListState<Type> =>
  produce(state, draft => {
    const pagedListActionsTypes = PagedListActionTypes(pagedListKey);

    switch (action.type) {
      case pagedListActionsTypes.FETCH: {
        draft.fetchInProgress = true;
        return;
      }
      case pagedListActionsTypes.FETCH_SUCCESS: {
        const { items } = action;
        draft.items = items;
        draft.fetchInProgress = false;
        return;
      }
      default:
        return state;
    }
  });


