import { HomePageType } from "common-types/homePageTypeEnum";
import produce from "immer";
import { EntitiesActionTypes } from "./entities-actions";
import { SetHomePageType } from "./types/entities-actions-types";
import { EntitiesState } from "./types/entities-state";

const initialState: EntitiesState = {
  homePageType: HomePageType.RecentlyViewedEntities,
};

export default (state = initialState, action: SetHomePageType): EntitiesState =>
  produce(state, (draft) => {
    if (action.type != EntitiesActionTypes.SET_HOMEPAGETYPE) return state;

    const { homePageType } = action;
    draft.homePageType = homePageType;
  });
