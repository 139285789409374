export const boolean = (value: boolean | null | undefined, trueString: string, falseString: string, defaultString: string = "") : string => {
  if (value === true) {
    return trueString;
  }

  if (value === false) {
    return falseString;
  }

  return defaultString;
};

export const yesNo = (value: boolean | null | undefined) => {
  return boolean(value, "Yes", "No");
};