import { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { NoteDto } from "common-types/serviceRequests/noteDto";
import * as ServiceRequestApiModule from "api/service-requests-api";
import { ApiResponse } from "helpers/apiCaller/ApiResponse";
import { KirbyButton, KirbyTextfield } from "maples-kirby-react";
import { kirbyAdd, kirbyRemove } from "maples-kirby-icons";
import { getFormattedDateFromString } from "../../../../helpers/formatting/dateConverter"
import { OrderDto } from "common-types/serviceRequests/orderDto";
import styles from "./OrderNotes.module.scss";

export interface OrderReviewProps {
  readonly order: OrderDto;
  readonly disabled: boolean;
}

export default function OrderNotes(props: OrderReviewProps) {
  const { order, disabled } = props;
  const [noteText, setNoteText] = useState<string | undefined>();
  const [noteError, setNoteError] = useState<string | undefined>();
  const [savingNote, setSavingNote] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteDto[]>([]);
  const [deleteNote, setDeleteNote] = useState<NoteDto | undefined>();
  const { id } = useParams();
  const entityId = id ? parseInt(id) : 0;

  const getOrderNotes = useMemo(() => async (entityId: number, orderId: number): Promise<NoteDto[]> => {
    if (order.id <= 0)
      return [];

    return await ServiceRequestApiModule.fetchOrderNotes(entityId, orderId)
      .then((response) => !response ? [] : response)
      .catch(() => []);
  }, [order]);

  /*
  * Get order notes on component load.
  */
  useEffect(() => {
    getOrderNotes(entityId, order.id)
      .then((notes: NoteDto[]) => {
        setNotes(notes);
    });
  }, [order.id]);

  /*
    Delete note when deleteNote state is set.
  */
  useEffect(() => {

    if (deleteNote) {
      ServiceRequestApiModule.deleteOrderNote(entityId, order.id, deleteNote.id)
        .then((response: ApiResponse<any>) => {
          if (!response.success)
            return;

          notes.splice(notes.findIndex(n => n.id === deleteNote.id), 1);
          let newNotes = notes;
          setNotes(newNotes);
          setDeleteNote(undefined);
        });
    }
  }, [deleteNote]);

  const handleNoteChange = useCallback((a: any) => {
    setNoteText(a.target.value);
    if (a.target.value.trim().length > 1000) {
      setNoteError("Maximum length 1000 characters.");
      return;
    }

    setNoteError(undefined);
  }, []);

  const isCreateNoteDisabled = useCallback((): boolean => {
    return savingNote || noteError !== undefined || !noteText || noteText?.trim() === '' || disabled;
  }, [savingNote, noteError, noteText, disabled]);

  const createNote = useCallback(() => {

    if(noteText && !isCreateNoteDisabled()){

      setSavingNote(true);

      ServiceRequestApiModule.addOrderNote(entityId, order.id, noteText.trim())
        .then((note: NoteDto) => {
          setNotes([note, ...notes]);
          setSavingNote(false);
          setNoteText(undefined);
        });
    }

  }, [noteText, isCreateNoteDisabled]);
  
  return (
    <>
      <h5>Notes</h5>
      <div className={styles.addNote}>
        <KirbyTextfield
          disabled={disabled}
          label="Add New Note"
          error={noteError}
          handleChange={handleNoteChange}
          value={noteText}
          loading={savingNote}
          onKeyDown={(a: any) => {
            if (a.code === 'Enter' || a.code === 'NumpadEnter') {
              if (!a.target.value)
                return;

              handleNoteChange(a);
              if (!isCreateNoteDisabled()) {
                createNote();
                a.target.blur();
              }

            }
          }}
        />
        <KirbyButton title="Add" icon={kirbyAdd} onClick={createNote} disabled={isCreateNoteDisabled()} ></KirbyButton>
      </div>
      <ul className={styles.notes} data-testid={'notes-container'}>
        {notes.map((note) => {
          return <li key={note.id}>
            <div className={styles.note}>
              <span className={styles.notemeta}>{`${note.createdBy} - `}
                <span className={styles.date}>
                  {`${getFormattedDateFromString(note.createdDate.toString(), 'dd-LLL-yyyy, H:mm:ss')} (UTC)`}
                </span>
              </span>
              <span className={styles.notecontent}>{note.note}</span>
            </div>
            <KirbyButton icon={kirbyRemove} onClick={() => setDeleteNote(note)} title="Remove" disabled={disabled}/>
          </li>
        })}
      </ul>
    </>
  )
}
