import { UserAuthenticatedDto } from "common-types/user/userAuthenticatedDto";

export const AuthActionTypes = {
  FETCH_AUTHENTICATED_USER: 'FETCH_AUTHENTICATED_USER',
  FETCH_AUTHENTICATED_USER_SUCCESS: 'FETCH_AUTHENTICATED_USER_SUCCESS',
  FETCH_AUTHENTICATED_USER_FAILURE: 'FETCH_AUTHENTICATED_USER_FAILURE',
  FETCH_IS_AUTHENTICATED: 'FETCH_IS_AUTHENTICATED',
  FETCH_IS_AUTHENTICATED_SUCCESS: 'FETCH_IS_AUTHENTICATED_SUCCESS',
  FETCH_IS_AUTHENTICATED_FAILURE: 'FETCH_IS_AUTHENTICATED_FAILURE'
};

export const AuthActions = {
  fetchIsAuthenticated: () => ({
    type: AuthActionTypes.FETCH_IS_AUTHENTICATED
  }),

  fetchIsAuthenticatedSuccess: (isAuthenticated: boolean) => ({
    type: AuthActionTypes.FETCH_IS_AUTHENTICATED_SUCCESS,
    isAuthenticated
  }),

  fetchIsAuthenticatedFailure: () => ({
    type: AuthActionTypes.FETCH_IS_AUTHENTICATED_FAILURE
  }),

  fetchAuthenticatedUser: () => ({
    type: AuthActionTypes.FETCH_AUTHENTICATED_USER
  }),

  fetchAuthenticatedUserSuccess: (user: UserAuthenticatedDto) => ({
    type: AuthActionTypes.FETCH_AUTHENTICATED_USER_SUCCESS,
    user
  }),

  fetchAuthenticatedUserFailure: (error: any) => ({
    type: AuthActionTypes.FETCH_AUTHENTICATED_USER_FAILURE,
    error
  })
};