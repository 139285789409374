import { Component, createRef } from "react";
import { FetchStatus } from "common-types/fetchStatusEnum";
import {
  RegistersComponentProps,
  RegistersOwnProps,
} from "modules/registers/types/registers-component-types";
import { KirbySection } from "maples-kirby-react";
import styles from "./Registers.module.scss";
import { RegistersPropertiesComponentProps } from "modules/registerProperties/types/registerProperties-component-types";
import { RegisterManifestDto } from "common-types";
import { downloadRegisters } from "./Register-download-actions";
import Register from "./Register";

export type RegistersProps = RegistersComponentProps &
  RegistersPropertiesComponentProps &
  RegistersOwnProps;

class Registers extends Component<RegistersProps> {
  state: {
    collapsedWidthClass: false;
  };

  resizeObserver: ResizeObserver;
  resizeElement = createRef<HTMLDivElement>();
  responsiveBoundary: number = 880;

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetchRegisters(this.props.entity.id);
      this.props.fetchRegisterProperties(this.props.entity.id);
    }
  }

  componentDidUpdate() {
    if (
      this.props.registers !== undefined &&
      this.props.fetchStatus === FetchStatus.Complete &&
      !this.resizeObserver
    ) {
      this.resizeObserver = new ResizeObserver(
        (entries: Array<ResizeObserverEntry>) => {
          this.setState({
            collapsedWidthClass:
              entries[0].contentRect.width <= this.responsiveBoundary
                ? true
                : false,
          });
        }
      );

      this.resizeObserver.observe(this.resizeElement.current as Element);
    }
  }

  handleDownloadClick = (register: RegisterManifestDto) => {
    if (this.props.entity) {
      downloadRegisters(this.props.entity.id, register, {
        toastMessage: register.registerName,
        toastCompleteMessage: register.registerName,
      });
    }
  };

  componentWillUnmount() {
    this.props.cancel()
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    const {
      registers,
      registerProperties,
      fetchStatus,
      collapsed,
      header,
      handleOpenToggle,
    } = this.props;

    if (registers === undefined) return null;

    if (fetchStatus !== FetchStatus.Complete) return null;

    return (
      <KirbySection
        data-testid="entity-registers-section"
        sectionTitle={header}
        open={!collapsed}
        handleOpenToggle={() => handleOpenToggle && handleOpenToggle(header)}>
        <div
          className={`${styles.sectionContent} ${
            this.state?.collapsedWidthClass ? styles.collapsedWidth : ""
          }`}
          ref={this.resizeElement}>
          {registers?.map((register, i) => (
            <Register
              register={register}
              handleDownloadClick={this.handleDownloadClick}
              registerProperties={registerProperties}
              key={`register_${register.displayOrder}_${register.internalCode}`}
            />
          ))}
        </div>
      </KirbySection>
    );
  }
}

export default Registers;
