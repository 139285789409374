import { MaplesTeamDto } from "common-types/maplesTeamDto";
import { ENTITIES_ENDPOINT, MAPLES_TEAM_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchMaplesTeam";

export const fetchMaplesTeam = async(entityId: number): Promise<Array<MaplesTeamDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${MAPLES_TEAM_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchMaplesTeam = (): void => tryCancelPending(signalKey);
