import { useCallback, useState, Fragment } from "react";
import {
  KirbyIcon,
  KirbyMenu,
  KirbyMenuItem,
  KirbyButton,
} from "maples-kirby-react";
import { kirbyAlert, kirbyChevronRight } from "maples-kirby-icons";
import styles from "./EntityHeader.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getHeader, getMessage } from "./EntityHeaderUtils";
import { navItems } from "../EntityNav/EntityNavItems";
import { EntityHeaderProps } from "./EntityHeader";

export default function EntityMobileHeader(props: EntityHeaderProps) {
  const { entity } = props;
  const [viewMenuOpen, setViewMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const header = getHeader(entity);
  const navItemsList = navItems();
  const message =
    entity.dataMappings.length > 0 ? getMessage(entity) : undefined;

  const entityNavigationClick = useCallback(() => {
    setViewMenuOpen(true);
  }, [setViewMenuOpen]);

  const menuHandleClose = useCallback(() => {
    setViewMenuOpen(false);
  }, [setViewMenuOpen]);

  const findActiveNav = useCallback(
    (item: any) => {
      let isDefault =
        item.path === "" &&
        location.pathname.endsWith(entity.id.toString() ?? "0");
      return (
        isDefault || (item.path !== "" && location.pathname.endsWith(item.path))
      );
    },
    [location, entity]
  );

  return (
    <>
      <header data-testid="entity-header" className={`${styles.pageHeader}`}>
        {message ? (
          <div className={styles.closedmessage}>
            <KirbyIcon icon={kirbyAlert} title={message}></KirbyIcon>
            <h5 className={styles.closedtitle}>{message}</h5>
          </div>
        ) : null}
        <div className={styles.entityNav}>
          <span className={styles.entityName} title={header}>
            {header}
          </span>
          <div className={styles.divider}></div>
          <KirbyButton
            className={styles.menuButton}
            id="viewBut"
            variant="tertiary"
            title="Entity Navigation"
            handleClick={entityNavigationClick}>
            {entity.dataMappings.length > 0
              ? navItemsList.find(findActiveNav)?.title
              : ""}
            <KirbyIcon
              icon={kirbyChevronRight}
              className={styles.chevron}></KirbyIcon>
          </KirbyButton>
          <KirbyMenu
            target="#viewBut"
            position="bottom-end"
            open={viewMenuOpen}
            handleClose={menuHandleClose}>
            {navItemsList.map((item, index) => (
              <Fragment key={`key${item.path || 'home'}`}>
                <KirbyMenuItem
                  key={`key${item.path || 'home'}`}
                  handleClick={() => navigate(item.path)}>
                  <span>{item.text}</span>
                </KirbyMenuItem>
                {item.subPages && <div className="subMenu" key={`key${item.path || 'home'}subwrap`}>
                  {item.subPages.map((subPage) =>
                    <KirbyMenuItem
                      key={`key${item.path}${subPage.path}`}
                      handleClick={() => navigate(`${subPage.path}`)}
                      className="subItem"
                    >
                      <div className="spacer">-</div><span>{subPage.text}</span>
                    </KirbyMenuItem>
                  )}
                </div>}
              </Fragment>
            ))}
          </KirbyMenu>
        </div>
      </header>
    </>
  );
}
