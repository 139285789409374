import { ToTreeFolderItem } from "common-types/converters/iTreeItemConverters";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { TreeItem } from "maples-kirby/dist/types/types/tree";

export const convertToTree = (entityFolders : DocumentFolderDto[]) => {
    let newTreeItems = entityFolders.filter(x => x.isRoot).map((doc: DocumentFolderDto) => {

        let ti = ToTreeFolderItem(doc);

        addChildren(ti, entityFolders);

        return ti;

      });
    return newTreeItems;
}

const addChildren = (parent : TreeItem, entityFolders : DocumentFolderDto[]) => {

    entityFolders?.filter(x => x.parentId === parent.key && x.id !== x.parentId).forEach((doc: DocumentFolderDto) => {
        let ti = ToTreeFolderItem(doc);
        parent?.children?.push(ti);
        addChildren(ti, entityFolders);
    })
    parent.hasChildren = parent.children !== undefined ? parent.children?.length > 0 : false;
}