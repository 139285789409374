import {
  EntityPageRequest,
  EntityPageRequestToQueryString,
} from "./interfaces/entityPageRequest";
import { ENTITIES_ENDPOINT, PARTNERSHIPS_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { PartnershipDto } from "common-types";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchPartnerships";

export const fetchPartnerships = async (
  request: EntityPageRequest
): Promise<PagedListDto<PartnershipDto>> =>
  ApiCaller.get(
    `${ENTITIES_ENDPOINT}/${
      request.entityId
    }${PARTNERSHIPS_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchPartnerships = (): void => tryCancelPending(signalKey);
  
  