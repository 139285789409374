import { useCallback, useEffect } from "react";
import { KirbyDialog } from "maples-kirby-react";
import { Copy } from "constants/copy/document-management/copy";

export interface DeleteDocumentsDialogProps {
  open: boolean;
  handleClose: Function;
  confirmFunction: Function;
  message?: string;
}

const DeleteDocumentsDialog = (props: DeleteDocumentsDialogProps) => {
  const { open, handleClose } = props;

  useEffect(() => {
    if (open) {
      const keyDownHandler = (event: {
        key: string;
        preventDefault: () => void;
      }) => {
        if (event.key === "Escape") {
          event.preventDefault();
          handleClose();
        }
      };
      window.document.addEventListener("keydown", keyDownHandler);

      return () => {
        window.document.removeEventListener("keydown", keyDownHandler);
      };
    }
  }, [open, handleClose]);

  const confirmFunction = () => {
    props.confirmFunction();
  };

  const deleteDialogHandleClose = useCallback(() => {
    props.handleClose();
  }, [props]);

  const displayMessage = props.message ?? Copy.DeleteConfirmMessage;

  return (
    <KirbyDialog
      open={props.open}
      handleClose={deleteDialogHandleClose}
      header="Delete Folders/Documents"
      actions={[{ action: "Confirm", func: confirmFunction, primary: true }]}>
      <p>{displayMessage}</p>
    </KirbyDialog>
  );
};

export default DeleteDocumentsDialog;
