import { ENTITIES_ENDPOINT, SERVICEREQUESTBASKET_ENDPOINT, SERVICEREQUESTHISTORY_ENDPOINT, SERVICEREQUESTITEMS_ENDPOINT, SERVICEREQUESTORDER_ENDPOINT, SERVICEREQUESTSUMMARIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { getAbortSignalTryCancelPending } from "./utils/requestAbortion";
import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { BasketItemDto } from "common-types/serviceRequests/basketItemDto";
import { ApiResponse } from "helpers/apiCaller/ApiResponse";
import { OrderDto } from "common-types/serviceRequests/orderDto";
import { NoteDto } from "common-types/serviceRequests/noteDto";
import { OrderSummaryDto } from "common-types/serviceRequests/orderSummaryDto";
import { servicerequestOrderStatusenum } from "common-types/enums/serviceRequestOrderStatusEnum";
import { AxiosRequestConfig, CanceledError } from "axios";
import { Locker } from "./utils/locker";

export const fetchRequestItems = async (entityId: number): Promise<ServiceRequestItemDto[]> => {
  return ApiCaller.get(`${requestItemsUrl(entityId)}`, { signal: getAbortSignalTryCancelPending("fetchRequestItems") });
}

export const fetchBasket = async (entityId: number, cancelSignal: string = "fetchBasket"): Promise<OrderDto> => {
  return ApiCaller.get(`${basketUrl(entityId)}`, { signal: getAbortSignalTryCancelPending(cancelSignal) });
}

const basketUpdateOperationLocker = new Locker();

/*
  This method is built to run in different ways to help avoid concurrency errors.
  cancelPrevious arg, will cancel any previous requests to update an item (built for scenario where user smashes the up arrow on quantity).
  If the basket items order ID is 0, only one request can be made at a time, to allow the order to get created on the first request.
  All other requests are just sent to the server.
*/
export const updateBasket = async (entityId: number, basketItem: BasketItemDto, enableCancellation: boolean = false): Promise<BasketItemDto> => {
  if (enableCancellation)
    return ApiCaller.post(`${basketUrl(entityId)}`, basketItem, { signal: getAbortSignalTryCancelPending("updateBasket" + basketItem.id + basketItem.name) })
      .catch((e: CanceledError<BasketItemDto>) => {
        if (e.code === 'ERR_CANCELED')
          return basketItem;
        throw e;
      });
  
  if (basketItem.serviceRequestOrderId === 0) {
    return basketUpdateOperationLocker.executeWithLock(() => ApiCaller.post(`${basketUrl(entityId)}`, basketItem));
  }

  return ApiCaller.post(`${basketUrl(entityId)}`, basketItem);
}

export const removeFromBasket = async (entityId: number, basketItem: BasketItemDto): Promise<ApiResponse<any>> => {
  return basketUpdateOperationLocker.executeWithLock(
    () => ApiCaller.remove(`${basketUrl(entityId)}/${basketItem.serviceRequestOrderId}/item/${basketItem.id}`));
}

export const deleteOrder = async (entityId: number, orderId: number): Promise<ApiResponse<any>> => {
  return basketUpdateOperationLocker.executeWithLock(
    () => ApiCaller.remove(`${orderUrl(entityId, orderId)}`));
}

export const fetchOrderNotes = async (entityId: number, orderId: number): Promise<NoteDto[]> => {
  return ApiCaller.get(`${notesUrl(entityId, orderId)}`, { signal: getAbortSignalTryCancelPending("fetchOrderNotes") });
}

export const addOrderNote = async (entityId: number, orderId: number, noteText: string): Promise<NoteDto> => {
  return ApiCaller.post(`${notesUrl(entityId, orderId)}?note=${encodeURIComponent(noteText)}`, {});
}

export const deleteOrderNote = async (entityId: number, orderId: number, noteId: number): Promise<ApiResponse<any>> => {
  return await ApiCaller.remove<NoteDto>(`${notesUrl(entityId, orderId)}/${noteId}`);
}

export const fetchOrderHistory = async (entityId: number, cancelSignal: string = "fetchOrderHistory"): Promise<OrderDto[]> => {
  return ApiCaller.get(`${historyUrl(entityId)}`, { signal: getAbortSignalTryCancelPending(cancelSignal) });
}

export const submitOrder = async (entityId: number, orderId: number): Promise<OrderDto> =>
  ApiCaller.patch(`${orderUrl(entityId, orderId)}`, servicerequestOrderStatusenum.Submit);

export const cancelOrder = async (entityId: number, orderId: number): Promise<OrderDto> =>
  ApiCaller.patch(`${orderUrl(entityId, orderId)}`, servicerequestOrderStatusenum.Cancel);

export const completeOrder = async (entityId: number, orderId: number): Promise<OrderDto> =>
  ApiCaller.patch(`${orderUrl(entityId, orderId)}`, servicerequestOrderStatusenum.Complete);

export const fetchOrder = async (entityId: number, orderId: number, enableCancellation: boolean = true): Promise<OrderDto> => {
  let config: AxiosRequestConfig<any> = {};
  if (!enableCancellation)
    config.signal = getAbortSignalTryCancelPending("fetchOrder");

  return ApiCaller.get(`${orderUrl(entityId, orderId)}`, config);
}

export const fetchOrderSummaries = async (): Promise<OrderSummaryDto[]> =>
  ApiCaller.get(`${orderSummariesUrl()}`, { signal: getAbortSignalTryCancelPending("fetchOrderSummaries") });

const requestItemsUrl = (entityId: number) => `${ENTITIES_ENDPOINT}/${entityId}${SERVICEREQUESTITEMS_ENDPOINT}`;
const notesUrl = (entityId: number, orderId: number) => `${ENTITIES_ENDPOINT}/${entityId}${SERVICEREQUESTORDER_ENDPOINT}${orderId}/notes`;
const basketUrl = (entityId: number) => `${ENTITIES_ENDPOINT}/${entityId}${SERVICEREQUESTBASKET_ENDPOINT}`;
const orderUrl = (entityId: number, orderId: number) => `${ENTITIES_ENDPOINT}/${entityId}${SERVICEREQUESTORDER_ENDPOINT}${orderId}`;
const historyUrl = (entityId: number) => `${ENTITIES_ENDPOINT}/${entityId}${SERVICEREQUESTHISTORY_ENDPOINT}`;
const orderSummariesUrl = () => SERVICEREQUESTSUMMARIES_ENDPOINT;