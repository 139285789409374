import { ENTITIES_ENDPOINT, AUTHORISEDSHARECAPITAL_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { AuthorisedShareCapitalDto } from "common-types/authorisedShareCapitalDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchAuthorisedShareCapital";

export const fetchAuthorisedShareCapital = async(request: EntityPageRequest): Promise<PagedListDto<AuthorisedShareCapitalDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${AUTHORISEDSHARECAPITAL_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchAuthorisedShareCapital = (): void => tryCancelPending(signalKey);
