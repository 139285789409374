import { useEffect, useState } from "react";
import EntityNav from "../EntityNav/EntityNav";
import styles from "./EntityHeader.module.scss";
import { KirbyIcon } from "maples-kirby-react";
import { kirbyAlert } from "maples-kirby-icons";
import { EntityDto } from "common-types";
import { getHeader, getMessage } from "./EntityHeaderUtils";
import localForage from "localforage";
import { emitCustomEvent } from 'react-custom-events';
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";
import { useLocation } from "react-router";

export interface EntityHeaderProps {
  entity: EntityDto;
}

export default function EntityHeader(props: EntityHeaderProps) {
  const { entity } = props;
  const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(false);
  const [navCollapsed, setNavCollapsed] = useState<boolean>(false);
  const [serviceRequestsEnabled] = useFeatureFlag('ServiceRequests');
  const location = useLocation();

  const store = localForage.createInstance({
    name: "kirbyConfig",
  });

  const getTabName = (location: any) => {
    if (location.pathname.includes('docs')) {
      return 'Document Management';
    } else if (location.pathname.includes('servicerequests')) {
      return 'Service Request';
    }

    return 'Entity Overview';
  }

  const tabName = () => document.title = `${entity.name } : ${entity.clientNumber } : ${getTabName(location)}`;

  useEffect(() => {
    if (!entity.name && !entity.clientNumber) {
      document.title = getTabName(location);
    } else {
      tabName()
    }
    window.document.addEventListener("kirbyConfigUpdate", onConfigUpdate);
    window.document.addEventListener("scroll", scrollHandler);
    getKirbyNav();
    return () => {
      window.document.removeEventListener("kirbyConfigUpdate", onConfigUpdate);
      window.document.removeEventListener("scroll", scrollHandler);
    };
  });

  useEffect(() => {
    emitCustomEvent('header-update');
  }, [entity]);

  function onConfigUpdate(e: any) {
    setNavCollapsed(e?.detail?.navCollapsed);
  }

  async function getKirbyNav() {
    const kirbyConfig: any = await store.getItem("kirbyConfig");
    if (kirbyConfig) {
      setNavCollapsed(kirbyConfig.navCollapsed);
    }
  }

  const scrollHandler = () => {
    if (window.scrollY > 0 && headerCollapsed === false) {
      setHeaderCollapsed(true);
    } else if (window.scrollY <= 0 && headerCollapsed === true) {
      setHeaderCollapsed(false);
    }
  };

  let message = getMessage(entity);

  return (
    <>
      <header
        data-testid="entity-header"
        className={`
          ${styles.pageHeader} 
          ${navCollapsed ? styles.navCollapsed : ""} 
          ${headerCollapsed ? styles.collapsed + ' collapsed' : ""}
          ${serviceRequestsEnabled ? styles.srEnabled : ""}
        `}
      >
        {message ? (
          <div className={styles.closedmessage}>
            <KirbyIcon icon={kirbyAlert} title={message}></KirbyIcon>
            <h5 className={styles.closedtitle}>{message}</h5>
          </div>
        ) : null}
        <h2 title={getHeader(entity)}>{getHeader(entity)}</h2>
        <EntityNav />
      </header>
    </>
  );
}
