import { Component } from "react";
import ServerTableContainer from "components/common/ServerTableContainer";
import { SimplePagedSectionProps } from "components/common/types";
import { PartnerDto } from "common-types";

class CurrentPartners extends Component<SimplePagedSectionProps<PartnerDto>> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default CurrentPartners;
