import React, { useEffect, useRef } from "react";
import { ServiceRequestItemDto } from "common-types/serviceItemDto";
import { kirbyCertificate } from "maples-kirby-icons";
import { KirbyCheckbox, KirbyIcon, KirbyTextfield, KirbyTooltip } from "maples-kirby-react";
import styles from "./ServiceRequestItem.module.scss";
import { AddButton } from "./Fields/AddButton";
import CertificationTypesDropDown from "./CertificateTypesDropDown";
import { QuantityField } from "./Fields/QuantityField";

type ServiceRequestItemStandardProps = {
    requestItem: ServiceRequestItemDto;
    addHandleClick: Function;
    selectedCertType: any;
    setCertificateType: Function;
    setSelectedCertType: Function; 
    express: boolean;
    setExpress: Function;
    quantity: number | undefined;
    setNewQuantity: Function;
    quantityError: string | undefined;
    setQuantityError: Function;
    selectedCertTypeError: string | undefined;
    setSelectedCertTypeError: Function;
    hasMultipleCertTypesAndSupportExpress: boolean;
    isDisabledSupportExpress: boolean;
    setIsDisabledSupportExpress: Function;
}

const ServiceRequestItemStandard = (props : ServiceRequestItemStandardProps) => {
    const { 
        requestItem, addHandleClick, selectedCertType, setSelectedCertType, setCertificateType, express, setExpress,
        quantity, setNewQuantity, quantityError, setQuantityError, setSelectedCertTypeError, selectedCertTypeError,
        hasMultipleCertTypesAndSupportExpress, isDisabledSupportExpress, setIsDisabledSupportExpress
    } = props;
    const maxQuantity: number = 10;
    const requestItemEl = useRef(null);

    const hasSupportedExpress = requestItem.certificationTypes.length === 1 && requestItem.certificationTypes[0].supportExpress || hasMultipleCertTypesAndSupportExpress;

    useEffect(() => {
      function focusChange(e: any) {
        if (requestItemEl.current !== document.querySelector(`.${styles.requestItem}:focus-within`)) {
          if (quantityError) {
            setNewQuantity(undefined);
            setQuantityError(undefined);
          }
          if (selectedCertTypeError) {
            setSelectedCertType('');
            setSelectedCertTypeError(undefined);
          }
        }
      };

      document.addEventListener('focus', focusChange, true)

      return () => document.removeEventListener('focus', focusChange, true);
    }, [quantityError, quantity, setSelectedCertType, selectedCertTypeError]);

    return (
        <div data-testid={`service-requests-item-component-${requestItem.id}`} className={styles.requestItem} ref={requestItemEl}>
            <div className={styles.requestName}>
            <KirbyIcon icon={kirbyCertificate}></KirbyIcon>
                {
                requestItem.nameOverride ?
                <KirbyTextfield data-testid={`service-requests-item-name-override-${requestItem.id}`} label={requestItem.name} required></KirbyTextfield>
                : <div data-testid={`service-requests-item-name-${requestItem.id}`}><span dangerouslySetInnerHTML={{ __html: requestItem.name.replace('/', '/<wbr>') }}></span></div>
                }
            </div> 
            <div className={styles.certLabel}>         
            {
                requestItem.certificationTypes.length > 1 ?
                <CertificationTypesDropDown 
                    id={requestItem.id}
                    certificationTypes={requestItem.certificationTypes}
                    selectedCertType={selectedCertType}
                    setCertificateType={setCertificateType}
                    setSelectedCertType={setSelectedCertType}
                    setIsDisabledSupportExpress={setIsDisabledSupportExpress}
                    express
                    setExpress={setExpress}
                    setSelectedCertTypeError={setSelectedCertTypeError}
                    selectedCertTypeError={selectedCertTypeError}
                />
                : <div data-testid={`service-requests-item-cert-label-${requestItem.id}`} >{requestItem.certificationTypes[0].name}</div>
            }
            </div>
            <div className={styles.expressCheck}>
                {
                hasSupportedExpress &&
                <>
                    {
                        isDisabledSupportExpress &&
                        <KirbyTooltip
                            hover-open
                            position="bottom-start"
                            tipid={`express-checkbox-id-${requestItem.id}`}
                        >
                            This is only applicable for the 'Certificate from Registry' certification type
                        </KirbyTooltip>
                    }
                    <KirbyCheckbox
                        aria-describedby={`express-checkbox-id-${requestItem.id}`}
                        data-testid={`service-requests-item-support-express-${requestItem.id}`}
                        label="Express"
                        handleChange={(field: any) => setExpress(field.target.checked)}
                        value={String(express)}
                        checked={express}
                        disabled={isDisabledSupportExpress}
                    />
                </>
                }
            </div> 
            <div className={styles.quantity}>
                <QuantityField 
                    requestItem={requestItem} 
                    quantity={quantity} 
                    setQuantity={(quantity) => setNewQuantity(quantity)}
                    maxQuantity={maxQuantity}
                    error={quantityError}
                />
            </div>
            <div className={styles.addButton}>
                <AddButton 
                    enabled={true}
                    handleClick={addHandleClick}
                    requestItem={requestItem}
                />
            </div>
        </div>
    );
}

export default ServiceRequestItemStandard;
