import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "modules/entity/entity-selectors";
import AuthorisedShareCapital from "./AuthorisedShareCapital";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import { AuthorisedShareCapitalDto } from "common-types";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import PagedListKeys from "constants/pagedListKeys";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import { getAuthorisedShareCapitalGridColumns } from "./authorisedShareCapitalGridColumns";
import headerText from "constants/copy/registers/headerText";

const stateSelector = (state: AppState): GenericPagedListState<AuthorisedShareCapitalDto> => state.authorisedShareCapital;

const getAuthorisedShareCapital = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<AuthorisedShareCapitalDto> & ServerTableContainerOwnProps => {
  return {
    list: getAuthorisedShareCapital(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state), 
    header: headerText.AuthorisedShareCapital,
    columns: getAuthorisedShareCapitalGridColumns(state.registers.registers),
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 65,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.AUTHORISEDSHARECAPITAL);

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisedShareCapital);