import { connect } from "react-redux";
import { Actions, hasAction } from "../helpers/userActions";
import EntityFilingsContainer from "./EntityFilings/EntityFilingsContainer";
import styles from "./Home.module.scss";
import Entities from "./Pages/Entities/EntitiesContainer";
import AccessDenied from "./Pages/Errors/accessDenied";

const Home = () => {
  return (
    <>
      {hasAction(Actions.ViewEntity) ?
        (<div className={styles.homePage}>
          <Entities />
        </div>) :
        (hasAction(Actions.ReadEconomicSubstanceFiling) ? (
          <div className={styles.homePage}>
            <EntityFilingsContainer />
          </div>
        ) : <AccessDenied></AccessDenied>)
      }
    </>
  );
};


export default connect()(Home);
