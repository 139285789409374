import { CSSProperties, Component, cloneElement } from "react";
import {
  EntityComponentDispatchProps,
  EntityComponentProps,
} from "modules/entity/types/component-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import EntityDetailContainer from "./EntityDetail/EntityDetailContainer";
import {
  KirbyPill,
  KirbyLoaderPage,
  KirbySorter,
  KirbyButton,
  KirbyIcon
} from "maples-kirby-react";
import styles from "./Entity.module.scss";
import EntityOverviewAccordionComponents, {
  AccordionMember,
} from "./EntityOverviewAccordion";
import { withParams } from "components/common/hoc";
import { EntityDataMappingDto } from "common-types/entityDataMappingDto";
import localForage from "localforage";
import { EntityDataTypeKeys } from "constants/entity/entityDataTypes";
import {
  OverviewStates,
  Ribbon,
} from "components/common/types/overviewLocalStorage";
import { kirbyChevronDown, kirbyGrid, kirbyChevronRight } from "maples-kirby-icons";
import { handleScroll, handleResize, setHeaderHeight } from "./EntityOverviewUtils";
import { UseNavigationProps, withNavigation } from "components/common/hoc/withNavigation";

export type EntityProps = EntityComponentProps & EntityComponentDispatchProps & UseNavigationProps & EntityWithUseParamsProps;

export type EntityWithUseParamsProps = {
  id: number;
  docId: string;
  invoiceId: string;
};

export class EntityOverview extends Component<EntityProps> {
  accordionComponents = EntityOverviewAccordionComponents;

  store = localForage.createInstance({
    name: "overviewStates",
  });

  state: any = this.accordionComponents.reduce(
    (result, accordionComponent, index) => {
      (result as any)[accordionComponent.name] = {
        visible: accordionComponent.visible,
        collapsed: accordionComponent.collapsed,
        pageSize: accordionComponent.pageSize,
        position: index,
      };
      return result;
    },
    {
      detailsOpen: false,
      fixDetails: false,
      headerHeight: 0,
      maxHeaderHeight: 0,
      mobileMode: false,
      pillsShowing: false,
    }
  );
  initialState: any = {};

  componentDidMount() {
    window.addEventListener("scroll", handleScroll.bind(this));
    window.addEventListener("resize", handleResize.bind(this));
    window.addEventListener("header-update", handleResize);
    let newHeaderHeight = document.querySelector("header")?.getBoundingClientRect().height;
    this.setState({
      headerHeight: newHeaderHeight,
      maxHeaderHeight: newHeaderHeight,
      mobileMode: window.innerWidth < 600,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("header-update", handleResize);
  }

  componentDidUpdate(
    prevProps: Readonly<EntityProps>
  ): void {
    if (prevProps.id !== this.props.id)
      this.refreshEntity();
    if (
      this.props.entity?.jurisdictionEntityType?.code !==
      prevProps.entity?.jurisdictionEntityType?.code
    ) {
      this.getLocalStore();
    }
    setHeaderHeight(this);

    if (this.props.docId && this.props.id) {
      this.props.downloadDocument(this.props.id, this.props.docId);
      this.props.navigate(`/entities/${this.props.id}/`);
    }

    if (this.props.invoiceId && this.props.id) {
      this.props.downloadInvoice(this.props.id, this.props.invoiceId);
      this.props.navigate(`/entities/${this.props.id}/`);
    }
  }

  handleOpenToggle(name: string) {
    const clickedAccordion: any = this.accordionComponents
      .filter((c) => this.isRegisterComponentSupported(c.name))
      .find((accordion) => accordion.text === name);
    this.setState((prevState: any) => ({
      [clickedAccordion?.name]: {
        ...prevState[clickedAccordion.name],
        visible: prevState[clickedAccordion.name].visible,
        pageSize: prevState[clickedAccordion.name].pageSize,
        collapsed: !prevState[clickedAccordion.name].collapsed,
      },
    }));
    this.updateLocalStorage();
  }

  async getLocalStore() {
    const { entity } = this.props;
    try {
      const vals: OverviewStates | null = await this.store.getItem(
        "entityTypes"
      );

      if (vals && vals[entity?.jurisdictionEntityType?.code]) {
        let initialState: any = {};
        this.setState((prevState: any) => ({
          ...prevState,
          pillsShowing: vals[entity?.jurisdictionEntityType?.code].pillsShowing
        }))
        vals[entity?.jurisdictionEntityType?.code]?.ribbons?.forEach(
          (ribbon: Ribbon) => {
            initialState[ribbon.name] = {
              ...this.state[ribbon.name],
              visible: ribbon.visible,
              collapsed: ribbon.collapsed,
              pageSize: ribbon.pageSize,
              position: ribbon.position
            };
          }
        );
        this.setState(initialState);
        this.initialState = initialState;
      } else {
        this.updateLocalStorage();
      }
    } catch (err) {
      console.log(err);
    }
  }

  refreshEntity() {
    const { fetchEntity, id } = this.props as EntityProps &
      EntityWithUseParamsProps;
    fetchEntity(id || "");
    this.resetState();
  }

  resetState() {
    let initialState: any = {};
    for (const [key] of Object.entries(this.state)) {
      initialState[key] =
        key === "headerHeight" || key === "maxHeaderHeight" || key === "fixDetails" || key === "mobileMode"
          ? false
          : {
            visible: this.state[key].visible,
            collapsed: this.state[key].collapsed,
            pageSize: this.state[key].pageSize,
            loading: this.state[key].loading,
            pillState: this.state[key].pillState,
          };
    }
    initialState.mobileMode = this.state.mobileMode;
    this.setState(initialState);
  }

  sortAccordions(accordionComponents: AccordionMember[]): AccordionMember[] {
    return accordionComponents.sort((a, b) => this.filteredSort(a, b));
  }

  async updateLocalStorage() {
    const { entity } = this.props;
    const self = this;
    let entityTypes: any = {};
    await self.store
      .getItem("entityTypes")
      .then((data: any) => (entityTypes = { ...data }));
    const activeAccordions = this.sortAccordions(self.accordionComponents);
    const ribbons: Ribbon[] = [];
    activeAccordions.forEach((accordion) => {
      const accordionDetails = {
        id: accordion.name,
        name: accordion.name,
        visible: this.state[accordion.name].visible,
        collapsed: this.state[accordion.name].collapsed,
        pageSize: this.state[accordion.name].pageSize,
        position: this.state[accordion.name].position,
      };
      ribbons.push(accordionDetails);
    });
    entityTypes[entity?.jurisdictionEntityType?.code] = {
      ...entityTypes[entity?.jurisdictionEntityType?.code],
      ribbons: ribbons,
      pillsShowing: this.state.pillsShowing
    };
    self.store.setItem("entityTypes", entityTypes).catch(function (err: any) {
      console.log(err);
    });
  }

  isRegisterComponentSupported = (registerName: string): boolean => {
    let dataMappings = this.props.entity.dataMappings.concat(
      new EntityDataMappingDto(EntityDataTypeKeys.MaplesTeam)
    );

    if (this.entityTypeSupportsRegisters()) {
      dataMappings = dataMappings.concat(
        new EntityDataMappingDto(EntityDataTypeKeys.Registers)
      );
    }

    return dataMappings
      .map((item) => ({
        ...item,
        name: item.name === "GeneralPartners" ? "Partnerships" : item.name,
      }))
      .map((a) => a.name.toLowerCase())
      .includes(registerName.toLowerCase());
  };

  entityTypeSupportsRegisters(): boolean {
    const entityTypesNotSupportingRegisters = [
      "BVIPA",
      "FLP",
      "FOR",
      "TMK",
      "VES",
    ];

    const entityType =
      this.props.entity.jurisdictionEntityType.code.toUpperCase();

    return !entityTypesNotSupportingRegisters.includes(entityType);
  }

  handleRibbonLoading(ribbonState: any) {
    const clickedAccordion: any = this.accordionComponents.find(
      (accordion) => accordion.text === ribbonState.name
    );
    this.setState((prevState: any) => ({
      [clickedAccordion.name]: {
        ...prevState[clickedAccordion.name],
        loading:
          ribbonState.loading && prevState[clickedAccordion.name].visible,
        pillState: ribbonState.hasData ? "" : "empty",
      },
    }));
  }

  showRegisterComponent = (name: string): boolean =>
    this.isRegisterComponentSupported(name) && this.state[name].visible;

  getPillTitle(isLoading: boolean, ribbonLabel: string, pillState: string) {
    if (isLoading)
      return `${ribbonLabel} Loading`;
    
    if (pillState === "empty")
      return `${ribbonLabel} (Empty)`;
      
    return ribbonLabel;
  }

  handleTableChange(name: string, size: number) {
    const clickedAccordion: any = this.accordionComponents.find(
      (accordion) => accordion.text === name
    );
    const pageSizeHasChanged =
      size !== this.state[clickedAccordion.name].pageSize;
    if (pageSizeHasChanged) {
      this.setState((prevState: any) => ({
        [clickedAccordion?.name]: {
          ...prevState[clickedAccordion.name],
          pageSize: size,
        },
      }));
      this.updateLocalStorage();
    }
  }

  handlePillMove = (
    _element: HTMLElement,
    _startPos: number,
    _endPos: number,
    contents: Array<HTMLElement>
  ) => {
    this.setState(
      function (currentState: any) {
        let newState: any = {};
        contents.forEach((pillEl, index) => {
          newState[pillEl.id] = {
            ...currentState[pillEl.id],
            position: index,
          };
        });
        return newState;
      },
      () => {
        this.updateLocalStorage();
      }
    );
  };

  filteredSort = (a: any, b: any) => {
    if (!this.state[a.name]?.position && !this.state[b.name]?.position)
      return 0;
    return this.state[a.name]?.position > this.state[b.name]?.position ? 1 : -1;
  };

  entityContent() {
    const onPillChange = (id: string, show: boolean) => {
      this.setState((prevState: any) => ({
        [id]: {
          ...prevState[id],
          visible: show,
          collapsed: prevState[id].collapsed,
        },
      }));
      this.updateLocalStorage();
    };

    return (
      <>
        <div className={styles.accordions}>
          <div className={styles.pillsToggle}>
            <div className={styles.mobileButtons}>
              <KirbyButton
                className={styles.detailsButton}
                variant="secondary"
                handleClick={() =>
                  this.setState((prevState: any) => ({ detailsOpen: !prevState.detailsOpen }))
                }>
                Entity Details
              </KirbyButton>
              <KirbyButton
                variant="tertiary"
                icon={kirbyGrid}
                handleClick={() =>
                  this.setState((prevState: any) => ({
                    pillsShowing: !prevState.pillsShowing,
                  }), () => {
                    this.updateLocalStorage();
                  })
                }>
                Manage View
                <KirbyIcon icon={this.state.pillsShowing ? kirbyChevronDown : kirbyChevronRight}></KirbyIcon>
              </KirbyButton>
            </div>

            <KirbySorter
              className={`${styles.pills} ${!this.state.pillsShowing ? styles.mobilePillsClosed : ""
                }`}
              handleMove={this.handlePillMove}
              direction={this.state.mobileMode ? "vertical" : "horizontal"}>
              {this.accordionComponents
                .filter((c) => this.isRegisterComponentSupported(c.name))
                .sort((a, b) => this.filteredSort(a, b))
                .map((componentState, i) => (
                  <KirbyPill
                    key={`pill_${componentState.name}_${componentState.text}`}
                    label={componentState.text}
                    id={componentState.name}
                    active={this.state[componentState.name].visible}
                    handleChange={onPillChange}
                    pillState={this.state[componentState.name].pillState}
                    loading={
                      this.state[componentState.name].visible &&
                      this.state[componentState.name].loading
                    }
                    pillTitle={this.getPillTitle(
                      this.state[componentState.name].visible &&
                      this.state[componentState.name].loading,
                      componentState.text,
                      this.state[componentState.name].pillState
                    )}
                    showhandle></KirbyPill>
                ))}
            </KirbySorter>
          </div>
          {this.accordionComponents
            .filter((c) => this.showRegisterComponent(c.name))
            .sort((a, b) => this.filteredSort(a, b))
            .map((componentState) => (
              <div key={`${componentState.name}-${componentState.text}`}>
                {cloneElement(componentState.component, {
                  collapsed: this.state[componentState.name].collapsed,
                  pageSize: this.state[componentState.name].pageSize,
                  handleOpenToggle: (name: string) =>
                    this.handleOpenToggle(name),
                  updateLoadingStatus: (resp: any) =>
                    this.handleRibbonLoading(resp),
                  handleTableChange: (name: string, size: number) =>
                    this.handleTableChange(name, size),
                })}
              </div>
            ))}
        </div>
        <div
          className={`${styles.entityDetail} ${this.state.detailsOpen ? styles.detailsOpen : ""
            }`}>

          <EntityDetailContainer
            closeDetails={() => {
              this.setState((prevState: any) => ({ detailsOpen: !prevState.detailsOpen }));
            }}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div
        className={`${styles.container} ${this.state.fixDetails ? "fixed" : ""
          }`}
        style={
          {
            "--entity-page-header-height": this.state.headerHeight + "px",
            "--entity-page-max-header-height": this.state.maxHeaderHeight + "px"
          } as CSSProperties
        }>
        {this.props.fetchStatus === FetchStatus.Complete ? (
          this.entityContent()
        ) : (
          <KirbyLoaderPage />
        )}
      </div>
    );
  }
}

export default withNavigation(withParams(EntityOverview));

// Shallow tests no longer work when wrapped in withParams.
export { EntityOverview as TestableEntityOverview };
