import produce from "immer";
import { RegisterPropertiesActionTypes } from "./registerProperties-actions";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { RegisterPropertiesState } from "./types/registerProperties-state";
import {
  FetchRegisterPropertiesAction,
  FetchRegisterPropertiesSuccessAction,
} from "./types/registerProperties-actions-types";

const initialState: RegisterPropertiesState = {
  registerProperties: undefined,
  fetchStatus: undefined,
  entityId: 0,
};

export default (
  state = initialState,
  action: FetchRegisterPropertiesAction & FetchRegisterPropertiesSuccessAction | undefined = undefined
): RegisterPropertiesState =>
  produce(state, (draft) => {
    switch (action?.type) {
      case RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES: {
        const { entityId } = action;
        draft.entityId = entityId;
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES_SUCCESS: {
        const { registerProperties } = action;
        draft.registerProperties = registerProperties;
        draft.fetchStatus = registerProperties
          ? FetchStatus.Complete
          : FetchStatus.NoContent;
        return;
      }
      case RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES_FAILURE: {
        draft.registerProperties = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      default:
        return state;
    }
  });
