import { useState } from "react";
import styles from "./DocManagement.module.scss";
import { DocumentSearch } from "./Search/DocumentSearch";
import FolderContent from "./FolderContent";
import { DocumentFolderDto } from "common-types/documentFolderDto";
import { TreeItem } from "maples-kirby/dist/types/types/tree";

export interface DocumentDisplayProps {
  selectedFolder: Partial<DocumentFolderDto> | undefined;
  searchTerm: string;
  resetSearch: () => void;
  isSearchActive: boolean;
  setMobileOpen?: Function;
}

export default function DocumentDisplay(props: DocumentDisplayProps) {
  const {
    selectedFolder,
    isSearchActive,
    searchTerm,
    resetSearch,
    setMobileOpen,
  } = props;

  const [docClipboard, setDocClipboard] = useState<Array<TreeItem>>([]);
  const [clipboardCut, setClipboardCut] = useState<boolean>(false);


  return (
    <div
      className={`${styles.folderContent}`}
      data-testid="folder-display">
      {isSearchActive ? (
        <DocumentSearch
          searchTerm={searchTerm ?? ""}
          resetSearch={resetSearch}
          setDocClipboard={setDocClipboard}
          setClipboardCut={setClipboardCut}
          setMobileOpen={setMobileOpen}
        />
      ) : (
        <FolderContent
          selectedFolder={selectedFolder}
          docClipboard={docClipboard}
          setDocClipboard={setDocClipboard}
          clipboardCut={clipboardCut}
          setClipboardCut={setClipboardCut}
          setMobileOpen={setMobileOpen}
        />
      )}
    </div>
  );
}
