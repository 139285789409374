import { RegisterPropertyDto } from "common-types/registerPropertiesDto";
import { getFormattedDate } from "../../../../helpers/formatting/dateConverter"

export interface RegisterPropertyProps {
  registerProperties: RegisterPropertyDto | undefined;
  registerName: string;
}

function registerProperty(
  key: number,
  label: string,
  value: string | undefined
) {
  if (!value) return null;

  return (
    <div className="register-properties" key={key}>
      <label>{label}</label>
      <span>{value}</span>
    </div>
  );
}

function registerDateProperty(
  key: number,
  label: string,
  value: Date | undefined
) {
  if (!value) return null;
  return registerProperty(key, label, getFormattedDate(value, "dd-LLL-yyyy"));
}

function renderGroupNameProperties(
  registerName: string,
  registerProperties: RegisterPropertyDto | undefined
) {
  if (!registerProperties) return null;

  switch (registerName) {
    case "Register of Directors and Officers": {
      return (
        <>
          {registerProperty(
            1,
            "Maintained At Address:",
            registerProperties.rodMaintainedAddress
          )}
          {registerProperty(
            2,
            "Maintained By:",
            registerProperties.rodMaintainedBy
          )}
          {registerDateProperty(
            3,
            "Filing Date:",
            registerProperties.registerOfDirectorsFilingDate
          )}
        </>
      );
    }
    case "Register of Members": {
      return (
        <>
          {registerProperty(
            4,
            "Maintained At Address:",
            registerProperties.romMaintainedAddress
          )}
          {registerProperty(
            5,
            "Maintained By:",
            registerProperties.romMaintainedBy
          )}
          {registerDateProperty(
            6,
            "Filing Date:",
            registerProperties.registerOfMembersFilingDate
          )}
        </>
      );
    }
    case "Register of LLC Members": {
      return (
        <>
          {registerProperty(
            7,
            "Maintained At Address:",
            registerProperties.roLlcmMaintainedAddress
          )}
          {registerProperty(
            8,
            "Maintained by:",
            registerProperties.roLlcmMaintainedBy
          )}
        </>
      );
    }
    case "Register of Managers": {
      return (
        <>
          {registerProperty(
            9,
            "Maintained At Address:",
            registerProperties.roManagersMaintainedAddress
          )}
          {registerProperty(
            10,
            "Maintained By:",
            registerProperties.roManagersMaintainedBy
          )}
        </>
      );
    }
    case "Register of Partnership Interests": {
      return (
        <>
          {registerProperty(
            11,
            "Maintained At Address:",
            registerProperties.ropiMaintainedAddress
          )}
          {registerProperty(
            12,
            "Maintained By:",
            registerProperties.ropiMaintainedBy
          )}
        </>
      );
    }
    case "Simplified Register of Partnership Interests": {
      return (
        <>
          {registerProperty(
            13,
            "Maintained At Address:",
            registerProperties.ropiMaintainedAddress
          )}
          {registerProperty(
            14,
            "Maintained By:",
            registerProperties.ropiMaintainedBy
          )}
        </>
      );
    }
    case "Register of Unit Holders": {
      return (
        <>
          {registerProperty(
            15,
            "Maintained At Address:",
            registerProperties.unitHolderMaintainedAddress
          )}
          {registerProperty(
            16,
            "Maintained By:",
            registerProperties.unitHolderMaintainedBy
          )}
        </>
      );
    }
    default:
      return null;
  }
}

export default function RegisterProperties(props: RegisterPropertyProps) {
  const { registerProperties, registerName } = props;
  return renderGroupNameProperties(registerName, registerProperties);
}
