import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from "redux-saga";
import { AuthActions, AuthActionTypes } from './auth-actions';
import { FetchAuthenticatedUserAction, FetchIsAuthenticatedAction } from './auth-action-types';
import { fetchAuthenticatedUser, fetchIsAuthenticated } from 'api/auth-api';

export function* watchIsAuthenticated(): SagaIterator {
  yield takeEvery(AuthActionTypes.FETCH_IS_AUTHENTICATED, function* (_action: FetchIsAuthenticatedAction): any {
    try
    {
      let isAuthenticated: boolean = yield call(fetchIsAuthenticated);

      yield put(AuthActions.fetchIsAuthenticatedSuccess(isAuthenticated));
    }
    catch
    {
      yield put(AuthActions.fetchIsAuthenticatedFailure());
    }
  });
}

export function* watchFetchAuthenticatedUser(): SagaIterator {
  yield takeEvery(AuthActionTypes.FETCH_AUTHENTICATED_USER, function* (_action: FetchAuthenticatedUserAction): any {
    try {
      let result = yield call(fetchAuthenticatedUser);
      yield put(AuthActions.fetchAuthenticatedUserSuccess(result));
    }
    catch(error) {
      yield put(AuthActions.fetchAuthenticatedUserFailure("No user found"));
    }
  });
}

export default function* AuthSaga(): SagaIterator {
  yield all([
    fork(watchIsAuthenticated),
    fork(watchFetchAuthenticatedUser)
  ]);
}
