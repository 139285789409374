import { ENTITIES_ENDPOINT, CURRENTDIRECTORSOFFICERSBVI_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { DirectorOfficerBviDto } from "../common-types/directorOfficerBviDto";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey = "fetchCurrentDirectorsAndOfficersBvi";

export const fetchCurrentDirectorsAndOfficersBvi = async (request: EntityPageRequest): Promise<PagedListDto<DirectorOfficerBviDto>> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${CURRENTDIRECTORSOFFICERSBVI_ENDPOINT}${EntityPageRequestToQueryString(request)}`,
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchCurrentDirectorsAndOfficersBvi = (): void => tryCancelPending(signalKey);
