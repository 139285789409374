export const currency = (value: number | null | undefined, numberOfDecimalPlaces: number = 2, defaultString: string = "") : string => {
  if (value === null || value === undefined) {
    return defaultString;
  }
  
  let formatter = new Intl.NumberFormat('en', { 
    minimumFractionDigits: numberOfDecimalPlaces,
    maximumFractionDigits: numberOfDecimalPlaces
  });
  
  return formatter.format(value);
};