import { getAbortSignalTryCancelPending, tryCancelPending } from "api/utils/requestAbortion";
import { CoreEntityDetailsDto } from "common-types/coreEntityDetailsDto";
import { COREENTITYDETAILS_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";

const signalKey = "fetchCoreEntityDetails";

export const fetchCoreEntityDetails = async (entityId: number): Promise<CoreEntityDetailsDto> =>
    ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${COREENTITYDETAILS_ENDPOINT}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });
    
export const cancelFetchCoreEntityDetails = (): void => tryCancelPending(signalKey);