import { connect } from "react-redux";
import { AppState } from "store/AppState";
import { getEntity } from "../../../../modules/entity/entity-selectors";
import { ShippingDetailsActions } from "../../../../modules/shippingDetails/shipping-details-actions";
import { getShippingDetails, getShippingDetailsFetchStatus } from "../../../../modules/shippingDetails/shipping-details-selectors";
import { FetchShippingDetailsAction } from "../../../../modules/shippingDetails/types/shipping-details-actions-types";
import ShippingDetails, { ShippingDetailsProps } from "./ShippingDetails";
import { Action } from "typesafe-actions";

const mapStateToShippingDetailsProps = (state: AppState): Partial<ShippingDetailsProps> => ({
  shippingDetailsDto: getShippingDetails(state),
  fetchShippingDetailsStatus: getShippingDetailsFetchStatus(state),
  entity: getEntity(state),
});

const mapDispatchToShippingDetailsProps = {
  fetchShippingDetails: (entityId: number): FetchShippingDetailsAction => ShippingDetailsActions.fetchShippingDetails(entityId),
  cancel: (): Action => ShippingDetailsActions.cancel()
};

export default connect(
  mapStateToShippingDetailsProps, 
  mapDispatchToShippingDetailsProps
  )(ShippingDetails);
