import { createSelector, Selector } from "reselect";
import { AppState } from "store/AppState";
import { EntityFilingsState } from "./types/entityFilings-state";
import { PagedListDto } from "common-types";
import { EconomicSubstanceFilingDto } from "common-types/economicSubstanceFilingDto";
import { EmptyPagedList } from "common-types/paging/pagedListDto";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { PagedDataRequest } from "api/interfaces/pagedDataRequest";
import { SortOrderEnum } from "api/interfaces/sortOrderEnum";
import { comparisonOperatorEnum } from "api/interfaces/comparisonOperatorEnum";

const entityFilingsStateSelector = (state: AppState): EntityFilingsState => state.entityFilings;

export const getCurrentPage: Selector<AppState, PagedListDto<EconomicSubstanceFilingDto>> = createSelector(
    entityFilingsStateSelector,
    (entityFilingsState: EntityFilingsState): PagedListDto<EconomicSubstanceFilingDto> => {
        if (!entityFilingsState?.currentPage || (entityFilingsState?.currentPage?.page?.length ?? 0) <= 0) {
            return EmptyPagedList<EconomicSubstanceFilingDto>();
        }

        return entityFilingsState.currentPage;
    }
);

export const getFetchStatus: Selector<AppState, FetchStatus> = createSelector(
    entityFilingsStateSelector,
    (entityFilingsState: EntityFilingsState): FetchStatus => entityFilingsState.fetchStatus ?? FetchStatus.Default
);

export const getDownloadStatus: Selector<AppState, FetchStatus> = createSelector(
    entityFilingsStateSelector,
    (entityFilingsState: EntityFilingsState): FetchStatus => entityFilingsState.downloadStatus ?? FetchStatus.Default
);

export const getFilterRequest: Selector<AppState, PagedDataRequest> = createSelector(
    entityFilingsStateSelector,
    (entityFilingsState: EntityFilingsState): PagedDataRequest => {
        if (entityFilingsState.request)
            return entityFilingsState.request;

        return defaultFilingsFilter;
    }
);

export const defaultFilingsFilter = {
    filters: [
    { comparison: comparisonOperatorEnum.IN, fieldName: 'status', value1: ['Pending', 'Overdue', 'Under Review'].join(',') }
    ],
    paging: {
        pageNumber: 1,
        pageSize: 20
    },
    sorting: {
        fieldName: 'duedate',
        sortOrder: SortOrderEnum.ASC
    }
};
