import { DocumentDto } from "common-types/documentDto";

const placeholders = {
  folderDocumentPlaceHolder: "[FolderDocument]"
}

export const isFolderReplacement = (document: DocumentDto | undefined, message: string): string => 
  replace(message, placeholders.folderDocumentPlaceHolder, () => document?.isFolder ?? false, "Folder", "Document");

const replace = (message: string, placeHolder: string, func: () => boolean, trueValue: string, falseValue: string): string =>
  message?.replace(placeHolder, func() ? trueValue : falseValue) ?? '';
