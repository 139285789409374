import { connect } from "react-redux";
import { getEntity } from "modules/entity/entity-selectors";
import { AppState } from "store/AppState";
import FscLicence from "./FscLicence";
import { FetchFscLicenceAction } from "modules/fscLicence/types/fscLicense-actions-types";
import { FscLicenceActions } from "modules/fscLicence/fscLicence-actions";
import { getFscLicence, getFscLicenceFetchStatus } from "modules/fscLicence/fscLicence-selectors";
import { FscLicenceComponentProps } from "modules/fscLicence/types/fscLicense-component-types";
import { Action } from "typesafe-actions";

const mapStateToProps = (state: AppState): Partial<FscLicenceComponentProps> => ({
    license: getFscLicence(state),
    fetchStatus: getFscLicenceFetchStatus(state),
    entity: getEntity(state),
});

const mapDispatchToProps = {
  fetch: (entityId: number): FetchFscLicenceAction => FscLicenceActions.fetchFscLicence(entityId),
  cancel: (): Action => FscLicenceActions.cancel()
};

export default connect(mapStateToProps, mapDispatchToProps)(FscLicence)
