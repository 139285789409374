import { ApiCaller } from "helpers";

export const TokenHeaderName= 'X-CSRF-TOKEN';

export const getToken = async (): Promise<string> => {

  const getTokenFromCookie = (): string => {
    let name = `${TokenHeaderName}=`;
    let decodedCookies = decodeURIComponent(document.cookie);
    let ca = decodedCookies.split(';');
  
    for (const element of ca) {
      let c = element;
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0)
        return c.substring(name.length, c.length);
    }
  
    return '';
  }

  const token = getTokenFromCookie();

  if (token)
    return token;

  await ApiCaller.get(`antiforgery/token`);
  return getTokenFromCookie();
}
