import { Component } from "react";
import { CoreEntityDetailsComponentProps } from "modules/coreEntityDetails/types/core-entity-details-component-type";
import { FetchStatus } from "common-types/fetchStatusEnum";
import Collapsible from "components/controls/collapsible/Collapsible";
import headerText from "constants/copy/registers/headerText";
import EntityProperty from "../EntityProperty";
import { format } from "../../../../helpers";
import {
  getLocalStorage,
  updateLocalStorage,
} from "../utils/EntityDetailsUtils";
import {
  AccordionObject,
  AccordionState,
} from "components/common/types/overviewLocalStorage";
import EntityBooleanProperty from "../EntityBooleanProperty";
import { coreEntityDetailsDtoHasContent } from "common-types/helpers/coreEntityDetailsDtoHelpers";

export type CoreEntityDetailsProps = CoreEntityDetailsComponentProps;

class CoreEntityDetails extends Component<CoreEntityDetailsComponentProps> {
  entityType: string = this.props.entity?.jurisdictionEntityType
    ?.code as string;
  state: AccordionState = {
    open: false,
  };

  componentDidMount() {
    if (this.props.entity) {
      this.props.fetchCoreEntityDetails(this.props.entity.id);
    }
    getLocalStorage().then((store: any) => {
      const entityTypeDetails = store[this.entityType];
      if (entityTypeDetails?.details) {
        let accordionFound = entityTypeDetails.details.find(
          (o: AccordionObject) => o.coreEntityDetails !== undefined
        );
        accordionFound &&
          this.setState({
            open: accordionFound.coreEntityDetails,
          });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<CoreEntityDetailsProps>): void {
    if (this.props.entity) {
      if (this.props.entity.id !== prevProps.entity?.id) {
        this.props.fetchCoreEntityDetails(this.props.entity.id);
      }
    }
  }

  componentWillUnmount(): void {
    this.props.cancel();
  }

  switchContentOnFetchStatus() {
    const { coreEntityDetails, fetchCoreEntityDetailsStatus } = this.props;
    return fetchCoreEntityDetailsStatus === FetchStatus.Complete &&
      coreEntityDetailsDtoHasContent(coreEntityDetails)
      ? this.coreEntityDetailsContent()
      : null;
  }

  toggleOpen() {
    this.setState((prevState: AccordionState) =>
      ({
        open: !prevState.open,
      }),
      () =>
        updateLocalStorage(
          this.props.entity?.jurisdictionEntityType?.code as string,
          "coreEntityDetails",
          this.state.open
        )
    );
  }

  coreEntityDetailsContent() {
    const { coreEntityDetails } = this.props;
    const {
      entityId,
      sezcRegistrationDate,
      filingAgentAddress,
      generalMeetingDate,
      establishmentJurisdiction,
      previousJurisdiction,
      previousJurisdictionIncorpDate,
      proprietorName,
      taxCertificateExpiryDate,
      taxCertificateIssueDate,
      tblExpiryDate,
      trustType,
      trademarkName,
      trademarkRegistrationDate,
      trademarkRegistrationExpiryDate,
      trademarkRegistrationNumber,
      bcActTransProvApplicable,
      registerOfUnitHoldersMaintainedBy,
      addressWhereRegisterOfUnitHoldersIsMaintained,
    } = coreEntityDetails || {};

    return (
      <Collapsible
        open={this.state.open}
        handleOpenToggle={() => this.toggleOpen()}
        title={headerText.CoreEntityDetails}
        headerText={headerText.CoreEntityDetails}>
        <div className={"fields"}>
          <EntityProperty title="Entity Id" value={entityId} />
          <EntityProperty
            title="SEZC Registration Date"
            value={format.date(new Date(sezcRegistrationDate ?? ""))}
          />
          <EntityProperty
            title="Filing Agent Address"
            value={filingAgentAddress}
          />
          <EntityProperty
            title="General Meeting Date"
            value={format.date(new Date(generalMeetingDate ?? ""))}
          />
          <EntityProperty
            title="Jurisdiction of Establishment"
            value={establishmentJurisdiction}
          />
          <EntityProperty
            title="Previous Jurisdiction"
            value={previousJurisdiction}
          />
          <EntityProperty
            title="Previous Jurisdiction Incorporation Date"
            value={format.date(new Date(previousJurisdictionIncorpDate ?? ""))}
          />
          <EntityProperty title="Proprietor Name" value={proprietorName} />
          <EntityProperty
            title="Tax Certificate Issue Date"
            value={format.date(new Date(taxCertificateIssueDate ?? ""))}
          />
          <EntityProperty
            title="Tax Certificate Expiry Date"
            value={format.date(new Date(taxCertificateExpiryDate ?? ""))}
          />
          <EntityProperty
            title="TBL Expiry Date"
            value={format.date(new Date(tblExpiryDate ?? ""))}
          />
          <EntityProperty title="Trust Type" value={trustType} />
          <EntityProperty title="Trademark Name" value={trademarkName} />
          <EntityProperty
            title="Trademark Registration Date"
            value={format.date(new Date(trademarkRegistrationDate ?? ""))}
          />
          <EntityProperty
            title="Trademark Registration Expiry Date"
            value={format.date(new Date(trademarkRegistrationExpiryDate ?? ""))}
          />
          <EntityProperty
            title="Trademark Registration Number"
            value={trademarkRegistrationNumber}
          />
          <EntityBooleanProperty
            title="BC Act Transitional Provisions Applicable"
            value={bcActTransProvApplicable}
          />
          <EntityProperty
            title="Register of Unit Holders Maintained By"
            value={registerOfUnitHoldersMaintainedBy}
          />
          <EntityProperty
            title="Address where Register of Unit Holders is Maintained"
            value={addressWhereRegisterOfUnitHoldersIsMaintained}
          />
        </div>
      </Collapsible>
    );
  }

  render() {
    return this.switchContentOnFetchStatus();
  }
}

export default CoreEntityDetails;
