import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router'
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import history from '../helpers/history';
import reducers from './reducers';

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware(history)
  const middlewares = [sagaMiddleware, routerMiddleware];

  const store = createStore(
    combineReducers({
      ...reducers,
      router: createRouterReducer(history)
    }),
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
