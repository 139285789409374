import { CoreEntityDetailsDto } from "common-types/coreEntityDetailsDto";
import { dtoHasNoContent, dtoHasNoContentCustom } from "./dtoHelpers";

export const coreEntityDetailsDtoHasNoContent = (
  dto: CoreEntityDetailsDto | undefined
): boolean =>
  dtoHasNoContent(dto) ||
  dtoHasNoContentCustom(
    dto!,
    ["entityId"],
    (value) => value === undefined || value === null || value === ""
  );

export const coreEntityDetailsDtoHasContent = (
  dto: CoreEntityDetailsDto | undefined
): boolean => !coreEntityDetailsDtoHasNoContent(dto);
