import { useNavigate } from "react-router-dom";

export type UseNavigationProps = {
    navigate: Function;
};
  
export function withNavigation(WrappedComponent: any) {
    return function(props: any) {
        const navigate = useNavigate();
        return <WrappedComponent {...props} navigate={navigate} />;
    };
}