import { KirbyDialog, KirbyIcon } from "maples-kirby-react";
import styles from "../../FolderContent.module.scss";
import { TreeItem } from "maples-kirby";
import { kirbyDocument, kirbyFolderOpen } from "maples-kirby-icons";

export interface OverwriteFileDialogProps {
    open: boolean;
    handleClose: Function;
    handleCancel: Function;
    confirm: Function;
    files: TreeItem[];
}

const OverwriteFileDialog = (props: OverwriteFileDialogProps) => {
    const {open, files, handleClose, handleCancel} = props;

    const confirmFunction = () => {
        props.confirm(true);
    };

    const pluralChar = `${files.length > 1 ? "s" : ""}`;
    const headerText = `Destination has ${files.length} file${pluralChar} with the same name${pluralChar}`;

    return (
        <KirbyDialog
            open={open}
            handleClose={handleClose}
            nocancel={true}
            header={headerText}
            actions={[
                { action: 'Cancel', title: 'Cancel', func: () => {handleCancel()} },
                { action: `Replace Item${pluralChar}`, func: confirmFunction, primary: true }                
            ]}>
            <ul className={styles.confirmPasteList}>
                {files?.map((item: TreeItem) => {
                    return (
                        <li key={item.key}>
                            <KirbyIcon
                            icon={
                                item.type === "folder" ? kirbyFolderOpen : kirbyDocument
                            }
                            title={item.value.displayName}
                            />
                            {item.value.displayName}
                        </li>
                    );
                })}
            </ul>
        </KirbyDialog>
    );
};

export default OverwriteFileDialog;