import { EntityDto } from "common-types/entityDto";
import { PagedListDto } from "common-types/paging/pagedListDto";

export const AllEntitiesActionTypes = {
  FETCH_ALLENTITIES: "FETCH_ALLENTITIES",
  FETCH_ALLENTITIES_SUCCESS: "FETCH_ALLENTITIES_SUCCESS",
  FETCH_ALLENTITIES_FAILURE: "FETCH_ALLENTITIES_FAILURE",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  IN_PROGRESS: "IN_PROGRESS",
};

export const AllEntitiesActions = {
  fetchAllEntities: (pageNumber: number, pageSize: number) => ({
    type: AllEntitiesActionTypes.FETCH_ALLENTITIES,
    pageNumber,
    pageSize,
  }),

  fetchAllEntitiesSuccess: (allEntities: PagedListDto<EntityDto>) => ({
    type: AllEntitiesActionTypes.FETCH_ALLENTITIES_SUCCESS,
    allEntities,
  }),

  fetchAllEntitiesFailure: (error: any) => ({
    type: AllEntitiesActionTypes.FETCH_ALLENTITIES_FAILURE,
    error,
  }),

  setCurrentPage: (pageNumber: number) => ({
    type: AllEntitiesActionTypes.SET_PAGE_NUMBER,
    pageNumber,
  }),

  setPageSize: (pageSize: number) => ({
    type: AllEntitiesActionTypes.SET_PAGE_SIZE,
    pageSize,
  }),
};
