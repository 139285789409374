import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";

export interface SubMenuNav {
    text: string;
    title: string;
    path: string;
    enabled: boolean;
    subPages?: Array<SubMenuNav>
  }
  
export const navItems = () => {
  const [serviceRequestsEnabled] = useFeatureFlag('ServiceRequests');
  let navList: SubMenuNav[] = [{
    text: "Overview",
    title: "Overview",
    path: "",
    enabled: true
  },
  {
    text: "Document Management",
    title: "Document Management",
    path: "docs",
    enabled: true
  }
  ];
  if (serviceRequestsEnabled) {
    navList.push({
      text: "Service Requests",
      title: "Service Requests",
      path: "servicerequests",
      enabled: true,
      subPages: [
        {
          text: "Order Review",
          title: "Order Review",
          path: "servicerequests/orderreview",
          enabled: true
        },
        {
          text: "Order History",
          title: "Order History",
          path: "servicerequests/orderhistory",
          enabled: true
        },
      ]
    })
  }
  return navList;
};
