import { createSelector } from "reselect";
import { AppState } from "../../store/AppState";
import { EntityIdentifiersState } from "./types/entity-identifiers-state";

const EntityIdentifiersStateSelector = (
  state: AppState
): EntityIdentifiersState => state.entityIdentifiers;

export const getEntityIdentifiers = createSelector(
  EntityIdentifiersStateSelector,
  (entityIdentifiersState) => {
    if (!entityIdentifiersState || !entityIdentifiersState.entityIdentifiers)
      return undefined;
    return entityIdentifiersState.entityIdentifiers;
  }
);

export const getEntityIdentifiersFetchStatus = createSelector(
  EntityIdentifiersStateSelector,
  (properties) => properties?.fetchEntityIdentifiersStatus
);
