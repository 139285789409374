import { RegisterPropertyDto } from "common-types/registerPropertiesDto";
import { FetchRegisterPropertiesAction } from "./types/registerProperties-actions-types";

export const RegisterPropertiesActionTypes = {
  FETCH_REGISTERPROPERTIES: "FETCH_REGISTERPROPERTIES",
  FETCH_REGISTERPROPERTIES_SUCCESS: "FETCH_REGISTERPROPERTIES_SUCCESS",
  FETCH_REGISTERPROPERTIES_FAILURE: "FETCH_REGISTERPROPERTIES_FAILURE",
  CANCEL_REGISTERPROPERTIES: "CANCEL_REGISTERPROPERTIES"
};

export const RegisterPropertiesActions = {
  fetchRegisterProperties: (entityId: number): FetchRegisterPropertiesAction => ({
    type: RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES,
    entityId,
  }),

  fetchRegisterPropertiesSuccess: (registerProperties: RegisterPropertyDto) => ({
    type: RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES_SUCCESS,
    registerProperties,
  }),

  fetchRegisterPropertiesFailure: (error: any) => ({
    type: RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES_FAILURE,
    error,
  }),

  cancelRegisterProperties: () => ({
    type: RegisterPropertiesActionTypes.CANCEL_REGISTERPROPERTIES
  })
};
