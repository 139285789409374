import store from '../../store/configureStore';

export const hasAction = (actionName: string): boolean => {
  if (!actionName) {
    return false;
  }

  let userActions = store.getState().auth.user?.allowedActions ?? [];
  let allowedActions = userActions.map(a => a.toUpperCase());

  return allowedActions.includes(actionName.toUpperCase());
}

export const Actions = {
  BypassTermsAndConditions: 'BypassTermsAndConditions',
  EntityFilingsNavigateToOfflineEntity: 'EconomicSubstanceFiling.NavigateToOfflineEntity',
  ReadEconomicSubstanceFiling: "EconomicSubstanceFiling.Read",
  ViewEntity: "Entities.Read",
  BVIAnnualReturn: "EconomicSubstanceFiling.BVIAnnualReturn",
  BVIEconomicSubstance: "EconomicSubstanceFiling.BVIEconomicSubstance"
}
