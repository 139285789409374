import { useEffect } from "react";
import { useCustomEventListener } from 'react-custom-events';

export default function DocManagementListeners(setLayout: Function, setHeaderHeight: Function) {
  const updateLayout = () => {
    setLayout(window.innerWidth > 599 ? "full" : "mobile");
    setHeaderHeight(
      document.querySelector("header")?.getBoundingClientRect().height ?? 0
    );
  }

  useEffect(() => {
    window.addEventListener("resize", updateLayout);
    updateLayout();
    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  useCustomEventListener('header-update', updateLayout);

}
