import { Component } from "react";
import { AuthorisedShareCapitalDto } from "common-types/authorisedShareCapitalDto";
import { SimplePagedSectionProps } from "components/common/types";
import ServerTableContainer from "components/common/ServerTableContainer";

export type AuthorisedShareCapitalProps =
  SimplePagedSectionProps<AuthorisedShareCapitalDto>;

class AuthorisedShareCapital extends Component<AuthorisedShareCapitalProps> {
  render() {
    return (
      <>{this.props.id > 0 ? <ServerTableContainer {...this.props} /> : null}</>
    );
  }
}

export default AuthorisedShareCapital;
