
export const propertyNames: { [key: string] : string; } = {
  "CimaNumber": "CIMA Number",
  "ClientNumber": "Client Number",  
  "EINNumber": "EIN Number",
  "FiNumber": "FI Number",
  "FormerName": "Former Registered Name",
  "GIINNumber": "GIIN Number",
  "IncorporationNumber": "Incorporation Number",
  "MsnNumber": "MSN Number",
  "Name": "Registered Name",
  "RegistrationNumber": "Registration Number",
}
