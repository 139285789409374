import { FetchStatus } from "common-types/fetchStatusEnum";
import produce from "immer";
import {
  FetchAllEntitiesSuccessAction,
  SetCurrentPageAction,
  SetPageSizeAction,
} from "../types/entities-actions-types";
import { AllEntitiesState } from "../types/entities-state";
import { AllEntitiesActionTypes } from "./allEntities-actions";

const initialState: AllEntitiesState = {
  allEntities: undefined,
  currentPage: 1,
  pageSize: 20,
  fetchStatus: undefined,
};

export default (
  state = initialState,
  action: FetchAllEntitiesSuccessAction &
    SetCurrentPageAction &
    SetPageSizeAction
): AllEntitiesState =>
  produce(state, (draft) => {
    switch (action.type) {
      case AllEntitiesActionTypes.FETCH_ALLENTITIES: {
        draft.fetchStatus = FetchStatus.InProgress;
        return;
      }
      case AllEntitiesActionTypes.FETCH_ALLENTITIES_SUCCESS: {
        const { allEntities } = action;
        draft.allEntities = allEntities;
        draft.fetchStatus = FetchStatus.Complete;
        return;
      }
      case AllEntitiesActionTypes.FETCH_ALLENTITIES_FAILURE: {
        draft.allEntities = undefined;
        draft.fetchStatus = FetchStatus.NotFound;
        return;
      }
      case AllEntitiesActionTypes.SET_PAGE_NUMBER: {
        const { pageNumber } = action;
        draft.currentPage = pageNumber;
        return;
      }
      case AllEntitiesActionTypes.SET_PAGE_SIZE: {
        const { pageSize } = action;
        draft.pageSize = pageSize;
        return;
      }
      default:
        return state;
    }
  });
