import { NotificationType } from 'modules/user/types/component-types';

export const getNotificationTypeHeader = (notificationType: NotificationType): string | undefined => {
  
  const notificationTypeHeaders = new Map<NotificationType, string>([
    [NotificationType.ExecutedDocuments, "Executed Documents"],
    [NotificationType.MinuteBook, "Minute Book"]
  ])

  return notificationTypeHeaders.has(notificationType) ? notificationTypeHeaders.get(notificationType) : notificationType.toString();
}
