import { updateToast, addToast } from 'components/common/Toaster';
import { IProgressRequest, ToastProgressError } from './types/Toaster';

// Initiliazes the toast progress and returns methods to process items.
// Returns action to process item (processNextItem()) 
// Returns action to call when all items are processed (onAllProcessed()) 
const initProgressToast = async <TRequest, TResult>(request: IProgressRequest<TRequest, TResult>): Promise<{ 
    processNextItem: <TRequest>(request: TRequest) => Promise<TResult>, 
    onAllProcessed: (title: string, message: string | null) => Array<TResult> 
  }> => {

  const processNextItem = async <TRequest>(r: TRequest): Promise<TResult> => {
    let result = await request.action<TRequest, TResult>(r);
    results.push(result.value);

    if (!result.success)
      errors.push({ title: '', text: result.message })
    else 
      successCount = successCount + 1;
    
    updateToast(toastId, request.title, result.message, "progress", {
      errors,
      success: successCount,
      total: request.totalItemsToProcess
    })

    return Promise.resolve(result.value);
  }
  
  const onAllProcessed = (title: string, message: string | null): TResult[] => {
    if(!message)
      message = errors.length > 0 ? 'Completed with errors.' : 'Complete.'
    
    updateToast(toastId, title, message, "progress", {
      errors,
      success: successCount,
      total: request.totalItemsToProcess
    });

    return results;
  }

  const toastId = await addToast(request.title, request.message, "progress");
  const errors: Array<ToastProgressError> = [];
  const results: Array<TResult> = [];
  let successCount: number = 0;

  return { processNextItem, onAllProcessed };
}

export default initProgressToast;
