import { AMLO_ENDPOINT, ENTITIES_ENDPOINT } from "constants/urls";
import { ApiCaller } from "helpers";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { AmlOfficerDto } from "common-types";
import { EntityPageRequest, EntityPageRequestToQueryString } from "./interfaces/entityPageRequest";
import { getAbortSignalTryCancelPending, tryCancelPending } from "./utils/requestAbortion";

const signalKey: string = "fetchAMLOfficers";

export const fetchAmlOfficers = async(request: EntityPageRequest): Promise<PagedListDto<AmlOfficerDto>> => 
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${request.entityId}${AMLO_ENDPOINT}${EntityPageRequestToQueryString(request)}`, 
    { signal: getAbortSignalTryCancelPending(signalKey) });

export const cancelFetchAmlOfficers = (): void => tryCancelPending(signalKey);
