import { connect } from "react-redux";
import { ServerTableContainerOwnProps } from "components/common/types";
import { MaplesTeamActions } from "modules/maplesTeam/maples-team-actions";
import { getMaplesTeamFetchStatus, getMaplesTeam } from "modules/maplesTeam/maples-team-selectors";
import { FetchMaplesTeamAction } from "modules/maplesTeam/types/maples-team-actions-types";
import headerText from "constants/copy/registers/headerText";
import { AppState } from "store/AppState";
import MaplesTeam from './MaplesTeam';
import { Action } from "typesafe-actions";

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps) => ({
    maplesTeam: getMaplesTeam(state),
    fetchStatus: getMaplesTeamFetchStatus(state),
    header: headerText.MaplesTeam,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle
});

const mapDispatchToProps = {
    fetchMaplesTeam: (entityId: number): FetchMaplesTeamAction => MaplesTeamActions.fetchMaplesTeam(entityId),
    cancel: (): Action => MaplesTeamActions.cancel()
};

export default connect(mapStateToProps, mapDispatchToProps)(MaplesTeam);
