import { KirbySection } from "maples-kirby-react";
import { Link } from "react-router-dom";
import { EmailHelp } from ".";
import styles from "./Errors.module.scss";

export default function AccessDenied() {

  return (
    <div className={styles.errorPage}>
      <KirbySection sectionTitle="Access Denied">
        <div className={styles.content}>
          <p>
            You do not have access to the requested page. Please contact{" "}
            <EmailHelp /> for further assistance if you believe this is
            incorrect.
          </p>
          <Link to="/" reloadDocument>Return to Homepage</Link>
        </div>
      </KirbySection>
    </div>
  );
}
