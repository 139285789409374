import { EntityDto } from "common-types";
import { EmptyPagedList } from "common-types/paging/pagedListDto";
import { createSelector } from "reselect";
import { AppState } from "store/AppState";
import { AllEntitiesState } from "../types/entities-state";

const allEntitiesStateSelector = (state: AppState): AllEntitiesState =>
  state.allEntities;

export const getAllEntities = createSelector(
  allEntitiesStateSelector,
  (allEntitiesState) => {
    if ((allEntitiesState?.allEntities?.page?.length ?? 0) <= 0)
      return EmptyPagedList<EntityDto>();

    return allEntitiesState.allEntities;
  }
);

export const getAllEntitiesFetchStatus = createSelector(
  allEntitiesStateSelector,
  (allEntitiesState) => allEntitiesState.fetchStatus
);

export const getCurrentPage = createSelector(
  allEntitiesStateSelector,
  (allEntitiesState) => {
    if (!allEntitiesState?.currentPage) return 1;

    return allEntitiesState.currentPage;
  }
);

export const getPageSize = createSelector(
  allEntitiesStateSelector,
  (allEntitiesState) => {
    if (!allEntitiesState?.pageSize) return 20;

    return allEntitiesState.pageSize;
  }
);
