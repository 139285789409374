import { cancelFetchAmlOfficers, fetchAmlOfficers } from "api/amlOfficers-api";
import {
  cancelFetchAuthorisedShareCapital,
  fetchAuthorisedShareCapital,
} from "api/authorisedShareCapital-api";
import {
  cancelFetchAuthorisedShareCapitalBvi,
  fetchAuthorisedShareCapitalBvi,
} from "api/authorisedShareCapitalBvi-api";
import {
  cancelFetchCurrentManagers,
  fetchCurrentManagers,
} from "api/currentManagers-api";
import { cancelFetchMembers, fetchMembers } from "api/members-api";
import { cancelFetchNameHistory, fetchNameHistory } from "api/namehistory-api";
import { cancelFetchOfficers, fetchOfficers } from "api/officers-api";
import { cancelFetchPartners, fetchPartners } from "api/partners-api";
import {
  cancelFetchPartnerships,
  fetchPartnerships,
} from "api/partnerships-api";
import {
  cancelFetchSegregatedPortfolios,
  fetchSegregatedPortfolios,
} from "api/segregatedportfolios-api";
import {
  cancelFetchShareholders,
  fetchShareholders,
} from "api/shareholders-api";
import {
  cancelFetchUnitPartnershipInterests,
  fetchUnitPartnershipInterests,
} from "api/unit-partnership-interests-api";
import { cancelFetchVessels, fetchVessels } from "api/vessels-api";
import pagedListKeys from "constants/pagedListKeys";
import AuthSaga from "modules/auth/auth-saga";
import EntityCimaRegistrationSaga from "modules/cimaRegistrations/cima-registrations-saga";
import PagedListSaga from "modules/common/paged-list/paged-list-saga";
import CoreEntityDetailsSaga from "modules/coreEntityDetails/core-entity-details-saga";
import AllEntitiesSaga from "modules/entities/allEntities/allEntities-saga";
import RecentlyViewedEntitySaga from "modules/entities/recentlyViewedEntities/recentlyViewedEntities-saga";
import SearchResultsSaga from "modules/entities/searchResults/searchResults-saga";
import EntitySaga from "modules/entity/entity-saga";
import EntityTerminationSaga from "modules/entityTermination/entityTermination-saga";
import EntityFscLicenceSaga from "modules/fscLicence/fscLicence-saga";
import invoiceSaga from "modules/invoice/invoice-saga";
import maplesTeamSaga from "modules/maplesTeam/maples-team-saga";
import RegisterPropertiesSaga from "modules/registerProperties/registerProperties-saga";
import RegistersSaga from "modules/registers/registers-saga";
import UserSaga from "modules/user/user-saga";
import { all } from "redux-saga/effects";
import {
  cancelCurrentDirectorsAndOfficers,
  fetchCurrentDirectorsAndOfficers,
} from "../api/directors-officers-api";
import {
  cancelFetchCurrentDirectorsAndOfficersBvi,
  fetchCurrentDirectorsAndOfficersBvi,
} from "../api/directors-officers-bvi-api";
import {
  cancelFetchSecurityInterests,
  fetchSecurityInterests,
} from "../api/securityInterestsOverLlcInterests-api";
import {
  cancelFetchTrademarkClasses,
  fetchTrademarkClasses,
} from "../api/trademarkClasses-api";
import EntityIdentifiersSaga from "../modules/entityIdentifiers/entity-identifiers-saga";
import ShippingDetailsSaga from "../modules/shippingDetails/shipping-details-saga";
import orderSaga from "modules/order/order-saga";
import entityFilingsSaga from "modules/entityfilings/entityFilings-saga";

export default function* rootSaga(): any {
  yield all([
    AuthSaga(),
    CoreEntityDetailsSaga(),
    EntitySaga(),
    AllEntitiesSaga(),
    RecentlyViewedEntitySaga(),
    SearchResultsSaga(),
    PagedListSaga(
      pagedListKeys.AMLOFFICERS,
      fetchAmlOfficers,
      cancelFetchAmlOfficers
    ),
    PagedListSaga(
      pagedListKeys.AUTHORISEDSHARECAPITAL,
      fetchAuthorisedShareCapital,
      cancelFetchAuthorisedShareCapital
    ),
    PagedListSaga(
      pagedListKeys.AUTHORISEDSHARECAPITALBVI,
      fetchAuthorisedShareCapitalBvi,
      cancelFetchAuthorisedShareCapitalBvi
    ),
    PagedListSaga(
      pagedListKeys.PARTNERSHIPS,
      fetchPartnerships,
      cancelFetchPartnerships
    ),
    PagedListSaga(pagedListKeys.VESSELS, fetchVessels, cancelFetchVessels),
    PagedListSaga(pagedListKeys.OFFICERS, fetchOfficers, cancelFetchOfficers),
    PagedListSaga(
      pagedListKeys.SHAREHOLDERS,
      fetchShareholders,
      cancelFetchShareholders
    ),
    PagedListSaga(
      pagedListKeys.SEGREGATED_PORTFOLIOS,
      fetchSegregatedPortfolios,
      cancelFetchSegregatedPortfolios
    ),
    PagedListSaga(
      pagedListKeys.NAME_HISTORY,
      fetchNameHistory,
      cancelFetchNameHistory
    ),
    PagedListSaga(
      pagedListKeys.UNIT_PARTNERSHIP_INTERESTS,
      fetchUnitPartnershipInterests,
      cancelFetchUnitPartnershipInterests
    ),
    PagedListSaga(
      pagedListKeys.CURRENT_MANAGERS,
      fetchCurrentManagers,
      cancelFetchCurrentManagers
    ),
    PagedListSaga(pagedListKeys.MEMBERS, fetchMembers, cancelFetchMembers),
    PagedListSaga(
      pagedListKeys.CURRENT_PARTNERS,
      fetchPartners,
      cancelFetchPartners
    ),
    PagedListSaga(
      pagedListKeys.CURRENT_DIRECTORSANDOFFICERS,
      fetchCurrentDirectorsAndOfficers,
      cancelCurrentDirectorsAndOfficers
    ),
    PagedListSaga(
      pagedListKeys.CURRENT_DIRECTORSANDOFFICERSBVI,
      fetchCurrentDirectorsAndOfficersBvi,
      cancelFetchCurrentDirectorsAndOfficersBvi
    ),
    PagedListSaga(
      pagedListKeys.SECURITYINTERESTSOVERLLCINTERESTS,
      fetchSecurityInterests,
      cancelFetchSecurityInterests
    ),
    PagedListSaga(
      pagedListKeys.TRADEMARK_CLASSES,
      fetchTrademarkClasses,
      cancelFetchTrademarkClasses
    ),
    RegistersSaga(),
    RegisterPropertiesSaga(),
    EntityCimaRegistrationSaga(),
    EntityIdentifiersSaga(),
    EntityFscLicenceSaga(),
    UserSaga(),
    EntityTerminationSaga(),
    maplesTeamSaga(),
    ShippingDetailsSaga(),
    invoiceSaga(),
    orderSaga(),
    entityFilingsSaga()
  ]);
}
