import { AppState } from "store/AppState";
import { OrderState } from "./types/order-state";
import { OrderDto } from "common-types/serviceRequests/orderDto";
import { Selector, createSelector } from "reselect";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { OrderSummaryDto } from "common-types/serviceRequests/orderSummaryDto";

const orderStateSelector = (state: AppState): OrderState => state.order;

export const getOrder = (state: AppState): OrderDto | undefined => state.order.order;
export const getOrderFetchStatus = (state: AppState): FetchStatus => state.order.orderFetchStatus ?? FetchStatus.NotFound;
export const getOrdersFetchStatus = (state: AppState): FetchStatus => state.order.ordersFetchStatus ?? FetchStatus.NotFound;
export const getOrderSummary = (state: AppState): OrderSummaryDto[] => state.order.ordersOverview;
export const getOrderSummaryFetchStatus = (state: AppState): FetchStatus | undefined => state.order.orderOverviewFetchStatus;

export const getOrders: Selector<AppState, OrderDto[]> = createSelector(
    orderStateSelector,
    (orderState: OrderState): OrderDto[] => {
        if (!orderState?.orders) 
            return [];

        return orderState.orders;
    }
);