import { FscLicenceDto } from "common-types/FscLicenseDto";
import { ENTITIES_ENDPOINT, FSCLICENCE_ENDPOINT } from "constants/urls";
import { ApiCaller } from "../../helpers";
import { getAbortSignalTryCancelPending, tryCancelPending } from "api/utils/requestAbortion";

const signalKey = "fetchFscLicence";

export const fetchFscLicence = async(entityId: number): Promise<FscLicenceDto> =>
  ApiCaller.get(`${ENTITIES_ENDPOINT}/${entityId}${FSCLICENCE_ENDPOINT}`, 
  { signal: getAbortSignalTryCancelPending(signalKey) });
  
export const cancelFetchFscLicense = (): void => tryCancelPending(signalKey);
