import { GenericCopy } from "../generic-copy";

export const ServiceRequestsCopy = {
    OrderItems : {
        Title: "Order Items",
        ExpressMessage : "Express service requests for documents issued by the Registrar received prior to 3:00 pm local business time will be completed within the same business day.",
        MandatoryMessage : "*Indicates mandatory field",
        NoItemsAvailable : "There are no items available to order",
        PromiseErrorTitle : GenericCopy.genericError,
        PromiseErrorMessage : "An error occurred"
    },
    OrderPreview : {
      Title : "Order Preview"
    },
    OrderHistory : {
      Title : "Order History",
      PromiseErrorTitle : GenericCopy.genericError,
      PromiseErrorMessage : "An error occurred"
    },
    OrdersOverview : {
      Title : "Service Requests Overview",
      PromiseErrorTitle : GenericCopy.genericError,
      PromiseErrorMessage : "An error occurred"
    }
  }