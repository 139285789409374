import { connect } from "react-redux";
import { ServerTableContainerProps, ServerTableContainerOwnProps } from "components/common/types";
import { getEntity } from "modules/entity/entity-selectors";
import { AppState } from "store/AppState";
import { PagedListDispatchPropsActions } from "modules/common/paged-list/paged-list-actions";
import { GenericPagedListState } from "modules/common/paged-list/paged-list-state";
import PagedListSelectors from 'modules/common/paged-list/paged-list-selectors';
import PagedListKeys from "constants/pagedListKeys";
import NameHistory from "./NameHistory";
import { NameHistoryDto } from "common-types";
import headerText from "constants/copy/registers/headerText";
import { nameHistoryGridColumns } from "./NameHistoryContainerGridColumns";

const stateSelector = (state: AppState): GenericPagedListState<NameHistoryDto> => state.nameHistory;

const getNameHistory = PagedListSelectors.getItemsSelector(stateSelector);
const getCurrentPageInfo = PagedListSelectors.getPageInfoSelector(stateSelector);
const getFetchStatus = PagedListSelectors.getFetchStatusSelector(stateSelector);

const mapStateToProps = (state: AppState, ownProps: ServerTableContainerOwnProps): ServerTableContainerProps<NameHistoryDto> & ServerTableContainerOwnProps => {
  return {
    list: getNameHistory(state)?.page,
    currentPageInfo: getCurrentPageInfo(state),
    loading: getFetchStatus(state), 
    header: headerText.NameHistory,
    columns: nameHistoryGridColumns,
    id: getEntity(state).id,
    collapsed: ownProps.collapsed,
    handleOpenToggle: ownProps.handleOpenToggle,
    updateLoadingStatus: ownProps.updateLoadingStatus,
    responsiveWidth: 25,
    handleTableChange: ownProps.handleTableChange,
    pageSize: ownProps.pageSize,
    rowFormatter: undefined
  };
}

const mapDispatchToProps = PagedListDispatchPropsActions(PagedListKeys.NAME_HISTORY);

export default connect(mapStateToProps, mapDispatchToProps)(NameHistory);
