import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { RegisterPropertiesActions, RegisterPropertiesActionTypes } from "./registerProperties-actions";
import { FetchRegisterPropertiesAction } from "./types/registerProperties-actions-types";
import { fetchRegisterProperties } from "../../api/registerProperties-api";
import { RegisterPropertyDto } from "common-types/registerPropertiesDto";

export function* watchFetchRegisterProperties(): SagaIterator {
  yield takeEvery(
    RegisterPropertiesActionTypes.FETCH_REGISTERPROPERTIES,
    function* (action: FetchRegisterPropertiesAction): any {
      try {
        const { entityId } = action;
        let registerProperties: RegisterPropertyDto = yield call(fetchRegisterProperties, entityId);
        yield put(RegisterPropertiesActions.fetchRegisterPropertiesSuccess(registerProperties));
      } catch (e) {
        yield put(RegisterPropertiesActions.fetchRegisterPropertiesFailure(e));
      }
    }
  );
}

export default function* RegisterPropertiesSaga(): SagaIterator {
  yield all([fork(watchFetchRegisterProperties)]);
}
