import { TreeItem } from "maples-kirby/dist/types/types/tree";

export const findNode = (nodeArray:  Array<TreeItem>, targetKey: string): any => {
  const findLoop = (node: TreeItem): boolean => {
    if (node.key.toString() === targetKey.toString()) {
      result = node;
      return true;
    }

    if (!node.children)
      return false;
    
    return node.children.length > 0 && node.children.some(findLoop);
  }

  let result;
  nodeArray.some(findLoop);
  return result;
}
